import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';

import {VALIDATE_FIELD_THUMBNAIL} from '../../../constants/article';
import {EVENT_MEDIA_PICKER} from '../../../constants/events';
import {MEDIA_TYPE_IMAGE} from '../../../constants/media';
import {getIsReadOnly} from '../../../selectors/article';
import ToolEventObserver from '../../../utils/editorjs/tools/toolEventObserver';
import useGlobalNotificationsError from '../../../utils/notifications/useGlobalNotificationsError';
import ExpandablePanel from '../expandablePanel';
import IconButton from '../iconButton';

const Thumbnail = ({url}) => {
  const isReadOnly = useSelector(getIsReadOnly);
  const {error, removeError} = useGlobalNotificationsError();

  const handleThumbnailClick = () => {
    removeError(VALIDATE_FIELD_THUMBNAIL);
    ToolEventObserver.trigger(EVENT_MEDIA_PICKER, {
      type: MEDIA_TYPE_IMAGE,
      showMeta: false,
    });
  };

  const btnProps = {disabled: isReadOnly, icon: 'image', inline: false, onClick: handleThumbnailClick};
  if (url) {
    btnProps.icon = 'background';
    btnProps.iconUrl = url;
  }
  const isThumbnailError = error && error[VALIDATE_FIELD_THUMBNAIL];

  return (
    <ExpandablePanel title="Article Thumbnail" isRequired={true} ariaLabel="Article Thumbnail" expanded={true}>
      <IconButton
        className={classnames(['button--basic', 'thumbnail-selector', {'thumbnail-selector-empty': !url}, {'has-error': isThumbnailError}])}
        {...btnProps}
      />
    </ExpandablePanel>
  );
};

export default Thumbnail;
