// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * An Instagram post will briefly render as a blockquote before loading the Instagram styles
 * Override the primary CSS styles and make it render closer to the final embedded post styles first
 */
blockquote.preload-media {
  display: block;
  width: 612px;
  font-family: 'Helvetica Neue', Roboto, 'Segoe UI', Calibri, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.6rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  margin: 1rem auto !important;
  padding: 1rem 1.6rem 1.6rem 1.6rem;
  opacity: 0.3;
}

blockquote.preload-media::before {
  display: none !important;
  content: none !important;
}

.instagram-media-rendered {
  width: 612px;
  margin: 1rem auto !important;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/instagram/index.css"],"names":[],"mappings":"AAAA;;;EAGE;AACF;EACE,cAAc;EACd,YAAY;EACZ,sEAAsE;EACtE,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,4BAA4B;EAC5B,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,UAAU;AACZ","sourcesContent":["/**\n * An Instagram post will briefly render as a blockquote before loading the Instagram styles\n * Override the primary CSS styles and make it render closer to the final embedded post styles first\n */\nblockquote.preload-media {\n  display: block;\n  width: 612px;\n  font-family: 'Helvetica Neue', Roboto, 'Segoe UI', Calibri, sans-serif;\n  font-size: 1.6rem;\n  font-weight: normal;\n  line-height: 1.6rem;\n  border: 0.1rem solid #ddd;\n  border-radius: 0.5rem;\n  margin: 1rem auto !important;\n  padding: 1rem 1.6rem 1.6rem 1.6rem;\n  opacity: 0.3;\n}\n\nblockquote.preload-media::before {\n  display: none !important;\n  content: none !important;\n}\n\n.instagram-media-rendered {\n  width: 612px;\n  margin: 1rem auto !important;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
