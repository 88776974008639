import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isEqual} from 'lodash';

import {VALIDATE_FIELD_TAGS} from '../../constants/article';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';

import Tags from '../atoms/tags';
import {BREAKING_NEWS_TAGS} from '../../constants/article';
import {PERSON, TAG_TYPES} from '../../constants/tags';
import {getTagsByIds} from '../../apis/tags/tagsAPI';

const OTHER = 'other';
const TAGS = [{type: [PERSON], text: 'People', key: 'person'}];

const TAG_STYLES = {
  control: (styles) => ({...styles, border: 'none'}),
  indicatorsContainer: (styles) => ({...styles, display: 'none'}),
  valueContainer: (styles) => ({...styles, padding: '0'}),
  multiValue: (styles) => ({
    ...styles,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    borderRadius: '3rem',
    backgroundColor: '#2a2a2a',
    color: '#fff',
  }),
  multiValueLabel: (styles) => ({...styles, color: '#fff'}),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#f82a2a',
      cursor: 'pointer',
    },
  }),
};

function VideoTags({onTagSelect, selected}) {
  const [tags, setTags] = useState([]);
  const {error, removeError} = useGlobalNotificationsError();
  const isTagsError = error && error[VALIDATE_FIELD_TAGS];

  useEffect(() => {
    const isLoaded = isEqual(
      selected,
      tags.map((tag) => tag.id.toString())
    );
    if (selected.length && !isLoaded) {
      // Load tags
      getTagsByIds(selected).then(setTags);
    }
  }, [selected]);

  const updateTags = (list) => {
    const listIds = list.map((tag) => tag.id);
    setTags(list);
    onTagSelect(listIds);
  };

  const handleInsert = (tags) => {
    removeError(VALIDATE_FIELD_TAGS);
    updateTags(tags);
  };

  const handleRemove = (type, left) => {
    const list = tags
      .filter((tag) => {
        return type === OTHER ? TAG_TYPES.includes(tag.type) : !type.includes(tag.type);
      })
      .concat(left);
    updateTags(list);
  };

  const getTag = ({type, key, text, isRequired}) => {
    const tagsList = tags.filter((tag) => {
      return type === OTHER ? !TAG_TYPES.includes(tag.type) && !BREAKING_NEWS_TAGS.includes(tag.id.toString()) : type.includes(tag.type);
    });

    return (
      <div key={key} className={classnames('row', `tags-search-${key}`, {'is-required': isRequired}, {'is-empty': !tagsList.length})}>
        <Tags
          value={tagsList}
          onSelect={(tags) => handleRemove(type, tags)}
          label={text}
          isRequired={isRequired}
          isSearchable={false}
          menuIsOpen={false}
          cacheOptions={false}
          placeholder="--"
          styles={TAG_STYLES}
        />
      </div>
    );
  };

  return (
    <>
      <div className="row tags-search">
        <Tags
          value={tags}
          tagFilter={'Person'}
          onSelect={handleInsert}
          isRequired={false}
          placeholder="Add people mentioned in the video…"
          controlShouldRenderValue={false}
          hasError={isTagsError}
          noOptionsMessage={() => 'Search tags by name'}>
          <div className="note">
            <em>Add tags for each person mentioned in the video to improve the SEO of your article</em>
          </div>
          <br />
        </Tags>
      </div>
      <div className={classnames('tag-groups', {'tag-groups-error': isTagsError})}>{TAGS.map(getTag)}</div>
    </>
  );
}

VideoTags.defaultProps = {
  selected: [],
  onTagSelect: () => {},
};

VideoTags.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number),
  onTagSelect: PropTypes.func.isRequired,
};

export default VideoTags;
