import {cloneDeep, isEqual, omit} from 'lodash';
import {isFirstBlockFeaturedMediaType} from '../../helpers/articleHelpers';

const metadataFields = [
  'author',
  'betTags',
  'hideBetTags',
  'hidePublishedAt',
  'image',
  'isBreakingNews',
  'primaryEditorGatekeeperId',
  'socialTitle',
  'tagList',
  'teaser',
];

/**
 * Cleans up unnecessary data from blocks for isChanged comparison
 * or may incorrectly flag block as changed
 *
 * @param {*} block
 * @returns bool
 */
export const sanitizeBlocksForComparison = (block) => {
  // strip external rel/target attributes
  if (block.type === 'paragraph' && block.data.text) {
    block.data.text = block.data.text.replace(' rel="noopener"', '');
  }

  // strip attributes from images that appear in saved data but not current
  if (block.type === 'image') {
    block.data = omit(block.data, ['caption', 'stretched']);
  }

  // even when the content is the same, IDs will be different so need to strip from comparison
  // The block's `data` may include verbatim content in the `__original__` field; when detcting
  // content changes, this verbatim content should not be considered either.
  return omit({...block, data: omit(block.data, '__original__')}, 'id');
};

/**
 * Determines if metadata has changed
 * @param {object} param0 object dataTemp and dataOriginal metadata properties
 * @param {object} param0.dataTemp object containing new metadata
 * @param {object} param0.dataOriginal object containing original metadata
 * @returns {boolean} boolean value indicating whether metadata has changed
 */
export function detectMetadataChange({dataTemp, dataOriginal}) {
  for (const field of metadataFields) {
    const currentValue = dataTemp[field];
    const originalValue = dataOriginal[field];
    const originalValueNoUndefined = originalValue ? originalValue : false; // treat undefined as false

    // not all fields are defined. So if you toggle a checkbox "on" / true will flag as changed
    // and if you click it "off" / false it will also flag as changed because false !== undefined
    if (originalValue !== currentValue && originalValueNoUndefined !== currentValue) return true;
  }
  return false;
}

/**
 * Determines if content blocks have changed
 * @param {array} originalBlocks the original blocks
 * @param {array} newBlocks the new blocks
 * @returns {boolean} boolean value indicating whether blocks have changed
 */
export function detectBlocksChange(originalBlocks, newBlocks) {
  // detect changes in content...
  // first check if there's a different number of editor body blocks
  // otherwise compare the original block content against the new block content
  if (originalBlocks.length !== newBlocks.length) return true;

  // Deep-cloning these values because executing sanitizeBlocksForComparison on the blocks of the
  // original objects was causing the editor to jump/re-render and the carat to lose its position/focus
  // upon initial edit of the body text in a saved article/slideshow
  const blockCompareOriginal = cloneDeep(originalBlocks).map(sanitizeBlocksForComparison);
  const blockCompareNew = cloneDeep(newBlocks).map(sanitizeBlocksForComparison);

  if (!isEqual(blockCompareOriginal, blockCompareNew)) return true;

  return false;
}

/**
 * Checks if a slideshow's current state is different from the saved article
 *
 * @param {*} original article saved data
 * @param {*} comparable article current data
 * @returns bool
 */
export function isSlideshowChanged(original, comparable) {
  if (original.title !== comparable.title) {
    // Slideshow article title field was changed
    return true;
  }

  if (original.elements.length !== comparable.elements.length) {
    // List of slideshow slides were changed
    return true;
  }

  for (let i = 0; i < original.elements.length; i++) {
    const originalSlide = original.elements[i];
    const comparableSlide = comparable.elements[i];
    if (originalSlide.title !== comparableSlide.title) {
      // Slide title information was changed
      return true;
    }

    if (detectBlocksChange(originalSlide.elements, comparableSlide.elements)) {
      return true;
    }
  }
  return false;
}

/**
 * Deletes the id from the featured media block
 *
 * @param {array} blocks The article or slide blocks
 */
export function removeIdFromFeaturedMedia(blocks) {
  if (!blocks.length) return;
  // deletes the featured media block id so there's no UI refresh when a user
  // begins typing in the body of the article (@see CTT-930 / CTT-959)
  if (isFirstBlockFeaturedMediaType(blocks)) delete blocks[0].id;
}
