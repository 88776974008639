import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {fetchDashboard} from '../../../actions/dashboard';
import {isAdmin, isAnyEditor, isStandardWriter} from '../../../helpers/articleHelpers';
import {getShowMineUrlParam} from '../../../helpers/dashboardHelpers';
import {selectFilter, selectShowEdited, selectShowMine, selectShowUnedited} from '../../../selectors/dashboard';

const DashboardFiltersMine = () => {
  const dispatch = useDispatch();
  const filteredBy = useSelector(selectFilter);
  const showMine = useSelector(selectShowMine);
  const showEdited = useSelector(selectShowEdited);
  const showUnedited = useSelector(selectShowUnedited);
  const location = useLocation();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(showMine);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // when the showMine param is in the url, we're going to let that supersede state
    const showMineUrl = getShowMineUrlParam();
    if (showMineUrl !== null) setIsChecked(showMineUrl);
    // typically we treat admins and editors the same, but adding an exception because we fetch articles differently for editors and admins
    // for editors "drafts", we already only fetch their own so checkbox should be checked and disabled (to prevent a useless reload)
    // for admins "drafts", we fetch everyone's drafts so need to be able to filter for their own
    if (filteredBy === 'draft' && isAnyEditor() && !isAdmin()) {
      setIsChecked(true);
      setIsDisabled(true);
    } else {
      setIsChecked(showMine);
      setIsDisabled(false);
    }
  }, [location, filteredBy, showMine]);

  const onFilterMineChanged = (checked) => {
    dispatch(fetchDashboard({offset: 0, showMine: checked, showEdited, showUnedited}));
  };

  const getOnlyMineControl = () => {
    return (
      <div className="control control-only-mine">
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            disabled={isDisabled}
            onChange={({target}) => {
              if (getShowMineUrlParam() !== null) navigate({search: ''}, {replace: true});
              setIsChecked(!isChecked);
              onFilterMineChanged(target.checked);
            }}
          />
          Show My Articles
        </label>
      </div>
    );
  };

  // "Standard Writer" roles only see their own articles in the dashboard article lists, so no need to show the "mine only" filter
  if (isStandardWriter()) return false;

  return <div className="atom dashboard-filters-mine">{getOnlyMineControl()}</div>;
};

export default DashboardFiltersMine;
