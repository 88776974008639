import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';

import {selectLoading} from '../../selectors/editorStats';

import Count from '../atoms/editorStats/countRoster';
import FilterDates from '../atoms/editorStats/filterDates';

const EditorStatsFiltersRoster = () => {
  const isLoading = useSelector(selectLoading);

  return (
    <section className={classnames('molecule', 'editor-stats-filters', {'is-loading': isLoading})}>
      <div className="filters-group">
        <Count />
      </div>
      <div className="filters-group">
        <FilterDates />
      </div>
    </section>
  );
};

export default EditorStatsFiltersRoster;
