import {
  EDITOR_TOOL_HEADER_LABEL,
  EDITOR_TOOL_LIST_ORDERED_LABEL,
  EDITOR_TOOL_LIST_UNORDERED_LABEL,
  EDITOR_TOOL_SUBHEADER_LABEL,
} from '../../constants/editor';

/**
 * Localize UI text of editor's core & plugins
 * @see //https://editorjs.io/i18n
 */
export const EDITORJS_I18N = {
  messages: {
    tools: {
      header: {
        'Heading 2': EDITOR_TOOL_HEADER_LABEL,
        'Heading 3': EDITOR_TOOL_SUBHEADER_LABEL,
      },
      list: {
        Ordered: EDITOR_TOOL_LIST_ORDERED_LABEL,
        Unordered: EDITOR_TOOL_LIST_UNORDERED_LABEL,
      },
    },
  },
};
