import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';

import {fetchSearch, setCursorLast, setSortDirection, setSortKey} from '../../actions/search';
import {getUserProfilesByIds} from '../../actions/users';
import {
  selectArticles,
  selectCount,
  selectCursorCurrent,
  selectError,
  selectHasMore,
  selectLimit,
  selectLoading,
  selectPage,
  selectSortKey,
  selectSortDirection,
} from '../../selectors/search';

import ArticleRow from '../atoms/search/articleRow';
import Loading from '../atoms/loading';
import Pagination from '../atoms/pagination';
import ColumnHeader from '../atoms/search/columnHeader';
import {SORT_KEY_AUTHOR, SORT_DIR_ASC, SEARCH_HEADERS, SORT_KEY_PUBLISH_DATE} from '../../constants/search';

const SearchTable = () => {
  const articles = useSelector(selectArticles);
  const count = useSelector(selectCount);
  const cursor = useSelector(selectCursorCurrent);
  const error = useSelector(selectError);
  const hasMore = useSelector(selectHasMore);
  const isLoading = useSelector(selectLoading);
  const isNoResults = !articles.length;
  const limit = useSelector(selectLimit);
  const pageNumber = useSelector(selectPage);
  const sortKey = useSelector(selectSortKey);
  const sortDirection = useSelector(selectSortDirection);

  const dispatch = useDispatch();

  const sortKeyRef = useRef();
  const pageNumberRef = useRef();

  useEffect(() => {
    dispatch(fetchSearch());
  }, []);

  useEffect(() => {
    if (!isNoResults) {
      // search payload only includes the primaryEditorId, so we need to grab all the editorIds and then fetch those profiles
      const primaryEditorIds = articles.map((article) => article?.primaryEditorId).filter((id) => id !== null);
      dispatch(getUserProfilesByIds(primaryEditorIds));
    }
  }, [articles]);

  useEffect(() => {
    const hasSortKeyChanged = sortKey !== sortKeyRef.current;
    const hasPageNumberChanged = pageNumber !== pageNumberRef.current;

    // when a user changes the sort key on any page, we only want to update the refs not fetch articles
    const isSamePageSortChange = hasSortKeyChanged && !hasPageNumberChanged;
    const shouldFetchArticles = !isSamePageSortChange && count; // only fetch when needed & not on init load (!search.count)
    if (shouldFetchArticles) dispatch(fetchSearch());

    // update refs
    sortKeyRef.current = sortKey;
    pageNumberRef.current = pageNumber;
  }, [sortKey, pageNumber]);

  const sort = ({data, sortKey}) => {
    if (!articles || !data) return null;
    if (!sortKey) return data;
    const sortedData = data.sort((a, b) => {
      // Sort by name if the sort key is SORT_KEY_AUTHOR (author is an object containing the author's name)
      if (sortKey === SORT_KEY_AUTHOR) return a[sortKey].name > b[sortKey].name ? 1 : -1;
      // Sort published dates - (dsc - recent to oldest) - (asc - oldest to recent)
      if (sortKey === SORT_KEY_PUBLISH_DATE) return a[sortKey] < b[sortKey] ? 1 : -1;
      // Sort article title - (dsc - 0-9/A-Z) - (asc - Z-A/9-0)
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    // Reverse the array if the sort direction is toggled
    if (sortDirection === SORT_DIR_ASC) return sortedData.reverse();
    return sortedData;
  };

  const sortedData = useCallback(() => sort({data: articles, sortKey}), [articles, sortKey, sortDirection]);

  const changeSort = (key) => {
    if (isLoading || isNoResults) return;
    if (sortKey === key) dispatch(setSortDirection());
    else dispatch(setSortKey(key));
  };

  const getTableHeaders = () => {
    const searchHeaders = Object.keys(SEARCH_HEADERS);
    return searchHeaders.map((key) => (
      <ColumnHeader
        columnKey={key}
        columnTitle={SEARCH_HEADERS[key]}
        onClick={() => changeSort(key)}
        key={key}
        sortKey={sortKey}
        sortDirection={sortDirection}
      />
    ));
  };

  const noRowsRenderer = () => {
    return (
      <div className="table-row is-empty" key="row0">
        <div className="table-data">
          <div>
            <strong>Sorry, we couldn't find any articles.</strong>
            <div>Try a new search by changing the search term, search type or the date range.</div>
          </div>
        </div>
      </div>
    );
  };

  const rowRenderer = () => {
    if (isLoading || !articles) return <Loading />;
    if (error || !articles?.length) return noRowsRenderer();

    return sortedData().map((article, index) => {
      return <ArticleRow key={article.id} index={index} />;
    });
  };

  const getPagination = () => {
    return (
      <Pagination
        count={count}
        currentPage={pageNumber}
        cursor={cursor}
        hasMore={hasMore}
        isLoading={isLoading}
        limit={limit}
        onPageChange={({cursor, page}) => {
          dispatch(setCursorLast(cursor));
          dispatch(fetchSearch(cursor, page));
        }}
      />
    );
  };

  return (
    <div className={classnames('molecule', 'search-table', {'is-loading': isLoading}, {'is-empty': isNoResults})}>
      {getPagination()}
      <div className="table-header">{getTableHeaders()}</div>
      <div className="table-body">{rowRenderer()}</div>
      {getPagination()}
    </div>
  );
};

export default SearchTable;
