import PropTypes from 'prop-types';
import {ARTICLE_ACTIVE_STATUSES} from '../constants/article';

export const articleDashboard = PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  articleUpdatedAt: PropTypes.string.isRequired,
  publishedAt: PropTypes.string,
  scheduledAt: PropTypes.string,
  status: PropTypes.oneOf(ARTICLE_ACTIVE_STATUSES).isRequired,
  renderStrategy: PropTypes.string.isRequired,
});

export const mediaImage = PropTypes.shape({
  imageId: PropTypes.string.isRequired,
  article: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  urlComp: PropTypes.string.isRequired,
  urlThumb: PropTypes.string.isRequired,
});

export const mediaSearchVideo = PropTypes.shape({
  brId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const mediaVideoNode = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnailUrl: PropTypes.string.isRequired,
  hls: PropTypes.string.isRequired,
  mp4: PropTypes.string.isRequired,
  analytics: PropTypes.shape({
    publishedAt: PropTypes.string,
  }),
});

export const articleTag = PropTypes.shape({
  href: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  permalink: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const articleFeedback = PropTypes.shape({
  updatedAt: PropTypes.string.isRequired,
  insertedAt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  editorGatekeeperId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  articleId: PropTypes.number.isRequired,
});

export const articleAuthor = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  photoUrl: PropTypes.string,
  title: PropTypes.string,
  twitterHandle: PropTypes.string,
});

export const article = PropTypes.shape({
  author: articleAuthor,
  elements: PropTypes.arrayOf(PropTypes.object),
  feedbacks: PropTypes.arrayOf(articleFeedback),
  hidePublishedAt: PropTypes.bool,
  id: PropTypes.number,
  isBreakingNews: PropTypes.bool,
  status: PropTypes.string,
  tagList: PropTypes.string,
  teaser: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
});
