import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {selectVideo} from '../../../actions/media';
import {selectIsVideoRetrieved, selectPickedVideo, selectVideos} from '../../../selectors/media';
import {MEDIA_SEARCH_NO_RESULTS, VIDEO_DATE_FORMAT} from '../../../constants/media';
import {formatDateWithTimezone} from '../../../helpers/dateHelpers';

import IconButton from '../iconButton';
import Video from '../video';

const VideoSelector = ({onZoom, zoomed}) => {
  const dispatch = useDispatch();
  const isRetrieved = useSelector(selectIsVideoRetrieved);
  const selected = useSelector(selectPickedVideo);
  const videos = useSelector(selectVideos);

  const renderVideoFinder = () => {
    if (!videos.length) return MEDIA_SEARCH_NO_RESULTS;

    return videos.map((video) => {
      const classes = classnames('media', {'is-active': selected && Number(selected.id) === Number(video.brId)});

      return (
        <button className={classes} key={video.brId} onClick={() => dispatch(selectVideo(video.brId))}>
          <div className="thumbnail" style={{backgroundImage: `url(${video.thumbnail})`}}>
            <div className="title">{video.title}</div>
          </div>
          <div className="meta">
            <div className="date">{formatDateWithTimezone(video.createdAt, VIDEO_DATE_FORMAT)}</div>
            <div className="description">{video.description || 'No description'}</div>
          </div>
        </button>
      );
    });
  };

  const renderZoomedVideo = () => {
    const videoProps = isRetrieved ? {id: 'retrieved'} : {};
    return (
      <div className="video-zoom">
        {!isRetrieved && (
          // Hide zoom action for retrieved video
          <IconButton icon="window-collapse" onClick={() => onZoom(false)} />
        )}
        <Video src={selected} {...videoProps} />
      </div>
    );
  };

  const isFinderActive = () => {
    // If were videos fetched show video finder
    return videos !== null && !zoomed;
  };

  const isVideoZoomActive = () => {
    // Show zoomed video block if it's required by props
    return selected !== null && zoomed;
  };

  return (
    <div className="atom media-selector media-selector-videos">
      {isFinderActive() && <div className="media-container media-videos">{renderVideoFinder()}</div>}
      {isVideoZoomActive() && renderZoomedVideo()}
    </div>
  );
};

VideoSelector.propTypes = {
  onZoom: PropTypes.func.isRequired,
  zoomed: PropTypes.bool.isRequired,
};

export default VideoSelector;
