import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select/async';
import debounce from 'debounce-promise';

import {searchForTags} from '../../apis/tags/tagsAPI';
import {NO_ADS_TAG} from '../../constants/article';
import {getIsReadOnly} from '../../selectors/article';
import {articleTag} from '../../utils/types';

const MIN_QUERY_LENGTH = 3;

function Tags(props) {
  const {children, hasError, hideBetTags, isRequired, label, onSelect, toggleHideBetTags, value} = props;
  const isReadOnly = useSelector(getIsReadOnly);
  const handleChange = (list, event) => {
    let tags = list;
    const listContainsNoAdsTag = list.some(({id}) => id.toString() === NO_ADS_TAG);
    const valueContainsNoAdsTag = value.some(({id}) => id.toString() === NO_ADS_TAG);

    if (listContainsNoAdsTag !== valueContainsNoAdsTag) {
      toggleHideBetTags(!hideBetTags);
    }

    if (list === null && event.action === 'remove-value') {
      tags = value.filter(({id}) => id !== event.removedValue.id);
    }
    onSelect(tags);
  };

  const handleSearch = debounce((query, callback) => {
    if (query.length < MIN_QUERY_LENGTH) return new Promise((resolve) => resolve([])).then(callback);
    return searchForTags(query)
      .then((tags) => (props.tagFilter ? tags.filter((tag) => tag.type === props.tagFilter) : tags))
      .then(callback)
      .catch((error) => callback(null, error));
  }, 200);

  // Prevent overwriting select display utils functions
  // eslint-disable-next-line no-unused-vars
  const {onChange, loadOptions, getOptionLabel, getOptionValue, ...selectProps} = props;

  return (
    <div className={classnames('atom', 'tags', {'is-readonly': isReadOnly})}>
      <h4>
        {label}
        {isRequired && (
          <span className="required">
            <sup>*</sup> (Required)
          </span>
        )}
      </h4>
      <Select
        backspaceRemovesValue={false}
        isMulti={true}
        isClearable={false}
        isDisabled={isReadOnly}
        value={value}
        onChange={handleChange}
        loadOptions={handleSearch}
        cacheOptions={false}
        getOptionLabel={(tag) => `${tag.name} — ${tag.site && tag.site !== 'none' ? tag.site.replace(/_/g, ' ') : tag.type}`}
        getOptionValue={(tag) => tag.id}
        className={classnames({'has-error': hasError})}
        {...selectProps}
      />
      {children}
    </div>
  );
}

Tags.defaultProps = {
  label: '',
  isRequired: false,
  value: [],
  tagFilter: '',
  hasError: false,
  children: null,
  onBetTagSelect: () => {},
};

Tags.propTypes = {
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.arrayOf(articleTag),
  tagFilter: PropTypes.string,
  hasError: PropTypes.bool,
  children: PropTypes.node,
};

export default Tags;
