import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Loading({appearance}) {
  const classes = classnames('atom', 'loading', {[`loading--${appearance}`]: appearance});
  return <div className={classes}>Loading…</div>;
}

Loading.defaultProps = {
  appearance: 'default',
};

Loading.propTypes = {
  appearance: PropTypes.oneOf(['basic', 'default']),
};
