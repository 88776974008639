import {unescape} from 'lodash';
import {decode} from 'html-entities';
import {MAXLENGTH, QUOTES, TAGS_TO_ESCAPE, WORDS_UPPERCASE, WORDS_MIXEDCASE, WORDS_LOWERCASE} from '../constants/text';

const REGEX_SPACES = /[\u0009\u000A-\u000D\u0020\u0085\u00A0\u180E\u2000-\u200D\u2028-\u2029\u202F\u205F\u2060\u3000\uFEFF]+/g; // eslint-disable-line no-control-regex

/**
 * Truncate the text to a max-length
 *
 * @param {text} Text string to truncate
 * @param {max} Max length of the string
 * @param {wordBoundary} Truncate at the end of a word (not in the middle)
 * @return {string} Truncated string with ellipsis character (if needed)
 */
export function truncateText(text, max = MAXLENGTH, wordBoundary = true) {
  if (text.length <= max) return text;

  const ellipsis = '…';
  const maxlength = max - ellipsis.length;
  const truncateAt = wordBoundary ? text.substring(0, maxlength).lastIndexOf(' ') : maxlength;
  return `${text.substring(0, truncateAt)}${ellipsis}`;
}

/**
 * Escape characters with HTML entity encoding to prevent XSS injection
 * @param {string} text string which may contain some XSS injection
 * @returns {string} sanitized version of the initial string
 */
export function sanitizeText(string) {
  return string.replace(/&|<|>|"|'|\//g, (tag) => TAGS_TO_ESCAPE[tag] || tag);
}

/**
 * Capitalize a string
 *
 * @param {str} string to update
 * @return {string} same string with auto-capitalization
 */
export function capitalize(originalString) {
  let s = originalString.toLowerCase();
  s = s.split(' ');

  const unQuote = (word, isLeftQuoted, isRightQuoted) => {
    return word.slice(isLeftQuoted ? 1 : 0, isRightQuoted ? word.length - 1 : word.length);
  };

  s.forEach((piece, index) => {
    const leftQuoted = QUOTES.includes(piece.charAt(0));
    const rightQuoted = QUOTES.includes(piece.charAt(piece.length - 1));

    // remove quotes
    const word = unQuote(piece, leftQuoted, rightQuoted);
    const previousWord = (index > 0 && unQuote(s[index - 1], leftQuoted, rightQuoted).toLowerCase()) || null;

    // update capitalization
    if (index > 0 && WORDS_LOWERCASE.includes(word) && !WORDS_MIXEDCASE.map((w) => w.toLowerCase()).includes(`${previousWord} ${word}`)) {
      s[index] = word;
    } else if (WORDS_UPPERCASE.includes(word.toUpperCase())) {
      s[index] = word.toUpperCase();
    } else if (WORDS_MIXEDCASE.map((w) => w.toLowerCase()).includes(word)) {
      s[index] = WORDS_MIXEDCASE.filter((w) => w.toLowerCase() === word).toString();
    } else {
      s[index] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    // add left/right quotes
    if (leftQuoted) {
      s[index] = piece.charAt(0) + s[index];
    }
    if (rightQuoted) {
      s[index] += piece.charAt(piece.length - 1);
    }
  });

  return s.join(' ');
}

/**
 * Add rel/target attributes to external links
 *
 * @param {html} html to check for external links
 * @return {html} same html with rel/target attributes added to <a> that are external links
 */
export function addAttrToExternalLinks(html = '') {
  const str = `(<a\s*(?!.*rel=)[^>]*)(href="https?://)((?!(?:([^/]+.)?bleacherreport.com))[^"]+)"((?!.*rel=)[^>]*)(?:[^>]*)>`; // eslint-disable-line
  return html.replace(new RegExp(str, 'igm'), '$1$2$3"$4 rel="noopener" target="_blank">');
}

/**
 * Normalize multiple space characters to the "normal" space (\u0020).
 *
 * @param {string} text to normalize
 * @return {string} normalized text
 */
export function normalizeSpaces(string) {
  if (string === null || typeof string === 'undefined') return null;
  return string.replace(REGEX_SPACES, ' ');
}

/**
 * Remove &nbsp; from a string
 *
 * @param {string} string from remove &nbsp;
 * @return {string} text after &nbsp; removed
 */
export function removeNonBreakingSpaces(string) {
  return string.replace(/&nbsp;/g, '');
}

/**
 * Replace &nbsp; with ' ' and trim
 *
 * @param {string} string from replace &nbsp; with ' ' and trim
 * @return {string} text after &nbsp; replaced with ' ' and trimmed
 */
export function replaceNonBreakingSpacesAndTrim(string) {
  return string
    .replace(/&nbsp;/g, ' ')
    .replace(/ +/g, ' ')
    .trim();
}

/**
 * Checks if a string contains incorrect quotation marks
 *
 * @param {string} string to check for incorrect quotation marks
 * @return {boolean} true if string contains incorrect quotation marks
 */
export function containsIncorrectQuotations(string) {
  if (!string) return false;
  if (typeof string !== 'string') return false;
  const regex = /[\u201c\u201d\u2018\u2019]/gu;
  return regex.test(string);
}

/**
 * Replace incorrect quotation marks (u201c & u201d) to match typed quotation marks inside editor
 *
 * @param {string} string from replace incorrect quotations with typed quotations
 * @return {string|null} text after incorrect quotations are replaced with typed quotations
 */
export function replaceQuotations(string) {
  if (!string) return null;
  if (typeof string !== 'string') return null;
  if (!containsIncorrectQuotations(string)) return string;
  return string.replace(/[\u201c\u201d]/gu, '"').replace(/[\u2018\u2019]/gu, "'");
}

/**
 * Remove HTML tags from a string
 *
 * @param {string} string from remove html tags
 * @return {string} text after html tags removed
 */
export function removeHtmlTags(string) {
  return string.replace(/(<([^>]+)>)/gi, '');
}

/**
 * Decode html entities from a string
 *
 * @param {string} text original string
 * @return {string} text after decoded
 */
export function decodeString(string) {
  return decode(unescape(string));
}

/**
 * Remove empty html tags from a string
 * @param {string} string the original string
 * @returns {string} the string after empty html tags removed
 */
export const removeEmptyHtmlTags = (string) => {
  if (!string) return null;
  if (typeof string !== 'string') return null;

  return string
    .replace(/(<([^</]+)><(\/[^>]+)>)/gi, '')
    .replace(/ +/g, ' ')
    .trim();
};

/**
 * Remove self-closing html tags from a string
 * @param {string} string the original string
 * @returns {string} the string after self-closing html tags removed
 */
export const removeSelfClosingHtmlTags = (string) => {
  if (!string) return null;
  if (typeof string !== 'string') return null;

  return string
    .replace(/(<([^<]+)\/>)/gi, '')
    .replace(/ +/g, ' ')
    .trim();
};

/**
 * Remove specific html tags from a string
 * @param {string} string the original string
 * @param {string[]} tags an array of tag names to remove
 * @returns {string} the string after specified html tags removed
 */
export const removeSpecificHtmlTags = (string, tags = []) => {
  if (!string) return null;
  if (typeof string !== 'string') return null;
  // Preserve version history diff span tags
  if (string.includes('ce-diff')) return string;

  let newString = string;

  for (const tag of tags) {
    const openingTag = new RegExp(`<${tag}[^>]*>`, 'ig');
    const closingTag = new RegExp(`</${tag}>`, 'ig');
    newString = newString.replace(openingTag, '').replace(closingTag, '');
  }

  return newString.trim();
};

/**
 * Remove specific html attributes from a string
 * @param {string} string the original string
 * @param {string[]} attributes an array of attribute names to remove
 * @returns the string after specified html atributes removed
 */
export const removeSpecificHtmlAttributes = (string, attributes = []) => {
  if (!string) return null;
  if (typeof string !== 'string') return null;

  let newString = string;

  for (const attribute of attributes) {
    let regex = new RegExp(`\\b${attribute}=("[^"]*"|'[^']*')`, 'ig');

    // Preserve version history diff class attributes
    if (attribute === 'class') regex = new RegExp(`\\b${attribute}=("(?!ce-diff)[^"]*"|'(?!ce-diff)[^']*')`, 'ig');

    newString = newString.replace(regex, '').replace(/ (?=>)/g, '');
  }

  return newString.trim();
};

/**
 * Replace searchStrings with replacementString (generic replace)
 * @param {string} string the original string
 * @param {string[]} searchStrings an array of text strings to replace
 * @param {string} replacementString the replacement text
 * @returns {string} the string after searchStrings replaced with searchString
 */
export const replaceWith = (string, searchStrings = [], replacementString = '') => {
  if (!string) return null;
  if (typeof string !== 'string') return null;

  let newString = string;

  for (const searchString of searchStrings) {
    const regex = new RegExp(searchString, 'ig');
    newString = newString.replace(regex, replacementString);
  }

  return newString;
};

/**
 * Sanitize older article content
 * @param {string} string the original string
 * @returns {string} the string after it has been sanitized
 */
export const sanitizeOlderArticleContent = (string) => {
  if (!string) return null;
  if (typeof string !== 'string') return null;

  // We replace specific html entities here because we don't want to decode/unescape &amp; in urls
  let sanitizedText = removeSpecificHtmlTags(string, ['span']);
  sanitizedText = removeEmptyHtmlTags(sanitizedText);
  sanitizedText = removeSelfClosingHtmlTags(sanitizedText);
  sanitizedText = removeSpecificHtmlAttributes(sanitizedText, ['rel', 'id', 'class']);
  sanitizedText = replaceWith(sanitizedText, ['&#39;', '&apos;'], "'");
  sanitizedText = replaceWith(sanitizedText, ['&quot;'], '"');
  sanitizedText = normalizeSpaces(sanitizedText);

  return sanitizedText;
};
