// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-container-video {
  text-align: center;
}

.media-container-video i {
  color: #666;
  font-size: 1rem;
  font-style: italic;
}

.video {
  position: relative;
  width: 100%;
  max-height: 366px;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.video.--placeholder {
  cursor: pointer;
}

.video.--error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 366px;
  padding-bottom: 0;
  background: #eee;
  color: #ccc;
}

.video-placeholder__image {
  position: absolute;
  top: 0;
  left: 0;
}

.video-placeholder__image,
.video-placeholder__play {
  display: none;
}

.video.--placeholder .video-placeholder__image,
.video.--placeholder .video-placeholder__play {
  display: block;
}

.video-placeholder__play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/video/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".media-container-video {\n  text-align: center;\n}\n\n.media-container-video i {\n  color: #666;\n  font-size: 1rem;\n  font-style: italic;\n}\n\n.video {\n  position: relative;\n  width: 100%;\n  max-height: 366px;\n  padding-bottom: 56.25%;\n  overflow: hidden;\n}\n\n.video.--placeholder {\n  cursor: pointer;\n}\n\n.video.--error {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 366px;\n  padding-bottom: 0;\n  background: #eee;\n  color: #ccc;\n}\n\n.video-placeholder__image {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.video-placeholder__image,\n.video-placeholder__play {\n  display: none;\n}\n\n.video.--placeholder .video-placeholder__image,\n.video.--placeholder .video-placeholder__play {\n  display: block;\n}\n\n.video-placeholder__play {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 80px;\n  height: 80px;\n  margin-left: -40px;\n  margin-top: -40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
