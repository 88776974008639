const ALLOWED_CHILD_NODES = ['A', 'SPAN', 'EM', 'LI'];
const TAGS_TO_PARSE = ['P', 'UL', 'OL'];

const getParagraphBlock = (content) => ({
  type: 'paragraph',
  data: {
    text: Array.isArray(content) ? content.join('') : content,
  },
});

const getListBlock = (type, items) => ({
  type: type.toLowerCase(),
  data: {items},
});

function getChildContent(node) {
  switch (node.nodeName) {
    case 'A': {
      const href = node.attributes.getNamedItem('href').value;
      return `<a href="${href}">${node.textContent}</a>`;
    }
    case 'SPAN': {
      if (node.hasChildNodes()) {
        return Array.from(node.childNodes)
          .map(getChildContent)
          .filter((node) => node.trim().length)
          .join('');
      }
      return node.textContent;
    }
    default: {
      // Handles text node, EM, LI
      return node.textContent;
    }
  }
}

function getContent(node) {
  if (!node.hasChildNodes()) return node.innerText;

  // Filter list of children
  const childList = Array.from(node.childNodes).filter((childNode) => {
    if (childNode.nodeType === Node.TEXT_NODE) return true;
    return ALLOWED_CHILD_NODES.includes(childNode.nodeName);
  });

  return childList.map(getChildContent).filter((node) => node.trim().length);
}

/**
 * Given an object from the Articles backend representing an HTML `<div>` tag with text & other content,
 * create zero-or-more JS objects representing content blocks which EditorJS will understand.
 *
 * returns {array<object>} potentially-empty
 */
export function getContentFromDivBlock(block) {
  if (block.length && block[0] !== '<') {
    // Plain text received
    // TODO remove this distinction, do not ever assume we have only plain text
    return [getParagraphBlock(block)];
  }

  const nodes = new DOMParser().parseFromString(block, 'text/html').getElementsByTagName('*');
  if (!nodes) {
    // There are no tags to parse
    return [];
  }

  const blocks = Array.from(nodes)
    .filter((node) => TAGS_TO_PARSE.includes(node.nodeName))
    .map((node) => {
      const content = getContent(node);
      if (!content.length) return null;

      if (['UL', 'OL'].includes(node.nodeName)) {
        // Return editor.js list block
        return getListBlock(node.nodeName, content);
      }

      // Return paragraph block
      return getParagraphBlock(content);
    });

  return blocks.filter((block) => block !== null);
}
