import {createAction} from '@reduxjs/toolkit';

import * as ArticlesAPI from '../apis/articles/articlesAPI';
import {getVideo} from '../apis/media/mediaAPI';
import * as types from '../constants/action-types';
import {STATUS_PUBLISHED} from '../constants/article';
import {CTT_USER} from '../constants/user';

import {getArticleDescription} from '../helpers/articleContent';
import {isArticleReadOnlyForWriter} from '../helpers/articleHelpers';
import {formatDateForServer} from '../helpers/dateHelpers';
import {removeNonBreakingSpaces} from '../helpers/textHelpers';
import {getStorageData} from '../helpers/authHelpers';
import {isCurrentUserTheAuthor} from '../helpers/softlockHelpers';
import {editorJsDataToArticlesFormat, editorJsDataToSlideshowFormat} from '../reducers/helpers/transformContent';

import {clearDashboard} from './dashboard';

// init
export const initStandardArticle = createAction(types.ARTICLE_STANDARD_INIT);
export const initSlideshowArticle = createAction(types.ARTICLE_SLIDESHOW_INIT);

// article
export const receiveArticle = createAction(types.ARTICLE_RECEIVE);
export const setFetching = createAction(types.SET_FETCHING);
export const clearArticle = createAction(types.ARTICLE_CLEAR);
export const articleCreated = createAction(types.ARTICLE_CREATED);
export const articleDeleted = createAction(types.ARTICLE_DELETED);
export const articleUpdated = createAction(types.ARTICLE_UPDATED);
export const updateArticleData = createAction(types.ARTICLE_UPDATE_DATA);
export const updateArticleReadOnly = createAction(types.ARTICLE_UPDATE_READONLY);
export const updateArticleStatus = createAction(types.ARTICLE_UPDATE_STATUS);
export const feedbackAdded = createAction(types.ARTICLE_ADD_FEEDBACK);
export const setAction = createAction(types.SET_ACTION);
export const clearAction = createAction(types.CLEAR_ACTION);
export const setMedia = createAction(types.ARTICLE_SET_MEDIA);
export const setIdleTimer = createAction(types.SET_IDLETIMER);
export const setEditorPrimary = createAction(types.ARTICLE_EDITOR_PRIMARY);
export const setScheduled = createAction(types.ARTICLE_SCHEDULED_AT);

// softlock
export const bypassArticleLock = createAction(types.ARTICLE_LOCK_BYPASS);
export const bypassArticleLockUpdateStatus = createAction(types.ARTICLE_LOCK_BYPASS_UPDATE_STATUS);
export const releaseArticleLock = createAction(types.ARTICLE_LOCK_RELEASE);
export const setArticleLock = createAction(types.ARTICLE_LOCK_SET);

// slideshows
export const updateSlideshowData = createAction(types.ARTICLE_SLIDESHOW_UPDATE_DATA);
export const addSlideshowSlide = createAction(types.ARTICLE_SLIDESHOW_ADD_SLIDES);
export const removeSlideshowSlide = createAction(types.ARTICLE_SLIDESHOW_REMOVE_SLIDES);
export const reorderSlideshowSlides = createAction(types.ARTICLE_SLIDESHOW_REORDER_SLIDES);
export const selectSlide = createAction(types.ARTICLE_SLIDESHOW_SELECT_SLIDE);
export const setSlideMedia = createAction(types.ARTICLE_SLIDESHOW_SET_MEDIA);

function setArticleEditor(articleId) {
  return ArticlesAPI.saveArticle(articleId, {
    editedByGatekeeperId: getStorageData(CTT_USER),
  });
}

function unsetArticleEditor(articleId) {
  return ArticlesAPI.saveArticle(articleId, {
    editedByGatekeeperId: null,
  });
}

function bypassArticleEditor(articleId) {
  return ArticlesAPI.saveArticle(articleId, {
    bypass_lock: true,
    editedByGatekeeperId: getStorageData(CTT_USER),
  });
}

function bypassArticleEditorUpdateStatus(articleId, status) {
  return ArticlesAPI.saveArticle(articleId, {
    bypass_lock: true,
    editedByGatekeeperId: getStorageData(CTT_USER),
    status,
  });
}

function updateCurrentEditors(articleId, add = true) {
  return ArticlesAPI.saveArticle(articleId, {
    add_user_to_current_editors_list: add,
  });
}

export const setLock = (articleId) => {
  return async (dispatch) => {
    await setArticleEditor(articleId);
    return dispatch(setArticleLock({editedByGatekeeperId: getStorageData(CTT_USER)}));
  };
};

export const releaseLock = () => {
  return async (dispatch) => {
    return dispatch(releaseArticleLock());
  };
};

export const bypassLock = (articleId) => {
  return async (dispatch) => {
    await bypassArticleEditor(articleId);
    return dispatch(bypassArticleLock({editedByGatekeeperId: getStorageData(CTT_USER)}));
  };
};

export const bypassLockUpdateStatus = (articleId, status) => {
  return async (dispatch) => {
    await bypassArticleEditorUpdateStatus(articleId, status);
    return dispatch(bypassArticleLockUpdateStatus({editedByGatekeeperId: getStorageData(CTT_USER), status}));
  };
};

export const fetchNewArticle = (isSlideshow) => {
  return async (dispatch) => {
    dispatch(setAction({}));
    return dispatch(isSlideshow ? initSlideshowArticle() : initStandardArticle());
  };
};

export const fetchArticle = ({id, isSlideshow = false, activeSlide = 0, readonly = false}) => {
  // standard articles update the softlock by default, except when we explicitly know this article is readonly
  // slideshows don't use the softlock
  const updateLock = !isSlideshow && !readonly;

  return async (dispatch, getState) => {
    const {fetching, data: oldArticleData} = getState().article;

    if (fetching) return Promise.resolve();

    await dispatch(setFetching(true));

    // for slideshows, add the user to the list of current editors when they open the article
    if (isSlideshow) await updateCurrentEditors(id, true);

    if (updateLock && oldArticleData) {
      const oldArticleId = oldArticleData.id || oldArticleData.breportId;
      // "unlock" old article before fetching and locking the new one
      await unsetArticleEditor(oldArticleId);
    }

    await dispatch(setAction({}));

    const article = await ArticlesAPI.getArticle(id);

    await dispatch(
      receiveArticle({
        article,
        error: !!article.error,
        activeSlide,
      })
    );

    // set "Soft Lock" in the current user's name, unless both the current user is the author
    // and the article is in "Read-Only" mode (e.g. because editors are reviewing it)
    if (updateLock && (!isCurrentUserTheAuthor(article) || !isArticleReadOnlyForWriter(article.status))) {
      await bypassArticleEditor(id);
      await dispatch(
        setArticleLock({
          editedByGatekeeperId: getStorageData(CTT_USER),
        })
      );
    }

    return dispatch(setFetching(false));
  };
};

/**
 * Save article to backend
 * @param {*} messageSuccess toast confirmation message that will appear to user in article header
 * @param {*} inBackground when save is in the background, we won't do things like reset the idle timer or clear the dashboard cache. ex./ delete a slideshow slide from filmstrip
 */
export const saveArticle = (messageSuccess = 'Save', inBackground = false) => {
  return async (dispatch, getState) => {
    const {
      article: {data, isSlideshow},
    } = getState();

    // intentionally not sending lastEditedAt, updatedAt, and permalink; as they are added by the Articles backend when receiving this data
    // also not including 'status' key as can inadvertently revert published article to prior status when multiple editors (CTT-410)
    // also not sending `editedByGatekeeperId` as it is set to `null` here, but the Front-End has already sent a separate request to set it to the current user's GK ID
    const {
      ampUrl, // eslint-disable-line no-unused-vars
      breportId,
      currentEditorsGkIds, // eslint-disable-line no-unused-vars
      editedByGatekeeperId, // eslint-disable-line no-unused-vars
      elements,
      feedbacks, // eslint-disable-line no-unused-vars
      id,
      lastEditedAt, // eslint-disable-line no-unused-vars
      updatedAt, // eslint-disable-line no-unused-vars
      permalink, // eslint-disable-line no-unused-vars
      status, // eslint-disable-line no-unused-vars
      teaser,
      tagList,
      url, // eslint-disable-line no-unused-vars
      ...rest
    } = data;

    // tagsList is a string of comma-separated integers;
    // keep sorted into ascending numerical order
    const tags = tagList
      ? tagList
          .split(',') // eslint-disable-line indent
          .sort((a, b) => (a === b ? 0 : Number(a) > Number(b) ? 1 : -1)) // eslint-disable-line indent
          .join(',') // eslint-disable-line indent
      : '';

    const description = teaser ? removeNonBreakingSpaces(teaser) : getArticleDescription(data.elements);
    const payload = {
      ...rest,
      elements: isSlideshow ? editorJsDataToSlideshowFormat(elements) : editorJsDataToArticlesFormat(elements),
      teaser: description,
      tagList: tags,
      renderStrategy: isSlideshow ? 'slideshow' : 'article',
      socialTitle: rest.socialTitle || rest.title, // Take title for social title field if it left untouched Or empty
    };

    await dispatch(setAction({status: messageSuccess, complete: false}));
    if (!inBackground) await dispatch(setIdleTimer(Date.now()));

    // if breportId (legacy article id field) OR id field is missing/empty it means that it's a new article
    const articleId = breportId || id;
    if (articleId) {
      // Update existing article content
      const res = await ArticlesAPI.saveArticle(articleId, payload);
      const error = res && res.error ? res.error : false;
      await dispatch(articleUpdated({articleId, data: payload}));
      await dispatch(setAction({status: messageSuccess, complete: true, error}));
    } else {
      // creates new article or slideshow
      const article = isSlideshow ? await ArticlesAPI.createSlideshow(payload) : await ArticlesAPI.createArticle(payload);
      // When the backend creates an article, it sets the author_gatekeeper_id to the value pulled from the "sub"
      // property of the JWT (the gatekeeper id of the currently logged in user). We make an ArticlesAPI.saveMetadata
      // call here if payload.authorGatekeeperId does not match the currently logged in user's gatekeeper id, because
      //  this indicates that the user creating the article wants a different user to be set as the article author
      // (i.e. - they used the author swap feature before saving - see: CTT-977)
      const {authorGatekeeperId: authorGatekeeperIdFromPayload} = payload;
      if (article.authorGatekeeperId !== authorGatekeeperIdFromPayload) {
        await ArticlesAPI.saveMetadata(article.id, {author_gatekeeper_id: authorGatekeeperIdFromPayload});
      }
      await dispatch(articleCreated({id: article.id}));
    }

    if (!inBackground) return dispatch(clearDashboard());
    return null;
  };
};

export const saveSlide = (isNewSlideOnPublishedArticle = false) => {
  return async (dispatch, getState) => {
    const {
      article: {
        activeSlide,
        data: {elements, id, status},
      },
    } = getState();

    const data = {
      content_type: 'slide',
      elements: editorJsDataToSlideshowFormat(elements)[activeSlide].elements,
      id: elements[activeSlide].id,
      slide_number: activeSlide,
      title: elements[activeSlide].title,
      user_gk_uuid: getStorageData(CTT_USER),
    };

    // update existing slide content & display visual cue to user
    // when this is a new slide on a published slideshow we need to use the addSlides endpoint
    // if you pass a slide ID that doesn't exist to the saveSlide endpoint it will return a 404
    const res = isNewSlideOnPublishedArticle ? await ArticlesAPI.addSlides(id, [data]) : await ArticlesAPI.saveSlide(id, data);
    const error = res?.error ? res.error : false;
    const message = status === STATUS_PUBLISHED ? 'publish slide' : 'save slide';
    await dispatch(articleUpdated({slideId: id, data}));
    await dispatch(setAction({status: message, complete: true, error}));
  };
};

export const saveMetadata = () => {
  return async (dispatch, getState) => {
    const {
      article: {
        data: {author, authorGatekeeperId, betTags, hideBetTags, hidePublishedAt, image, isBreakingNews, socialTitle, status, tagList, teaser, id},
      },
    } = getState();

    // include all article metadata properties because we don't know which ones changed
    const data = {
      author,
      author_gatekeeper_id: authorGatekeeperId,
      image,
      is_breaking_news: isBreakingNews,
      social_title: socialTitle,
      tag_list: tagList,
      teaser,
    };

    // avoid passing undefinted for uninitialized properties
    if (typeof betTags !== 'undefined') data.bet_tags = betTags;
    if (typeof hideBetTags !== 'undefined') data.hide_bet_tags = hideBetTags;
    if (typeof hidePublishedAt !== 'undefined') data.hide_published_at = hidePublishedAt;

    // update slideshow metadata & display visual cue to user
    const res = await ArticlesAPI.saveMetadata(id, data);
    const error = res?.error ? res.error : false;
    const message = status === STATUS_PUBLISHED ? 'publish metadata' : 'save metadata';
    await dispatch(articleUpdated({slideshowId: id, metadata: data}));
    await dispatch(setAction({status: message, complete: true, error}));
  };
};

/**
 * Schedule an article for publish
 *
 * note: must use the schedule article endpoint and not save article endpoint to schedule an article
 * @see https://statmilk.atlassian.net/browse/CTT-1228?focusedCommentId=167774
 *
 * @param {number} id article id to be scheduled
 * @param {string} scheduledAt the future datetimestamp when the article should be scheduled
 */
export const scheduleArticle = (id, scheduledAt) => {
  return async (dispatch) => {
    await ArticlesAPI.scheduleArticle(id, formatDateForServer(scheduledAt));
    dispatch(setAction({status: 'scheduled for publish', complete: true}));
    dispatch(clearDashboard());
  };
};

/**
 * Unschedule an article for publish
 *
 * note: must use the schedule article endpoint and not save article endpoint to unschedule an article
 * @see https://statmilk.atlassian.net/browse/CTT-1228?focusedCommentId=167774
 *
 * @param {number} id article id to be unscheduled
 */
export const unscheduleArticle = (id) => {
  return async (dispatch) => {
    await ArticlesAPI.unscheduleArticle(id);
    dispatch(setAction({status: 'unscheduled for publish', complete: true}));
    dispatch(setScheduled({scheduledAt: null}));
  };
};

export const deleteArticle = (id) => {
  return async (dispatch) => {
    await ArticlesAPI.deleteArticle(id);
    dispatch(articleDeleted({id}));
    dispatch(clearDashboard());
  };
};

export const unsetArticle = () => {
  return async (dispatch, getState) => {
    const {
      article: {data},
    } = getState();

    if (data) {
      // If there's no breportId OR id, then it is a new article and there is no need to update the article editors
      const articleId = data.breportId || data.id;

      if (articleId) {
        // "unlock" article for other users (only if the current user owns the softlock)
        // refetch the latest article data, otherwise possible to clear another user's lock after they've stolen
        const articleCurrent = await ArticlesAPI.getArticle(articleId);
        if (!articleCurrent.editedByGatekeeperId || articleCurrent.editedByGatekeeperId === getStorageData(CTT_USER)) {
          await unsetArticleEditor(articleId);
        }

        // for slideshows, remove the current user from the current editor list
        if (articleCurrent.renderStrategy === 'slideshow') await updateCurrentEditors(articleId, false);
      }

      return dispatch(clearArticle());
    }
    return Promise.resolve();
  };
};

export const updateArticle = (properties) => {
  return async (dispatch) => dispatch(updateArticleData({properties}));
};

export const updateReadOnly = (isReadOnly) => {
  return async (dispatch) => dispatch(updateArticleReadOnly({isReadOnly}));
};

export const updateStatus = (status) => {
  return async (dispatch, getState) => {
    const {
      article: {data},
    } = getState();

    const id = data.id || data.breportId;
    await dispatch(setAction({status, complete: false, error: false}));
    const res = await ArticlesAPI.saveArticle(id, {status});
    const error = res && res.error ? res.error : false;
    await dispatch(updateArticleStatus({status}));
    await dispatch(setAction({status, complete: true, error}));
    return dispatch(clearDashboard());
  };
};

export const addFeedback = (id, message, notify) => {
  return async (dispatch, getState) => {
    const {fetching} = getState().article;

    if (fetching) {
      return Promise.resolve();
    }

    dispatch(setFetching(true));
    const notifyAll = notify ? notify : false;
    const res = await ArticlesAPI.addArticleFeedback(id, message, notifyAll);
    await dispatch(feedbackAdded({feedback: res}));
    await dispatch(setFetching(false));
    return dispatch(clearDashboard());
  };
};

export const setActiveSlideContent = (properties, articleProperties) => {
  return (dispatch, getState) => {
    const {activeSlide} = getState().article;
    return dispatch(updateSlideshowData({blockIndex: activeSlide, properties, articleProperties}));
  };
};

export const addSlides = (count) => {
  return async (dispatch, getState) => {
    const {
      data: {id, status},
    } = getState().article;
    dispatch(addSlideshowSlide({count}));
    // save only if this is an existing draft slideshow
    if (id && status !== STATUS_PUBLISHED) {
      const res = await ArticlesAPI.addSlides(id, count);
      const error = res?.error ? res.error : false;
      await dispatch(articleUpdated({slideshowId: id}));
      await dispatch(setAction({status: `${count === 1 ? 'slide' : `${count} slides`} added`, complete: true, error}));
    }
  };
};

export const removeSlide = (slideId) => {
  return async (dispatch, getState) => {
    const {
      data: {id},
    } = getState().article;
    await dispatch(removeSlideshowSlide({slideId}));

    // save only if this is an existing slideshow
    if (id) {
      const res = await ArticlesAPI.deleteSlide(id, slideId);
      const error = res?.error ? res.error : false;
      await dispatch(articleUpdated({slideshowId: id}));
      await dispatch(setAction({status: 'slide deleted', complete: true, error}));
    }
  };
};

export const reorderSlides = (index, newIndex) => {
  return async (dispatch, getState) => {
    const {
      data: {id},
    } = getState().article;
    await dispatch(reorderSlideshowSlides({index, newIndex}));

    // save only if this is an existing slideshow
    if (id) {
      // compose the updated slides list for reordering
      const {
        data: {elements},
      } = getState().article;
      const reorderedSlides = elements.map((slide, index) => ({id: slide.id, slide_number: index}));

      // save and display visual cue to user
      const res = await ArticlesAPI.reorderSlides(id, reorderedSlides);
      const error = res?.error ? res.error : false;
      await dispatch(articleUpdated({slideshowId: id}));
      await dispatch(setAction({status: 'slides reordered', complete: true, error}));
    }
  };
};

export const setActiveSlide = (slideIndex) => {
  return (dispatch) => dispatch(selectSlide(slideIndex));
};

export const setActiveSlideMedia = (mediaBlock = null) => {
  return async (dispatch, getState) => {
    const {activeSlide} = getState().article;
    // if the user is trying to embed a B/R video as an iframe (eg. https://vid.bleacherreport.com/videos/179450)
    // fetch video data based on URL inputted using video id and send video object to reducer
    if (mediaBlock?.type === 'iframe' && mediaBlock?.data.src.includes('bleacherreport.com/videos')) {
      const url = new URL(mediaBlock.data.src);
      const path = url.pathname;
      const videoId = path.substring(path.lastIndexOf('/') + 1);
      mediaBlock.video = await getVideo(videoId);
    }
    return await dispatch(setSlideMedia({blockIndex: activeSlide, mediaBlock}));
  };
};

export const setArticleMedia = (mediaBlock = null) => {
  return (dispatch) => dispatch(setMedia({mediaBlock}));
};

export const saveEditorPrimary = (articleId) => {
  const user = getStorageData(CTT_USER);
  return async (dispatch) => {
    await ArticlesAPI.addArticleEditorPrimary(articleId, user);
    await dispatch(setEditorPrimary({primaryEditorGatekeeperId: user}));
  };
};

export const deleteEditorPrimary = (articleId) => {
  return async (dispatch) => {
    await ArticlesAPI.deleteArticleEditorPrimary(articleId);
    await dispatch(setEditorPrimary({primaryEditorGatekeeperId: null}));
  };
};
