import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
function ExpandablePanel(props) {
  const {ariaLabel, children, classes, expanded, isRequired, title, titleText, titleTextVisibleClosed, titleTextVisibleOpen} = props;
  const [isExpanded, setExpand] = useState(expanded);
  const label = isExpanded ? 'Hide' : 'Show';
  const toggleAttributes = {
    'aria-label': ariaLabel ? `${label} ${ariaLabel}` : label,
  };

  const shouldDisplayTitleText = () => {
    if (!titleText) return false;
    if (isExpanded && titleTextVisibleOpen) return true;
    if (!isExpanded && titleTextVisibleClosed) return true;
    return false;
  };

  return (
    <div className={classnames('atom', 'expandable-panel', classes, {'is-expanded': isExpanded})}>
      <button className="panel-toggle button--basic" onClick={() => setExpand(!isExpanded)} {...toggleAttributes}>
        <h3>
          {title}
          {isRequired && <sup>*</sup>}
          <span className="text"> {shouldDisplayTitleText() && `(${titleText})`}</span>
        </h3>
      </button>
      <div className="panel-content">{children}</div>
    </div>
  );
}

ExpandablePanel.defaultProps = {
  ariaLabel: '',
  expanded: false,
  classes: '',
  isRequired: false,
  titleTextVisibleClosed: true,
  titleTextVisibleOpen: true,
};

ExpandablePanel.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.string,
  expanded: PropTypes.bool,
  isRequired: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleText: PropTypes.string,
  titleTextVisibleClosed: PropTypes.bool,
  titleTextVisibleOpen: PropTypes.bool,
};

export default ExpandablePanel;
