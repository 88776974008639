import {createAction} from '@reduxjs/toolkit';

import * as ArticlesAPI from '../apis/articles/articlesAPI';
import * as statuses from '../constants/article';
import * as types from '../constants/action-types';

import {getUserProfilesByIds} from './users';
import {getArticleStatusForFilter} from '../helpers/dashboardHelpers';
import {formatDateForServer} from '../helpers/dateHelpers';

export const clearAction = createAction(types.DASHBOARD_ACTION_CLEAR);
export const clearDashboard = createAction(types.DASHBOARD_CLEAR);
export const setAction = createAction(types.DASHBOARD_ACTION);
export const setArticles = createAction(types.DASHBOARD_ARTICLES);
export const setFetching = createAction(types.DASHBOARD_FETCHING);
export const setPrefetch = createAction(types.DASHBOARD_PREFETCH);

/**
 * Fetch Dashboard Articles
 *
 * Fetches dashboard articles and makes an additional call to load the user profiles
 * into state for each of the editor user ids within the dashboard articles
 *
 * @param {*} dispatch
 * @param {*} state
 * @param {*} options
 */
async function fetchDashboardArticles(dispatch, state, options = {}) {
  const params = {
    filter: state.articles.filter,
    limit: state.limit,
    offset: state.articles.data.offset,
    showEdited: state.articles.showEdited,
    showMine: state.articles.showMine,
    showUnedited: state.articles.showUnedited,
    sorting: state.articles.sorting,
    ...options,
  };

  const response = await ArticlesAPI.getDashboardArticles(
    getArticleStatusForFilter(params.filter),
    params.sorting.join(','),
    params.offset,
    params.limit,
    params.showMine,
    params.showEdited,
    params.showUnedited
  );

  const editors = response.articles.map((article) => article.editedByGatekeeperId).filter((id) => id !== null);
  dispatch(getUserProfilesByIds(editors));
  dispatch(setArticles({...response, ...params, error: !!response.error}));
}

/**
 * Fetch Dashboard
 *
 * @param {*} options
 */
export const fetchDashboard = (options = {}) => {
  return async (dispatch, getState) => {
    const {isLoading, articles} = getState().dashboard;
    if (isLoading) return;

    dispatch(setPrefetch(options.filter || articles.filter));
    await fetchDashboardArticles(dispatch, getState().dashboard, options);
    dispatch(setFetching(false));
  };
};

const getTitleById = (articles, id) => {
  if (!articles.data.list || !id) return null;
  return articles.data.list.find((x) => x.id === id).title;
};

export const makeArticlePublished = (id, publishedAt) => {
  return async (dispatch, getState) => {
    const {articles, isLoading} = getState().dashboard;
    if (isLoading) return;

    const props = {
      publishedAt,
      status: statuses.STATUS_PUBLISHED,
    };

    dispatch(setFetching(true));
    await ArticlesAPI.saveArticle(id, props);
    await fetchDashboardArticles(dispatch, getState().dashboard);
    await dispatch(setAction({status: `'${getTitleById(articles, id)}' published`, complete: true}));
    dispatch(setFetching(false));
  };
};

export const makeArticleRestored = (id) => {
  return async (dispatch, getState) => {
    const {articles, isLoading} = getState().dashboard;
    if (isLoading) return;

    const props = {
      articleUpdatedAt: formatDateForServer(new Date()),
      publishedAt: null,
      status: statuses.STATUS_DRAFT,
    };

    dispatch(setFetching(true));
    await ArticlesAPI.saveArticle(id, props);
    await fetchDashboardArticles(dispatch, getState().dashboard);
    await dispatch(setAction({status: `'${getTitleById(articles, id)}' restored`, complete: true}));
    dispatch(setFetching(false));
  };
};

export const makeArticleScheduled = (id, scheduledAt) => {
  return async (dispatch, getState) => {
    const {articles, isLoading} = getState().dashboard;
    if (isLoading) return;

    dispatch(setFetching(true));
    await ArticlesAPI.scheduleArticle(id, formatDateForServer(scheduledAt));
    await fetchDashboardArticles(dispatch, getState().dashboard);
    await dispatch(setAction({status: `'${getTitleById(articles, id)}' scheduled for publish`, complete: true}));
    dispatch(setFetching(false));
  };
};

export const makeArticleUnscheduled = (id) => {
  return async (dispatch, getState) => {
    const {articles, isLoading} = getState().dashboard;
    if (isLoading) return;

    dispatch(setFetching(true));
    await ArticlesAPI.unscheduleArticle(id);
    await fetchDashboardArticles(dispatch, getState().dashboard);
    await dispatch(setAction({status: `'${getTitleById(articles, id)}' unscheduled for publish`, complete: true}));
    dispatch(setFetching(false));
  };
};

export const makeArticleDeleted = (id) => {
  return async (dispatch, getState) => {
    const {articles, isLoading} = getState().dashboard;
    if (isLoading) return;

    dispatch(setFetching(true));
    await ArticlesAPI.deleteArticle(id);
    await fetchDashboardArticles(dispatch, getState().dashboard);
    await dispatch(setAction({status: `'${getTitleById(articles, id)}' deleted`, complete: true}));
    dispatch(setFetching(false));
  };
};

export const copyArticleLink = (id, isEditLink) => {
  return async (dispatch, getState) => {
    const {articles} = getState().dashboard;
    await dispatch(
      setAction({
        status: `'${getTitleById(articles, id)}' ${isEditLink ? 'edit' : 'published'} url copied`,
        complete: true,
      })
    );
  };
};
