import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function SelectField({onChange, children, wrapperClassName, ...rest}) {
  return (
    <div className={classnames('field', 'field-select', wrapperClassName)}>
      <select onChange={onChange} {...rest}>
        {children}
      </select>
    </div>
  );
}

SelectField.defaultProps = {
  wrapperClassName: '',
};

SelectField.propTypes = {
  wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
