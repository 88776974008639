import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Go upper in a DOM to find LI node
 * @param {node} target HTML node
 * @returns {node|null}
 */
const getListOptionTag = (node) => {
  // Return list option node
  if (node.tagName === 'LI') return node;

  // Stop recursion on list parent tag
  if (node.tagName === 'UL') return null;

  // Go upper in a DOM to find LI
  return getListOptionTag(node.parentNode);
};

function Selector({defaultValue, children, ...props}) {
  const [isOpen, setOpenTo] = useState(false);

  const onDocumentClicked = useCallback(() => {
    return isOpen && setOpenTo(false);
  }, [isOpen]);

  useEffect(() => {
    // Close selector on document body click
    document.addEventListener('click', onDocumentClicked);
    return () => {
      document.removeEventListener('click', onDocumentClicked);
    };
  }, [onDocumentClicked]);

  const onSelectorClicked = (event) => {
    // Prevent click event bubbling
    event.stopPropagation();
    setOpenTo(!isOpen);
  };

  const onSelectorOptionClicked = (event) => {
    const option = getListOptionTag(event.target);
    if (option) {
      // Propagate click event for close selector area
      props.onSelect(option.dataset.value);
    }
  };

  const classes = classnames('atom', 'selector', {'is-open': isOpen});
  return (
    <div className={classes} onClick={onSelectorClicked}>
      <div className="selector-selected">
        <span className="inner">{defaultValue}</span>
      </div>
      <ul onClick={onSelectorOptionClicked}>{children}</ul>
    </div>
  );
}

Selector.defaultProps = {
  defaultValue: '…',
};

Selector.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Selector.Option = ({value, children, selected}) => {
  return (
    <li data-value={value} className={classnames({active: selected})}>
      {children}
    </li>
  );
};

export default Selector;
