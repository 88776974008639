import {IconDelimiter} from '@codexteam/icons';
import {addDiffClass} from '../difference';

/**
 * Displays a delimiter
 * @see example {@link https://bleacherreport.com/articles/2888769 2888769}
 *
 * Clone of the Delimiter Block for Editor.js
 * Using forked version so we can customize how the delimiter appears
 * @see {@link https://github.com/editor-js/delimiter @editorjs/delimiter}
 */
require('./index.css');

/**
 * @typedef {Object} DelimiterData
 * @description Tool's input and output data format
 */
export default class Delimiter {
  /**
   * Allow Tool to have no content
   * @return {boolean}
   */
  static get contentless() {
    return true;
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: DelimiterData, config: object, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   */
  constructor({data, readOnly}) {
    this.data = data;
    this.readOnly = readOnly;
    this._CSS = {
      wrapper: 'ce-delimiter',
    };
    this._element = this.drawView();
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    const div = document.createElement('div');

    div.classList.add(this._CSS.wrapper);
    addDiffClass(div, this.data);

    return div;
  }

  /**
   * Return Tool's view
   * @returns {HTMLDivElement}
   * @public
   */
  render() {
    return this._element;
  }

  /**
   * Extract Tool's data from the view
   * @returns {DelimiterData} - saved data
   * @public
   */
  save() {
    return {};
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconDelimiter,
      title: 'Delimiter',
    };
  }
}
