import React from 'react';

import {isPermitted} from '../../helpers/authHelpers';
import {scopes} from '../../constants';

import PageTitle from '../atoms/pageTitle';
import PermissionDenied from './permissionDenied';
import ToolsHeader from '../atoms/tools/header';

export const ToolsRequest = () => {
  const title = 'Request Data';

  if (!isPermitted(scopes.REVIEW_AND_PUBLISH || scopes.VIEW_ADMIN_MENU)) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to access request data.</p>
      </PermissionDenied>
    );
  }

  return (
    <section className="organism tools">
      <PageTitle title={title} />
      <ToolsHeader />
      <div className="tools-layout">
        <h2>Submit Request for Data</h2>
        <div className="page-content">
          <div className="section">
            <h3>Need data or version history from a deleted article?</h3>
            <a
              href="https://bleacherreportinternal.zendesk.com/hc/en-us/requests/new?ticket_form_id=1500000627662"
              target="_blank"
              rel="noopener noreferrer"
              className="button">
              Submit Request for Data
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ToolsRequest;
