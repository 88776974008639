import {getUsersProfiles} from '../apis/auth/gatekeeper-api';
import {getStorageData} from './authHelpers';
import {CTT_USER} from '../constants/user';

/**
 * Returns the currently logged in user's Gatekeeper ID
 * @returns {string} Gatekeeper Id (ex./ b8132dc0-25be-432b-963e-4fccb4e74585)
 */
export const getUserId = () => {
  return getStorageData(CTT_USER);
};

/**
 * Checks if the current users matches a particular user id
 * @param {string} userId a user id
 * @returns {boolean} does the user id match the currently logged in user?
 */
export const isCurrentUserThisUser = (userId) => {
  return userId === getStorageData(CTT_USER);
};

/**
 * Returns a user's initials from their profile
 * @param {object[]} profile a user profile
 * @returns {string[]} users initials
 */
export const getUserInitials = (profile) => {
  if (!profile || typeof profile !== 'object' || Array.isArray(profile)) return null;
  return `${profile.firstName.charAt(0).toUpperCase()}${profile.lastName.charAt(0).toUpperCase()}`;
};

/**
 * Returns a user's full name (first last) from their profile
 * @param {object[]} profile a user profile
 * @returns {string} users full name
 */
export const getUserFullName = (profile) => {
  if (!profile || typeof profile !== 'object' || Array.isArray(profile)) return null;
  return `${profile.firstName} ${profile.lastName}`;
};

/**
 * Returns the user object from the profile
 * @param {object[]} profile a user profile
 * @returns {object} content tools user object
 */
export const getUserFromProfile = (profile) => {
  if (!profile || typeof profile !== 'object' || Array.isArray(profile)) return null;

  const user = {
    id: profile.profileId,
    name: `${profile.firstName} ${profile.lastName}`,
    photoUrl: profile.photoPath,
    title: profile.title,
    twitterHandle: profile.twitterHandle,
  };

  return user;
};

/**
 * Returns a user(s) profile from their Gatekeeper ID
 * @param {string} id user(s) gatekeeper id
 * @returns {object[]} object of user(s)
 */
export const getProfileFromUserId = async (id) => {
  if (!id || typeof id !== 'string') return null;

  // failed calls to getUsersProfiles() return an object with the format {error: true}
  // the actual errors are caught and logged from within a .catch block in the gatekeeper api
  const {users, error} = await getUsersProfiles([id]);

  if (error) return null;
  return users;
};
