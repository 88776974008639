/* eslint-disable @br/laws-of-the-game/alphabetize-properties */
import Header from '@editorjs/header';
import Underline from '@editorjs/underline';

import Ad from './tools/ad/';
import BlockQuote from './tools/block-quote';
import Delimiter from './tools/delimiter/';
import Embed from './tools/embed-extended';
import FacebookPost from './tools/facebook-post/';
import Iframe from './tools/iframe/';
import Image from './tools/image';
import Infogram from './tools/infogram';
import Instagram from './tools/instagram';
import Linebreak from './tools/linebreak/';
import LinkOpen from './inline-tools/link-open';
import List from './tools/list/list';
import Media from './tools/media';
import Paragraph from './tools/paragraph';
import Placeholder from './tools/placeholder';
import PlaceholderEmbed from './tools/placeholder-embed';
import Poll from './tools/poll';
import PullQuote from './tools/pull-quote';
import Statmilk from './tools/statmilk/';
import Strikethrough from './inline-tools/strikethrough';
import Table from './tools/table';
import Tweet from './tools/tweet/';
import Unsupported from './tools/unsupported';
import Video from './tools/video';

import {configHeader, configList} from './toolsConfig';

export const EDITORJS_TOOLS = {
  // order tools in order should appear in block menu
  // note: inline toolbar required to allow conversion between block formats
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    ...configHeader,
  },
  list: {
    class: List,
    ...configList,
  },
  mediaBlock: Media,
  hr_transparent: {
    class: Linebreak,
    inlineToolbar: false,
  },
  hr: {
    class: Delimiter,
    inlineToolbar: false,
  },
  quote_indent: {
    class: BlockQuote,
    inlineToolbar: true,
  },
  quote_pull: {
    class: PullQuote,
    inlineToolbar: [],
  },
  tweet: {
    class: Tweet,
    inlineToolbar: false,
  },
  instagram: {
    class: Instagram,
    inlineToolbar: false,
  },
  iframe: {
    class: Iframe,
    inlineToolbar: false,
  },
  // below tools not impact toolbar order
  ad: {
    class: Ad,
    inlineToolbar: false,
  },
  embed: {
    class: Embed,
    inlineToolbar: true,
    config: {
      services: {
        youtube: true,
      },
    },
  },
  facebook_post: {
    class: FacebookPost,
    inlineToolbar: false,
  },
  gif: {
    class: Image, // use image block without tool configuration
    inlineToolbar: false,
  },
  image: {
    class: Image,
    inlineToolbar: false,
  },
  infogram: {
    class: Infogram,
    inlineToolbar: false,
  },
  linkOpen: LinkOpen,
  placeholder: Placeholder,
  placeholderEmbed: PlaceholderEmbed,
  poll: {
    class: Poll,
    inlineToolbar: false,
  },
  statmilk: {
    class: Statmilk,
    inlineToolbar: false,
  },
  strikethrough: Strikethrough,
  table: {
    class: Table,
    inlineToolbar: false,
  },
  underline: Underline,
  unsupported: {
    class: Unsupported,
    inlineToolbar: false,
  },
  video: {
    class: Video,
    inlineToolbar: false,
  },
};

export const EDITORJS_TOOLS_TEXTONLY = {
  // order tools in order should appear in block menu
  // note: inline toolbar required to allow conversion between block formats
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  header: {
    ...configHeader,
  },
  list: {
    class: List,
    ...configList,
  },
  hr_transparent: {
    class: Linebreak,
    inlineToolbar: false,
  },
  hr: {
    class: Delimiter,
    inlineToolbar: false,
  },
  quote_indent: {
    class: BlockQuote,
    inlineToolbar: true,
  },
  quote_pull: {
    class: PullQuote,
    inlineToolbar: [],
  },
  // below tools not impact toolbar order
  linkOpen: LinkOpen,
  strikethrough: Strikethrough,
  table: {
    class: Table,
    inlineToolbar: false,
  },
  underline: Underline,
  unsupported: {
    class: Unsupported,
    inlineToolbar: false,
  },
};

export const EDITORJS_TOOLS_FEATURED_MEDIA = {
  embed: EDITORJS_TOOLS.embed,
  iframe: EDITORJS_TOOLS.iframe,
  image: EDITORJS_TOOLS.image,
  placeholderEmbed: EDITORJS_TOOLS.placeholderEmbed,
  statmilk: EDITORJS_TOOLS.statmilk,
  video: EDITORJS_TOOLS.video,
};
