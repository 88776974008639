import React from 'react';
import classNames from 'classnames';
import {SEARCH_HEADERS_SORTABLE, SORT_DIR_ASC} from '../../../constants/search';

const ColumnHeader = ({columnKey, columnTitle, onClick, sortKey, sortDirection}) => {
  if (!columnKey) return null;

  const isSortable = SEARCH_HEADERS_SORTABLE.includes(columnKey);
  const sortButtonTitle = `Sort ${sortDirection === SORT_DIR_ASC ? 'ascending' : 'descending'}`;
  const sortButtonClasses = classNames('sort-button', `sort-${sortDirection}`, {'is-active': sortKey === columnKey});

  if (!isSortable) return <span className="atom column-header">{columnTitle}</span>;
  return (
    <span className="atom column-header" onClick={onClick}>
      {columnTitle}
      <span title={sortButtonTitle} className={sortButtonClasses}>
        (sort)
      </span>
    </span>
  );
};

export default ColumnHeader;
