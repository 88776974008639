import React from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import classnames from 'classnames';

import {TAB_FEEDBACK_HASH} from '../../../constants/article';

const FeedbackButton = ({isNewArticle}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.hash === TAB_FEEDBACK_HASH;
  const classes = classnames('article-button--feedback', {'is-active': isActive});
  const handleClick = () => navigate(isActive ? '#' : TAB_FEEDBACK_HASH);

  return isNewArticle ? null : (
    <button className={classes} onClick={handleClick}>
      <span className="sr-only">Feedback</span>
      <span className="icon icon-comment icon-medium" />
    </button>
  );
};

FeedbackButton.propTypes = {
  isNewArticle: PropTypes.bool.isRequired,
};

export default FeedbackButton;
