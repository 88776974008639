import React from 'react';
import PropTypes from 'prop-types';
import {BUTTON_PREVIEW_LABEL, BUTTON_PREVIEW_TITLE} from '../../../constants/ui';
import {openPreview} from '../../../helpers/articleHelpers';
function PreviewButton({articleId, articleUrl}) {
  if (!articleId || !articleUrl) return false;

  return (
    <button className="article-button--preview" onClick={openPreview(articleId, articleUrl)} title={BUTTON_PREVIEW_TITLE}>
      <span className="sr-only">{BUTTON_PREVIEW_LABEL}</span>
      <span className="icon icon-eye icon-medium" />
    </button>
  );
}

PreviewButton.propTypes = {
  articleId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null, false])]),
  articleUrl: PropTypes.string,
};

export default PreviewButton;
