// TODO refactor this into the Reducer or API layer

import reporter from '../reporter';

import logger from '../logger';

import {getContentFromDivBlock} from './divBlockConverter';
import {sanitizeOlderArticleContent} from './textHelpers';
import {EDITOR_TOOL_LIST_ORDERED_STYLE, EDITOR_TOOL_LIST_UNORDERED_STYLE} from '../constants/editor';

/* We must convert the data for the article's content from the format which
 * the Articles back-end provides it, into the format that EditorJS expects.
 * Articles Back-End:
 *   {
 *     elements: [
 *       {
 *         content_type: '',
 *         content: {}
 *       }, …
 *     ],
 *     …
 *   }
 *
 * EditorJS:
 *   {
 *     blocks: [
 *       {
 *         type: '',
 *         data: {}
 *       }, …
 *     ],
 *   }
 */

/* Helper function to create objects of a similar format:
 *   {id, type, data: {__original__, …}}
 * Returns a function which closes over `blockData`, which returns the object
 * corresponding to blockData along with any type-specfic modifications that
 * are passed into the returned function.
 */
function createBlockFactory(blockData) {
  const {content, id} = blockData;

  return (type, dataToMerge = {}) => {
    // The `dataToMerge` param can be excluded, for "data-less" blocks such as `hr` and Ad placeholders.
    return {
      id,
      type,
      data: {
        ...dataToMerge,
        // The __original__ value is meant to provide a record of what the format originally was... however some things appear to require it.
        __original__: Object.assign({}, content),
      },
    };
  };
}

/**
 * Given an element from an article's content data, transform its format from
 * that of the Articles back-end service, into the EditorJS format. It is
 * recursive when the block type is a slide (i.e. within Slideshows).
 * NOTE: Callers of this function should be refactored to use `transformElements` instead.
 *
 * @returns {object|null} null or empty object if data format is unexpected,
 * otherwise an object which EditorJS will recognize as a block of article
 * content, or potentially an array containing such blocks (in the case of a
 * legacy `div` block containing multiple child blocks).
 */
export function transform(blockData) {
  if (!blockData || typeof blockData !== 'object') {
    reporter.inform(new Error('Cannot transform blockData from Articles backend'), {block_data: blockData});
    return null;
  }

  const blockFactory = createBlockFactory(blockData);

  switch (blockData.contentType) {
    case 'ad': {
      return blockFactory('ad');
    }

    case 'div': {
      return getContentFromDivBlock(blockData.content.html);
    }

    case 'facebook_post': {
      return blockFactory('facebook_post', {
        id: blockData.id,
        url: blockData.url,
      });
    }

    case 'gif': {
      // TODO: these should be pulled in by Featured Media zone as well id=2150000
      return blockFactory('gif', {
        __original__: {
          // gif blockData.content not include the URL. This could be problematic, so we're adding it to the __original__ value
          ...blockData.content,
          url: blockData.url,
        },
        credit: blockData.content.credit,
        file: {url: blockData.url},
      });
    }

    case 'header':
    case 'h1':
    case 'h2': {
      return blockFactory('header', {
        level: 2,
        text: blockData.content.html,
      });
    }
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6': {
      return blockFactory('header', {
        level: 3,
        text: blockData.content.html,
      });
    }

    case 'hr': {
      return blockFactory('hr');
    }

    case 'hr_transparent': {
      return blockFactory('hr_transparent');
    }

    case 'iframe': {
      // ...also needs to be able to work in Featured, see id=2650000
      return blockFactory('iframe', {
        height: blockData.content.height, // custom pixel height of iframe (optional) otherwise will use default sizing
        provider: blockData.content.provider,
        src: blockData.content.src,
        thumbnail: blockData.content.thumbnail,
      });
    }

    case 'image': {
      const {content} = blockData;
      const {caption, credit, fullWidth, url, userCaption} = content;
      const {id, ...rest} = blockFactory('image', {
        caption,
        credit,
        file: {url},
        stretched: fullWidth, // as of Dec 2020 neither NodeReport nor mobile apps do anything with this flag
        userCaption,
      });

      // Only preserve id when an image has an editorjs-generated (stable) id
      // Strip numeric (legacy) ids to prevent featured media from refreshing when typing in the article body
      const imageHasStableId = isNaN(id);
      if (imageHasStableId) return {...rest, id};
      return rest;
    }

    case 'infogram':
      return blockFactory('infogram', {
        id: blockData.content.id,
      });

    case 'instagram': {
      return blockFactory('instagram', {
        id: blockData.content.id, // Instagram post ID
        metadata: blockData.content.metadata, // JSON data for Instagram Embed JS to render the post
        url: blockData.content.url, // Instagram url
      });
    }

    case 'list':
    case 'ol':
    case 'ul': {
      return blockFactory('list', {
        items: blockData.content.items,
        style: blockData.contentType === 'ol' ? EDITOR_TOOL_LIST_ORDERED_STYLE : EDITOR_TOOL_LIST_UNORDERED_STYLE,
      });
    }

    case 'paragraph': {
      // Older articles/slideshows sometimes include markup in the body content that should not be present. This markup
      // causes a UI refresh when the featured media is edited, so we must parse the problem content out here.
      const sanitizedText = sanitizeOlderArticleContent(blockData.content.html);

      if (!sanitizedText) return null;

      return blockFactory('paragraph', {
        text: sanitizedText,
      });
    }

    case 'placeholder': {
      return blockFactory('placeholder');
    }

    case 'poll': // article id=2020000
      return blockFactory('poll', {
        poll: blockData.content.poll,
      });

    case 'quote_indent': {
      // This is a text block styled in italics and indented from normal text
      // TODO fix line-height
      return blockFactory('quote_indent', {
        text: blockData.content.html, // html-string
      });
    }

    case 'quote_pull':
    case 'blockquote': {
      // This is a text block which is styled in a larger font, with a quotation mark
      // TODO fix line-height
      return blockFactory('quote_pull', {
        text: blockData.content.html, // html-string
      });
    }

    case 'slide':
      reporter.inform(new Error('Article data contains a slide'), {block: blockData});
      return blockFactory('slide');

    case 'statmilk':
      return blockFactory('statmilk', {
        height: blockData.content.height, // custom pixel height of statmilk iframe (optional) otherwise will use default sizing
        src: blockData.content.src, // statmilk iframe url (https)
      });

    case 'table': // TODO: CTT-46... article id=2020000
      return blockFactory('table', {
        table: blockData.content.table,
      });

    case 'tweet': {
      return blockFactory('tweet', {
        id: blockData.content.id, // this is the Tweet ID
        metadata: blockData.content.metadata, // this contains JSON which will be used to construct a <blockquote> embed code, which Twitter's widget.js can hydrate/vivify.
        url: blockData.content.url, // NodeReport uses this to construct its own embed code
      });
    }

    case 'unsupported': {
      return blockFactory('unsupported');
    }

    case 'video': {
      const {metadata} = blockData.content;
      // TODO needs to work within featured id=2770000
      return blockFactory('video', {
        metadata: {
          caption: metadata?.caption,
          description: metadata?.description,
          hideFromRegions: metadata?.hideFromRegions,
          startSeconds: metadata?.startSeconds,
          thumbnail: metadata?.thumbnail,
          title: metadata?.title,
          videoId: metadata?.videoId,
          videoTags: metadata?.videoTags,
        },
      });
    }

    case 'youtube':
    case 'youtube_video': {
      const metadata = blockData.content.metadata;
      const startSeconds = metadata.startSeconds || metadata.start_seconds;
      const videoId = metadata.videoId || metadata.video_id;

      let source = `https://youtu.be/${videoId}`;
      let embed = `https://www.youtube.com/embed/${videoId}`;

      if (startSeconds) {
        source += `?start=${startSeconds}`;
        embed += `?start=${startSeconds}`;
      }

      return blockFactory('embed', {
        service: 'youtube',
        source,
        embed,
        caption: '',
        thumbnail: `https://img.youtube.com/vi/${videoId}/0.jpg`,
      });
    }

    // eslint-disable-next-line no-undefined
    case undefined: {
      // This is detritus from the buggy way that Breport handled media within
      // article content. These blocks usually contain no content, and thus
      // cannot be salvaged into blocks with a well-defined format; therefore
      // we will remove them from the article rather than preserving the broken
      // data.
      return null;
    }

    default:
      logger.debug('Unrecognized Articles data format…', blockData);
      reporter.inform(new Error('Unrecognized Articles data format…'), {block: blockData});
      return blockFactory('unsupported');
  }
}

/**
 * Given an array of elements from an article's content, transform them into
 * the format which EditorJS expects. It will use `transform` and filter out
 * any entries which do not look valid.
 *
 * return {array<object>} (potentially-empty) list of EditorJS-ready objects
 */
export function transformElements(elements) {
  // The `transform` function can return `null` or an empty array;
  // let's filter those out.

  return elements
    .map(transform)
    .flat()
    .filter((block) => !!block && !!block.type);
}

/**
 * Helper for the Version comparison UX, to convert JSON into EditorJS format,
 * in both Standard and Slideshow articles.
 */
export const jsonTransformForEditor = (data) => {
  if (typeof data !== 'object' || data === null) {
    return null;
  }
  const {elements, ...rest} = data;
  if (!elements || !Array.isArray(elements)) {
    reporter.inform(new Error('Cannot transform data from Articles backend'), {article_data: data});
    return {};
  }
  return {
    ...rest,
    blocks: transformElements(elements),
  };
};
