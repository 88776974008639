import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {refreshToken} from '../../actions/users';
import {getAuthTokens, isAuthorized, isTokenExpired} from '../authHelpers';

const FIVE_MINUTES = 5 * 60 * 1000;

const defaultOptions = {
  refreshAttemptInterval: FIVE_MINUTES,
};

function useAuthRefresh(props = {}) {
  const navigate = useNavigate();

  const refreshAuthToken = async () => {
    // Do nothing if token is still valid
    if (!isTokenExpired()) return;

    const {refreshToken: token} = getAuthTokens();
    if (token) {
      // Trying to refresh current token
      if (!(await refreshToken(token))) {
        // In case of failure refresh - redirect customer to the login page
        navigate('/login', {replace: true});
      }
    }
  };

  useEffect(() => {
    const firstAuthTokenCheck = async () => await refreshAuthToken();

    // Start first check on component render
    firstAuthTokenCheck();
  }, []);

  useEffect(() => {
    if (!isAuthorized()) {
      // In case of missing auth token redirect customer to the login page
      navigate('/login', {replace: true});
      return;
    }
  }, []);

  const options = {...defaultOptions, ...props};

  useEffect(() => {
    // Start refresh token interval
    const intervalId = global.setInterval(refreshAuthToken, options.refreshAttemptInterval);
    return () => {
      global.clearInterval(intervalId);
    };
  }, []);
}

export default useAuthRefresh;
