import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {selectShowCompareView} from '../../../selectors/versions';
import {textChangedPercentage} from '../../../helpers/versionHelpers';
import ExpandablePanel from '../expandablePanel';

export const FIELDS = {
  textChanged: 'Text Changed',
};

const SidebarDocument = ({textChanged}) => {
  if (!textChanged) return null;
  const isComparison = useSelector(selectShowCompareView);

  const outputChanges = () => {
    return (
      <ul>
        <li>
          <div className="label">
            {FIELDS.textChanged}
            <span className="label-colon">:</span>
          </div>
          <div className="value">
            <span className="ce-diff__added">{textChangedPercentage(textChanged)}</span>
          </div>
        </li>
      </ul>
    );
  };

  if (isComparison) {
    return (
      <>
        <ExpandablePanel
          ariaLabel="Document"
          classes="group document"
          expanded={false}
          title="Document"
          titleText={FIELDS.textChanged}
          titleTextVisibleOpen={false}>
          {outputChanges()}
        </ExpandablePanel>
      </>
    );
  }

  return (
    <>
      <div className="atom group document">
        <h3>Document</h3>
        {outputChanges()}
      </div>
    </>
  );
};

SidebarDocument.propTypes = {
  textChanged: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
};

export default SidebarDocument;
