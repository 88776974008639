export function setDiffProps(block) {
  if (block.added) {
    block.data.diff = {added: true};
  }
  if (block.removed) {
    block.data.diff = {removed: true};
  }
}

export function addDiffClass(element, data) {
  if (!data || !data.__original__ || !data.__original__.diff) return;

  if (data.__original__.diff.added) {
    element.classList.add('ce-diff__block', 'ce-diff__added');
  }

  if (data.__original__.diff.removed) {
    element.classList.add('ce-diff__block', 'ce-diff__removed');
  }
}

// TODO: delete this. do we ever have `data.diff` and not `data.__original__.diff`??
/*export function addDiffClass(element, data) {
  if (!data || !data.diff) return;

  if (data.diff.added || data.__original__.diff.added) {
    element.classList.add('ce-diff__block', 'ce-diff__added');
  }
  if (data.diff.removed || data.__original__.diff.removed) {
    element.classList.add('ce-diff__block', 'ce-diff__removed');
  }
}*/
