import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Selector from '../selector';
import VersionChanges from './changes';
import {setActiveVersion, setCompareVersion} from '../../../actions/versions';
import {getUsersProfiles} from '../../../apis/auth/gatekeeper-api';
import {selectIndexes, selectShowCompareView, selectVersions} from '../../../selectors/versions';
import {formatDateWithTimezone} from '../../../helpers/dateHelpers';
import {getUserInitials} from '../../../helpers/userHelpers';

function VersionSelector({isCompareVersion = false, isHeader = false}) {
  const {id: articleId} = useParams();
  const dispatch = useDispatch();
  const {activeIndex, compareIndex, currentIndex} = useSelector(selectIndexes);
  const isComparison = useSelector(selectShowCompareView);
  let versions = useSelector(selectVersions);
  if (!isHeader && isComparison && isCompareVersion) versions = versions.filter(({id}) => id !== activeIndex);
  if (!isHeader && isComparison && !isCompareVersion) versions = versions.filter(({id}) => id !== compareIndex);

  const thisIndex = isComparison && isCompareVersion ? compareIndex : activeIndex;
  const thisVersion = versions.find((version) => version.id === thisIndex);
  const [userProfiles, setUserProfiles] = useState();

  const onVersionChanged = (versionId) => {
    if (isCompareVersion) return dispatch(setCompareVersion(articleId, parseInt(versionId, 10)));
    return dispatch(setActiveVersion(articleId, parseInt(versionId, 10)));
  };

  const getDefaultValue = () => {
    if (!versions.length) return '';
    if (isHeader && isComparison) return 'Compare Versions';
    if (!thisVersion) return 'Select Version';
    if (thisVersion.id === currentIndex) return 'Current Version';
    return `Version ${thisVersion.versionNumber}`;
  };

  const getVersionOptionTitle = (version) => {
    if (!versions.length) return '';

    // version considered current if it's on the top of the versions list (versions sorted by desc on backend)
    const isCurrentVersion = version.id === currentIndex;
    return isCurrentVersion ? 'Current Version' : `${version.versionNumber}`;
  };

  const getVersionOptions = (withAuthor = true) => {
    return versions.map((version, index) => {
      const selected = isCompareVersion ? thisVersion?.id === version.id : activeIndex === version.id;
      const authorProfile = withAuthor ? userProfiles.find((profile) => profile.id === version.userGatekeeperId) : null;
      return (
        <Selector.Option key={`version-${index}`} value={version.id} selected={selected}>
          <div className="list-row">
            <div className="list-num">
              <span className="sr-only">Version </span>
              {getVersionOptionTitle(version)}
            </div>
            <div className="version-date">{formatDateWithTimezone(version.updatedAt)}</div>
            {withAuthor && <div className="version-author">{getUserInitials(authorProfile)}</div>}
          </div>
          <VersionChanges added={version.changes?.added} removed={version.changes?.removed} textChanged={version?.textChangePercentage} />
        </Selector.Option>
      );
    });
  };
  const [selectorOptions, setSelectorOptions] = useState(getVersionOptions(false));

  useEffect(() => {
    const userIds = versions.map((version) => version.userGatekeeperId);
    const userIdsUnique = [...new Set(userIds)];
    getUsersProfiles(userIdsUnique).then(({users}) => {
      setUserProfiles(users);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(userProfiles)) setSelectorOptions(getVersionOptions());
  }, [userProfiles]);

  const showVersionDate = () => {
    if (!thisVersion) return null;
    if (isHeader && isComparison) return null;
    return <div className="selector-date">Saved: {formatDateWithTimezone(thisVersion.updatedAt)}</div>;
  };

  return (
    <>
      <Selector defaultValue={getDefaultValue()} onSelect={onVersionChanged}>
        {selectorOptions}
      </Selector>
      {showVersionDate()}
    </>
  );
}

VersionSelector.propTypes = {
  isCompareVersion: PropTypes.bool,
  isHeader: PropTypes.bool,
};

export default VersionSelector;
