import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import classnames from 'classnames';

import {fetchEditorStatsById} from '../../actions/editorStats';
import {EDITOR_STATS_EDIT_TYPES} from '../../constants/editorStats';
import {selectDateEnd, selectDateStart, selectEditType, selectIndividual, selectLoading} from '../../selectors/editorStats';
import ArticleRow from '../atoms/editorStats/articleRow';
import Loading from '../atoms/loading';

const EditorStatsTableIndividual = () => {
  const dispatch = useDispatch();
  const {id: editorId} = useParams();
  const dateStart = useSelector(selectDateStart);
  const dateEnd = useSelector(selectDateEnd);
  const editType = useSelector(selectEditType);
  const individual = useSelector(selectIndividual);
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(fetchEditorStatsById({id: editorId}));
  }, [dateEnd, dateStart, editorId, editType]);

  const rows = () => {
    if (isLoading) return <Loading />;

    if (!individual.length) {
      const isEditTypeSelected = editType !== 'all';
      const editTypeLabel = EDITOR_STATS_EDIT_TYPES.filter((type) => type.value === editType)[0].label;
      return (
        <div className="table-row is-empty" key="row0">
          <div className="table-data">
            <div>
              <strong>Sorry, we couldn't find any {isEditTypeSelected ? editTypeLabel : null} articles for this time period.</strong>
              <div>{isEditTypeSelected ? 'Try changing the edit type or the date range.' : 'Try changing the date range.'}</div>
            </div>
          </div>
        </div>
      );
    }

    return Object.values(individual).map((row, index) => {
      const {
        article: {id},
      } = row;
      return <ArticleRow key={id} index={index} />;
    });
  };

  return (
    <div className={classnames('molecule', 'editor-stats-individual', {'is-loading': isLoading, 'is-empty': !individual.length})}>
      <div className="table-header">
        <div className="column-header">Article Title</div>
        <div className="column-header">Last Edited</div>
        <div className="column-header">Duration</div>
        <div className="column-header">Changed</div>
        <div className="column-header">&nbsp;</div>
      </div>
      <div className="table-body">{rows()}</div>
    </div>
  );
};

export default EditorStatsTableIndividual;
