import {IconTable} from '@codexteam/icons';
import {addDiffClass} from '../difference';
import ToolEventObserver from '../toolEventObserver';
import {EDITOR_SETTINGS_EDIT_ICON, EDITOR_SETTINGS_EDIT_LABEL} from '../../../../constants/editor';
import {EVENT_EMBED_EDIT} from '../../../../constants/events';
import {PROMPT_BUTTON_SAVE, PROMPT_STATMILK_EMBED} from '../../../../constants/prompt';
import {prompt} from '../../../../helpers/promptHelper';

import {getIframeUrl} from '../iframe';

require('./index.css');

// note: if the default height is changed, it also needs to be updated in `nodereport/src/reducers/articles.js`
// to keep the display of statmilks consistent within content tools, and on preview/production
const STATMILK_HEIGHT_DEFAULT = '450'; // default statmilk pixel height (when not specified)

export const PROMPT_STATMILK_TITLE = 'Add Statmilk';
export const PROMPT_STATMILK_TITLE_EDIT = 'Edit Statmilk';
export const PROMPT_STATMILK_DESCRIPTION =
  'You can enter either a Statmilk iframe html or widget url. Optionally specify a custom pixel height or leave blank to use the default sizing.';
export const STATMILK_PROVIDER = 'statmilk';
export const STATMILK_THUMBNAIL = '/images/media/statmilk-thumbnail-placeholder.svg';

/**
 * Parses a Statmilk iframe embed code for the height
 * @param {string} input iframe embed code <iframe src="https:///statmilk.bleacherreport.com..." STYLE="width:100%;border: none;height:643px"></iframe>
 * @returns {string|null} height as string (pixel value with no pixel units)
 */
export const getStatmilkHeight = (input) => {
  if (typeof input !== 'string') return null;
  const height = input.match(/(?<=height:)\d+(?=px)/gi);
  if (height) return height[0];
  return null;
};

/**
 * Displays a Statmilk iframe
 * @see {@link https://statmilk.bleacherreport.com}
 * @see {@link https://statmilk.bleacherreport.com/Whiteboard/WidgetLibrary}
 * @see examples {@link https://bleacherreport.com/articles/2888775 2888775}
 * {@link https://statmilk.bleacherreport.com/NFL/DraftBoard/91619753/4511 src}
 * and {@link https://bleacherreport.com/articles/2647629 2647629}
 * {@link https://statmilk.bleacherreport.com/NBA/DraftBoard/7639/5057 src}
 */
export default class Statmilk {
  static ERROR_MESSAGE = 'Ooops! Please enter a valid Statmilk iframe html or widget url.';

  static get toolbox() {
    return {
      icon: IconTable,
      title: 'Statmilk',
    };
  }

  constructor({api, data, block, readOnly}) {
    this.api = api;
    this.data = data;
    this.block = block;
    this.readOnly = readOnly;
    this.iframe = '';
    this.contentTool = null;
    this.shouldReplace = false;
    this.editorBlockIndex = 0;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  isNewBlock() {
    return !Object.keys(this.data).length;
  }

  static checkValidUrl(url) {
    // TODO: add better validation based on statmilk url patterns
    return url.substring(0, 36) === 'https://statmilk.bleacherreport.com/';
  }

  renderAdd() {
    const promptTitle = this.shouldReplace ? PROMPT_STATMILK_TITLE_EDIT : PROMPT_STATMILK_TITLE;

    prompt(PROMPT_STATMILK_EMBED)
      .withTexts(promptTitle, PROMPT_STATMILK_DESCRIPTION, PROMPT_BUTTON_SAVE)
      .withUserInput((input) => {
        const text = input.trim();
        const valid = getIframeUrl(text) || Statmilk.checkValidUrl(text);
        return !valid ? Statmilk.ERROR_MESSAGE : null;
      }, this.data.src || '')
      .withAdditionalInput('number', 'Height', this.data.height || '')
      .show((input, h = '') => {
        let src = input.trim();
        let height = h;

        // update source when youtube iframe or iframe used instead of a url string
        const iframeUrl = getIframeUrl(src);
        if (iframeUrl) {
          src = iframeUrl;
          height = getStatmilkHeight(input) || height;
        }

        const content = {src, height, thumbnail: STATMILK_THUMBNAIL, provider: STATMILK_PROVIDER, type: 'statmilk'};
        this.data = {...content, __original__: content};

        // when the statmilk iframe is first added the block doesn't exist yet, but will exist when editing
        const blockExists = this.block.holder;

        if (!blockExists) {
          this.api.blocks.insert('statmilk', this.data, {}, this.editorBlockIndex);
        } else {
          // catch for when block is edited via block tune settings
          if (this.shouldReplace) this.shouldReplace = false;

          this.api.blocks.update(this.block.id, this.data);
        }

        // update the redux article state
        ToolEventObserver.trigger(EVENT_EMBED_EDIT, {
          content: {type: 'statmilk', data: this.data},
          editorBlockIndex: this.editorBlockIndex,
        });
      });

    // Editor.js expects render() to return a valid Node otherwise will throw console error:
    // `Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'.`
    // But returning a node here will insert empty blocks into the editor which are
    // not necesssary and can cause unintended side effects
    // @see CTT-940/CTT-950
    // Note we are currently supressing the console error in `src/client.js`
    // and cypress uncaught:exception in `cypress/support/index.js`
    this.iframe = null;
  }

  buildBlock() {
    if (!this.data.src) return this.iframe;
    const isValidUrl = Statmilk.checkValidUrl(this.data.src);
    const container = document.createElement('div');
    container.classList.add('media-container', 'media-container-iframe', 'media-container-statmilk');
    addDiffClass(container, this.data);

    if (isValidUrl) {
      const iframe = document.createElement('iframe');
      iframe.width = '100%';
      iframe.height = this.data.height || STATMILK_HEIGHT_DEFAULT;
      iframe.style = 'border: 0;';
      iframe.src = this.data.src;
      container.appendChild(iframe);
    } else {
      this.isValidUrl = false;
      // catch for older articles (ex./articles/standard/2659646) that already have an invalid or HTTP statmilk
      const placeholder = document.createElement('div');
      placeholder.classList.add('media-placeholder');
      placeholder.innerHTML = `Can't display Statmilk: Invalid URL <a href="${this.data.src}" rel="noopener noreferrer" target="_blank">Link to media</a>`; // eslint-disable-line max-len
      container.appendChild(placeholder);
    }

    this.iframe = container;
    return this.iframe;
  }

  render() {
    this.isNewBlock() || this.shouldReplace ? this.renderAdd() : this.buildBlock();

    // index / getCurrentBlockIndex() needs to be set during render() to catch new blocks being added
    // inside renderAdd() it only correctly grabs the index for edit/replace events, not new blocks being added
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    if (currentBlockIndex >= 0) this.editorBlockIndex = currentBlockIndex;

    return this.iframe;
  }

  renderSettings() {
    return [
      {
        icon: EDITOR_SETTINGS_EDIT_ICON,
        label: EDITOR_SETTINGS_EDIT_LABEL,
        onActivate: () => {
          // edit button re-triggers the url prompt
          this.shouldReplace = true;
          this.render();
        },
      },
    ];
  }

  save() {
    return this.data;
  }
}
