// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-additional {
  display: flex;
  align-items: end;
  justify-content: flex-start;
  line-height: 1.5;
  text-align: left;
}

.image-caption {
  color: #000;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.image-credit {
  color: #666;
  font-size: 1.0rem;
  font-style: italic;
}

.media-container-image {
  text-align: center;
}

.media-container-image img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/image/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".image-additional {\n  display: flex;\n  align-items: end;\n  justify-content: flex-start;\n  line-height: 1.5;\n  text-align: left;\n}\n\n.image-caption {\n  color: #000;\n  font-size: 1.2rem;\n  margin-right: 0.5rem;\n}\n\n.image-credit {\n  color: #666;\n  font-size: 1.0rem;\n  font-style: italic;\n}\n\n.media-container-image {\n  text-align: center;\n}\n\n.media-container-image img {\n  display: block;\n  max-width: 100%;\n  margin: 0 auto;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
