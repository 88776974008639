import React from 'react';
import PropTypes from 'prop-types';
import {USER_IMAGE_DEFAULT, USER_TEMPLATE_AVATAR, USER_TEMPLATE_BASIC, USER_TEMPLATE_LOCK} from '../../constants/user';

const User = ({user, template, usePlaceholder}) => {
  if (!user && usePlaceholder) {
    return (
      <div className="atom user user-placeholder">
        <div className="user-avatar">&nbsp;</div>
        <div className="user-info">
          <span className="user-name">None</span>
        </div>
      </div>
    );
  }

  if (!user) return null;
  if (!user.photoUrl) user.photoUrl = USER_IMAGE_DEFAULT;

  const {photoUrl: thumbnail, name, title, twitterHandle: twitter} = user;
  const showAdditionalUserInfo = template !== USER_TEMPLATE_BASIC;
  const avatar = () => (
    <div className="user-avatar" style={{backgroundImage: `url(${thumbnail})`}}>
      &nbsp;
    </div>
  );

  if (template === USER_TEMPLATE_AVATAR) return avatar();
  if (template === USER_TEMPLATE_LOCK) {
    return (
      <div className="atom user user-lock">
        {avatar()}
        <div className="user-info">
          <span className="user-name">{name}</span> <span className="user-action">is currently editing</span>
        </div>
      </div>
    );
  }
  return (
    <div className="atom user">
      {avatar()}
      <div className="user-info">
        <div className="user-name">{name}</div>
        {title && showAdditionalUserInfo && <div className="user-title">{title}</div>}
        {twitter && showAdditionalUserInfo && (
          <a href="#" className="user-twitter">
            {twitter}
          </a>
        )}
      </div>
    </div>
  );
};

User.defaultProps = {
  template: '',
  usePlaceholder: false,
};

User.propTypes = {
  template: PropTypes.string,
  usePlaceholder: PropTypes.bool,
};

export default User;
