import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {IMAGE_SEARCH_FILTERED_PAGE_SIZE, IMAGE_SEARCH_UNFILTERED_PAGE_SIZE, IMAGE_SOURCE_AP, IMAGE_SOURCE_GETTY} from '../../../constants/media';
import {
  selectIsLoading,
  selectHasMore,
  selectHasMoreAP,
  selectHasMoreGetty,
  selectImageFilter,
  selectImages,
  selectImageSearch,
  selectImageSearchOptions,
  selectOffset,
  selectPickedImage,
  selectTotalImages,
} from '../../../selectors/media';
import {fetchImages, selectImageFromFile, setImageFilter} from '../../../actions/media';
import {formatNumber} from '../../../helpers/number';
import {ImageCropper, ImageFinder, ImageUploader} from '../image/';
import Pagination from '../pagination';

const ImageSelector = ({cropConfig, imageForUpload, onPaginateButton, onPick, onSelect, picked}) => {
  const dispatch = useDispatch();
  const filteredBy = useSelector(selectImageFilter);
  const hasMore = useSelector(selectHasMore);
  const hasMoreAP = useSelector(selectHasMoreAP);
  const hasMoreGetty = useSelector(selectHasMoreGetty);
  const images = useSelector(selectImages);
  const imageSearchOptions = useSelector(selectImageSearchOptions);
  const isLoading = useSelector(selectIsLoading);
  const offset = useSelector(selectOffset);
  const query = useSelector(selectImageSearch);
  const selectedImage = useSelector(selectPickedImage);
  const totalImages = useSelector(selectTotalImages);

  const handleImagePick = (image) => {
    const {caption, fullUrl, imageId, source, urlComp} = image;
    const credit = source === 'Getty' ? image.artist : image.credit;

    // Action represents image highlight before actual selection
    onPick({caption, credit, fullUrl, id: imageId, source, url: urlComp});
  };

  const handleImageUpload = (blob) => {
    // Dispatch select image action
    dispatch(selectImageFromFile(blob, ''));
  };

  const pageReset = (filteredBy) => {
    // Reset image page fetch back to page 1
    dispatch(fetchImages(query, 0, filteredBy, {...imageSearchOptions, page: 1}));
  };

  const handleFilterClick = (filter) => {
    pageReset(filter);
    dispatch(setImageFilter(filter));
  };

  const getSourceImages = (source) => {
    return images.filter((image) => image.source === source);
  };

  const renderPagination = () => {
    if (!images.length) return null;

    let totalImagesFilteredBy = totalImages.all;
    if (filteredBy === IMAGE_SOURCE_AP) totalImagesFilteredBy = totalImages.ap;
    if (filteredBy === IMAGE_SOURCE_GETTY) totalImagesFilteredBy = totalImages.getty;

    return (
      <Pagination
        count={totalImagesFilteredBy}
        currentPage={imageSearchOptions.page}
        isLoading={isLoading}
        hasMore={filteredBy ? (filteredBy === IMAGE_SOURCE_AP ? hasMoreAP : hasMoreGetty) : hasMore}
        limit={filteredBy ? IMAGE_SEARCH_FILTERED_PAGE_SIZE : IMAGE_SEARCH_UNFILTERED_PAGE_SIZE}
        onPageChange={({offset, page}) => {
          dispatch(fetchImages(query, offset, filteredBy, {...imageSearchOptions, page}));
          onPaginateButton();
        }}
        offset={offset}
      />
    );
  };

  const renderImageSourceFilter = () => {
    if (!images.length) return null;

    return (
      <div className="media-sources-container">
        <div className="media-sources">
          <button
            className={classnames('button--basic', {active: filteredBy === IMAGE_SOURCE_GETTY})}
            onClick={() => handleFilterClick(IMAGE_SOURCE_GETTY)}>
            {IMAGE_SOURCE_GETTY} ({formatNumber(totalImages.getty)})
          </button>
          <button
            className={classnames('button--basic', {active: filteredBy === IMAGE_SOURCE_AP})}
            onClick={() => handleFilterClick(IMAGE_SOURCE_AP)}>
            {IMAGE_SOURCE_AP} ({formatNumber(totalImages.ap)})
          </button>
        </div>
        {renderPagination()}
      </div>
    );
  };

  const isFinderActive = () => {
    // Show image finder if there is not image for upload (dnd to the page)
    return !imageForUpload;
  };

  const isUploaderActive = () => {
    // Show uploader if image was not selected yet and images was not requested
    return !selectedImage.url && !images.length;
  };

  const isCropperActive = () => {
    // Show cropper if image was selected
    return !!selectedImage.url;
  };

  let finderImages = images;
  if (filteredBy && images) {
    finderImages = getSourceImages(filteredBy);
  }

  return (
    <div className={classnames('atom', 'media-selector', 'media-selector-images')}>
      {renderImageSourceFilter()}
      {isFinderActive() && <ImageFinder images={finderImages} picked={picked} onImagePick={handleImagePick} />}
      {isUploaderActive() && <ImageUploader accepted={imageForUpload} onUpload={handleImageUpload} />}
      {isCropperActive() && <ImageCropper imageUrl={selectedImage.url} imageCrop={cropConfig} onCrop={(crop) => onSelect(crop)} />}
      {renderPagination()}
    </div>
  );
};

ImageSelector.propTypes = {
  cropConfig: PropTypes.object,
  imageForUpload: PropTypes.object,
  onPaginateButton: PropTypes.func,
  onPick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  picked: PropTypes.object,
};

export default ImageSelector;
