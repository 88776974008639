import React from 'react';

import {isPermitted} from '../../helpers/authHelpers';
import {scopes} from '../../constants';

import DeletedArticles from '../molecules/deletedArticles';
import PageTitle from '../atoms/pageTitle';
import PermissionDenied from './permissionDenied';
import ToolsHeader from '../atoms/tools/header';

export const ToolsDeleted = () => {
  const title = 'Deleted Articles';

  if (!isPermitted(scopes.VIEW_ADMIN_MENU)) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to access deleted articles.</p>
      </PermissionDenied>
    );
  }

  return (
    <section className="organism tools">
      <PageTitle title={title} />
      <ToolsHeader />
      <div className="tools-layout">
        <DeletedArticles />
      </div>
    </section>
  );
};

export default ToolsDeleted;
