import logger from '../logger';

import * as types from '../constants/action-types';
import {IMAGE_SOURCE_GETTY, MEDIA_TYPE_IMAGE} from '../constants/media';

export const initialState = {
  images: {
    filteredBy: IMAGE_SOURCE_GETTY,
    hasMore: false,
    hasMoreAP: false,
    hasMoreGetty: false,
    isSearched: false,
    list: [],
    offset: 0,
    search: '',
    selected: {
      url: null,
      credit: '',
      caption: '',
      crop: {},
      sourceCaption: null,
      sourceCredit: '',
      uploadedByUserID: null,
      userCaption: '',
    },
    total: {
      all: 0,
      ap: 0,
      getty: 0,
    },
  },
  videos: {
    search: '',
    list: null,
    selected: null,
    selectedCaption: '',
    selectedTitle: '',
    selectedDescription: '',
    selectedVideoTags: '',
    selectedStartSeconds: 0,
    isRetrieved: false,
  },
  isLoading: false,
  error: null,
};

function dateCreatedToNewDate({dateCreated, ...rest}) {
  return {
    ...rest,
    dateCreated: new Date(dateCreated),
  };
}

function dateCreatedDescending(a, b) {
  return a.dateCreated === b.dateCreated ? 0 : a.dateCreated < b.dateCreated ? 1 : -1;
}

const media = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FETCHING: {
      return {...state, isLoading: action.payload};
    }

    case types.MEDIA_GET_IMAGES: {
      const {
        images,
        itemTotalCount,
        itemTotalCountAP,
        itemTotalCountGetty,
        search,
        offset,
        hasMore,
        hasMoreAP,
        hasMoreGetty,
        page,
        paginationUrlAp,
        error,
      } = action.payload;
      // normalize date formats, and then sort descending (recent at head of list)
      const apImagesReformatted = images.ap.map(dateCreatedToNewDate);
      const gettyImagesReformatted = images.getty.map(dateCreatedToNewDate);
      const list = apImagesReformatted.concat(gettyImagesReformatted).sort(dateCreatedDescending);
      return {
        ...state,
        images: {
          ...state.images,
          total: {
            all: itemTotalCount,
            ap: itemTotalCountAP,
            getty: itemTotalCountGetty,
          },
          isSearched: true,
          filteredBy: state.images.filteredBy,
          search,
          offset,
          hasMore,
          hasMoreAP,
          hasMoreGetty,
          list,
        },
        page: page || 1,
        error,
        paginationUrlAp,
      };
    }

    case types.MEDIA_GET_VIDEOS: {
      const {videos, search, error} = action.payload;
      return {...state, error, videos: {search, list: videos}};
    }

    case types.MEDIA_SELECT_IMAGE: {
      const {caption, credit, error, image, sourceCaption, sourceCredit, userCaption} = action.payload;
      if (error) {
        logger.error(error);
        global.alert(`Uh oh, an error has occurred.\n\n${error}`);
        return {
          ...state,
          error,
        };
      }
      const selected = {
        caption,
        credit,
        sourceCaption,
        sourceCredit,
        url: image.url.replace(/&amp;/g, '&'), // TODO determine where these ampersand entities are being inserted... data on Articles BE does not include
      };
      if (!caption) delete selected.caption;
      if (userCaption) {
        selected.userCaption = userCaption;
      }
      if (action.payload.userID) {
        selected.uploadedByUserID = action.payload.userID;
      }
      return {
        ...state,
        images: {
          // Clean up loaded search images information from the state
          ...initialState.images,
          selected,
        },
      };
    }

    case types.MEDIA_IMAGE_FILTER: {
      const {type} = action.payload;
      return {
        ...state,
        images: {
          ...state.images,
          filteredBy: type,
        },
      };
    }

    case types.MEDIA_SELECT_VIDEO: {
      const {video, error} = action.payload;
      return {
        ...state,
        error,
        videos: {
          ...state.videos,
          selected: video,
        },
      };
    }

    case types.MEDIA_RETRIEVE_VIDEO: {
      const {video, caption, title, description, videoTags, startSeconds, error} = action.payload;
      return {
        ...state,
        error,
        videos: {
          ...state.videos,
          list: [video],
          selected: video,
          selectedCaption: caption,
          selectedTitle: title,
          selectedDescription: description,
          selectedVideoTags: videoTags,
          selectedStartSeconds: startSeconds,
          isRetrieved: true,
        },
      };
    }

    case types.MEDIA_CLEAR: {
      const {type} = action.payload;
      if (type === null) {
        return initialState;
      }
      // Clear specific media type
      const prop = type === MEDIA_TYPE_IMAGE ? 'images' : 'videos';
      return {...state, [prop]: initialState[prop]};
    }

    default: {
      return state;
    }
  }
};

export default media;
