import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {USER_TEMPLATE_LOCK} from '../../../constants/user';
import {timeAgo} from '../../../helpers/dateHelpers';
import {getArticleType, getArticleUrl} from '../../../helpers/articleHelpers';
import {isArticleLocked, isArticleLockedByAnotherUser, isCurrentUserTheLastEditor} from '../../../helpers/softlockHelpers';
import {getProfileFromUserId, getUserFromProfile, getUserFullName} from '../../../helpers/userHelpers';
import {selectProfiles} from '../../../selectors/users';
import {articleDashboard} from '../../../utils/types';

import ArticleEditLink from './articleEditLink';
import ArticleEditors from './articleEditors';
import ArticleEditorsCurrent from './articleEditorsCurrent';
import ArticleFeedbackLink from './articleFeedbackLink';
import ArticlePublish from './articlePublish';
import ArticleStatusBadge from './articleStatusBadge';
import ArticleToolbar from './articleToolbar';
import User from '../user';

const ArticleRow = ({article}) => {
  const profiles = useSelector(selectProfiles);
  const [editorPrimary, setEditorPrimary] = useState(null);

  const {
    articleUpdatedAt: lastEdited,
    author: authorName,
    authorGatekeeperId: authorId,
    currentEditorsGkIds: editorsCurrentUserIds,
    editedByGatekeeperId: editorCurrentId,
    primaryEditorGatekeeperId: editorPrimaryId,
    feedbacksCount,
    status,
    title,
    usersWhoEditedGkIds: editorsUserIds,
  } = article;
  const articleLink = getArticleUrl(article);
  const editor = isArticleLocked(article) ? getUserFromProfile(profiles[editorCurrentId]) : null;
  const showLockedBy = editor && !isCurrentUserTheLastEditor(article);

  useEffect(() => {
    if (editorPrimaryId) {
      const setPrimaryEditor = async () => {
        const profiles = await getProfileFromUserId(editorPrimaryId);
        setEditorPrimary(profiles[0]);
      };

      setPrimaryEditor();
    }
  }, []);

  return (
    <div className={`group group-type--${getArticleType(article)}`} aria-label="Article">
      <div className="article-title-group">
        <ArticleEditLink title={title} url={articleLink} status={status} isLocked={isArticleLockedByAnotherUser(article)} />
        <div className="article-details">
          <div className="details">
            {authorName && (
              <div className="author">
                <span>Author:</span> {authorName}
                <ArticleEditorsCurrent userIds={editorsCurrentUserIds} />
                <ArticleEditors userIds={editorsUserIds} />
              </div>
            )}
            {editorPrimaryId && (
              <div className="qceditor">
                <span>QC Editor:</span> {getUserFullName(editorPrimary)}
              </div>
            )}
            <div className="edited">
              <span>Last Edited:</span> {timeAgo(lastEdited)}
            </div>
          </div>
        </div>
      </div>
      <div className="subgroup">
        {showLockedBy && <User user={editor} template={USER_TEMPLATE_LOCK} />}
        {feedbacksCount > 0 && (
          <div className="article-feedback">
            <ArticleFeedbackLink count={feedbacksCount} url={articleLink} status={status} editedBy={editor} author={{id: authorId}} />
          </div>
        )}
        <ArticleStatusBadge status={status} />
        <div className="article-date">
          <ArticlePublish article={article} />
        </div>
      </div>
      <div className="article-actions">
        <ArticleToolbar article={article} />
      </div>
    </div>
  );
};

ArticleRow.propTypes = {
  article: articleDashboard.isRequired,
};

export default ArticleRow;
