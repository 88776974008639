import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import {getUserRecentArticles} from '../../helpers/recentArticlesHelper';
import {CTT_USER_LEGACY_ID} from '../../constants/user';

const ArticlesRecent = () => {
  const [articles, setArticles] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const userId = parseInt(localStorage.getItem(CTT_USER_LEGACY_ID), 10);
    const articlesMap = getUserRecentArticles(userId);
    setArticles(Array.from(articlesMap));
  }, [location.pathname]);

  const compareFn = ([, itemA], [, itemB]) => itemB.lastEdited - itemA.lastEdited;

  const articlesList = articles.sort(compareFn).map(([id, item]) => {
    const {title, type} = item;
    return (
      <li key={id}>
        <NavLink end={true} to={{pathname: `/articles/${type}/${id}`, isAppLink: true}} title={title}>
          <span className={`article-icon article-icon-${type}`} />
          <span className="article-title">{title}</span>
        </NavLink>
      </li>
    );
  });

  return (
    <div className="articles-recent">
      <h3>Recently Edited Articles</h3>
      <ul>{articlesList}</ul>
    </div>
  );
};

export default ArticlesRecent;
