import {createAction} from '@reduxjs/toolkit';

import * as Gatekeeper from '../apis/auth/gatekeeper-api';
import {GATEKEEPER_API_LIMIT} from '../apis/auth/gatekeeper-api';
import * as types from '../constants/action-types';
import {chunk} from '../helpers/javascript';

import {setAuthData, setUserData, setAuthTokens, clearAuthData} from '../helpers/authHelpers';

export const setUserProfiles = createAction(types.USERS_PROFILES);

/**
 * Dispatch action to update redux state with the user profiles information
 * @param {array} ids list of user gatekeeper id's
 */
export const getUserProfilesByIds = (ids) => {
  return async (dispatch, getState) => {
    if (!ids) return; // do not send API request if no ids passed

    const {profiles: profilesInState} = getState().users;
    const idsUnique = [...new Set(ids)];
    const newIds = idsUnique.filter((id) => !profilesInState[id]);
    if (!newIds.length) return; // do not send the API request if id's are already present in state

    // chunk the newIds into groups not larger than the gatekeeper api limit
    const newIdsChunked = chunk(newIds, GATEKEEPER_API_LIMIT);

    const users = [];
    let apiError = false;
    for await (const group of newIdsChunked) {
      const res = await Gatekeeper.getUsersProfiles(group);
      if (res.error) {
        apiError = true;
        break;
      }
      users.push(...res.users);
    }

    const profiles = users.reduce((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {});

    dispatch(setUserProfiles({profiles, error: apiError}));
  };
};

/**
 * Obtain the API access token and store user profile information
 * @param {string} username gatekeeper account username
 * @param {string} password gatekeeper account password
 */
export async function login(username, password) {
  const {err, ...token} = await Gatekeeper.createJWT({username, password});
  if (err) return err;

  // retrieve user information by id-token pair
  const result = await Gatekeeper.fetchUser(token.userId, token.accessToken);
  if (result.err) return result.err;

  const profileId = (result.user && result.user.profileId) || null;
  if (!profileId) return new Error('User does not have a profile');

  // load user profile information
  const profile = await Gatekeeper.getUserProfileByProfileId(profileId);
  if (profile.error) return profile.error;

  setAuthData(token);
  setUserData(result.user, profile);
  return null;
}

/**
 * Retrieve access to the API by refreshing old token
 * This will set tokens in LocalStorage.
 *
 * @param {string} refreshToken API refresh token
 */
export async function refreshToken(refreshToken) {
  const {err, ...tokens} = await Gatekeeper.refreshJWT(refreshToken);
  if (err) {
    // refresh request failed, need to redirect to login page
    clearAuthData();
    return false;
  }
  setAuthTokens(tokens.accessToken, tokens.refreshToken);
  return true;
}
