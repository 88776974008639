import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO} from '../../constants/media';
import ALL_EVENTS, {
  EVENT_FEATURED_MEDIA_SEARCH,
  EVENT_FEATURED_MEDIA_UPLOAD,
  EVENT_IMAGE_UPLOAD,
  EVENT_MEDIA_EDIT,
  EVENT_MEDIA_PICKER,
  EVENT_MEDIA_SEARCH,
} from '../../constants/events';

import ToolEventObserver from '../../utils/editorjs/tools/toolEventObserver';
import {getActiveSlide, getIsSlideshow, getThumbnail} from '../../selectors/article';
import {selectIsLoading} from '../../selectors/media';
import {clearMedia, retrieveVideoById, setSelectedImage} from '../../actions/media';

import ImageSelector from './media/imageSelector';
import ImageSidebar from './media/imageSidebar';
import Modal from './modal';
import Search from './media/search';
import VideoSelector from './media/videoSelector';
import VideoSidebar from './media/videoSidebar';

export const getEditorBlockContent = (type, original, blockContent = original) => {
  const {__original__: original1, ...dataBlock} = blockContent;
  const {__original__: original2, ...dataOriginal} = original;

  return {
    type,
    data: {...dataBlock, __original__: dataOriginal},
  };
};

const EditorMedia = ({onMediaAdd, onMediaModalOpen}) => {
  const initialState = {
    blockId: null,
    event: null,
    imageCrop: null,
    isMetaVisible: true,
    isSearchVisible: true,
    isVisible: false,
    mediaForUpload: null,
    mediaHighlighted: null,
    mediaType: MEDIA_TYPE_IMAGE,
    onlyMediaType: null,
    shouldReplace: false,
    shouldReplaceArticleThumbnail: false,
  };

  const dispatch = useDispatch();
  const modalContentRef = useRef(null);
  const [state, setState] = useState(initialState);

  const activeSlide = useSelector(getActiveSlide);
  const isLoading = useSelector(selectIsLoading);
  const isSlideshow = useSelector(getIsSlideshow);
  const thumbnail = useSelector(getThumbnail);

  const onMediaSearchEventTriggered = ({editorBlockIndex, shouldReplace = false, shouldReplaceArticleThumbnail = false, ...rest}) => {
    setState({
      ...state,
      isVisible: true,
      event: EVENT_MEDIA_SEARCH,
      blockId: editorBlockIndex,
      mediaType: rest.mediaType || state.mediaType,
      onlyMediaType: rest.mediaType || state.onlyMediaType,
      shouldReplace,
      shouldReplaceArticleThumbnail,
    });
    onMediaModalOpen();
  };

  const onImageUploadEventTriggered = (image) => {
    setState({
      ...state,
      isVisible: true,
      event: EVENT_IMAGE_UPLOAD,
      mediaForUpload: image,
    });
    onMediaModalOpen();
  };

  const onMediaPickerEventTriggered = ({type, showMeta}) => {
    setState({
      ...state,
      isVisible: true,
      event: EVENT_MEDIA_PICKER,
      isMetaVisible: showMeta,
      mediaType: type,
      onlyMediaType: type,
    });
    onMediaModalOpen();
  };

  const onMediaEditEventTriggered = ({editorBlockIndex, mediaType, media}) => {
    if (mediaType === MEDIA_TYPE_IMAGE) {
      // Set selected image in redux for make it editable by media workflow
      const {caption, credit, file, sourceCaption, sourceCredit, userCaption} = media;
      dispatch(setSelectedImage(file, {caption, credit, sourceCaption, sourceCredit, userCaption}));
    }
    if (mediaType === MEDIA_TYPE_VIDEO) {
      // Dispatch action for retrieve video
      const {caption, description, id, title, startSeconds, videoTags} = media;
      dispatch(retrieveVideoById(id, caption, title, description, videoTags, startSeconds));
    }
    setState({
      ...state,
      isVisible: true,
      event: EVENT_MEDIA_EDIT,
      blockId: editorBlockIndex,
      onlyMediaType: mediaType,
      shouldReplace: true,
      isSearchVisible: false,
      mediaType,
    });
    onMediaModalOpen();
  };

  const onFeaturedMediaUploadEventTriggered = (image) => {
    setState({
      ...state,
      isVisible: true,
      event: EVENT_FEATURED_MEDIA_UPLOAD,
      mediaForUpload: image,
      isSearchVisible: false,
    });
    onMediaModalOpen();
  };

  const onFeaturedMediaSearchEventTriggered = () => {
    setState({
      ...state,
      isVisible: true,
      event: EVENT_FEATURED_MEDIA_SEARCH,
      blockId: false,
    });
    onMediaModalOpen();
  };

  const subscribeTo = (event) => {
    switch (event) {
      case EVENT_MEDIA_SEARCH: {
        // Subscribe to editor media tool event
        return ToolEventObserver.subscribe(event, onMediaSearchEventTriggered);
      }
      case EVENT_IMAGE_UPLOAD: {
        // Subscribe to event that triggered by page image dnd
        return ToolEventObserver.subscribe(event, onImageUploadEventTriggered);
      }
      case EVENT_MEDIA_PICKER: {
        // Subscribe to event that triggered by article image thumbnail
        return ToolEventObserver.subscribe(event, onMediaPickerEventTriggered);
      }
      case EVENT_MEDIA_EDIT: {
        // Subscribe to event that triggered by image block settings action
        return ToolEventObserver.subscribe(event, onMediaEditEventTriggered);
      }
      case EVENT_FEATURED_MEDIA_UPLOAD: {
        // Subscribe to event that triggered by slideshow feature image dnd
        return ToolEventObserver.subscribe(event, onFeaturedMediaUploadEventTriggered);
      }
      case EVENT_FEATURED_MEDIA_SEARCH: {
        // Subscribe to event that triggered by slideshow feature block media search
        return ToolEventObserver.subscribe(event, onFeaturedMediaSearchEventTriggered);
      }
      default: {
        return null;
      }
    }
  };

  const handleSubmit = (block, isForThumbnail = false) => {
    const {blockId, event, shouldReplace, shouldReplaceArticleThumbnail} = state;
    onMediaAdd(block, blockId, event, shouldReplace, shouldReplaceArticleThumbnail, isForThumbnail);
    setState(initialState);
    onMediaModalOpen(false);
    dispatch(clearMedia(null));
  };

  const handleCancel = () => {
    setState(initialState);
    onMediaModalOpen(false);
    dispatch(clearMedia(null));
  };

  const handleSearchReset = () => {
    // reset selected image to clear sidebar
    dispatch(clearMedia(null));
    setState({
      ...state,
      imageCrop: initialState.imageCrop,
      mediaHighlighted: initialState.mediaHighlighted,
    });
  };

  const handleSearchClear = () => {
    const {mediaType} = state;
    handleSearchReset();
    dispatch(clearMedia(mediaType));
  };

  const getImageSelector = () => {
    const {imageCrop, mediaForUpload, mediaHighlighted} = state;
    return (
      <ImageSelector
        cropConfig={imageCrop}
        imageForUpload={mediaForUpload}
        onPaginateButton={() => modalContentRef.current.scrollTo(0, 0)}
        onPick={(mediaHighlighted) => setState({...state, mediaHighlighted})}
        onSelect={(imageCrop) => setState({...state, imageCrop})}
        picked={mediaHighlighted}
      />
    );
  };

  const getImageSidebar = () => {
    const {blockId, imageCrop, isMetaVisible, mediaHighlighted, shouldReplace} = state;

    let showReplaceThumbnail = blockId === 0 && (shouldReplace || thumbnail !== null);
    if (isSlideshow) showReplaceThumbnail = showReplaceThumbnail && activeSlide === 0;

    return (
      <ImageSidebar
        showMeta={isMetaVisible}
        showReplaceThumbnail={showReplaceThumbnail}
        picked={mediaHighlighted}
        cropConfig={imageCrop}
        onSubmit={(url, credit, userCaption, selection, isForThumbnail) => {
          const data = {
            ...selection,
            userCaption,
            credit,
            url,
          };
          if (selection.uploadedByUserID) {
            data.user_id = selection.uploadedByUserID;
          }
          const block = getEditorBlockContent('image', data, {
            userCaption,
            credit,
            file: {url},
          });
          handleSubmit(block, isForThumbnail);
        }}
        onCancel={handleCancel}
        onChangeThumbnail={(checked) => {
          setState({...state, shouldReplaceArticleThumbnail: checked});
        }}
      />
    );
  };

  const getVideoSelector = () => {
    const {isSearchVisible} = state;
    return <VideoSelector zoomed={!isSearchVisible} onZoom={(isZoomed) => setState({...state, isSearchVisible: !isZoomed})} />;
  };

  const getVideoSidebar = () => {
    const {isSearchVisible} = state;
    return (
      <VideoSidebar
        zoomed={!isSearchVisible}
        onZoom={(isZoomed) => setState({...state, isSearchVisible: !isZoomed})}
        onSubmit={(metadata) => {
          const {
            videoId,
            caption,
            thumbnail,
            title,
            description,
            videoTags,
            hideFromRegions,
            hls,
            progressive,
            startSeconds,
            originalTitle,
            originalDescription,
          } = metadata;
          const block = getEditorBlockContent(
            'video',
            {
              metadata: {
                title: originalTitle,
                description: originalDescription,
                hideFromRegions,
                hls,
                progressive,
                videoId,
              },
            },
            {
              metadata: {
                caption,
                description,
                hideFromRegions,
                hls,
                progressive,
                startSeconds,
                thumbnail,
                title,
                videoId,
                videoTags,
              },
            }
          );
          handleSubmit(block);
        }}
        onCancel={handleCancel}
      />
    );
  };

  const renderSelector = () => {
    if (state.mediaType === MEDIA_TYPE_IMAGE) return getImageSelector();
    return getVideoSelector();
  };

  const renderSidebar = () => {
    if (state.mediaType === MEDIA_TYPE_IMAGE) return getImageSidebar();
    return getVideoSidebar();
  };

  useEffect(() => {
    const subs = ALL_EVENTS.map((event) => [event, subscribeTo(event)]);

    return () =>
      subs.map(([event, id]) => {
        if (id !== null) ToolEventObserver.unsubscribe(event, id);
      });
  }, []);

  const {isSearchVisible, isVisible, mediaType, onlyMediaType} = state;

  // Hide media popup
  if (!isVisible) return null;

  return (
    <Modal closeOnBodyClick={false} title="Add Media" isLoading={isLoading} onClose={handleCancel}>
      <div className="content" ref={modalContentRef}>
        {isSearchVisible && (
          <Search
            mediaType={mediaType}
            hideImageFilter={onlyMediaType === MEDIA_TYPE_VIDEO}
            hideVideoFilter={onlyMediaType === MEDIA_TYPE_IMAGE}
            onCancel={handleSearchClear}
            onSearch={handleSearchReset}
            onMediaTypeSelect={(mediaType) => setState({...state, mediaType})}
          />
        )}
        {renderSelector()}
      </div>
      {renderSidebar()}
    </Modal>
  );
};

EditorMedia.defaultProps = {
  onMediaModalOpen: () => {},
};

EditorMedia.propTypes = {
  onMediaAdd: PropTypes.func.isRequired,
  onMediaModalOpen: PropTypes.func.isRequired,
};

export default EditorMedia;
