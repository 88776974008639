import reporter from '../../reporter';

import logger from '../../logger';

import {searchImages as searchAP} from './apAPI';
import {searchImages as searchGetty} from './gettyAPI';

import {Proxies, Video} from '../../endpoints';
import {fetchJSON} from '../helpers/fetch';

import {IMAGE_SEARCH_FILTERED_PAGE_SIZE, IMAGE_SEARCH_UNFILTERED_PAGE_SIZE, VIDEO_TYPE_VIDEO} from '../../constants/media';

const EMPTY_RESULTS = {
  assets: [],
  itemTotalCount: 0,
};

export const getImages = (query, offset, filteredBy = null, imageSearchOptions = {}) => {
  const page = imageSearchOptions.page || 1;
  const apSearch = searchAP({
    query,
    page,
    paginationUrlTemplate: imageSearchOptions.paginationUrlAp,
  }).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {
      ...EMPTY_RESULTS,
      error,
    };
  });

  const gettySearch = searchGetty({
    query,
    page,
  }).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {
      ...EMPTY_RESULTS,
      error,
    };
  });

  return Promise.all([gettySearch, apSearch])
    .then(([getty, ap]) => {
      const offsetNew = filteredBy ? offset + IMAGE_SEARCH_FILTERED_PAGE_SIZE : offset + IMAGE_SEARCH_UNFILTERED_PAGE_SIZE;

      return {
        hasMore: getty.itemTotalCount + ap.itemTotalCount > offsetNew,
        hasMoreAP: ap.itemTotalCount > offsetNew,
        hasMoreGetty: getty.itemTotalCount > offsetNew,
        images: {getty: getty.assets, ap: ap.assets},
        itemTotalCount: getty.itemTotalCount + ap.itemTotalCount,
        itemTotalCountAP: ap.itemTotalCount,
        itemTotalCountGetty: getty.itemTotalCount,
        offset: offsetNew,
        page,
        paginationUrlAp: ap.paginationUrlTemplate,
      };
    })
    .catch((err) => {
      reporter.inform(err);
      logger.error(err);
      return {itemTotalCount: 0, images: [], error: true};
    });
};

export const getVideos = async (query, limit, type = VIDEO_TYPE_VIDEO) => {
  let videos;
  try {
    // The frontend makes a call to the Librarian backend (defined in src/endpoints.js),
    // passing along the required param values. The proxyHandler in /src/routes/handlers/proxy.js
    // receives this request and decides how to handle it.
    const endpoint = Proxies.librarianVideoSearch(encodeURI(query), limit, type);
    videos = await fetchJSON(endpoint);
  } catch (error) {
    logger.error('Could not fetch videos from Librarian service');
    logger.error(error);
    reporter.inform(error);
    return {videos: [], error};
  }

  return {videos, error: null};
};

export const getVideo = (videoId) => {
  const endpoint = Video.getById(videoId);
  return fetchJSON(endpoint)
    .then((video) => {
      return {video, error: null};
    })
    .catch((error) => {
      logger.error(error);
      reporter.inform(error);
      return {video: null, error};
    });
};
