import {addDiffClass} from '../difference';

/**
 * Displays a 300x250px Ad placeholder
 */
require('./index.css');

export default class Ad {
  constructor({data, readOnly}) {
    this.ad = '';
    this.wrapper = null;
    this.data = data;
    this.readOnly = readOnly;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  render() {
    const container = document.createElement('div');
    container.classList.add('media-container', 'media-container-ad');
    addDiffClass(container, this.data);

    const ad = document.createElement('div');
    ad.classList.add('ad');
    ad.innerHTML = 'Ad';

    container.appendChild(ad);

    this.ad = container;
    return this.ad;
  }

  renderSettings() {
    return this.wrapper;
  }

  save() {
    return '';
  }
}
