import React from 'react';
import PropTypes from 'prop-types';

import useGlobalNotificationsError from './useGlobalNotificationsError';

/**
 * Helper that provides to use global notifications helpers in class components.
 * Global notification helper is available under "notifications" property.
 * @param {React.Component} component that should be wrapped in global notifications
 * @example
 *  class Example extends React.Component {
 *    render() {
 *      return <div>Example Component</div>
 *    }
 *  }
 *  export default withGlobalNotifications(Example)
 */
function withGlobalNotifications(Component) {
  return (props) => {
    const notifications = useGlobalNotificationsError();
    return <Component notifications={notifications} {...props} />;
  };
}

export const typeNotification = PropTypes.shape({
  error: PropTypes.object,
  addError: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
});

export default withGlobalNotifications;
