// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad {
  display: flex;
  width: 300px;
  height: 250px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 1.5rem;
  background: #eee;
  color: #a6a6a6;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/ad/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".ad {\n  display: flex;\n  width: 300px;\n  height: 250px;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n  padding: 1.5rem;\n  background: #eee;\n  color: #a6a6a6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
