import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {updateArticle} from '../../../actions/article';
import {
  ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH,
  ARTICLE_SOCIAL_TITLE_MAXLENGTH,
  VALIDATE_FIELD_SOCIAL_DESCRIPTION,
  VALIDATE_FIELD_SOCIAL_TITLE,
} from '../../../constants/article';
import {removeHtmlTags, removeNonBreakingSpaces} from '../../../helpers/textHelpers';
import {getIsReadOnly, getSocialTeaser, getSocialTitle, getTitle} from '../../../selectors/article';
import useGlobalNotificationsError from '../../../utils/notifications/useGlobalNotificationsError';
import EditorField from '../editorField';

import {PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL, PROMPT_SOCIAL_EDIT_CONFIRM} from '../../../constants/prompt';
import {prompt} from '../../../helpers/promptHelper';

const MetaSocial = () => {
  const dispatch = useDispatch();
  const isReadOnly = useSelector(getIsReadOnly);
  const title = useSelector(getTitle); // TODO: will be used to find out if "in sync"
  const socialTitle = useSelector(getSocialTitle);
  const socialTeaser = useSelector(getSocialTeaser);
  const {removeError} = useGlobalNotificationsError();

  // note: it's possible that the title could be unsync'd and not the description but for simplicity only using the title to proxy both fields
  const isSocialTitleSyncd = socialTitle === title;
  const [hasConfirmedSocialEdit, setHasConfirmedSocialEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(isReadOnly || isSocialTitleSyncd);

  const handleAllowEdit = () => {
    prompt(PROMPT_SOCIAL_EDIT_CONFIRM)
      .withTexts(
        'Are you sure you want to edit the social metadata?',
        'Warning: the social title & description are kept in sync with the article content. If you edit these directly they will unsync from any future content updates and need to be maintained separately. Proceed with caution.', // eslint-disable-line max-len
        PROMPT_BUTTON_CONFIRM,
        PROMPT_BUTTON_CANCEL
      )
      .withMute(false)
      .show(() => {
        setHasConfirmedSocialEdit(true);
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    setIsDisabled(isReadOnly || isSocialTitleSyncd);
  }, [isReadOnly]);

  return (
    <div className="atom meta-social">
      {!isSocialTitleSyncd && (
        <div className="note">
          <em>Note: the social metadata has been modified directly and is no longer in sync with the content.</em>
        </div>
      )}
      {isSocialTitleSyncd && !hasConfirmedSocialEdit && (
        <button disabled={isReadOnly} onClick={() => handleAllowEdit()}>
          Edit Social Metadata
        </button>
      )}
      <EditorField
        type="text"
        name="meta-social-title"
        autoFocus={false}
        placeholder="Add social title…"
        maxLength={ARTICLE_SOCIAL_TITLE_MAXLENGTH}
        disabled={isDisabled}
        value={socialTitle || ''}
        doNormalize={false}
        doNormalizeBlur={true}
        onChange={async ({target}) => {
          removeError(VALIDATE_FIELD_SOCIAL_TITLE);
          await dispatch(updateArticle({socialTitle: target.value}));
        }}>
        Social Title
      </EditorField>
      <EditorField
        type="text"
        name="meta-social-description"
        autoFocus={false}
        rows={4}
        placeholder="Add social description…"
        maxLength={ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH}
        disabled={isDisabled}
        value={socialTeaser ? removeNonBreakingSpaces(removeHtmlTags(socialTeaser)) : ''}
        doNormalize={false}
        doNormalizeBlur={true}
        onChange={async ({target}) => {
          removeError(VALIDATE_FIELD_SOCIAL_DESCRIPTION);
          await dispatch(updateArticle({teaser: target.value}));
        }}>
        Social Description
      </EditorField>
    </div>
  );
};

export default MetaSocial;
