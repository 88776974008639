import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';

import {fetchDashboard} from '../../../actions/dashboard';
import {STATUS_DELETED} from '../../../constants/article';
import {getFiltersList} from '../../../helpers/dashboardHelpers';
import {selectFilter, selectLoading, selectShowMine} from '../../../selectors/dashboard';

const DashboardFiltersStatus = () => {
  const dispatch = useDispatch();
  const filteredBy = useSelector(selectFilter);
  const isLoading = useSelector(selectLoading);
  const showMine = useSelector(selectShowMine);
  const navigate = useNavigate();

  if (filteredBy === STATUS_DELETED) return null;
  const filters = getFiltersList();

  const onFilterValueChanged = (filter) => {
    if (filter === filteredBy) return;
    navigate({search: ''}, {replace: true}); // remove all search params & hashes when change tabs
    dispatch(fetchDashboard({filter, showMine}));
  };

  const getStatusFilters = () => {
    return Object.entries(filters).map(([key, title]) => {
      return (
        <button key={key} className={classnames({active: key === filteredBy})} disabled={isLoading} onClick={() => onFilterValueChanged(key)}>
          {title}
        </button>
      );
    });
  };

  return <div className="atom dashboard-filters-status">{getStatusFilters()}</div>;
};

export default DashboardFiltersStatus;
