import {combineReducers} from 'redux';

import article from './article';
import dashboard from './dashboard';
import editorStats from './editorStats';
import media from './media';
import search from './search';
import ui from './ui';
import users from './users';
import versions from './versions';

export default combineReducers({
  article,
  dashboard,
  editorStats,
  media,
  search,
  ui,
  users,
  versions,
});
