import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';

import {fetchDashboard} from '../../../actions/dashboard';
import {FILTER_PUBLISHED, FILTER_TO_PUBLISH} from '../../../constants/dashboard';
import {isAdmin, isQCEditor} from '../../../helpers/articleHelpers';
import {selectFilter, selectShowEdited, selectShowMine, selectShowUnedited} from '../../../selectors/dashboard';

const DashboardFiltersEdited = () => {
  const dispatch = useDispatch();
  const filteredBy = useSelector(selectFilter);
  const showEdited = useSelector(selectShowEdited);
  const showUnedited = useSelector(selectShowUnedited);
  const showMine = useSelector(selectShowMine);
  const refAll = useRef(0);
  const refEdited = useRef(0);
  const refUnedited = useRef(0);

  const isVisible = () => {
    // Non-QC Editors currently excluded because they do not have the "MANAGE_EDITORS" scope which is required to make
    // the "only_edited=<true/false>" articles request param filter the articles endpoint response
    if (!isQCEditor() && !isAdmin()) return false;
    if (![FILTER_PUBLISHED, FILTER_TO_PUBLISH].includes(filteredBy)) return false;
    return true;
  };

  const setFilterChange = () => {
    dispatch(fetchDashboard({offset: 0, showMine, showEdited: refEdited.current.checked, showUnedited: refUnedited.current.checked}));
  };

  const onFilterAllChanged = () => {
    if (refAll.current.checked) {
      refEdited.current.checked = false;
      refUnedited.current.checked = false;
    }
    setFilterChange();
  };

  const onFilterEditedChanged = () => {
    if (refEdited.current.checked) {
      refAll.current.checked = false;
      refUnedited.current.checked = false;
    }
    if (!refEdited.current.checked && !refUnedited.current.checked) refAll.current.checked = true;
    setFilterChange();
  };

  const onFilterUneditedChanged = () => {
    if (refUnedited.current.checked) {
      refAll.current.checked = false;
      refEdited.current.checked = false;
    }
    if (!refEdited.current.checked && !refUnedited.current.checked) refAll.current.checked = true;
    setFilterChange();
  };

  const getAllControl = () => {
    const classes = classnames('control', 'control-all', {disabled: !refAll.current}, {checked: refAll.current && refAll.current.checked});
    return (
      <div className={classes}>
        <label>
          <input
            type="checkbox"
            defaultChecked={true}
            ref={refAll}
            onChange={() => {
              onFilterAllChanged();
            }}
          />
          All
        </label>
      </div>
    );
  };

  const getEditedControl = () => {
    const classes = classnames('control', 'control-edited', {
      disabled: !refEdited.current,
      checked: refEdited.current && refEdited.current.checked,
    });
    return (
      <div className={classes}>
        <label>
          <input type="checkbox" defaultChecked={showEdited} ref={refEdited} onChange={() => onFilterEditedChanged()} />
          Edited
        </label>
      </div>
    );
  };

  const getUneditedControl = () => {
    const classes = classnames('control', 'control-unedited', {
      disabled: !refUnedited.current,
      checked: refUnedited.current && refUnedited.current.checked,
    });
    return (
      <div className={classes}>
        <label>
          <input type="checkbox" defaultChecked={showUnedited} ref={refUnedited} onChange={() => onFilterUneditedChanged()} />
          Unedited
        </label>
      </div>
    );
  };

  if (!isVisible()) return null;

  return (
    <div className="atom dashboard-filters-edited">
      {getAllControl()}
      {getEditedControl()}
      {getUneditedControl()}
    </div>
  );
};

export default DashboardFiltersEdited;
