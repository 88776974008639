// Validation
export const VALIDATE_FIELD_MAIN = 'slideshow.main';
export const VALIDATE_FIELD_MEDIA = 'slideshow.media';

export const VALIDATE_FIELDS_SLIDESHOW = [VALIDATE_FIELD_MAIN, VALIDATE_FIELD_MEDIA];

// Default frames
export const SLIDES_COUNT_DEFAULT = 5;

// Add new frames
export const MIN_ADD_FRAMES = 1;
export const MAX_ADD_FRAMES = 99;
