import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import IconButton from '../iconButton';
import VersionSelector from './versionSelector';

import {bypassLock, fetchArticle, releaseLock, saveArticle, updateArticle, unsetArticle} from '../../../actions/article';
import {setShowChanges, setShowCompareView} from '../../../actions/versions';
import {PROMPT_VERSIONS_RESTORE} from '../../../constants/prompt';
import {getArticleUrl, isEditAllowed, isStandard} from '../../../helpers/articleHelpers';
import {prompt} from '../../../helpers/promptHelper';
import {selectActiveVersion, selectCurrentVersion, selectShowChanges, selectShowCompareView, selectVersions} from '../../../selectors/versions';
import classnames from 'classnames';

function VersionsHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refCompareView = useRef(0);
  const refShowChanges = useRef(0);

  const activeVersion = useSelector(selectActiveVersion);
  const currentVersion = useSelector(selectCurrentVersion);
  const versions = useSelector(selectVersions);

  const isActiveCurrent = activeVersion.id === currentVersion.id;
  const isComparison = useSelector(selectShowCompareView);
  const showChanges = useSelector(selectShowChanges);

  const showCompareVersions = isStandard(activeVersion.content);
  const showRestore = isEditAllowed() && !isActiveCurrent && !isComparison;
  const isCompareVersionsDisabled = versions.length === 1;

  const onCloseButtonClicked = () => {
    // return user to the article edit page
    navigate(getArticleUrl(currentVersion.content));
  };

  const onRestoreClick = async () => {
    const data = activeVersion.content;

    // saveArticle() expecting 'id' or 'breportId', but state using 'articleId', must be set or restore will save as a new article
    if (!data.id && !data.breportId && data.articleId) data.id = data.articleId;

    // save article version to redux state before saved
    await dispatch(updateArticle(data));

    prompt(PROMPT_VERSIONS_RESTORE)
      .withTexts(
        'Are you sure you want to restore to this version?',
        'Please note, if this article is published and you wish to restore this version it will immediately update the live article.',
        'Restore',
        'Cancel'
      )
      .withMute(false)
      .show(async () => {
        await dispatch(bypassLock(data.id)); // you must have the lock to save
        await dispatch(saveArticle()); // save this version as a new version
        await dispatch(releaseLock(data.id));
        await dispatch(unsetArticle()); // clear article state
        await dispatch(fetchArticle({id: data.id})); // refetch article before redirecting to edit page
        navigate(getArticleUrl(currentVersion.content)); // return user to the article edit page
      });
  };

  const onCompareVersionsClicked = async () => {
    await dispatch(setShowCompareView(refCompareView.current.checked));
  };

  const onShowChanges = async () => {
    await dispatch(setShowChanges(refShowChanges.current.checked));
  };

  return (
    <div className="atom versions-header">
      <div className="header-side header-left">
        <h1>Version History</h1>
        <VersionSelector isHeader={true} />
      </div>
      <div className="header-side header-right">
        {showRestore && (
          <button className="button-restore" onClick={onRestoreClick}>
            Restore to this version
          </button>
        )}
        {showCompareVersions && (
          <div className={classnames('control-compare-versions', {disabled: isCompareVersionsDisabled})}>
            <label>
              <input
                type="checkbox"
                defaultChecked={isComparison}
                onClick={onCompareVersionsClicked}
                ref={refCompareView}
                disabled={isCompareVersionsDisabled}
              />
              Compare Versions
            </label>
          </div>
        )}
        <div className="control-show-changes">
          <label>
            <input type="checkbox" defaultChecked={showChanges} onClick={onShowChanges} ref={refShowChanges} /> Show Changes
          </label>
        </div>
        <IconButton icon="close" className="button-close" onClick={onCloseButtonClicked} />
      </div>
    </div>
  );
}

export default VersionsHeader;
