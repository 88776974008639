import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {format} from 'date-fns';

import {setDateEnd, setDateStart} from '../../../actions/editorStats';
import {DATE_FORMAT_DAY, DATE_MINIMUM} from '../../../constants/date';
import {setDateValue} from '../../../helpers/dateHelpers';
import {selectDateEnd, selectDateStart} from '../../../selectors/editorStats';

const EditorStatsFilterDates = () => {
  const dispatch = useDispatch();
  const dateFormat = DATE_FORMAT_DAY;
  const dateEnd = useSelector(selectDateEnd);
  const dateStart = useSelector(selectDateStart);
  const dateStartValue = setDateValue(dateStart);
  const dateEndValue = setDateValue(dateEnd);

  const handleChangeDateStart = (dateSelected) => {
    const date = format(dateSelected, dateFormat);
    dispatch(setDateStart(date));
  };

  const handleChangeDateEnd = (dateSelected) => {
    const date = format(dateSelected, dateFormat);
    dispatch(setDateEnd(date));
  };

  return (
    <div className="atom filter-dates">
      <div className="date date-start">
        <span className="label">Start Date:</span>
        <DatePicker
          calendarIcon={null}
          clearIcon={null}
          format={dateFormat}
          locale="en-US"
          maxDate={new Date(dateEnd)}
          minDate={new Date(DATE_MINIMUM)}
          onChange={(selected) => handleChangeDateStart(selected)}
          onFocus={(e) => e.target.blur()}
          returnValue="start"
          value={dateStartValue}
        />
      </div>
      <div className="date date-end">
        <span className="label">End Date:</span>
        <DatePicker
          calendarIcon={null}
          clearIcon={null}
          format={dateFormat}
          locale="en-US"
          maxDate={new Date()}
          minDate={new Date(DATE_MINIMUM)}
          onChange={(selected) => handleChangeDateEnd(selected)}
          onFocus={(e) => e.target.blur()}
          returnValue="end"
          value={dateEndValue}
        />
      </div>
    </div>
  );
};

export default EditorStatsFilterDates;
