import React, {useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {default as EditorJS} from '@stfy/react-editor.js';
import classnames from 'classnames';
import Undo from 'editorjs-undo';

import logger from '../../logger';
import {EDITORJS_I18N} from '../../utils/editorjs/i18n';
import {EDITORJS_TOOLS, EDITORJS_TOOLS_TEXTONLY} from '../../utils/editorjs/tools';
import {
  FEATURED_MEDIA_BLOCK_INDEX,
  FEATURED_MEDIA_CONTENT_TYPES,
  VALIDATE_FIELD_BODY,
  VALIDATE_FIELD_FEATUREDMEDIA,
  VALIDATE_SLIDES_BODY_INDEX,
} from '../../constants/article';
import {VALIDATE_FIELD_MAIN} from '../../constants/slideshow';
import {EDITOR_ORIGIN_EVENTS} from '../../constants/events';
import {EDITOR_TOOLSET_TEXTONLY, EDITOR_TOOL_LIST_ORDERED_STYLE, EDITOR_TOOL_LIST_UNORDERED_STYLE} from '../../constants/editor';
import {getWordCount} from '../../helpers/wordCount';
import {getIsReadOnly, getIsSlideshow} from '../../selectors/article';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';

import EditorEmbeds from './editorEmbeds';
import EditorMedia from './editorMedia';
import {containsIncorrectQuotations, replaceQuotations} from '../../helpers/textHelpers';

const Editor = (props) => {
  const {
    activeSlideIndex,
    autofocus,
    data,
    editorProps,
    featuredMedia,
    name,
    onData,
    onMediaAdd,
    onMediaModalOpen,
    onReady,
    placeholder,
    toolset,
    useUndo,
  } = props;
  const isReadOnly = useSelector(getIsReadOnly);
  const isSlideshow = useSelector(getIsSlideshow);
  const {error, removeError} = useGlobalNotificationsError();

  // Checking for error for standard and slideshow article
  const hasErrorCheck = () => {
    if (name !== 'body') return false;
    const validateCurrentSlideBodyContent = isSlideshow
      ? error[VALIDATE_SLIDES_BODY_INDEX] && error[VALIDATE_SLIDES_BODY_INDEX][activeSlideIndex]
      : true;
    if ((error[VALIDATE_FIELD_BODY] || error[VALIDATE_FIELD_MAIN]) && validateCurrentSlideBodyContent) return true;
    return false;
  };
  const hasError = hasErrorCheck();
  const editorID = `editor-${name}`;
  const editorRef = useRef();
  const classes = classnames('atom', 'editor', editorID, {'has-featured-media': featuredMedia}, {'has-error': hasError}, {'is-readonly': isReadOnly});
  const logLevel = process.env.EDITORJS_LOGLEVEL || 'ERROR';

  const [undoTool, setUndoTool] = useState(null);
  const [isEditorInit, setIsEditorInit] = useState(false); // the EditorJS instance will be awaited once this is set to true
  const isTextOnly = toolset === EDITOR_TOOLSET_TEXTONLY;

  const isFirstBlockFeaturedMediaType = (index = 0) => {
    const {blocks} = editorRef.current.editor;
    if (!blocks) return false;

    const firstBlockType = blocks.getBlockByIndex(index).name;
    return FEATURED_MEDIA_CONTENT_TYPES.includes(firstBlockType);
  };

  const initUndo = () => {
    try {
      // Use 0 debounceTimer so every character typed is recorded as a change by editorjs-undo,
      // and also allow a maximum of 150 undo operations instead of the default of 30
      const undoOptions = {
        editor: editorRef.current.editor,
        config: {
          debounceTimer: 0,
        },
        onUpdate: null,
        maxLength: 150,
      };
      const undo = new Undo(undoOptions);
      const {blocks} = data;

      // Only initialize when editing an existing article
      if (blocks.length) undo.initialize(data);
      setUndoTool(undo);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (!useUndo && undoTool) {
      document.dispatchEvent(new Event('destroy'));
      setUndoTool(null);
    }
    return () => setUndoTool(null);
  }, [useUndo]);

  useEffect(() => {
    if (isEditorInit) {
      const {isReady, readOnly} = editorRef.current.editor;
      const waitUntilEditorReady = async () => await isReady;

      waitUntilEditorReady();

      if (data?.blocks) {
        const onReadyData = data.type === 'slide' ? {blocks: data.blocks} : data;
        onReady(onReadyData, getWordCount(data));
      }
      if (useUndo && !isReadOnly) initUndo();
      if (isReadOnly !== readOnly.isEnabled) readOnly.toggle();
    }
    return () => setUndoTool(null);
  }, [isEditorInit, isReadOnly]);

  useEffect(() => {
    const {blocks} = editorRef.current.editor;
    const {type, data} = featuredMedia || {};

    // when featured media is removed we need to also delete it from editor.js
    if (!featuredMedia && isFirstBlockFeaturedMediaType()) blocks?.delete(FEATURED_MEDIA_BLOCK_INDEX);

    // when featured media is added, also add it to editor.js
    if (featuredMedia && !isFirstBlockFeaturedMediaType()) blocks?.insert(type, data, {}, FEATURED_MEDIA_BLOCK_INDEX);
  }, [featuredMedia]);

  const scrollToBlock = (index) => {
    const block = editorRef.current.editor.blocks.getBlockByIndex(index);
    block?.scrollIntoView?.(true);
  };

  const isEditorMediaEvent = (event) => {
    return EDITOR_ORIGIN_EVENTS.includes(event);
  };

  const handleIncorrectQuotations = async () => {
    if (!editorRef?.current?.editor) return;
    const {blocks} = await editorRef.current.editor;
    const blockCount = blocks?.getBlocksCount();
    const indicies = [...Array(blockCount).keys()];
    indicies.forEach((index) => {
      const block = blocks.getBlockByIndex(index);
      const {
        holder: {innerText},
      } = block;

      if (!containsIncorrectQuotations(innerText)) return;

      switch (block.name) {
        case 'list': {
          const isOrderedList = block.holder.querySelector('ol') !== null;
          const items = [...block.holder.querySelectorAll('li')].map((item) => replaceQuotations(item.innerText));
          const data = {items, style: isOrderedList ? EDITOR_TOOL_LIST_ORDERED_STYLE : EDITOR_TOOL_LIST_UNORDERED_STYLE};
          blocks.update(block.id, data);
          break;
        }
        default: {
          const data = {text: replaceQuotations(innerText)};
          blocks.update(block.id, data);
          break;
        }
      }
    });
  };

  useEffect(() => {
    const handlePaste = (e) => {
      const pastedText = e.clipboardData.getData('text/plain');
      if (containsIncorrectQuotations(pastedText)) {
        handleIncorrectQuotations(); // this will update the editor blocks with the correct quotations
        const {value, selectionStart, selectionEnd} = e.target;
        // find the user's cursor position, and update the input value with the correct quotations
        if (value) e.target.value = `${value?.substring(0, selectionStart)}${replaceQuotations(pastedText)}${value?.substring(selectionEnd)}`;
        else e.target.value = replaceQuotations(pastedText);
        // set the cursor position where it was, otherwise it will be at the end of the input after pasting in the middle of a string
        e.target && e.target.focus();
        e.target.selectionStart = selectionStart + pastedText.length;
        e.target.selectionEnd = selectionEnd + pastedText.length;
        e.preventDefault();
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => window.removeEventListener('paste', handlePaste);
  }, []);

  const saveBlockChanges = async () => {
    const save = editorRef?.current?.editor?.saver?.save;
    if (save) {
      const editorData = await save();
      onData(editorData, getWordCount(editorData));
    }
  };

  const handleChange = () => {
    if (hasError) {
      removeError(VALIDATE_SLIDES_BODY_INDEX, VALIDATE_FIELD_BODY, VALIDATE_FIELD_MAIN, VALIDATE_FIELD_FEATUREDMEDIA);
    }
    // workaround for bug causing multiblock paste/delete to lose data on save
    // https://github.com/codex-team/editor.js/issues/1755#issuecomment-929550729
    setTimeout(saveBlockChanges, 200);
  };

  const handleMediaAdd = (content, blockId, event, shouldReplace = false, shouldReplaceArticleThumbnail, isForThumbnail = false) => {
    if (content === null) return;

    const {type, data} = content;
    const {blocks} = editorRef.current.editor;
    const index = blockId === null ? blocks.getBlocksCount() : blockId;

    if (isEditorMediaEvent(event)) {
      if (shouldReplace) blocks.update(blocks.getBlockByIndex(index).id, data);

      // Using !isForThumbnail here facilitates allowing users to drag and drop an image from their machine after
      // clicking the existing thumbnail to open the upload modal. Without this guard, the image would not only get
      // added as the new article thumbnail, but would also get added as the article featured media. This issue does
      // not happen when the user clicks the 'Upload Image' button in the upload modal because that action triggers a
      // different event.
      if (!shouldReplace && !isForThumbnail) {
        const blockIndex = blockId === null ? index - 1 : index;
        blocks.insert(type, data, {}, blockIndex);
        if (blockId === null) scrollToBlock(blockIndex);
      }
    }

    onMediaAdd(content, event, blockId, shouldReplace, shouldReplaceArticleThumbnail);
  };

  const handleEmbedChange = (props) => {
    const {blockId, content, editorBlockIndex, event, shouldReplaceArticleThumbnail} = props;
    // after an embed is updated we need to trigger a content update to update isChanged redux state to enable the save button
    // essentially we're not doing anything, just moving the block to the same position to trigger the body change
    const {blocks} = editorRef.current.editor;
    // sanity check we have a valid block index (not -1) and this is not the featured media block (not 0)
    if (editorBlockIndex > 0) blocks.move(editorBlockIndex, editorBlockIndex);
    // when an embed is being added to the editor, we must update the article data
    if (content) handleMediaAdd(content, blockId, event, true, shouldReplaceArticleThumbnail);
  };

  return (
    <>
      <div id={editorID} className={classes} />
      <EditorJS
        ref={editorRef}
        reinitOnPropsChange={false}
        holder={editorID}
        data={data}
        autofocus={autofocus}
        readOnly={isReadOnly}
        placeholder={placeholder}
        tools={isTextOnly ? EDITORJS_TOOLS_TEXTONLY : EDITORJS_TOOLS}
        i18n={EDITORJS_I18N}
        logLevel={logLevel}
        onChange={handleChange}
        onData={(dataChanged) => {
          onData(dataChanged, getWordCount(dataChanged));
        }}
        onReady={() => {
          // this can be called before `editorRef` has been replaced, so we will not await it here
          setIsEditorInit(true);
        }}
        {...editorProps}
      />
      {!isTextOnly && <EditorMedia onMediaAdd={handleMediaAdd} onMediaModalOpen={onMediaModalOpen} />}
      {!isTextOnly && <EditorEmbeds onChange={handleEmbedChange} onMediaAdd={handleMediaAdd} onMediaModalOpen={onMediaModalOpen} />}
    </>
  );
};

Editor.defaultProps = {
  activeSlideIndex: 0,
  autofocus: false,
  editorProps: {},
  featuredMedia: null,
  name: 'default',
  onData: () => {},
  onMediaAdd: () => {},
  onMediaModalOpen: () => {},
  onReady: () => {},
  toolset: 'default',
  useUndo: true,
};

Editor.propTypes = {
  activeSlideIndex: PropTypes.number,
  autofocus: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({}),
      })
    ),
  }),
  editorProps: PropTypes.object,
  featuredMedia: PropTypes.object,
  name: PropTypes.string,
  onData: PropTypes.func,
  onMediaAdd: PropTypes.func,
  onMediaModalOpen: PropTypes.func,
  onReady: PropTypes.func,
  placeholder: PropTypes.string,
  toolset: PropTypes.string,
  useUndo: PropTypes.bool,
};

export default Editor;
