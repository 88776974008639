import Unsupported from '../unsupported';

/**
 * Facebook Post content type not currently supported, appears in legacy articles
 * @see examples 2623817
 */
export default class FacebookPost extends Unsupported {
  constructor({api, config, data}) {
    super({api, config, data});
    this.originalData = data;
  }

  render() {
    const type = 'Facebook Post';
    const el = super.render(type);
    return el;
  }
}
