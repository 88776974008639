import jwtDecode from 'jwt-decode';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CTT_USER,
  CTT_USER_EMAIL,
  CTT_USER_IMAGE,
  CTT_USER_NAME,
  CTT_USER_TITLE,
  CTT_USER_TWITTER,
  CTT_USER_LEGACY_ID,
} from '../constants/user';

const getAccessTokenData = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  return token ? jwtDecode(token) : null;
};

const isItemUnset = (value) => {
  // Prevent undefined lint error, 'cause we need to compare value with it
  // eslint-disable-next-line no-undefined
  return [undefined, null, 'undefined', 'null'].includes(value);
};

export function setAuthTokens(access, refresh) {
  localStorage.setItem(ACCESS_TOKEN, access);
  localStorage.setItem(REFRESH_TOKEN, refresh);
}

export function setAuthData(token) {
  const {userId, accessToken, refreshToken} = token;

  setAuthTokens(accessToken, refreshToken);
  localStorage.setItem(CTT_USER, userId);
}

export function setUserData(user, profile) {
  const {user: userProfile, legacyUser} = profile;
  localStorage.setItem(CTT_USER_LEGACY_ID, (legacyUser && legacyUser.legacyId) || userProfile.profileId || null);
  localStorage.setItem(CTT_USER_EMAIL, user.email);
  localStorage.setItem(CTT_USER_IMAGE, userProfile.photoPath);
  localStorage.setItem(CTT_USER_NAME, `${userProfile.firstName} ${userProfile.lastName}`);
  localStorage.setItem(CTT_USER_TITLE, userProfile.title);
  localStorage.setItem(CTT_USER_TWITTER, userProfile.twitterHandle ? `@${userProfile.twitterHandle}` : null);
}

export function getStorageData(field, defaultValue = null) {
  const item = localStorage.getItem(field);
  return isItemUnset(item) ? defaultValue : item;
}

export function getAuthTokens() {
  return {
    accessToken: localStorage.getItem(ACCESS_TOKEN),
    refreshToken: localStorage.getItem(REFRESH_TOKEN),
  };
}

export function isAuthorized() {
  return localStorage.getItem(ACCESS_TOKEN) && localStorage.getItem(REFRESH_TOKEN);
}

export function isTokenExpired() {
  const jwt = getAccessTokenData();
  const now = new Date().getTime() / 1000;
  return !jwt || now > jwt.exp;
}

export function isPermitted(...permissions) {
  const jwt = getAccessTokenData();
  // Directly return false for invalid or empty token situation
  if (!jwt || !jwt.scopes) return false;

  const exists = permissions.filter((name) => jwt.scopes.includes(name));
  return exists.length === permissions.length;
}

export function clearAuthData() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(CTT_USER);
  localStorage.removeItem(CTT_USER_LEGACY_ID);
  localStorage.removeItem(CTT_USER_EMAIL);
  localStorage.removeItem(CTT_USER_IMAGE);
  localStorage.removeItem(CTT_USER_NAME);
  localStorage.removeItem(CTT_USER_TITLE);
  localStorage.removeItem(CTT_USER_TWITTER);
}
