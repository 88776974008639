import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unscheduleArticle} from '../../../actions/article';
import {getArticleData, getIsReadOnly} from '../../../selectors/article';
import {getArticleType} from '../../../helpers/articleHelpers';
import {prompt} from '../../../helpers/promptHelper';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_UNSCHEDULE} from '../../../constants/prompt';

const UnscheduleButton = () => {
  const dispatch = useDispatch();
  const isReadOnly = useSelector(getIsReadOnly);
  const article = useSelector(getArticleData);
  const {id, publishedAt, scheduledAt, title} = article;
  const articleType = getArticleType(article);

  const handleUnschedule = () => {
    prompt(PROMPT_UNSCHEDULE)
      .withTexts(`Are you sure you want to unschedule this ${articleType}?`, title, PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => dispatch(unscheduleArticle(id, title)));
  };

  // do not show unschedule button if article published or not scheduled
  if (publishedAt || !scheduledAt) return null;

  return (
    <button className="button-unschedule" disabled={isReadOnly} onClick={() => handleUnschedule()} title={`Unschedule ${articleType} for publish`}>
      Unschedule
    </button>
  );
};

export default UnscheduleButton;
