import {IconMarker, IconReplace} from '@codexteam/icons';
export const EDITOR_TOOLSET_TEXTONLY = 'textonly';

// custom block tunining menu icons
export const EDITOR_SETTINGS_EDIT_ICON = IconMarker;
export const EDITOR_SETTINGS_EDIT_LABEL = 'Edit';
export const EDITOR_SETTINGS_REPLACE_ICON = IconReplace;
export const EDITOR_SETTINGS_REPLACE_LABEL = 'Replace';

// tool: header
export const EDITOR_TOOL_HEADER_LABEL = 'Heading';
export const EDITOR_TOOL_SUBHEADER_LABEL = 'Subheading';

// tool: list
export const EDITOR_TOOL_LIST_ORDERED_LABEL = 'Ordered List';
export const EDITOR_TOOL_LIST_ORDERED_STYLE = 'ordered';
export const EDITOR_TOOL_LIST_UNORDERED_LABEL = 'Unordered List';
export const EDITOR_TOOL_LIST_UNORDERED_STYLE = 'unordered';
