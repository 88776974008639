import reporter from '../../reporter';
import {camelizeKeys} from 'humps';

import logger from '../../logger';

import {Getty, Proxies} from '../../endpoints';
import {IMAGE_SEARCH_FILTERED_PAGE_SIZE, IMAGE_SOURCE_GETTY} from '../../constants/media';

import {fetchJSON, postFetchOpts, timerFactory} from '../helpers/fetch';

const ACCESS_TOKEN = 'getty-access-token';

function getGettyAuthTokens() {
  return {
    accessToken: localStorage.getItem(ACCESS_TOKEN),
  };
}

function setGettyAuthTokens(access) {
  localStorage.setItem(ACCESS_TOKEN, access);
}

export function clearGettyAuthTokens() {
  localStorage.removeItem(ACCESS_TOKEN);
}

/**
 * Fetches, stores in localStorage, and returns the access OAuth token for Getty.
 *
 * @returns {Promise<Object>} object is {accessToken} or an error
 */
async function fetchAndStoreGettyTokens() {
  let tokens;
  try {
    tokens = await fetchJSON(Proxies.gettyOauthToken(), postFetchOpts({}));
  } catch (error) {
    logger.error('Could not fetch Getty tokens');
    logger.error(error);
    reporter.inform(error);
    return Promise.reject(error);
  }
  setGettyAuthTokens(tokens.accessToken);
  return Promise.resolve(tokens);
}

function buildFetchOptionsWithGettyAuth(method) {
  const {accessToken} = getGettyAuthTokens();
  const headers = new global.Headers();
  headers.append('Api-Key', process.env.GETTY_API_KEY);
  headers.append('Authorization', `Bearer ${accessToken}`);
  return {
    method,
    headers,
  };
}

/**
 * "Convert" a raw image search result into an object for EditorJS to handle
 *
 * @param {object} item raw image search result
 * @returns {object} bare minimum data to allow EditorJS to create a content block
 */
function transformImageData(item) {
  const {artist, caption, date_created, display_sizes, id, title} = item;
  return {
    artist,
    caption,
    dateCreated: date_created,
    imageId: id,
    source: IMAGE_SOURCE_GETTY,
    title,
    urlComp: display_sizes.find((size) => size.name === 'comp').uri,
    urlThumb: display_sizes.find((size) => size.name === 'thumb').uri,
  };
}

/**
 * Fetch a URL with authentication headers from Getty.
 * Is recursive, in that it can attach itself to the promise chain that it
 * returns, if authorization is needed in order to make the request.
 *
 * @param {object} options to pass to the fetch
 * @param {string} options.url the url to fetch from
 * @returns {Promise}
 */
function fetchWithGettyAuth(...options) {
  const [url, ...fetchOptions] = options;
  const timeRequest = timerFactory(url, fetchOptions);
  return fetch(...options)
    .then(timeRequest)
    .then((response) => {
      // Getty can return a 303 to mean "successful, but look elsewhere" along
      // with a `location:` header. Hapi appears to normalize this into a 200
      // with a `url` property on the `response` object.
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      let message = `HTTP ${response.status}`;
      if (response.url && response.url.split) {
        message = `${message} from ${response.url.split('/')[2]}`;
      }
      const error = new Error(message);
      error.response = response;
      reporter.inform(error);
      throw error;
    })
    .then((response) => (response.bodyUsed && camelizeKeys(response.json())) || response)
    .catch(async (thrownError) => {
      if (fetchOptions[0].refreshAttempted) {
        logger.debug('Attempted refresh already, exiting...');
        logger.error(thrownError);
        throw thrownError;
      }
      if ([401, 403].includes(thrownError.response.status)) {
        try {
          await fetchAndStoreGettyTokens();
        } catch (error) {
          logger.debug('Could not refresh Getty tokens');
          logger.error(error);
          reporter.inform(error);
          return Promise.reject(error);
        }

        const refreshedOpts = {
          ...buildFetchOptionsWithGettyAuth(),
          refreshAttempted: true,
        };
        const result = await fetchWithGettyAuth(url, refreshedOpts);
        if (result) {
          return result;
        }
        logger.debug('Unexpected error from Getty url:', url);
        logger.error(thrownError);
        reporter.inform(thrownError);
        throw thrownError;
      }
      logger.debug(`Error — could not load url: ${url}`);
      logger.error(thrownError);
      reporter.inform(thrownError);
      return Promise.reject(thrownError, {url, fetchOptions, thrownError});
    });
}

/**
 * Get high-res image URL from Getty (re-authorizing if necessary) and send it
 * to Cloudinary so that they download the image and host it on our account.
 *
 * @param {string} imageId identifier of the image from Getty
 * @returns {Promise} resolves with the Getty URL including tokens to allow access
 */
export function highResGettyImageUrl(imageId) {
  if (!process.env.GETTY_API_KEY) {
    const error = new Error('Can not fetch high-res Getty image without an API key.');
    reporter.inform(error);
    throw error;
  }
  return new Promise((resolve, reject) => {
    const opts = buildFetchOptionsWithGettyAuth('POST');
    fetchWithGettyAuth(Getty.imageDataHighRes(imageId), opts)
      .then((response) => {
        if (response.errorCode) {
          global.alert(`Uh oh, can't use that image.\n\n"${response.errorMessage}" [${response.errorCode}]`);
          logger.debug(response);
          const error = new Error('Unexpected response from Getty...');
          reporter.inform(error);
          throw error;
        }
        // Getty can return a 303 to mean "successful, but look elsewhere"
        // along with a `location:` header. Hapi appears to normalize this into
        // a 200 with a `url` property on the `response` object.
        if (response.url) {
          return response.url;
        }
        logger.debug(response);
        const error = new Error('Unrecognized response from Getty...');
        reporter.inform(error);
        throw error;
      })
      .then(resolve)
      .catch(reject);
  });
}

/**
 * Builds query params for Getty image search
 *
 * @param {string} query the search term
 * @returns {object} query params
 */
export function gettyImageSearchParams(query, page) {
  return {
    phrase: encodeURI(query.replace(/[\s+]+/g, '+')),
    fields: 'detail_set',
    page,
    page_size: IMAGE_SEARCH_FILTERED_PAGE_SIZE,
  };
}

/**
 * Fetches a url from the Getty Images API, and resolves the promise with an object
 * containing `itemTotalCount` and `assets`, in order to mimic the format of the CMS backend.
 * The default behavior of the `fetch` Promise has been modified so that it *will* reject if
 * the response has an error attribute (e.g. for HTTP errors).
 *
 * @param {string} query the query string
 * @param {number} page the page number
 * @returns {Promise<object>}
 */
export async function searchImages({query, page}) {
  if (!process.env.GETTY_API_KEY) {
    const error = new Error('Can not fetch Getty images without an API key.');
    reporter.inform(error);
    throw error;
  }

  try {
    // Let's make sure we have a Getty accessToken before making our very first search request
    const {accessToken} = getGettyAuthTokens();
    if (!accessToken) await fetchAndStoreGettyTokens();
  } catch (error) {
    logger.debug('Could not fetch getty tokens for initial image search request.');
    logger.error(error);
    reporter.inform(error);
    return Promise.reject(error);
  }

  return new Promise((resolve, reject) => {
    const opts = buildFetchOptionsWithGettyAuth('GET');
    const url = Getty.search(gettyImageSearchParams(query, page));
    fetchWithGettyAuth(url, opts)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          logger.error('Error searching Getty', {query, page});
          logger.error(response.error);
          reporter.inform(response.error);
          throw response.error;
        }

        const {result_count: itemTotalCount, images} = response;
        return {
          itemTotalCount,
          assets: images.map(transformImageData),
        };
      })
      .then(resolve)
      .catch(reject);
  });
}
