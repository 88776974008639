/* eslint-disable quotes */
/*
 * word list maintained by BR content team
 * @see https://docs.google.com/spreadsheets/d/1lf6hxaqxP5svm71pGXbiexupRccnQLe5v5MWlRmtrY8/edit#gid=0
 */
export const TEXT_MIXEDCASE = [
  '1B',
  '2B',
  '2OT',
  '247Sports',
  '3B',
  '3PT',
  'QBs',
  'RBs',
  'WRs',
  'TEs',
  'LBs',
  'All-Star',
  'All-Star Game',
  'Buzzer-Beater',
  'D-Backs',
  'DFB-Pokal',
  'D-Line',
  'Double-A',
  'DraftKings',
  'EuroLeague',
  'FanDuel',
  'FedEx',
  'FTs',
  'High-A',
  'HoH',
  'IndyCar',
  'JuJu',
  'KOs',
  'L2M',
  'Low-A',
  'No-Contest',
  'No-Decision',
  'No-Hitter',
  'O-Line',
  'Pac-Man',
  'Serie A',
  'Short-Handed',
  'Single-A',
  'SmackDown',
  'StatHero',
  'SummerSlam',
  'SunTrust',
  'SuperDraft',
  'TDs',
  'TeamTennis',
  'Triple-A',
  'T-Mobile',
  'T-Wolves',
  'UConn',
  'UMass',
  'UofL',
  'WarnerMedia',
  'WrestleMania',
  'X-Factor',
  'X-Games',
  'AOP',
  'LBJ',
  'PNC',
  'AT&T',
  'AJ',
  'CJ',
  'DJ',
  'EJ',
  'JC',
  'KJ',
  'LA',
  'RJ',
  'TJ',
  'B.T.',
  'C.J.',
  'D.J.',
  'E.J.',
  'J.C.',
  'J.D.',
  'J.K.',
  'J.L.',
  'J.T.',
  'K.C.',
  'K.J.',
  'P.J.',
  'P.K.',
  'R.J.',
  'T.J.',
  'Y.E.',
  "Ar'mani",
  'D-Wade',
  "d'Arnaud",
  "D'Marcus",
  'DaQuan',
  'deGrom',
  'DeAndre',
  'DeAngelo',
  "De'Andre",
  "De'Marquise",
  "De'Shawn",
  'DeJong',
  'DeSclafani',
  'DeVonta',
  'Gilgeous-Alexander',
  'Hyun-Jin',
  'Hyun-woo',
  'Hyung-Sung',
  'JaCoby',
  'JaVale',
  'Jay-Z',
  'Jung-gon Hwang',
  "Ke'Mon",
  'Kiner-Falefa',
  'LaMelo',
  'LaVar',
  'LeBron',
  'LiAngelo',
  'LaBryan',
  'McMahon',
  "Nay'Quan",
  "O'Neil",
  'R-Truth',
  'Sang-hee',
  'St-Pierre',
  "Ta'DaQuan",
  'VanDarius',
  'Wei-Yen',
  'Young-han',
];
