import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Table from '../molecules/searchTable';
import Filters from '../molecules/searchFilters';
import PageTitle from '../atoms/pageTitle';
import Toast from '../atoms/toast';
import {selectAction} from '../../selectors/search';

export const Search = () => {
  const [toast, setToast] = useState(null);

  const action = useSelector(selectAction);

  useEffect(() => {
    const toast = action?.status ? {message: action.status} : null;
    setToast(toast);
  }, [action]);

  return (
    <section className="organism search">
      <PageTitle title="Search" />
      <div className="search-header">
        <h1>Search</h1>
        <Toast toast={toast} />
      </div>
      <Filters />
      <Table />
    </section>
  );
};

export default Search;
