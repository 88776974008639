import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {BR} from '../../../endpoints';
import {PLACEHOLDER_EMPTY} from '../../../constants/text';
import {getArticleType, isAnyEditor, isAnyWriter} from '../../../helpers/articleHelpers';
import {formatDateWithTimezone} from '../../../helpers/dateHelpers';
import {getUserFullName} from '../../../helpers/userHelpers';
import {selectArticles} from '../../../selectors/search';
import {selectProfiles} from '../../../selectors/users';

import ArticleMoreMenu from './articleMoreMenu';

const SearchArticleRow = ({index}) => {
  const articles = useSelector(selectArticles);
  const profiles = useSelector(selectProfiles);
  const article = articles[index];
  const {author, permalink, primaryEditorId, publishedAt, title} = article;
  const editor = profiles[primaryEditorId];
  const articleType = getArticleType(article);
  const canUseActions = !isAnyWriter() || isAnyEditor();
  const classes = classnames('table-row', `article-type-${articleType}`, {odd: index % 2 !== 0});

  return (
    <div className={classes}>
      <div className="table-data table-data-title">
        <a href={BR.articlePublished(permalink)} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </div>
      <div className="table-data">{author?.name || PLACEHOLDER_EMPTY}</div>
      <div className="table-data">{getUserFullName(editor) || PLACEHOLDER_EMPTY}</div>
      <div className="table-data">{(publishedAt && formatDateWithTimezone(publishedAt)) || PLACEHOLDER_EMPTY}</div>
      <div className="table-data table-data-more">{canUseActions && <ArticleMoreMenu index={index} />}</div>
    </div>
  );
};

SearchArticleRow.defaultProps = {
  index: 0,
};

SearchArticleRow.propTypes = {
  index: PropTypes.number,
};

export default SearchArticleRow;
