import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {prompt} from '../../../helpers/promptHelper';
import {PROMPT_IDLE_TIMER} from '../../../constants/prompt';
import {getIdleTimer} from '../../../selectors/article';
import {epoch} from '../../../helpers/epoch';

export const TIMER_COUNTDOWN = Number(process.env.SOFTLOCK_TIMER) || 1800; // seconds until timer expires
export const TIMER_COUNTDOWN_WARNING = Number(process.env.SOFTLOCK_TIMER_WARNING) || 300; // seconds left when warning displays
export const TIMER_INTERVAL = 1000; // timer interval in msec
export const TIMER_EXPIRATION_TITLE = 'Your lock on this article expired after 30 minutes of inactivity';
export const TIMER_EXPIRATION_TEXT = 'Refresh this page to renew your lock or contact the assignment editor to prevent overwriting any changes.';
export const TIMER_WARNING_TITLE = "It's been a long time since you saved your work"; // eslint-disable-line quotes
export const TIMER_WARNING_TEXT =
  'Please save now so that your changes are not lost. Your lock on this article will be released in 5 minutes. If you do not save before then, this page will enter read-only mode.'; // eslint-disable-line max-len

const IdleTimer = (props) => {
  const {countdown, countdownWarning, expirationText, expirationTitle, onIdleTimerExpires, warningText, warningTitle} = props;
  const start = useSelector(getIdleTimer);
  const [secondsLeft, setSecondsLeft] = useState(countdown);
  const [isShowingPrompt, setIsShowingPrompt] = useState(false);
  let timer;

  const classes = classnames('idle-timer-countdown', {'is-warning': isShowingPrompt === 'warning'});

  useEffect(() => {
    // on startup / timer reset: determine epoch-timestamps for showing warning & expiration messages
    const startTime = epoch();
    const expirationTime = startTime + countdown;
    const warningTime = startTime + countdown - countdownWarning;

    // reset initial timer value on startup / timer reset so countdown begins at 30:00 every time
    setSecondsLeft(countdown);

    // ensure that we only ever have ONE timer / active setInterval
    if (timer) clearInterval(timer);

    // from then on, once per second...
    timer = setInterval(() => {
      // check the current epoch-timestamp, update the displayed timer value,
      // and decide whether prompts need to be shown
      const intervalTimestamp = epoch();
      setSecondsLeft(expirationTime - intervalTimestamp);
      if (intervalTimestamp >= expirationTime) {
        setIsShowingPrompt('expired');
      } else if (intervalTimestamp >= warningTime) {
        setIsShowingPrompt('warning');
      }
    }, TIMER_INTERVAL);
    return () => clearInterval(timer); // cancel the active timer / setInterval when the idleTimer component unmounts
  }, [start]); // reset the timer on startup / timer reset

  useEffect(() => {
    if (isShowingPrompt === 'warning') {
      prompt(PROMPT_IDLE_TIMER)
        .withTexts(warningTitle, warningText, 'OK', null)
        .withMute(false)
        .show(() => {});
    } else if (isShowingPrompt === 'expired') {
      onIdleTimerExpires(); // set read-only before they click prompt ok otherwise can keep typing with prompt open
      prompt(PROMPT_IDLE_TIMER)
        .withTexts(expirationTitle, expirationText, 'OK', null)
        .withMute(false)
        .show(() => {
          // TODO: should we do something different (ux) depending on whether there are unsaved changes?
          // TODO: investigate restoring unsaved changes with save-to-localStorage
        });
    }
  }, [isShowingPrompt]);

  const getWarningTimeLeft = () => {
    const m = Math.floor(secondsLeft / 60);
    const s = secondsLeft % 60;
    return `${m}:${s.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <div className={classes}>{getWarningTimeLeft()}</div>
      <div style={{display: 'none'}}>{secondsLeft}</div>
    </>
  );
};

IdleTimer.defaultProps = {
  countdown: TIMER_COUNTDOWN,
  countdownWarning: TIMER_COUNTDOWN_WARNING,
  expirationText: TIMER_EXPIRATION_TEXT,
  expirationTitle: TIMER_EXPIRATION_TITLE,
  onIdleTimerExpires: () => {},
  warningText: TIMER_WARNING_TEXT,
  warningTitle: TIMER_WARNING_TITLE,
};

IdleTimer.propTypes = {
  countdown: PropTypes.number,
  countdownWarning: PropTypes.number,
  expirationText: PropTypes.string,
  expirationTitle: PropTypes.string,
  onIdleTimerExpires: PropTypes.func,
  warningText: PropTypes.string,
  warningTitle: PropTypes.string,
};

export default IdleTimer;
