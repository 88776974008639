import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import {debounce} from 'lodash';

import {isPermitted} from '../../helpers/authHelpers';
import {scopes} from '../../constants';
import {USER_TEMPLATE_BASIC} from '../../constants/user';
import {articleAuthor} from '../../utils/types';

import ArticlesRecent from '../molecules/articlesRecent';
import ButtonLogout from '../atoms/buttonLogout';
import User from '../atoms/user';

const Sidebar = (props) => {
  const {user} = props;
  const location = useLocation();
  const [scrollY, setScrollY] = useState(window.scrollY);
  const debounceSetScrollY = debounce((y) => setScrollY(y), 100);

  const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const sidebarBreakpoint = 970; // scss variables: $sidebar_breakpoint
  const getIsExpanded = () => (getWidth() >= sidebarBreakpoint ? 1 : 0);
  const [isExpanded, setIsExpanded] = useState(getIsExpanded);
  const [showDropdown, setShowDropdown] = useState(null);
  const classes = classnames('organism', 'sidebar', {
    'is-expanded': isExpanded,
    'is-scrolled': scrollY > 0,
  });

  const routes = [
    {path: '/', title: 'Dashboard', permission: scopes.ACCESS_ARTICLE_ADMIN},
    {path: '/search', title: 'Search'},
    {
      path: '/tools',
      title: 'Tools',
      childRoutes: [
        {path: '/editor-stats', title: 'Editor Stats', permission: scopes.REVIEW_AND_PUBLISH || scopes.VIEW_ADMIN_MENU},
        {path: '/request', title: 'Submit Request for Data', permission: scopes.REVIEW_AND_PUBLISH || scopes.VIEW_ADMIN_MENU},
        {path: '/deleted', title: 'Deleted Articles', permission: scopes.VIEW_ADMIN_MENU},
        {path: '/help', title: 'Help', permission: scopes.ACCESS_ARTICLE_ADMIN},
      ],
    },
  ];

  const getActiveDropdownPath = () => {
    const matched = routes.find(
      ({path, childRoutes}) => childRoutes && childRoutes.some((childRoute) => location.pathname.includes(`${path}${childRoute.path}`))
    );
    return matched?.path;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(getIsExpanded);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // need to set scroll immediately when at top of page to trigger class change for visual effects
      if (scrollY === 0) setScrollY(window.scrollY);
      debounceSetScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollY]);

  useEffect(() => {
    setShowDropdown(getActiveDropdownPath());
  }, [location]);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDropdown = (path) => {
    if (getActiveDropdownPath() === path) return; // don't toggle if already on a dropdown path
    setShowDropdown(!showDropdown ? path : null);
  };

  const handleNavClick = () => {
    if (!getIsExpanded()) setIsExpanded(false);
  };

  const toggleAttributes = {
    'aria-label': isExpanded ? 'Hide Sidebar Menu' : 'Show Sidebar Menu',
  };

  const navTitle = (title) => {
    return <span>{title}</span>;
  };

  const navTitleIcon = (title) => {
    return (
      <>
        <span className={`icon-nav-${title.replace(' ', '-').toLowerCase()}`} />
        {navTitle(title)}
      </>
    );
  };

  const getKey = (path) => {
    return `route-${path.replace('/', '')}`;
  };

  return (
    <header className={classes}>
      <button className="sidebar-toggle button--basic" onClick={toggleSidebar} {...toggleAttributes}>
        <span className="sr-only">{isExpanded ? 'Toggle sidebar closed' : 'Toggle sidebar open'}</span>
      </button>

      <NavLink end={true} to="/" className="site-title">
        <div className="site-name">Content Tools</div>
      </NavLink>

      <div className="sidebar-content">
        <User user={user} template={USER_TEMPLATE_BASIC} />
        <ul className="menu">
          {routes.map(({childRoutes, path, permission, title}) => {
            if (permission && !isPermitted(permission)) return null;

            if (!childRoutes)
              return (
                <li key={getKey(path)}>
                  <NavLink end={true} to={path} onClick={handleNavClick}>
                    {navTitleIcon(title)}
                  </NavLink>
                </li>
              );

            return (
              <li key={getKey(path)}>
                <a onClick={() => toggleDropdown(path)}>{navTitleIcon(title)}</a>
                <ul className={classnames({'is-expanded': showDropdown === path})}>
                  {childRoutes.map(({path: subPath, permission, title}) => {
                    if (permission && !isPermitted(permission)) return null;
                    return (
                      <li key={getKey(subPath)}>
                        <NavLink
                          end={true}
                          to={path + subPath}
                          onClick={handleNavClick}
                          className={() => (location.pathname.includes(path + subPath) ? 'active' : '')}>
                          {navTitle(title)}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        <ArticlesRecent />
        <ButtonLogout />
      </div>
    </header>
  );
};

Sidebar.propTypes = {
  user: articleAuthor,
};

export default Sidebar;
