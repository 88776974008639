import React from 'react';
import {getArticleType, getArticleUrl, getArticleUrlVersions} from '../../../helpers/articleHelpers';

import ButtonMenu from '../buttonMenu';

const EditorStatsArticleMoreMenu = ({article}) => {
  if (!article) return false;
  const {title} = article;
  const type = getArticleType(article);

  return (
    <>
      <ButtonMenu icon="more" id="more" menuAlignment="right" classButton="button--basic" text="More…" textAria={`Article Menu for '${title}'`}>
        <ul>
          <li>
            <a href={getArticleUrl(article)} className="link-edit">
              Edit {type}
            </a>
          </li>
          <li>
            <a href={getArticleUrlVersions(article)} className="link-versions">
              View version history
            </a>
          </li>
        </ul>
      </ButtonMenu>
    </>
  );
};

export default EditorStatsArticleMoreMenu;
