import React from 'react';
import {Link} from 'react-router-dom';

import PageError from '../atoms/pageError';
import PageTitle from '../atoms/pageTitle';

const Error404 = () => {
  return (
    <section className="organism page-error page-error-404">
      <PageTitle title="404" />
      <PageError what="404 page not found">
        <p>Either you have a typo in the url, or this page no longer exists.</p>
        <Link to="/">&rsaquo; Return to Dashboard</Link>
      </PageError>
    </section>
  );
};

export default Error404;
