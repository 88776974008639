import {IconBrackets} from '@codexteam/icons';
import {addDiffClass} from '../difference';
import ToolEventObserver from '../toolEventObserver';
import {getYouTubeEmbedTool, getYouTubeId, sanitizeYouTubeUrl} from '../embed';
import {EDITOR_SETTINGS_EDIT_ICON, EDITOR_SETTINGS_EDIT_LABEL} from '../../../../constants/editor';
import {EVENT_EMBED_EDIT} from '../../../../constants/events';
import {PROMPT_BUTTON_SAVE, PROMPT_IFRAME_EMBED} from '../../../../constants/prompt';
import {YOUTUBE_URLS} from '../../../../constants/media';
import {prompt} from '../../../../helpers/promptHelper';
import {getStatmilkHeight, STATMILK_THUMBNAIL, STATMILK_PROVIDER} from '../statmilk';

require('./index.css');

// note: if the default height is changed, it also needs to be updated in `nodereport/src/reducers/articles.js`
// to keep the display of iframes consistent within content tools, and on preview/production
const IFRAME_HEIGHT_DEFAULT = '450'; // default iframe pixel height (when not specified)

export const ERROR_MESSAGE = 'Ooops! Please enter a valid HTTPS iframe or url.';
export const IFRAME_PROVIDER = 'unknown';
export const IFRAME_THUMBNAIL = '/images/media/iframe-thumbnail-placeholder.svg';
export const PROMPT_IFRAME_TITLE = 'Add Iframe';
export const PROMPT_IFRAME_TITLE_EDIT = 'Edit Iframe';
export const PROMPT_IFRAME_DESCRIPTION =
  'You can enter either a url or iframe, HTTPS required. Optionally specify a custom pixel height or leave blank to use the default sizing.';

/**
 * RegExp matching the full `src` value of an iframe element.
 * This URL must be on HTTPS! However there are many Breport-minted articles published
 * which have HTTP URLs, and don't necessarily support an equivalent route on HTTPS.
 * Rather than showing an insecure iframe, the component will show a `div` with a message.
 */
export const REGEX_IFRAME_SRC = /^https:\/\/[-\w%.]+\.[a-z]{2,}([/?][-\w%.:;@?=&+/]*)?$/i;

export const checkValidUrl = (url) => {
  return REGEX_IFRAME_SRC.test(url);
};

export const getIframeUrl = (data) => {
  if (typeof data !== 'string') return null;

  // validate this is an iframe
  const iframe = new DOMParser().parseFromString(data, 'text/html').querySelector('iframe');
  if (!iframe) return null;

  // validate the iframe has https src
  const iframeSrc = iframe.getAttribute('src');
  if (!iframeSrc || !checkValidUrl(iframeSrc)) return null;

  return iframeSrc;
};

/**
 * Returns the YouTube video url from a YouTube iframe
 * @param {data} string what the user entered
 * @returns {string|null} URL of image representing the slide or null if this wasn't a YouTube iframe
 */
export const getYouTubeUrl = (data) => {
  if (typeof data !== 'string') return null;

  const iframe = new DOMParser().parseFromString(data, 'text/html').querySelector('iframe');
  if (!iframe) return null;

  const videoURL = iframe.getAttribute('src');
  if (!videoURL) return null;

  const isValidFormat = YOUTUBE_URLS.some((format) => videoURL.includes(format));
  if (!isValidFormat) return null;

  return videoURL;
};

export const getYouTubeThumbnail = (id) => {
  if (typeof id !== 'string') return null;
  if (!id.length) return null;

  return `https://img.youtube.com/vi/${id}/0.jpg`;
};

/**
 * Displays an iframe
 * @see examples HTTP {@link https://bleacherreport.com/articles/2612753 2612753}
 * and HTTPS {@link https://bleacherreport.com/articles/2886201 2886201}
 */
export default class Iframe {
  static get toolbox() {
    return {
      icon: IconBrackets,
      title: 'Iframe',
    };
  }

  constructor({api, data, block, readOnly}) {
    this.api = api;
    this.data = data;
    this.block = block;
    this.readOnly = readOnly;
    this.iframe = '';
    this.shouldReplace = false;
    this.editorBlockIndex = 0;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  isNewBlock() {
    return !Object.keys(this.data).length;
  }

  renderAdd() {
    const promptTitle = this.shouldReplace ? PROMPT_IFRAME_TITLE_EDIT : PROMPT_IFRAME_TITLE;
    const source = this.data.src;

    // TODO: CTT-572 Can we better share prompt between this component and editorEmbed (/src/components/atoms/editorEmbeds.js)
    // TODO: if you make changes here, make sure they are also reflected in onFeaturedMediaIframeEventTriggered()
    prompt(PROMPT_IFRAME_EMBED)
      .withTexts(promptTitle, PROMPT_IFRAME_DESCRIPTION, PROMPT_BUTTON_SAVE)
      .withUserInput((input) => {
        const text = input.trim();
        const valid = getIframeUrl(text) || getYouTubeUrl(text) || checkValidUrl(text);
        return !valid ? ERROR_MESSAGE : null;
      }, source)
      .withAdditionalInput('number', 'Height', this.data.height || false)
      .show((input, height = '') => {
        let src = input.trim();

        // update source when youtube iframe or iframe used instead of a url string
        const youTubeUrl = getYouTubeUrl(src);
        const iframeUrl = getIframeUrl(src);
        if (youTubeUrl) src = youTubeUrl;
        else if (iframeUrl) src = iframeUrl;

        // check if this is is a block that should be handled by another tool
        const isYouTube = youTubeUrl || src.includes('youtube.com') || src.includes('youtu.be'); // can be added as an iframe or youtube url
        const isStatmilk = src.includes('https://statmilk.bleacherreport.com');

        let content = {
          src,
          height,
          thumbnail: IFRAME_THUMBNAIL,
          provider: IFRAME_PROVIDER,
        };

        if (isYouTube) {
          src = sanitizeYouTubeUrl(src);
          const embed = getYouTubeEmbedTool(null, src);
          content = {...embed._data, height, thumbnail: getYouTubeThumbnail(getYouTubeId(src))};
        } else if (isStatmilk) {
          content.height = getStatmilkHeight(input) || '';
          content.thumbnail = STATMILK_THUMBNAIL;
          content.provider = STATMILK_PROVIDER;
        }

        // set the tool data
        this.data = {...content, __original__: content};

        // when the iframe is first added the block doesn't exist yet, but will exist when editing
        const blockExists = this.block.holder;

        let blockType = 'iframe';
        if (!blockExists) {
          // handle adding youtube and statmilk as correct block types
          if (isYouTube) blockType = 'embed';
          else if (isStatmilk) blockType = 'statmilk';

          this.api.blocks.insert(blockType, this.data, {}, this.editorBlockIndex);
        } else {
          // catch for when block is edited via block tune settings
          if (this.shouldReplace) this.shouldReplace = false;

          this.api.blocks.update(this.block.id, this.data);
        }

        // update the redux article state
        ToolEventObserver.trigger(EVENT_EMBED_EDIT, {
          blockId: false,
          content: {type: blockType, data: this.data},
          editorBlockIndex: this.editorBlockIndex,
          event: 'EVENT_EMBED_EDIT',
        });
      });

    // Editor.js expects render() to return a valid Node otherwise will throw console error:
    // `Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'.`
    // But returning a node here will insert empty blocks into the editor which are
    // not necesssary and can cause unintended side effects
    // @see CTT-940/CTT-950
    // Note we are currently supressing the console error in `src/client.js`
    // and cypress uncaught:exception in `cypress/support/index.js`
    this.iframe = null;
  }

  buildBlock() {
    const {provider} = this.data;
    const source = this.data.src;
    const isValidUrl = checkValidUrl(source);
    const container = document.createElement('div');
    container.classList.add('media-container', 'media-container-iframe');
    addDiffClass(container, this.data);

    if (isValidUrl) {
      const iframe = document.createElement('iframe');
      iframe.width = '100%';
      iframe.height = this.data.height || IFRAME_HEIGHT_DEFAULT;
      iframe.style = 'border: 0;';
      iframe.src = source;

      container.appendChild(iframe);
      this.iframe = container;
    } else if (provider !== 'unknown') {
      // catch for older articles (ex./articles/standard/2612753) that already have an invalid HTTP iframe
      const placeholder = document.createElement('div');
      placeholder.classList.add('media-placeholder');
      placeholder.innerHTML = `Can't display iframe: HTTPS required <a href="${source}" rel="noopener noreferrer" target="_blank">Link to media</a>`; // eslint-disable-line max-len
      container.appendChild(placeholder);
      this.iframe = container;
    }
  }

  render() {
    this.isNewBlock() || this.shouldReplace ? this.renderAdd() : this.buildBlock();

    // index / getCurrentBlockIndex() needs to be set during render() to catch new blocks being added
    // inside renderAdd() it only correctly grabs the index for edit/replace events, not new blocks being added
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    if (currentBlockIndex >= 0) this.editorBlockIndex = currentBlockIndex;

    return this.iframe;
  }

  renderSettings() {
    return [
      {
        icon: EDITOR_SETTINGS_EDIT_ICON,
        label: EDITOR_SETTINGS_EDIT_LABEL,
        onActivate: () => {
          // edit button re-triggers the url prompt
          this.shouldReplace = true;
          this.render();
        },
      },
    ];
  }

  save() {
    return this.data;
  }

  validate(savedData) {
    if (!savedData) return false;
    return true;
  }
}
