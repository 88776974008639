import {createSelector} from 'reselect';

const mediaSelector = (state) => state.media;
const mediaImagesSelect = (state) => state.media.images;
const mediaVideosSelect = (state) => state.media.videos;

export const selectImageSearch = createSelector([mediaImagesSelect], (images) => images.search);

export const selectImageSearchOptions = createSelector([mediaSelector], (media) => ({
  page: media.page || 1,
  paginationUrlAp: media.paginationUrlAp,
}));

export const selectImageFilter = createSelector([mediaImagesSelect], (images) => images.filteredBy);

export const selectVideoSearch = createSelector([mediaVideosSelect], (videos) => videos.search);

export const selectOffset = createSelector([mediaSelector], (media) => media.images.offset);

export const selectHasMore = createSelector([mediaSelector], (media) => media.images.hasMore);

export const selectHasMoreAP = createSelector([mediaSelector], (media) => media.images.hasMoreAP);

export const selectHasMoreGetty = createSelector([mediaSelector], (media) => media.images.hasMoreGetty);

export const selectImages = createSelector([mediaSelector], (media) => media.images.list);

export const selectVideos = createSelector([mediaSelector], (media) => media.videos.list);

export const selectTotalImages = createSelector([mediaSelector], (media) => media.images.total);

export const selectPickedImage = createSelector([mediaSelector], (media) => media.images.selected);

export const selectPickedVideo = createSelector([mediaSelector], (media) => media.videos.selected);

export const selectPickedVideoCaption = createSelector([mediaSelector], (media) => media.videos.selectedCaption);

export const selectPickedVideoTitle = createSelector([mediaSelector], (media) => media.videos.selectedTitle);

export const selectPickedVideoDescription = createSelector([mediaSelector], (media) => media.videos.selectedDescription);

export const selectPickedVideoTags = createSelector([mediaSelector], (media) => media.videos.selectedVideoTags);

export const selectPickedVideoStartSeconds = createSelector([mediaSelector], (media) => media.videos.selectedStartSeconds || 0);

export const selectIsVideoRetrieved = createSelector([mediaSelector], (media) => media.videos.isRetrieved);

export const selectIsSearched = createSelector([mediaSelector], (media) => media.images.isSearched);

export const selectIsLoading = createSelector([mediaSelector], (media) => media.isLoading);

export const selectHasError = createSelector([mediaSelector], (media) => !!media.error);
