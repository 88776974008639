import {EVENT_PROMPT_RAISE} from '../constants/events';
import ToolEventObserver from '../utils/editorjs/tools/toolEventObserver';

export const STORAGE_KEY = 'cttPromptMuted';

/**
 * Get list of muted prompts from the local storage
 * @returns {array}
 */
function getMutedPrompts() {
  const muted = localStorage.getItem(STORAGE_KEY);
  return muted !== null ? muted.split('|').map(Number) : [];
}

/**
 * Store muted prompts into the local storage
 * @param {array} list list of prompts that should be muted for the user
 */
function setMutedPrompts(list) {
  localStorage.setItem(STORAGE_KEY, list.join('|'));
}

/**
 * Determines if target prompt type is muted for the user
 * @param {string} type prompt name (check src/constants/prompt.js)
 * @returns {boolean}
 */
export function isPromptMuted(type) {
  const list = getMutedPrompts();
  return list.includes(type);
}

/**
 * Mute prompt for the user
 * @param {string} type prompt name (check src/constants/prompt.js)
 */
export function mutePrompt(type) {
  const list = getMutedPrompts();
  if (!list.includes(type)) setMutedPrompts([...list, type]);
}

/**
 * Creates new prompt object
 * @param {string} type prompt name (check src/constants/prompt.js)
 * @returns {object} prompt raise object
 *
 * @example
 *
 * // Show a prompt window with user input field and custom texts
 * prompt(PROMPT_TYPE_ADD_FEEDBACK)
 *  .withUserInput()
 *  .withTexts('Leave your feedback', 'Some additional text info',  'Save', 'Cancel')
 *  .show(fnConfirmed, fnCanceled)
 *
 * // Show a prompt window with custom texts and no mute
 * prompt(PROMPT_TYPE_MESSAGE)
 *  .withTexts('Hello world')
 *  .withMute(false)
 *  .show(fnConfirmed, fnCanceled)
 *
 * // Show a prompt window with custom texts
 * prompt(PROMPT_TYPE_DELETE_SLIDE)
 *  .withTexts('Do you want to delete this slide ?', null, 'Yes, delete', 'No')
 *  .show(fnConfirmed, fnCanceled)
 *
 * // Show default confirmation window
 * prompt(PROMPT_TYPE_CONFIRM).show(fnConfirmed, fnCanceled)
 */
export function prompt(type) {
  let options = {type};
  return {
    withTexts(title, text, confirm, cancel) {
      options = {...options, title, text, confirm, cancel};
      return this;
    },
    withMute(bool) {
      options = {...options, withMute: bool};
      return this;
    },
    withUserInput(validator, defaultValue) {
      options = {...options, withUserInput: true, defaultValue};
      if (validator) options.userInputValidate = validator;
      return this;
    },
    withAdditionalInput(inputType, label, defaultValue, validator) {
      const acceptedInputTypes = ['checkbox', 'number', 'text', 'thumbnail'];
      if (!acceptedInputTypes.includes(inputType)) return this;

      let type = inputType;
      let restrictToTitleSlides = false;

      // add special handling for thumbnail
      if (inputType === 'thumbnail') {
        type = 'checkbox';
        restrictToTitleSlides = true;
      }

      options = {
        ...options,
        withAdditionalInput: true,
        additionalInput: {type, label, defaultValue, restrictToTitleSlides, validate: validator ? validator : () => null},
      };
      return this;
    },
    show(resolve, reject = () => {}) {
      ToolEventObserver.trigger(EVENT_PROMPT_RAISE, {
        ...options,
        onConfirm: resolve,
        onCancel: reject,
      });
    },
  };
}
