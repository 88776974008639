import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'react-tooltip';

import {getArticleEditorNames, isAdmin, isEditor} from '../../../helpers/articleHelpers';
import {getUsersProfiles} from '../../../apis/auth/gatekeeper-api';

const ArticleEditors = ({userIds}) => {
  if (!isAdmin() && !isEditor()) return null;
  if (!userIds || userIds.length < 1) return null; // note article editors does not include article author

  const [userNames, setUserNames] = useState([]);

  useEffect(() => {
    getUsersProfiles([...new Set(userIds)]).then(({users, error}) => {
      // Failed calls to getUsersProfiles return an object with the format {error: true}
      // The actual errors are caught and logged from within a .catch block in the gatekeeper api
      if (error) return;
      setUserNames(getArticleEditorNames(users));
    });
    return () => setUserNames([]);
  }, []);

  return (
    <>
      <div id="article-editors-tooltip" className="tooltip-link" data-tooltip-float="true" data-tooltip-content={userNames.join(', ')}>
        (see all editors)
      </div>
      <Tooltip anchorSelect="#article-editors-tooltip" />
    </>
  );
};

ArticleEditors.propTypes = {
  userIds: PropTypes.array,
};

export default ArticleEditors;
