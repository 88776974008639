function isCropEmpty({x, y, width, height}) {
  const sum = x + y + width + height;
  return sum === 0 || isNaN(sum);
}

function getCropURLParameters(crop) {
  return ['x', 'y', 'width', 'height']
    .map((field) => `${field[0]}_${parseInt(crop[field], 10)}`)
    .join(',')
    .concat(',c_crop/');
}

/**
 * Scale crop parameters
 * @param {object} crop image crop parameters (required properties: x, y, width, height)
 * @param {object} scale object that contain width and height scale parameters
 * @returns {object}
 */
export function scaleCrop(crop, {width, height}) {
  return {
    x: parseInt(crop.x, 10) * width,
    y: parseInt(crop.y, 10) * height,
    width: parseInt(crop.width, 10) * width,
    height: parseInt(crop.height, 10) * height,
  };
}

/**
 * Inject cloudinary crop parameters into the original image URL
 * (check cloudinary details here: https://cloudinary.com/documentation/image_transformations)
 * @param {string} url cloudinary image URL that will be used for crop parameters injection
 * @param {object} crop image crop parameters (required properties: x, y, width, height)
 * @returns {string}
 */
export function getCroppedURL(url, crop) {
  return url.replace(/https?:\/\/(.*\/image\/upload\/)(.*)/g, `https://$1${isCropEmpty(crop) ? '' : getCropURLParameters(crop)}$2`);
}

/**
 * Retrieve original URL from cropped version
 * @param {string} croppedURL cropped cloudinary image URL
 * @returns {string}
 */
export function getOriginalURL(croppedURL) {
  return croppedURL.replace(/https?:\/\/(.*\/image\/upload)(.*)(\/v.*)/g, 'https://$1$3');
}
