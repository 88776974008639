/**
 * Class responsible for linking EditorJS tools with Editor react component.
 * It's variation of the Observer pattern (https://en.wikipedia.org/wiki/Observer_pattern).
 *
 * Should be used in EditorJS tools classes for render React components instead of DOM API.
 *
 * Example usage:
 * > // components/atoms/editorFoo.js
 * > const id = ToolEventObserver.subscribe(EVENT_TEST, ({test}) => console.log(test))
 * > // tools/foo/foo.js
 * > ToolEventObserver.trigger(EVENT_TEST, {test: 'message'})
 * > // it will automatically trigger all subscribers to the EVENT_TEST
 * > // "message"
 */
export default class ToolEventObserver {
  static subscribers = {};

  /**
   * Fire specific event (usually called in editor tool class)
   * @param {string} event Name of the event taken from the const
   * @param {object} data Optional event data that will be passed to subscriber function
   */
  static trigger(event, data = {}) {
    if (!event) throw new Error('Missing event name in call to ToolEventObserver.trigger');
    if (ToolEventObserver.subscribers[event]) {
      ToolEventObserver.subscribers[event].map((fn) => fn(data));
    }
  }

  /**
   * Remove subscription from the event
   * @param {string} event Name of the event taken from the const
   * @param {number} id Subscription ID (was returned from the subscribe method)
   */
  static unsubscribe(event, id) {
    if (!event) throw new Error('Missing event name in call to ToolEventObserver.unsubscribe');
    if (!id && id !== 0) throw new Error('Missing subscription id in call to ToolEventObserver.unsubscribe');
    if (ToolEventObserver.subscribers[event]) {
      ToolEventObserver.subscribers[event].splice(id, 1);
    }
  }

  /**
   * Subscribe callback function to the event
   * @param {*} event Name of the event taken from the const
   * @param {*} fn Callback function that that will be called in case of event firing
   * @returns {number} ID of the subscription (can be used to cancel it)
   */
  static subscribe(event, fn) {
    if (!event) throw new Error('Missing event name in call to ToolEventObserver.subscribe');
    if (!fn) throw new Error('Missing callback function in call to ToolEventObserver.subscribe');
    if (!ToolEventObserver.subscribers[event]) {
      ToolEventObserver.subscribers[event] = [];
    }
    ToolEventObserver.subscribers[event].push(fn);
    return ToolEventObserver.subscribers[event].length - 1;
  }
}
