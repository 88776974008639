import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Notification = (props) => {
  const {children, onClear, type} = props;
  if (!children) return null;

  return (
    <div className={classnames('atom', 'notification', `notification-${type}`)}>
      <div className="notification-message">{children}</div>
      {onClear && (
        <button onClick={onClear}>
          <span className="sr-only">Clear</span>
        </button>
      )}
    </div>
  );
};

Notification.defaultProps = {
  type: 'error',
};

Notification.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warning']),
  onClear: PropTypes.func,
};

export default Notification;
