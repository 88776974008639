// Replace fm prefix with your app's to avoid cookie duplicates across .bleacher.com domains
export const CTT_USER = 'cttUser';
export const CTT_USER_EMAIL = 'cttUserEmail';
export const CTT_USER_IMAGE = 'cttUserImage';
export const CTT_USER_NAME = 'cttUserName';
export const CTT_USER_TITLE = 'cttUserTitle';
export const CTT_USER_TWITTER = 'cttUserTwitter';
export const CTT_USER_LEGACY_ID = 'cttUserLegacyId';

export const ACCESS_TOKEN = 'cttAccessToken';
export const REFRESH_TOKEN = 'cttRefreshToken';

export const USER_IMAGE_DEFAULT = '/images/users/br-user-default.png';
export const USER_TEMPLATE_AVATAR = 'avatar';
export const USER_TEMPLATE_BASIC = 'basic';
export const USER_TEMPLATE_LOCK = 'lock';
export const USER_TEMPLATE_PLACEHOLDER = 'placeholder';

export const grantTypes = {
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
};

export const authCookies = {
  ACCESS_TOKEN,
  CTT_USER,
  CTT_USER_EMAIL,
  CTT_USER_IMAGE,
  CTT_USER_NAME,
  CTT_USER_TITLE,
  CTT_USER_TWITTER,
  REFRESH_TOKEN,
};
