import {addDiffClass} from '../difference';

/**
 * Adds a line-break spacer
 * In Editor.js it will display as a placeholder
 * On Production it should just render an empty space with no content
 */
require('./index.css');

/**
 * @typedef {Object} Linebreak
 * @description Tool's input and output data format
 */
export default class Linebreak {
  /**
   * Allow Tool to have no content
   * @return {boolean}
   */
  static get contentless() {
    return true;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: Linebreak, config: object, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   */
  constructor({data, readOnly}) {
    this.data = data;
    this.readOnly = readOnly;
    this._CSS = {
      wrapper: 'ce-linebreak',
    };
    this._element = this.drawView();
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    const div = document.createElement('div');

    div.classList.add(this._CSS.wrapper);
    addDiffClass(div, this.data);

    return div;
  }

  /**
   * Return Tool's view
   * @returns {HTMLDivElement}
   * @public
   */
  render() {
    return this._element;
  }

  /**
   * Extract Tool's data from the view
   * @returns {Linebreak} - saved data
   * @public
   */
  save() {
    return {};
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * note: this is the CodeX `IconRedo` rotated, not a part of lib (https://github.com/codex-team/icons)
   *
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: '<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="linebreak" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="redo" transform="translate(12.250000, 12.000000) rotate(180.000000) translate(-12.250000, -12.000000) translate(6.000000, 7.000000)" stroke="#000000" stroke-width="2"><path d="M9.1667,6.6667 L12.5,3.3333 L9.1667,0 M12.5,3.3333 L3.33333,3.3333 C2.44928,3.3333 1.60143,3.6845 0.97631,4.3096 C0.35119,4.9348 0,5.7826 0,6.6667 C0,7.5507 0.35119,8.3986 0.97631,9.0237 C1.60143,9.6488 2.44928,10 3.33333,10 L4.16667,10" id="Shape"></path></g></g></svg>',
      title: 'Line Break',
    };
  }
}
