import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDropzone} from 'react-dropzone';

import {IMAGE_ACCEPTED_MIME_TYPES, IMAGE_MAX_SIZE_IN_BYTES} from '../../constants/media';
import {getFileRejectionErrorMessages} from '../../helpers/imageUploadHelpers';

/**
 * Wrap children component with media uploader functionality
 * @example
 * function TestComponent() {
 *  return (
 *    <MediaUploader onMediaAccept={(file) => { /* called when media was uploaded *\/ }}>
 *      <div>Test Component Content goes here</div>
 *    </MediaUploader>
 *  )
 * }
 * @param {*} props list of component parameters
 */
function MediaUploader({acceptedMimeTypes, children, ...props}) {
  const {isDragActive, fileRejections, getInputProps, open, getRootProps} = useDropzone({
    accept: acceptedMimeTypes,
    multiple: false,
    maxSize: IMAGE_MAX_SIZE_IN_BYTES,
    noClick: true,
    // Must receive the updated fileRejections here in the case that the user drops a file that triggers
    // a rejection, then immediately drops a new file that does not trigger a rejection
    onDrop: (files, fileRejections) => files.length && !fileRejections.length && props.onMediaAccept(files[0]),
    ...MediaUploader.dropzoneOptions,
  });

  // Compose list of error messages
  const errorMessages = getFileRejectionErrorMessages(fileRejections);

  const classes = classnames('molecule', 'media-uploader', {active: isDragActive});
  return (
    <div {...getRootProps({className: classes})}>
      <div className="media-uploader__overlay" />
      {MediaUploader.WITH_BUTTON && <input {...getInputProps()} />}
      {typeof children === 'function' ? children(open, errorMessages) : children}
    </div>
  );
}

/**
 * Wrap children component with media uploader functionality and provides custom upload button functionality
 * @example
 * function TestComponent() {
 *  return (
 *    <MediaUploader.WithButton onMediaAccept={(file) => { /* called when media was uploaded *\/ }}>
 *      {(openUploader) => {
 *        <button onClick={openUploader}>Upload Image</button>
 *      }}
 *    </MediaUploader>
 *  )
 * }
 * @param {*} props list of component parameters
 */
MediaUploader.WithButton = (props) => {
  MediaUploader.WITH_BUTTON = true;
  MediaUploader.dropzoneOptions = {noClick: true, noKeyboard: true};
  return <MediaUploader {...props} />;
};

MediaUploader.dropzoneOptions = {};

MediaUploader.defaultProps = {
  acceptedMimeTypes: IMAGE_ACCEPTED_MIME_TYPES,
};

MediaUploader.propTypes = {
  onMediaAccept: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  acceptedMimeTypes: PropTypes.object,
};

export default MediaUploader;
