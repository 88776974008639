import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';

import {setInputQuery, setInputType} from '../../../actions/search';
import {SEARCH_INPUT_QUERY_TYPES} from '../../../constants/search';
import {selectInputQuery, selectInputType, selectLoading} from '../../../selectors/search';

const SearchFilterInput = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const inputQuery = useSelector(selectInputQuery);
  const inputQueryRef = useRef(null);
  const inputType = useSelector(selectInputType);

  const handleChangeQuery = () => {
    const value = inputQueryRef.current.value;
    dispatch(setInputQuery(value));
  };

  const handleChangeType = (type) => {
    dispatch(setInputType(type.value));
  };

  return (
    <div className="filters-input">
      <div className="container">
        <input
          type="text"
          name="input-query"
          onChange={() => handleChangeQuery()}
          placeholder={SEARCH_INPUT_QUERY_TYPES[inputType.toLowerCase()].placeholder}
          disabled={isLoading}
          ref={inputQueryRef}
          value={inputQuery}
        />
        <Select
          className="select"
          isDisabled={isLoading}
          onChange={(type) => handleChangeType(type)}
          options={Object.values(SEARCH_INPUT_QUERY_TYPES)}
          value={SEARCH_INPUT_QUERY_TYPES[inputType.toLowerCase()]}
        />
      </div>
    </div>
  );
};

export default SearchFilterInput;
