import {IconStrikethrough} from '@codexteam/icons';
import {default as StrikethroughSource} from 'editorjs-strikethrough';

/**
 * Extends the editorjs-strikethough plugin so we can update the icon to match latest Editor.js Codex icons
 * @see https://www.npmjs.com/package/editorjs-strikethrough
 * @see https://github.com/codex-team/icons
 */
export default class Strikethrough extends StrikethroughSource {
  get toolboxIcon() {
    return IconStrikethrough;
  }
}
