import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';

import {setEditType} from '../../../actions/editorStats';
import {EDITOR_STATS_EDIT_TYPES} from '../../../constants/editorStats';
import {selectEditType, selectLoading} from '../../../selectors/editorStats';

const EditorStatsFilterTypes = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const editType = useSelector(selectEditType);
  const defaultValue = EDITOR_STATS_EDIT_TYPES.filter((option) => option.value === editType)[0];
  const handleChange = (editType) => dispatch(setEditType(editType.value));

  return (
    <div className="atom filter-edit-type">
      <Select
        className="select"
        defaultValue={defaultValue}
        isDisabled={isLoading}
        onChange={(editType) => handleChange(editType)}
        options={EDITOR_STATS_EDIT_TYPES}
      />
    </div>
  );
};

export default EditorStatsFilterTypes;
