import Backend from './backend';
import {generateQueryString} from './helpers/generateQueryString';
import {SEARCH_LIMIT_DEFAULT} from './constants/search';

const brHost = process.env.BR_HOST;
if (!brHost) throw new Error('missing BR_HOST');
const BR = new Backend(brHost);
BR.articlePreview = (articleId) => `preview/${articleId}`;
BR.articlePublished = (permalink) => `articles/${permalink}`;

const gkHost = process.env.GATEKEEPER_HOST;
if (!gkHost) throw new Error('missing GATEKEEPER_HOST');
const Gatekeeper = new Backend(gkHost);
Gatekeeper.oauth = '/oauth2/token';
Gatekeeper.publicKey = '/public_key';
Gatekeeper.userPrivate = (userId) => `/users/${userId}/private`;
Gatekeeper.getUsersProfiles = '/users/profiles';
Gatekeeper.getUserProfile = (profileId) => `/users/${profileId}/profile`;
Gatekeeper.getUserList = '/users/query';
Gatekeeper.searchUsers = (query) => `/users?query=${query}`;
Gatekeeper.getRoles = '/roles';

const articlesHost = process.env.ARTICLES_HOST;
if (!articlesHost) throw new Error('missing ARTICLES_HOST');
const Articles = new Backend(articlesHost);
// dashboard
Articles.getDashboardArticles = (offset, limit, showMine, showEdited, showUnedited) => {
  const endpoint = `dashboard/articles?offset=${offset}&limit=${limit}&only_my_articles=${showMine}`;
  if (showEdited === showUnedited) return endpoint;
  else if (showEdited) return `${endpoint}&only_edited=true`;
  return `${endpoint}&only_edited=false`;
};
Articles.searchArticles = ({cursor, limit = SEARCH_LIMIT_DEFAULT, searchInput, searchType, startDate, endDate}) =>
  `dashboard/search${generateQueryString({cursor, limit, searchInput, searchType, startDate, endDate})}`;
// articles
Articles.addFeedback = (id) => `dashboard/articles/${id}/feedbacks`;
Articles.createArticle = () => 'dashboard/articles';
Articles.deleteArticle = (id) => `dashboard/articles/${id}`;
Articles.getArticle = (id = '') => `dashboard/articles/${id}`;
Articles.saveArticle = (id) => `dashboard/articles/${id}`;
Articles.saveEditorPrimary = (id) => `dashboard/articles/${id}/editor/primary`;
Articles.saveMetadata = (id) => `dashboard/articles/${id}/metadata`;
Articles.scheduleArticle = (id) => `dashboard/articles/${id}/schedule`;
// slideshows
// @see https://docs.google.com/document/d/1ivLUkB48CiPxtnPYTgNIPvl8zwWML__VcBa_3lAqKck/edit#heading=h.dezk3cep7mjh
Articles.slideshowCreate = () => '/dashboard/slideshows';
Articles.slidesAdd = (id) => `/dashboard/slideshows/${id}/slides`;
Articles.slidesEdit = (id, slideId) => `/dashboard/slideshows/${id}/slides/${slideId}`;
Articles.slidesReorder = (id) => `/dashboard/slideshows/${id}/slides/reorder`;
// version history
Articles.getVersion = (article, version) => `dashboard/articles/${article}/versions/${version}`;
Articles.getVersions = (id) => `dashboard/articles/${id}/versions`;
// editor stats
Articles.getEditorStats = (startDate, endDate) => `/dashboard/editors/stats?start=${startDate}&end=${endDate}`;
Articles.getEditorStatsById = (id, startDate, endDate) => `/dashboard/editors/${id}/stats?start=${startDate}&end=${endDate}`;

const tagsHost = process.env.TAGS_HOST;
if (!tagsHost) throw new Error('missing TAGS_HOST');
const Tags = new Backend(tagsHost);
Tags.searchTags = (query) => `v2/tags/search/${query}?mode=match&editorial_visible=true`;
Tags.getTagsByIds = (ids) => `v2/tags?ids=${ids}&maintain_order=true`;

const videoHost = process.env.VIDEO_HOST;
if (!videoHost) throw new Error('missing VIDEO_HOST');
const Video = new Backend(videoHost);
Video.getById = (videoId) => `/videos/${videoId}`;

const cloudinaryHost = process.env.CLOUDINARY_HOST;
if (!cloudinaryHost) throw new Error('missing CLOUDINARY_HOST');
const Cloudinary = new Backend(cloudinaryHost);
Cloudinary.imageUpload = () => '/image/upload';

/**
 * Converts a key value object into a query string
 *
 * @param {object} obj the key value object to convert
 * @returns {string} a query string
 */
function objectToQueryString(obj) {
  return Object.entries(obj)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

const apHost = process.env.AP_HOST;
if (!apHost) throw new Error('missing AP_HOST');
const AP = new Backend(apHost);
AP.search = (qs) => `media/v2.2/content/search?${objectToQueryString(qs)}`;
// note: this search endpoint value is used for the first page of results OR a single page of results only;
// later pages must be requested via a URL which is provided in the response of the initial results.

const gettyHost = process.env.GETTY_HOST;
if (!gettyHost) throw new Error('missing GETTY_HOST');
const Getty = new Backend(gettyHost);
Getty.imageDataHighRes = (imageId) => `v3/downloads/images/${imageId}?file_type=jpg`;
Getty.oAuth = () => 'oauth2/token';
Getty.search = (qs) => `v3/search/images/editorial?${objectToQueryString(qs)}`;

// Our Librarian backend, which proxies frontend video search requests to the librarian service. Note that the value
// returned by Librarian.videoSearch is different from the value returned by Proxies.librarianVideoSearch below. This
// is because Proxies.librarianVideoSearch is used by the frontend and only has to pass along the query, type and limit
// param values the backend requires. Librarian.videoSearch is used by the backend and has the format that the
// actual Librarian service expects.
const librarianHost = process.env.LIBRARIAN_HOST;
if (!librarianHost) throw new Error('missing LIBRARIAN_HOST');
const Librarian = new Backend(librarianHost);
Librarian.videoSearch = (query, limit, type) => `search?q=${query}&type=${type}&video_state=published&limit=${limit}`;

const cttHost = process.env.CONTENT_TOOLS_HOST;
if (!cttHost) throw new Error('missing CONTENT_TOOLS_HOST');
const Proxies = new Backend(cttHost);
Proxies.gettyOauthToken = () => 'proxy/getty/oauth2-token';
Proxies.librarianVideoSearch = (query, limit, type) => `proxy/librarian/video-search?query=${query}&type=${type}&limit=${limit}`;
Proxies.articlesAuthorEditorSearch = ({cursor, limit = SEARCH_LIMIT_DEFAULT, searchInput, searchType, startDate, endDate}) =>
  `proxy/articles/author-editor-search${generateQueryString({cursor, limit, searchInput, searchType, startDate, endDate})}`;

export {AP, Articles, BR, Cloudinary, Gatekeeper, Getty, Librarian, Proxies, Tags, Video};
