import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getUserProfilesByIds} from '../../actions/users';
import {clearDashboard, fetchDashboard, makeArticleRestored} from '../../actions/dashboard';
import {STATUS_DELETED, STATUS_DRAFT, STATUS_PUBLISHED} from '../../constants/article';
import {PLACEHOLDER_EMPTY} from '../../constants/date';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_DELETE_RESTORE} from '../../constants/prompt';
import {getArticleType, isAdmin} from '../../helpers/articleHelpers';
import {prompt} from '../../helpers/promptHelper';
import {formatDateWithTimezone} from '../../helpers/dateHelpers';

import Loading from '../atoms/loading';
import Pagination from '../atoms/pagination';
import DashboardFiltersSort from '../atoms/dashboard/dashboardFiltersSort';

import {selectAction, selectArticles, selectError, selectHasMore, selectLoading, selectOffset} from '../../selectors/dashboard';
import {selectProfiles} from '../../selectors/users';
import Toast from '../atoms/toast';
import ArticleStatusBadge from '../atoms/tools/articleStatusBadge';

const DeletedArticles = () => {
  if (!isAdmin()) return null;

  const [toast, setToast] = useState(null);

  const action = useSelector(selectAction);
  const articles = useSelector(selectArticles);
  const offset = useSelector(selectOffset);
  const hasMore = useSelector(selectHasMore);
  const filter = STATUS_DELETED;
  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const dispatch = useDispatch();
  const profiles = useSelector(selectProfiles);

  if (articles && articles.length > 0) {
    const removers = articles.map((article) => article.deletedByGatekeeperId).filter((id) => id !== null);
    dispatch(getUserProfilesByIds(removers));
  }

  useEffect(() => {
    dispatch(fetchDashboard({filter}));
    return () => dispatch(clearDashboard());
  }, []);

  useEffect(() => {
    const toast = action?.status ? {message: action.status} : null;
    setToast(toast);
  }, [action]);

  const handleRestoreArticle = (id, title, status) => {
    prompt(PROMPT_DELETE_RESTORE)
      .withTexts(
        `Are you sure you want to restore this ${status} article?`,
        `${title} (${status === STATUS_PUBLISHED ? 'will be restored as a draft and need to be republished' : 'will be restored as a draft'})`,
        PROMPT_BUTTON_CONFIRM,
        PROMPT_BUTTON_CANCEL
      )
      .withMute(false)
      .show(() => dispatch(makeArticleRestored(id)));
  };

  const getUserName = (id) => {
    const user = profiles[id];
    if (!user) return null;
    return `${user.firstName} ${user.lastName}`;
  };

  const getDeletedArticlesList = () => {
    if (isLoading || !articles)
      return (
        <div className="group group-empty">
          <Loading />
        </div>
      );
    if (!articles.length) return <div className="group group-empty">No deleted articles</div>;

    return articles.map((article) => {
      const {author, deletedByGatekeeperId, id, insertedAt, publishedAt, title} = article;
      const isPublished = publishedAt !== null;
      const status = isPublished ? STATUS_PUBLISHED : STATUS_DRAFT; // all non-published articles are considered draft, instead of using article status
      return (
        <div className={`group group-type--${getArticleType(article)}`} key={id}>
          <div className="article-title-group">
            <strong>{title}</strong>
            <div className="article-details">
              <div className="details">
                {author && (
                  <div className="author">
                    <span>Author:</span> {author}
                  </div>
                )}
                {deletedByGatekeeperId && (
                  <div className="deleted-by">
                    <span>Deleted by:</span> {getUserName(deletedByGatekeeperId)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="subgroup">
            <ArticleStatusBadge status={status} />
            <div className="article-date-inner">
              <div className="label">{isPublished ? 'Published' : 'Created'}</div>
              <div className="value">{formatDateWithTimezone(isPublished ? publishedAt : insertedAt) || PLACEHOLDER_EMPTY}</div>
            </div>
          </div>
          <div className="article-actions article-actions-button">
            <button onClick={() => handleRestoreArticle(id, title, status)}>Restore</button>
          </div>
        </div>
      );
    });
  };

  const getPagination = () => {
    if ((!articles || !articles.length) && !offset) return null;
    return (
      <Pagination hasMore={hasMore} isLoading={isLoading} offset={offset} onPageChange={({offset}) => dispatch(fetchDashboard({filter, offset}))} />
    );
  };

  if (error) return <div>Unexpected error occur</div>;
  return (
    <section className="molecule dashboard-articles">
      <div className="dashboard-articles-header">
        <h2>Deleted Articles</h2>
        <div className="right">
          <Toast toast={toast} />
          <div className="filters">
            <DashboardFiltersSort />
          </div>
        </div>
      </div>
      <div className="dashboard-articles-container">
        <div className="articles-header">
          <div>&nbsp;</div>
          {getPagination()}
        </div>
        <div className="articles articles--active">
          {getDeletedArticlesList()}
          {getPagination()}
        </div>
      </div>
    </section>
  );
};

export default DeletedArticles;
