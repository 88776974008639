import {FILTER_DELETED, FILTER_DRAFT, FILTER_IN_REVIEW, FILTER_PUBLISHED, FILTER_TO_PUBLISH} from './dashboard';

export const WRITER = 'writer';
export const EDITOR = 'editor';
export const SORT_ARROW_UP = '\u{2191}';
export const SORT_ARROW_DOWN = '\u{2193}';

export const SORT_OPTION_AUTHOR_ASC = {text: `Author ${SORT_ARROW_UP}`, sort: ['author', 'asc']};
export const SORT_OPTION_AUTHOR_DESC = {text: `Author ${SORT_ARROW_DOWN}`, sort: ['author', 'desc']};
export const SORT_OPTION_DATE_CREATED_ASC = {text: `Date Created ${SORT_ARROW_UP}`, sort: ['date_created', 'asc']};
export const SORT_OPTION_DATE_CREATED_DESC = {text: `Date Created ${SORT_ARROW_DOWN}`, sort: ['date_created', 'desc']};
export const SORT_OPTION_LAST_EDITED_ASC = {text: `Last Edited ${SORT_ARROW_UP}`, sort: ['updated_at', 'asc']};
export const SORT_OPTION_LAST_EDITED_DESC = {text: `Last Edited ${SORT_ARROW_DOWN}`, sort: ['updated_at', 'desc']};
export const SORT_OPTION_PUBLISHED_AT_ASC = {text: `Published Time ${SORT_ARROW_UP}`, sort: ['published_at', 'asc']};
export const SORT_OPTION_PUBLISHED_AT_DESC = {text: `Published Time ${SORT_ARROW_DOWN}`, sort: ['published_at', 'desc']};
export const SORT_OPTION_SCHEDULED_AT_DESC = {text: `Scheduled Publish Time ${SORT_ARROW_DOWN}`, sort: ['scheduled_at', 'desc']};
export const SORT_OPTION_SUBMITTED_FOR_REVIEW_AT_ASC = {text: `Submitted For Review Time ${SORT_ARROW_UP}`, sort: ['submitted_for_review_at', 'asc']};
export const SORT_OPTION_SUBMITTED_FOR_REVIEW_AT_DESC = {
  text: `Submitted For Review Time ${SORT_ARROW_DOWN}`,
  sort: ['submitted_for_review_at', 'desc'],
};
export const SORT_OPTION_TITLE_ASC = {text: `Title ${SORT_ARROW_UP}`, sort: ['title', 'asc']};
export const SORT_OPTION_TITLE_DESC = {text: `Title ${SORT_ARROW_DOWN}`, sort: ['title', 'desc']};

export const SORT_OPTION_DEFAULT = SORT_OPTION_LAST_EDITED_DESC.sort;

export const SORT_OPTIONS = {
  [WRITER]: {
    [FILTER_DELETED]: [SORT_OPTION_LAST_EDITED_ASC, SORT_OPTION_LAST_EDITED_DESC, SORT_OPTION_PUBLISHED_AT_ASC, SORT_OPTION_PUBLISHED_AT_DESC],
    [FILTER_DRAFT]: [
      SORT_OPTION_DATE_CREATED_ASC,
      SORT_OPTION_DATE_CREATED_DESC,
      SORT_OPTION_LAST_EDITED_ASC,
      SORT_OPTION_LAST_EDITED_DESC,
      SORT_OPTION_TITLE_ASC,
      SORT_OPTION_TITLE_DESC,
    ],
    [FILTER_PUBLISHED]: [
      SORT_OPTION_DATE_CREATED_ASC,
      SORT_OPTION_DATE_CREATED_DESC,
      SORT_OPTION_LAST_EDITED_ASC,
      SORT_OPTION_LAST_EDITED_DESC,
      SORT_OPTION_PUBLISHED_AT_ASC,
      SORT_OPTION_PUBLISHED_AT_DESC,
      SORT_OPTION_TITLE_ASC,
      SORT_OPTION_TITLE_DESC,
    ],
  },
  [EDITOR]: {
    [FILTER_DELETED]: [SORT_OPTION_LAST_EDITED_ASC, SORT_OPTION_LAST_EDITED_DESC, SORT_OPTION_PUBLISHED_AT_ASC, SORT_OPTION_PUBLISHED_AT_DESC],
    [FILTER_DRAFT]: [
      SORT_OPTION_AUTHOR_ASC,
      SORT_OPTION_AUTHOR_DESC,
      SORT_OPTION_DATE_CREATED_ASC,
      SORT_OPTION_DATE_CREATED_DESC,
      SORT_OPTION_LAST_EDITED_ASC,
      SORT_OPTION_LAST_EDITED_DESC,
      SORT_OPTION_TITLE_ASC,
      SORT_OPTION_TITLE_DESC,
    ],
    [FILTER_IN_REVIEW]: [
      SORT_OPTION_AUTHOR_ASC,
      SORT_OPTION_AUTHOR_DESC,
      SORT_OPTION_DATE_CREATED_ASC,
      SORT_OPTION_DATE_CREATED_DESC,
      SORT_OPTION_LAST_EDITED_ASC,
      SORT_OPTION_LAST_EDITED_DESC,
      SORT_OPTION_SUBMITTED_FOR_REVIEW_AT_ASC,
      SORT_OPTION_SUBMITTED_FOR_REVIEW_AT_DESC,
      SORT_OPTION_TITLE_ASC,
      SORT_OPTION_TITLE_DESC,
    ],
    [FILTER_TO_PUBLISH]: [
      SORT_OPTION_AUTHOR_ASC,
      SORT_OPTION_AUTHOR_DESC,
      SORT_OPTION_DATE_CREATED_ASC,
      SORT_OPTION_DATE_CREATED_DESC,
      SORT_OPTION_LAST_EDITED_ASC,
      SORT_OPTION_LAST_EDITED_DESC,
      SORT_OPTION_SCHEDULED_AT_DESC,
      SORT_OPTION_TITLE_ASC,
      SORT_OPTION_TITLE_DESC,
    ],
    [FILTER_PUBLISHED]: [
      SORT_OPTION_AUTHOR_ASC,
      SORT_OPTION_AUTHOR_DESC,
      SORT_OPTION_DATE_CREATED_ASC,
      SORT_OPTION_DATE_CREATED_DESC,
      SORT_OPTION_LAST_EDITED_ASC,
      SORT_OPTION_LAST_EDITED_DESC,
      SORT_OPTION_PUBLISHED_AT_ASC,
      SORT_OPTION_PUBLISHED_AT_DESC,
      SORT_OPTION_TITLE_ASC,
      SORT_OPTION_TITLE_DESC,
    ],
  },
};
