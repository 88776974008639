import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectPickedVideo,
  selectPickedVideoCaption,
  selectPickedVideoDescription,
  selectPickedVideoStartSeconds,
  selectPickedVideoTags,
  selectPickedVideoTitle,
} from '../../../selectors/media';

import IconButton from '../iconButton';
import Video from '../video';
import VideoTags from '../../molecules/videoTags';

const VideoSidebar = ({onCancel, onSubmit, onZoom, zoomed}) => {
  const captionRef = useRef(null);
  const descriptionRef = useRef(null);
  const startTimeRef = useRef(null);
  const titleRef = useRef(null);
  const [state, setState] = useState({
    videoTags: [],
    startSeconds: 0,
  });

  const caption = useSelector(selectPickedVideoCaption);
  const description = useSelector(selectPickedVideoDescription);
  const selected = useSelector(selectPickedVideo);
  const startSeconds = useSelector(selectPickedVideoStartSeconds);
  const title = useSelector(selectPickedVideoTitle);
  const videoTags = useSelector(selectPickedVideoTags);

  const prevStartSecondsRef = useRef(startSeconds);
  const prevVideoTagsRef = useRef(videoTags);

  useEffect(() => setState({...state, startSeconds}), []);

  useEffect(() => {
    if (prevStartSecondsRef.current !== startSeconds) {
      setState({...state, startSeconds});
      prevStartSecondsRef.current = startSeconds;
    }

    if (prevVideoTagsRef.current !== videoTags) {
      setState({...state, videoTags});
      prevVideoTagsRef.current = videoTags;
    }
  }, [startSeconds, videoTags]);

  const handleSubmit = () => {
    const {description, hls, mp4, title} = selected;
    const metadata = {
      caption: captionRef.current.value,
      description: descriptionRef.current.value,
      hideFromRegions: selected?.hideFromRegions,
      hls,
      originalDescription: description,
      originalTitle: title,
      progressive: mp4,
      startSeconds: Number(startTimeRef.current.value),
      thumbnail: selected.thumbnailUrl,
      title: titleRef.current.value,
      videoId: selected.id,
      videoTags: state.videoTags,
    };

    onSubmit(metadata);
  };

  const getTagsVideo = () => <VideoTags onTagSelect={(tags) => setState({...state, videoTags: tags})} selected={state.videoTags} />;

  const renderVideo = () => {
    return (
      <>
        <IconButton icon="window-expand" onClick={() => onZoom(true)} />
        <Video src={selected} />
      </>
    );
  };

  const onStartSecondsChanged = (e) => setState({...state, startSeconds: e.target.value});

  const renderVideoMeta = () => {
    return (
      <>
        {!zoomed && renderVideo()}
        <h2>Metadata</h2>
        <div className="form-control">
          <label htmlFor="video-title">Video Title</label>
          <textarea ref={titleRef} defaultValue={title || selected.title} name="video-title" placeholder="Add video title…" />
        </div>
        <div className="form-control">
          <label htmlFor="video-description">Video Description</label>
          <textarea
            ref={descriptionRef}
            defaultValue={description || selected.description}
            name="video-description"
            placeholder="Add video description…"
          />
        </div>
        <div className="form-control">
          <label htmlFor="video-description">Who was mentioned in the video?</label>
          {getTagsVideo()}
        </div>
        <div className="form-control">
          <label htmlFor="video-caption">Video Caption (optional)</label>
          <textarea ref={captionRef} defaultValue={caption} name="video-caption" placeholder="Add video caption…" />
        </div>
        <div className="form-control">
          <label htmlFor="video-start-time">Video Start Time</label>
          <input
            type="range"
            ref={startTimeRef}
            defaultValue={startSeconds}
            name="video-start-time-range"
            min="0"
            max={selected.duration}
            onChange={onStartSecondsChanged}
          />
          <input name="video-start-time-seconds" type="number" value={state.startSeconds} disabled={true} readOnly={true} />
        </div>
      </>
    );
  };

  return (
    <div className="modal-sidebar">
      <div className="modal-sidebar-content">{selected && renderVideoMeta()}</div>
      <div className="modal-actions">
        <button onClick={onCancel}>Cancel</button>
        <button className="button--primary" disabled={!selected} onClick={handleSubmit}>
          Add Video
        </button>
      </div>
    </div>
  );
};

VideoSidebar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
  zoomed: PropTypes.bool.isRequired,
};

export default VideoSidebar;
