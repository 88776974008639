import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

import {ARTICLE_ACTIVE_STATUSES, TAB_FEEDBACK_HASH} from '../../../constants/article';

const ArticleFeedbackLink = ({count, url, status, editedBy, author}) => {
  const classesCount = classnames('count', {
    'count--high': count > 9,
  });

  return (
    <Link
      to={{
        pathname: url,
        hash: TAB_FEEDBACK_HASH,
        articleStatus: status,
        articleEditedBy: editedBy,
        articleAuthor: author,
      }}
      className="article-link--feedback">
      <span className="sr-only">Feedback</span>
      {count > 0 && <span className={classesCount}>{count}</span>}
    </Link>
  );
};

ArticleFeedbackLink.defaultProps = {
  count: 0,
};

ArticleFeedbackLink.propTypes = {
  count: PropTypes.number,
  url: PropTypes.string.isRequired,
  status: PropTypes.oneOf(ARTICLE_ACTIVE_STATUSES).isRequired,
  editedBy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default ArticleFeedbackLink;
