import React from 'react';

import VersionSelector from '../atoms/versions/versionSelector';
import VersionStandard from '../atoms/versions/versionStandard';

function VersionsCompare() {
  return (
    <div className="molecule versions-compare">
      <div className="side side-left">
        <div className="version-selector">
          <VersionSelector />
        </div>
        <VersionStandard />
      </div>
      <div className="side side-right">
        <div className="version-selector">
          <VersionSelector isCompareVersion={true} />
        </div>
        <VersionStandard isCompareVersion={true} />
      </div>
    </div>
  );
}

export default VersionsCompare;
