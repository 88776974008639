import {createAction} from '@reduxjs/toolkit';
import reporter from '../reporter';

import logger from '../logger';

import {CTT_USER} from '../constants/user';
import {getStorageData} from '../helpers/authHelpers';

import {
  SET_FETCHING,
  MEDIA_GET_IMAGES,
  MEDIA_GET_VIDEOS,
  MEDIA_CLEAR,
  MEDIA_SELECT_IMAGE,
  MEDIA_SELECT_VIDEO,
  MEDIA_RETRIEVE_VIDEO,
  MEDIA_IMAGE_FILTER,
} from '../constants/action-types';
import {getImages, getVideos, getVideo} from '../apis/media/mediaAPI';
import {highResGettyImageUrl} from '../apis/media/gettyAPI';
import {uploadImage, uploadURL} from '../apis/cloudinary/cloudinaryAPI';

export const actionSetFetching = createAction(SET_FETCHING);

export const actionSelectImage = createAction(MEDIA_SELECT_IMAGE);
export const actionSelectVideo = createAction(MEDIA_SELECT_VIDEO);
export const actionRetrieveVideo = createAction(MEDIA_RETRIEVE_VIDEO);

export const actionSetImageFilter = createAction(MEDIA_IMAGE_FILTER);

export const actionGetImages = createAction(MEDIA_GET_IMAGES);
export const actionGetVideos = createAction(MEDIA_GET_VIDEOS);
export const actionClear = createAction(MEDIA_CLEAR);

export function detectPhotogAndPublisher(caption, credit) {
  const REGEX_CONTENT_OF_PARENS_NEAR_END = /\((?:Photo by )?([^)]+?)[ .)]*$/;
  const REGEX_ENDINGS = /, (Pool|FILE)\s*$/;
  const REGEX_PHOTOG_LABEL_NEAR_END = / Photographer: (.+)[ .]*$/;

  // We get a match here if we find:
  // - an opening parenthesis
  // - optionally followed by the string "Photo by "
  // - followed by a sequence of characters that are not a closing parenthesis
  // - followed by any number of closing parenthesis, or period, or space character at the end of the string
  // We made the closing parenthesis optional due to a case where the caption supplied with an AP image
  // did not contain a closing parenthesis. @see https://statmilk.atlassian.net/browse/CTT-1235
  const parenthesesMatch = caption.match(REGEX_CONTENT_OF_PARENS_NEAR_END);
  if (parenthesesMatch) {
    const [, parenContents] = parenthesesMatch;
    if (REGEX_ENDINGS.test(parenContents)) {
      return parenContents.replace(REGEX_ENDINGS, '');
    }
    return parenContents;
  }
  const photogMatch = caption.match(REGEX_PHOTOG_LABEL_NEAR_END);
  if (photogMatch) {
    const [, text] = photogMatch;
    return text;
  }
  return credit;
}

function fetcher(loader) {
  return async (dispatch, getState) => {
    const {isLoading} = getState().media;

    if (isLoading) {
      return;
    }

    dispatch(actionSetFetching(true));
    await loader(dispatch);
    dispatch(actionSetFetching(false));
  };
}

export const fetchImages = (query, offset, filteredBy = null, {page, paginationUrlAp} = {}) => {
  return fetcher(async (dispatch) => {
    const payload = await getImages(query, offset, filteredBy, {page, paginationUrlAp});
    dispatch(actionGetImages({...payload, search: query, offset}));
  });
};

export const fetchVideos = (query, limit) => {
  return fetcher(async (dispatch) => {
    const payload = await getVideos(query, limit);
    dispatch(actionGetVideos({...payload, search: query}));
  });
};

export const clearMedia = (type) => {
  return (dispatch) => dispatch(actionClear({type}));
};

export const setSelectedImage = (image, params) => {
  return (dispatch) => dispatch(actionSelectImage({...params, image}));
};

export const setImageFilter = (filter) => {
  return (dispatch) => dispatch(actionSetImageFilter({type: filter}));
};

export const selectImageFromURL = (url, sourceCredit, sourceCaption) => {
  return fetcher(async (dispatch) => {
    // Upload image to Cloudinary
    let response;
    try {
      response = await uploadURL(url);
    } catch (error) {
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    if (!response) {
      const error = new Error('Error when requesting that Cloudinary download the image');
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    if (!response.uploaded) {
      const error = new Error('Missing `uploaded` data from Cloudinary response');
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    const image = {
      ...response.uploaded,
      url: response.uploaded.secureUrl,
    };
    const payload = {
      image,
      error: response.error,
      sourceCredit,
      sourceCaption,
      credit: detectPhotogAndPublisher(sourceCaption, sourceCredit),
    };
    return dispatch(actionSelectImage(payload));
  });
};

export const selectImageFromFile = (file, caption) => {
  return fetcher(async (dispatch) => {
    // Upload image to Cloudinary
    const {uploaded, error} = await uploadImage(file);
    const payload = {
      image: uploaded,
      userID: getStorageData(CTT_USER),
      error,
      caption,
    };
    dispatch(actionSelectImage(payload));
  });
};

export const selectVideo = (videoId) => {
  return fetcher(async (dispatch) => {
    const payload = await getVideo(videoId);
    dispatch(actionSelectVideo(payload));
  });
};

export const retrieveVideoById = (videoId, caption, title, description, videoTags, startSeconds) => {
  return fetcher(async (dispatch) => {
    const payload = await getVideo(videoId);
    return dispatch(actionRetrieveVideo({...payload, caption, title, description, videoTags, startSeconds}));
  });
};

export const selectGettyImageFromId = ({id, sourceCaption, sourceCredit}) => {
  return fetcher(async (dispatch) => {
    let url;
    try {
      url = await highResGettyImageUrl(id);
    } catch (error) {
      logger.error('Error determining high-res URL for Getty image', {id});
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    let response;
    try {
      response = await uploadURL(url);
    } catch (error) {
      logger.error('Error from Cloudinary fetching high-res Getty image', {id});
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    if (!response) {
      const error = new Error('Missing response from Cloudinary');
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    if (!response.uploaded) {
      const error = new Error('Missing `uploaded` Getty data from Cloudinary response');
      logger.error(error);
      reporter.inform(error);
      return dispatch(actionSelectImage({error}));
    }
    const image = {
      ...response.uploaded,
      url: response.uploaded.secureUrl,
    };
    const payload = {
      image,
      error: response.error,
      sourceCredit,
      sourceCaption,
      credit: detectPhotogAndPublisher(sourceCaption, sourceCredit),
    };
    return dispatch(actionSelectImage(payload));
  });
};
