import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

export default function PageTitle({title, children}) {
  const joined = Array.isArray(title) ? title.join(' | ') : title;
  return (
    <Helmet>
      <title>{joined} | B/R Content Tools</title>
      {children}
    </Helmet>
  );
}

PageTitle.defaultTypes = {
  children: null,
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.node,
};
