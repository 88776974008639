import React from 'react';
import {Link} from 'react-router-dom';

import {isCreateAllowed} from '../../helpers/articleHelpers';

const DashboardHeader = () => {
  if (!isCreateAllowed()) return null;

  return (
    <div className="molecule dashboard-header">
      <ul>
        <li>
          <Link to={'/articles/standard/new'}>New Standard Article</Link>
        </li>
        <li>
          <Link to={'/articles/slideshow/new'}>New Slideshow Article</Link>
        </li>
      </ul>
    </div>
  );
};

export default DashboardHeader;
