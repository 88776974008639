import {format, isAfter, subMonths} from 'date-fns';
import * as types from '../constants/action-types';
import {DATE_FORMAT_DAY} from '../constants/date';
import {EDITOR_STATS_EDIT_TYPE_DEFAULT_VALUE, EDITOR_STATS_EDIT_TYPES} from '../constants/editorStats';
import {setDateValue} from '../helpers/dateHelpers';

const setDateStart = (date = new Date()) => {
  return format(subMonths(date, 1), DATE_FORMAT_DAY); // set start date 1 month earlier
};

export const initialState = {
  dateEnd: format(new Date(), DATE_FORMAT_DAY),
  dateStart: setDateStart(),
  editType: EDITOR_STATS_EDIT_TYPE_DEFAULT_VALUE,
  editors: [],
  editorsCount: 0,
  error: false,
  fetching: false,
  individual: [],
  individualCount: 0,
};

const editorStats = (state = initialState, action) => {
  switch (action.type) {
    case types.EDITOR_STATS_DATE_END: {
      const dateEnd = action.payload;
      const dateEndValue = setDateValue(dateEnd);
      // if new end date is before current start date, reset start date to default before that
      if (isAfter(new Date(state.dateStart), dateEndValue)) {
        return {
          ...state,
          dateEnd,
          dateStart: setDateStart(dateEndValue),
        };
      }

      return {...state, dateEnd};
    }

    case types.EDITOR_STATS_DATE_START: {
      return {...state, dateStart: action.payload};
    }

    case types.EDITOR_STATS_EDIT_TYPE: {
      const acceptedTypes = EDITOR_STATS_EDIT_TYPES.map((type) => type.value);
      const editType = acceptedTypes.includes(action.payload) ? action.payload : EDITOR_STATS_EDIT_TYPE_DEFAULT_VALUE;
      return {
        ...state,
        editType,
      };
    }

    case types.EDITOR_STATS_EDITORS: {
      return {
        ...state,
        editors: action.payload,
        editorsCount: action.payload.length,
      };
    }

    case types.EDITOR_STATS_EDITORS_CLEAR: {
      return {
        ...state,
        editors: [],
        editorsCount: 0,
      };
    }

    case types.EDITOR_STATS_ERROR: {
      return {...state, error: true};
    }

    case types.EDITOR_STATS_FETCHING: {
      return {...state, fetching: action.payload};
    }

    case types.EDITOR_STATS_INDIVIDUAL: {
      return {
        ...state,
        individual: action.payload,
        individualCount: action.payload.length,
      };
    }

    case types.EDITOR_STATS_INDIVIDUAL_CLEAR: {
      return {
        ...state,
        individual: [],
        individualCount: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default editorStats;
