import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {TAB_FEEDBACK_HASH} from '../../constants/article';
import {getAuthor} from '../../selectors/article';
import {getArticleData, getIsNew} from '../../selectors/article';

import AuthorSwap from '../atoms/article/authorSwap';
import Feedbacks from '../atoms/article/feedbacks';
import MetaSocial from '../atoms/article/metaSocial';
import PublishDates from '../atoms/article/publishDates';
import Thumbnail from '../atoms/article/thumbnail';
import UnscheduleButton from '../atoms/article/unscheduleButton';
import User from '../atoms/user';
import UserEditor from '../atoms/article/userEditor';

const ArticleSidebar = (props) => {
  const {
    isFeedbackEnabled,
    permissionsBNTControl,
    publishDateControl,
    tagsAdmin,
    tagsArticle,
    thumbnailUrl,
    withAddFeedback,
    withFeedbacks,
    wordcount,
  } = props;
  const author = useSelector(getAuthor);
  const isArticleNew = useSelector(getIsNew);
  const article = useSelector(getArticleData);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const {publishedAt, scheduledAt} = article;
  const showPublishDate = !isArticleNew && (publishedAt || scheduledAt);

  useEffect(() => {
    const tabIndex = location.hash === TAB_FEEDBACK_HASH ? 1 : 0;
    setTabIndex(tabIndex);
  }, [location]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    const tabHash = index === 1 ? TAB_FEEDBACK_HASH : '#';
    navigate(tabHash);
  };

  return (
    <div className="molecule article-sidebar">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => handleTabChange(index)}>
        <TabList>
          <Tab>Metadata</Tab>
          <Tab disabled={!isFeedbackEnabled}>Feedback</Tab>
        </TabList>
        <TabPanel>
          {showPublishDate && (
            <div className="group publish-dates">
              <h3>{scheduledAt && !publishedAt ? 'Scheduled for Publish' : 'Published'}</h3>
              <div className="group-content">
                <PublishDates />
                <UnscheduleButton />
              </div>
            </div>
          )}
          <div className="group permissions">
            <h3>Article Permissions</h3>
            {permissionsBNTControl}
          </div>
          <div className="group author">
            <h3>Author</h3>
            <div className="group-content">
              <User user={author} />
              <AuthorSwap />
            </div>
          </div>
          <UserEditor />
          <div className="group thumbnail">
            <Thumbnail url={thumbnailUrl} />
          </div>
          <div className="group document">
            <h3>Document</h3>
            <div className="document-wordcount">Word Count: {wordcount}</div>
            <div className="document-publish-date">{publishDateControl}</div>
          </div>
          <div className="group tags">
            <h3>Tags & Social Metadata</h3>
            <Tabs>
              <TabList>
                <Tab>Article</Tab>
                <Tab>Social</Tab>
                <Tab>Admin</Tab>
              </TabList>

              <TabPanel>{tagsArticle}</TabPanel>
              <TabPanel>
                <MetaSocial />
              </TabPanel>
              <TabPanel>{tagsAdmin}</TabPanel>
            </Tabs>
          </div>
        </TabPanel>
        <TabPanel>{withFeedbacks && <Feedbacks withAddFeedback={withAddFeedback} />}</TabPanel>
      </Tabs>
    </div>
  );
};

ArticleSidebar.defaultProps = {
  isFeedbackEnabled: true,
  wordcount: 0,
};

ArticleSidebar.propTypes = {
  isFeedbackEnabled: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
  withAddFeedback: PropTypes.bool,
  withFeedbacks: PropTypes.bool,
  wordcount: PropTypes.number,
};

export default ArticleSidebar;
