import {isEmpty} from 'lodash';
import {
  ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH,
  ARTICLE_TITLE_MINLENGTH,
  ARTICLE_TITLE_MAXLENGTH,
  ARTICLE_CONTENT_MINLENGTH,
  FEATURED_MEDIA_CONTENT_TYPES,
  SLIDE_TITLE_MINLENGTH,
  VALIDATE_FIELD_TITLE,
  VALIDATE_FIELD_BODY,
  VALIDATE_FIELD_FEATUREDMEDIA,
  VALIDATE_FIELD_SOCIAL_DESCRIPTION,
  VALIDATE_FIELD_SOCIAL_TITLE,
  VALIDATE_FIELD_TAGS,
  VALIDATE_FIELD_THUMBNAIL,
} from '../constants/article';
import {TAG_TYPES_REQUIRED} from '../constants/tags';
import {getTagsByIds} from '../apis/tags/tagsAPI';

export function isTitleValid(title) {
  return title?.length >= ARTICLE_TITLE_MINLENGTH && title.length <= ARTICLE_TITLE_MAXLENGTH;
}

export function isSlideTitleValid(title) {
  return title?.length >= SLIDE_TITLE_MINLENGTH && title.length <= ARTICLE_TITLE_MAXLENGTH;
}

export function isSocialDescriptionValid(text) {
  return text?.length >= ARTICLE_TITLE_MINLENGTH && text.length <= ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH;
}

export function isContentValid(words) {
  return words >= ARTICLE_CONTENT_MINLENGTH;
}

export function isFeaturedMediaValid(elements) {
  if (!elements || !elements.length) return false;

  // check that the first content block is a valid feature media type
  if (!FEATURED_MEDIA_CONTENT_TYPES.includes(elements[0].type)) return false;

  return true;
}

export async function isTagsValid(tags) {
  if (!Array.isArray(tags) || !tags.length) return false;
  const tagsExpanded = await getTagsByIds(tags);
  const tagsOfRequiredType = tagsExpanded.filter((tag) => TAG_TYPES_REQUIRED.includes(tag.type));
  // check that we have at least 1 required tag
  return tagsOfRequiredType.length > 0;
}

export function isThumbnailValid(image) {
  // abstract the thumbnail validation into the function for make it easier to grow in a future
  return !!image;
}

export const errorMessageFieldMaxChars = (fieldDisplayName, maxlength) => `${fieldDisplayName} must be less than ${maxlength} characters`;
export const errorMessageFieldMinChars = (fieldDisplayName, minlength = ARTICLE_TITLE_MINLENGTH) =>
  `${fieldDisplayName} must be at least ${minlength} characters`;
export const errorMessageFieldMissing = (fieldDisplayName) => `${fieldDisplayName} missing`;
export const errorMessageTags = () => 'Tags must include a League and/or Sport';
export const errorMessageContentMinChars = () => `Content must be at least ${ARTICLE_CONTENT_MINLENGTH} words`;
export const errorMessageSocialDescription = (text) => {
  let error = errorMessageFieldMinChars('Social description');
  // newer articles should not have instances where the teaser is longer than the maxlength because we have protections
  // but possible for older articles to have this, so want to ensure we're throwing the correct error in this case
  const maxlength = ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH;
  if (text?.length > maxlength) error = errorMessageFieldMaxChars('Social description', maxlength);
  return error;
};

/**
 * Verifies standard article is valid
 * Warning: articles published with invalid social metadata will halt our sitemap feeds from receiving new content
 *
 * TODO: why are we passing tags as prop? can we not use article.tagList.split(,)?
 * TODO: why are we passing words as prop? should this function calculate the wordcount instead of articleForm?
 *
 * @param {object} article entire article slideshow object
 * @param {array} tags list of tags
 * @param {words} words number of words in content
 * @returns {(false|object)} false when no error, or error obj
 */
export default async function validateArticle(article, tags, words) {
  const {elements, image, socialTitle, teaser, title} = article;
  const err = {};

  if (!isTitleValid(title)) err[VALIDATE_FIELD_TITLE] = errorMessageFieldMinChars('Title');
  if (!isTitleValid(socialTitle)) err[VALIDATE_FIELD_SOCIAL_TITLE] = errorMessageFieldMinChars('Social title');
  if (!isSocialDescriptionValid(teaser)) err[VALIDATE_FIELD_SOCIAL_DESCRIPTION] = errorMessageSocialDescription(teaser);
  if (!isFeaturedMediaValid(elements)) err[VALIDATE_FIELD_FEATUREDMEDIA] = errorMessageFieldMissing('Featured media');
  if (!isContentValid(words)) err[VALIDATE_FIELD_BODY] = errorMessageContentMinChars();
  if (!isThumbnailValid(image)) err[VALIDATE_FIELD_THUMBNAIL] = errorMessageFieldMissing('Thumbnail');
  if (!(await isTagsValid(tags))) err[VALIDATE_FIELD_TAGS] = errorMessageTags();

  return isEmpty(err) ? null : err;
}
