import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Tooltip} from 'react-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {useSearch} from '../../actions/hooks/media';
import {EVENT_FEATURED_MEDIA_IFRAME, EVENT_FEATURED_MEDIA_SEARCH, EVENT_FEATURED_MEDIA_UPLOAD} from '../../constants/events';
import {IMAGE_ACCEPTED_MIME_TYPES as MIME_TYPES, MEDIA_TYPE_IMAGE, IMAGE_MAX_SIZE_IN_MB} from '../../constants/media';

import MediaUploader from './mediaUploader';
import {getIsReadOnly} from '../../selectors/article';
import ToolEventObserver from '../../utils/editorjs/tools/toolEventObserver';

const FeaturedMediaForm = ({withError}) => {
  const isReadOnly = useSelector(getIsReadOnly);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const searchRef = useRef(null);
  const {makeSearch} = useSearch(MEDIA_TYPE_IMAGE);

  const onMediaIframeButtonClicked = () => {
    // Raise the iframe modal window
    ToolEventObserver.trigger(EVENT_FEATURED_MEDIA_IFRAME);
  };

  const onMediaLibraryButtonClicked = () => {
    // Raise the media wizard modal window
    ToolEventObserver.trigger(EVENT_FEATURED_MEDIA_SEARCH);
  };

  const onMediaAccepted = (file) => {
    // Raise the media wizard modal window
    ToolEventObserver.trigger(EVENT_FEATURED_MEDIA_UPLOAD, file);
  };

  const onSearch = () => {
    makeSearch(searchRef.current.value);
    // Raise the media wizard modal window
    ToolEventObserver.trigger(EVENT_FEATURED_MEDIA_SEARCH);
    // Cleanup search query
    searchRef.current.value = '';
    setButtonDisabled(true);
  };

  const onSearchValueChanged = () => {
    setButtonDisabled(searchRef.current.value.length < 3);
  };

  const classes = classnames('molecule', 'featured-media', {'is-readonly': isReadOnly}, {'has-error': !!withError});
  return (
    <div className={classes}>
      <MediaUploader.WithButton onMediaAccept={onMediaAccepted} acceptedMimeTypes={MIME_TYPES}>
        {(openUploader, uploadErrorMessages) => (
          <div className={classnames('feature-media-wrapper', {'has-error': !!uploadErrorMessages.length})}>
            <h3>
              <span className="icon icon-image" />
              Featured Media
            </h3>
            <div className="feature-media-description">
              <p>Search the media library for an image or video, upload one or drag and drop an image file. You can also use an iframe.</p>
            </div>
            <div className="feature-media-group">
              <div className="feature-media-buttons">
                <button onClick={onMediaLibraryButtonClicked} disabled={isReadOnly}>
                  Media Library
                </button>
                <button
                  id="max-image-size-tooltip"
                  onClick={openUploader}
                  disabled={isReadOnly}
                  data-tooltip-content={`Uploaded images must be ${IMAGE_MAX_SIZE_IN_MB}MB or smaller`}
                  data-tooltip-offset="20">
                  Upload Image
                </button>
                <button onClick={onMediaIframeButtonClicked} disabled={isReadOnly}>
                  Iframe
                </button>
                <Tooltip anchorSelect="#max-image-size-tooltip" />
              </div>
              <div className="feature-media-or">OR</div>
              <div className="feature-media-search">
                <input
                  ref={searchRef}
                  type="text"
                  disabled={isReadOnly}
                  onChange={onSearchValueChanged}
                  onKeyUp={({key}) => (key === 'Enter' ? onSearch() : null)}
                />
                <button disabled={isButtonDisabled} onClick={onSearch} className={classnames('button--primary', {active: !isButtonDisabled})}>
                  Search
                </button>
              </div>
              <div className="feature-media-upload-error">{uploadErrorMessages.join('. ')}</div>
            </div>
          </div>
        )}
      </MediaUploader.WithButton>
    </div>
  );
};

FeaturedMediaForm.propTypes = {
  withError: PropTypes.string,
};

export default FeaturedMediaForm;
