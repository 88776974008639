import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
export default function IconButton({children, icon, inline, iconUrl, size, ...rest}) {
  const isThumbnail = iconUrl;
  let thumbnailAspectRatio = 1;
  const [thumbnailHeight, setThumbnailHeight] = useState('210px');

  const classes = classnames('icon', `icon-${icon}`, {
    'icon-inline': inline,
    [`icon-${size}`]: !!size,
  });

  if (isThumbnail) {
    const thumbnailWidth = 319; // pixel width of the thumbnail image in the article sidebar
    const thumbnail = new Image();
    thumbnail.src = iconUrl;
    thumbnail.onload = () => {
      thumbnailAspectRatio = thumbnail.width / thumbnail.height;
      setThumbnailHeight(`${Math.round(thumbnailWidth / thumbnailAspectRatio)}px`);
    };
  }

  const spanProps = isThumbnail ? {style: {backgroundImage: `url(${iconUrl})`, height: thumbnailHeight}} : {};

  return (
    <button {...rest}>
      {children && <span className="button-text">{children}</span>}
      <span className={classes} {...spanProps} />
    </button>
  );
}

IconButton.defaultProps = {
  children: null,
  disabled: false,
  inline: true,
  size: '',
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  iconUrl: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.string,
};
