/**
 * Formats a number with thousands separators
 *
 * @param {number} number to format
 * @return {string} number with thousands
 */

export function formatNumber(number) {
  if (!number && number !== 0) return null;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
