/*
 * word list maintained by BR content team
 * @see https://docs.google.com/spreadsheets/d/1lf6hxaqxP5svm71pGXbiexupRccnQLe5v5MWlRmtrY8/edit#gid=0
 */
export const TEXT_UPPERCASE = [
  'HHH',
  'ROH',
  'QB',
  'RB',
  'WR',
  'TE',
  'LB',
  'ABC',
  'ACC',
  'ACL',
  'AD',
  'AEW',
  'AL',
  'ALCS',
  'ALDS',
  'A&M',
  'AMA',
  'AP',
  'ARI',
  'ASG',
  'ATL',
  'ATP',
  'ATS',
  'AT&T',
  'BAL',
  'BC',
  'BIG3',
  'BKN',
  'BOS',
  'B/R',
  'BUF',
  'BYU',
  'CB',
  'CBA',
  'CBB',
  'CBJ',
  'CBS',
  'CF',
  'CFB',
  'CFP',
  'CHA',
  'CHC',
  'CHI',
  'CIN',
  'CLE',
  'COL',
  'CTE',
  'COVID',
  'CWS',
  'DAL',
  'DB',
  'DC',
  'DEN',
  'DET',
  'DH',
  'DL',
  'DNP',
  'DUI',
  'EDM',
  'ELEAGUE',
  'EPL',
  'ESPN',
  'FBS',
  'FC',
  'FCS',
  'FG',
  'FIFA',
  'FT',
  'GB',
  'GK',
  'GM',
  'GOAT',
  'GP',
  'GSW',
  'GW',
  'HOF',
  'HBCU',
  'HBO',
  'HC',
  'HOU',
  'HR',
  'IC',
  'IG',
  'II ',
  'III',
  'IND',
  'IR',
  'INT',
  'IU',
  'JAX',
  'JUCO',
  'KAT',
  'KC',
  'KO',
  'LAA',
  'LAC',
  'LAD',
  'LAL',
  'LBJ',
  'LF',
  'LGBT',
  'LHP',
  'LW',
  'LS',
  'LSU',
  'LPGA',
  'LV',
  'MCL',
  'MEM',
  'MIA',
  'MIL',
  'MIN',
  'MLB',
  'MLS',
  'MMA',
  'MNF',
  'MPH',
  'MSG',
  'MSU',
  'MVP',
  'NASCAR',
  'NBA',
  'NBC',
  'NBPA',
  'NCAA',
  'ND',
  'NE',
  'NFL',
  'NFLPA',
  'NHL',
  'NHLPA',
  'NIL',
  'NIT',
  'NL',
  'NLCS',
  'NLDS',
  'NWSL',
  'NXT',
  'NYG',
  'NYI',
  'NYJ',
  'NYK',
  'NYM',
  'NYR',
  'NYY',
  'OAK',
  'OBJ',
  'OC',
  'OKC',
  'OL',
  'OLY',
  'ORL',
  'OSU',
  'OT',
  'OTT',
  'O/U',
  'PCL',
  'PED',
  'PF',
  'PG',
  'PGA',
  'PHI',
  'PHX',
  'PIT',
  'PK',
  'PPR',
  'PPV',
  'PUP',
  'Q&A',
  'RBI',
  'RF',
  'RHP',
  'RPO',
  'ROY',
  'RP',
  'RW',
  'SAC',
  'SAS',
  'SCAR',
  'SD',
  'SEA',
  'SEC',
  'SF',
  'SG',
  'SJ',
  'SMU',
  'SNF',
  'SP',
  'SU',
  'SS',
  'STL',
  'TAMU',
  'TB',
  'TCU',
  'TD',
  'TEX',
  'TLC',
  'TNF',
  'TNT',
  'TOR',
  'TPC',
  'TUF',
  'TV',
  'UCF',
  'UCLA',
  'UDFA',
  'UEFA',
  'UFA',
  'UFC',
  'UGA',
  'UK',
  'UNC',
  'UNLV',
  'USA',
  'USC',
  'USMNT',
  'USWNT',
  'UT',
  'UVA',
  'VAR',
  'VCU',
  'VGK',
  'WFT',
  'WNBA',
  'WTA',
  'WVU',
  'WWE',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'XIV',
  'XV',
  'XVI',
  'XVII',
  'XVIII',
  'XIX',
  'XX',
  'XXI',
  'XXII',
  'XXIII',
  'XXIV',
  'XXV',
  'XXVI',
  'XXVII',
  'XXVIII',
  'XXIX',
  'XXX',
  'XXXI',
  'XXXII',
  'XXXIII',
  'XXXIV',
  'XXXV',
  'XXXVI',
  'XXXVII',
  'XXXVIII',
  'XXXIX',
  'XL',
  'XLI',
  'XLII',
  'XLIII',
  'XLIV',
  'XLV',
  'XLVI',
  'XLVII',
  'XLVIII',
  'XLIX',
  'L',
  'LI',
  'LII',
  'LIII',
  'LIV',
  'LV',
  'LVI',
  'LVII',
  'LVIII',
  'LIX',
  'LX',
  'LXI',
  'LXII',
  'LXIII',
  'LXIV',
  'LXV',
  'LXVI',
  'LXVII',
  'LXVIII',
  'LXIX',
  'LXX',
  'LXXI',
  'LXXII',
  'LXXIII',
  'LXXIV',
  'LXXV',
  'LXXVI',
  'LXXVII',
  'LXXVIII',
  'LXXIX',
  'LXXX',
];
