import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {updateArticle} from '../../../actions/article';
import {getUserByEmail, getUsersProfiles} from '../../../apis/auth/gatekeeper-api';
import {scopes} from '../../../constants';
import {PROMPT_AUTHOR_SWAP, PROMPT_AUTHOR_SWAP_ERROR_EMAIL} from '../../../constants/prompt';
import {isPermitted} from '../../../helpers/authHelpers';
import {isEmailValid} from '../../../helpers/emailValid';
import {prompt} from '../../../helpers/promptHelper';
import {getUserFromProfile} from '../../../helpers/userHelpers';
import {getIsReadOnly} from '../../../selectors/article';

const AuthorSwap = () => {
  const isReadOnly = useSelector(getIsReadOnly);
  const [newAuthor, setNewAuthor] = useState(null);
  const [newAuthorGatekeeperId, setNewAuthorGatekeeperId] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (newAuthor) dispatch(updateArticle({author: newAuthor, authorGatekeeperId: newAuthorGatekeeperId}));
  }, [newAuthor]);

  useEffect(() => {
    if (newEmail) {
      getUserByEmail(newEmail).then(({users}) => {
        if (!users.length) {
          prompt(PROMPT_AUTHOR_SWAP_ERROR_EMAIL)
            .withTexts('Author not found', 'No B/R user with that email address was found.', 'OK', null)
            .show(() => {});
        } else {
          const user = users[0];
          const id = user.id;
          setNewAuthorGatekeeperId(id);

          getUsersProfiles([id]).then(({users}) => {
            setNewAuthor(getUserFromProfile(users[0]));
          });
        }
      });
    }
  }, [newEmail]);

  const handleClick = () => {
    prompt(PROMPT_AUTHOR_SWAP)
      .withUserInput((input) => {
        const email = input.trim();
        const valid = isEmailValid(email);
        return !valid ? 'Invalid email address' : null;
      }, '')
      .withTexts('Enter the email address of the new author', null, 'Change')
      .show((email) => {
        if (email && isEmailValid(email)) setNewEmail(email);
      });
  };

  if (isReadOnly) return null;
  if (!isPermitted(scopes.REVIEW_AND_PUBLISH)) return null;
  return (
    <button className="article-action--author-swap button--basic" onClick={handleClick}>
      <span className="sr-only">Change Author</span>
    </button>
  );
};

export default AuthorSwap;
