import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {mediaImage} from '../../../utils/types';

function ImageButton({image, picked, onClick}) {
  const ref = useRef(null);

  const classes = classnames('media', 'thumbnail', {
    'is-active': picked && picked.url === image.urlComp,
  });

  // we need to get the portrait/landscape orientation of the image since the APIs don't return any relevant info
  const imageTemp = new Image();
  imageTemp.src = image.urlComp;
  imageTemp.onload = () => {
    const orientationClass = imageTemp.width >= imageTemp.height ? 'is-landscape' : 'is-portrait';
    if (ref.current) ref.current.classList.add(orientationClass);
  };

  return (
    <button
      aria-label={`image-${image.imageId}`}
      className={classes}
      style={{backgroundImage: `url(${image.urlComp})`}}
      onClick={() => onClick(image)}
      ref={ref}
    />
  );
}
export default function ImageFinder({images, picked, onImagePick}) {
  if (!images.length) return null;

  return (
    <div className="media-container media-images">
      {images.map((image) => {
        return <ImageButton key={image.imageId} image={image} picked={picked} onClick={onImagePick} />;
      })}
    </div>
  );
}

ImageFinder.propTypes = {
  picked: mediaImage,
  images: PropTypes.arrayOf(mediaImage).isRequired,
  onImagePick: PropTypes.func.isRequired,
};
