import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {clearAction as clearActionArticle} from '../../actions/article';
import {clearAction as clearActionDashboard} from '../../actions/dashboard';
import {clearAction as clearActionSearch} from '../../actions/search';

export const TOAST_EXPIRES = 10000; // msec until toast notification expires / disappears

const Toast = ({toast}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!toast) return () => null;
    setIsSuccess(true);
    if (toast.error) setIsSuccess(false);
    setIsExpired(false);
    setMessage(toast.message);
    const timer = setTimeout(() => setIsExpired(true), TOAST_EXPIRES);
    return () => clearTimeout(timer);
  }, [toast]);

  useEffect(() => {
    // when the page changes, clear any existing action from redux state
    return () => {
      dispatch(clearActionArticle());
      dispatch(clearActionDashboard());
      dispatch(clearActionSearch());
    };
  }, [location]);

  if (!toast) return null;
  if (!toast.message) return null;

  const classes = classnames('atom', 'toast', {'is-success': isSuccess && message.length}, {'is-error': !isSuccess}, {'is-expired': isExpired});

  return (
    <div className={classes}>
      <em>{message}</em>
    </div>
  );
};

Toast.propTypes = {
  toast: PropTypes.shape({
    message: PropTypes.string,
    error: PropTypes.bool,
  }),
};

export default Toast;
