import {isEmpty} from 'lodash';

import {getWordCount} from '../helpers/wordCount';
import {
  ARTICLE_CONTENT_MINLENGTH,
  FEATURED_MEDIA_CONTENT_TYPES,
  SLIDE_TITLE_MINLENGTH,
  VALIDATE_FIELD_BODY,
  VALIDATE_FIELD_SOCIAL_DESCRIPTION,
  VALIDATE_FIELD_SOCIAL_TITLE,
  VALIDATE_FIELD_TAGS,
  VALIDATE_FIELD_THUMBNAIL,
  VALIDATE_FIELD_TITLE,
  VALIDATE_SLIDES_BODY_INDEX,
} from '../constants/article';
import {VALIDATE_FIELD_MAIN, VALIDATE_FIELD_MEDIA} from '../constants/slideshow';
import {
  errorMessageContentMinChars,
  errorMessageFieldMinChars,
  errorMessageFieldMissing,
  errorMessageSocialDescription,
  errorMessageTags,
  isContentValid,
  isSlideTitleValid,
  isSocialDescriptionValid,
  isTagsValid,
  isThumbnailValid,
  isTitleValid,
} from './articleValidate';

/**
 * Verifies the slide contains valid media block
 * @param {object} elements article slide block object
 */
export function isSlideMediaValid({elements}) {
  if (!elements.length) return false;
  const firstBlock = elements[0];

  // featured media block content should not be empty
  if (!firstBlock.data || isEmpty(firstBlock.data)) return false;

  // check the featured media is a valid type
  if (!FEATURED_MEDIA_CONTENT_TYPES.includes(firstBlock.type)) return false;

  return true;
}

/**
 * Verifies that slide contains valid body content
 * @param {object} err
 */
export function isSlidesBodyContentValid(err) {
  return Object.keys(err).length === 1 && !err[VALIDATE_SLIDES_BODY_INDEX].includes(true);
}

/**
 * Verifies that slide content is valid
 * @param {object} slide slideshow slide content object
 * @returns {object|null} validation errors
 */
export function errorMessage(slide) {
  const {elements, slideNumber, title} = slide;
  const err = {};

  if (slideNumber === 0 && !isTitleValid(title)) err[VALIDATE_FIELD_TITLE] = errorMessageFieldMinChars('Title');
  if (slideNumber !== 0 && !isSlideTitleValid(title)) err[VALIDATE_FIELD_TITLE] = errorMessageFieldMinChars('Title');

  if (!isSlideMediaValid(slide)) err[VALIDATE_FIELD_MEDIA] = 'Slide featured media block is required';

  const words = getWordCount({blocks: elements});
  if (!isContentValid(words)) err[VALIDATE_FIELD_BODY] = errorMessageContentMinChars();

  return isEmpty(err) ? null : err;
}

/**
 * Verifies slideshow article is valid
 * Warning: articles published with invalid social metadata will halt our sitemap feeds from receiving new content
 *
 * TODO: why are we passing tags as prop? can we not use article.tagList.split(,)?
 * TODO: articleValidate also checks wordcount
 *
 * @param {object} slideshow entire article slideshow object
 * @param {array} tags list of tags
 * @returns {(false|object)} false when no error, or error obj
 */
export default async function validateSlideshow(slideshow, tags) {
  const {elements, image, socialTitle, teaser} = slideshow;
  const err = {};

  if (!isThumbnailValid(image)) err[VALIDATE_FIELD_THUMBNAIL] = errorMessageFieldMissing('Article thumbnail');
  if (!isTitleValid(socialTitle)) err[VALIDATE_FIELD_SOCIAL_TITLE] = errorMessageFieldMinChars('Social title');
  if (!isSocialDescriptionValid(teaser)) err[VALIDATE_FIELD_SOCIAL_DESCRIPTION] = errorMessageSocialDescription(teaser);
  if (!(await isTagsValid(tags))) err[VALIDATE_FIELD_TAGS] = errorMessageTags();

  // show one common error message if any slide has an error
  const anySlideHasError = elements.find((slide) => errorMessage(slide));
  if (anySlideHasError)
    err[
      VALIDATE_FIELD_MAIN
    ] = `Each slide must have a title that's at least ${SLIDE_TITLE_MINLENGTH} characters, featured media and content must be at least ${ARTICLE_CONTENT_MINLENGTH} words. Slides with missing elements are highlighted in red and marked with an asterisk, and must be updated or deleted.`; // eslint-disable-line max-len

  // check each slide to make sure body content valid
  err[VALIDATE_SLIDES_BODY_INDEX] = elements.map((slide) => !isContentValid(getWordCount({blocks: slide.elements})));

  if (isEmpty(err)) return null;
  if (isSlidesBodyContentValid(err)) return null;
  return err;
}

/**
 * Returns the list of invalid slide indexes
 * @param {object} slideshow article slideshow object model
 * @returns {array} list of slides with error
 */
export function getInvalidSlidesIndexList(slideshow) {
  return slideshow.elements.reduce((invalidSlides, slide, index) => (errorMessage(slide) ? [...invalidSlides, index] : invalidSlides), []);
}
