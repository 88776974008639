import {format, isAfter, parseISO, subMonths} from 'date-fns';
import * as types from '../constants/action-types';
import {DATE_FORMAT_DAY} from '../constants/date';
import {SEARCH_LIMIT_DEFAULT, SORT_KEY_PUBLISH_DATE, SORT_DIR_ASC, SORT_DIR_DSC} from '../constants/search';

const setDateStart = (dateRef = new Date()) => {
  const date = typeof dateRef === 'string' ? new Date(dateRef) : dateRef;
  return format(subMonths(date, 3), DATE_FORMAT_DAY); // set start date 3 months earlier
};

export const initialState = {
  action: null,
  articles: [],
  count: 0,
  cursorCurrent: null,
  cursorLast: null,
  dateEnd: format(new Date(), DATE_FORMAT_DAY),
  dateStart: setDateStart(),
  error: false,
  fetching: false,
  hasMore: false,
  inputQuery: '',
  inputType: 'Title',
  limit: SEARCH_LIMIT_DEFAULT,
  page: 1,
  sortDirection: SORT_DIR_DSC,
  sortKey: SORT_KEY_PUBLISH_DATE,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_ACTION: {
      const {complete, error = false, status} = action.payload;
      return {
        ...state,
        action: {
          status,
          complete,
          error,
        },
      };
    }

    case types.SEARCH_ACTION_CLEAR: {
      return {
        ...state,
        action: null,
      };
    }

    case types.SEARCH_CLEAR: {
      return {
        ...initialState,
      };
    }

    case types.SEARCH_CURSOR_LAST: {
      return {
        ...state,
        cursorLast: action.payload,
      };
    }

    case types.SEARCH_INPUT_QUERY: {
      return {...state, inputQuery: action.payload};
    }

    case types.SEARCH_INPUT_TYPE: {
      return {...state, inputType: action.payload};
    }

    case types.SEARCH_RESULTS: {
      const {articles, count, cursor, page = 1} = action.payload;
      const {limit} = initialState;

      return {
        ...state,
        articles,
        count,
        cursorCurrent: cursor,
        hasMore: count > limit * page,
        page,
      };
    }

    case types.SEARCH_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: state.sortDirection === SORT_DIR_DSC ? SORT_DIR_ASC : SORT_DIR_DSC,
      };
    }

    case types.SEARCH_SORT_KEY: {
      return {
        ...state,
        sortKey: action.payload,
        sortDirection: initialState.sortDirection,
      };
    }

    case types.SEARCH_DATE_END: {
      const dateEnd = action.payload;
      // if new end date is before current start date, reset start date to default before that
      if (isAfter(parseISO(state.dateStart), parseISO(dateEnd))) {
        return {
          ...state,
          dateEnd,
          dateStart: setDateStart(dateEnd),
        };
      }

      return {...state, dateEnd};
    }

    case types.SEARCH_DATE_START: {
      return {...state, dateStart: action.payload};
    }

    case types.SEARCH_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case types.SEARCH_FETCHING: {
      return {
        ...state,
        fetching: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default search;
