import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {addFeedback} from '../../../actions/article';
import {getUsersProfiles} from '../../../apis/auth/gatekeeper-api';
import {CTT_USER_NAME, CTT_USER} from '../../../constants/user';
import {FEEDBACK_MAXLENGTH, FEEDBACK_MINLENGTH, FEEDBACK_TRUNCATION_LIMIT} from '../../../constants/article';
import {getStorageData} from '../../../helpers/authHelpers';
import {formatDateWithTimezone} from '../../../helpers/dateHelpers';
import {isCurrentUserTheAuthor} from '../../../helpers/softlockHelpers';
import {getArticleData, getAuthorId, getFeedbacks} from '../../../selectors/article';
import {truncateText} from '../../../helpers/textHelpers';
import classNames from 'classnames';
import EditorField from '../editorField';

const getEditorsMap = (profiles) => {
  return profiles.reduce((acc, profile) => {
    return {...acc, [profile.id]: profile};
  }, {});
};

/**
 * Article Feedbacks
 * @see examples HTTP {@link https://contenttools.8bleacherreport.com/articles/standard/10000049 10000049}
 */
const Feedbacks = ({withAddFeedback}) => {
  const dispatch = useDispatch();

  const article = useSelector(getArticleData);
  const authorId = useSelector(getAuthorId);
  const feedbacks = useSelector(getFeedbacks);
  const [editors, setEditors] = useState(null);
  const [message, setMessage] = useState('');
  const [notify, setNotify] = useState(false);
  const [expandedFeedbacks, setExpandedFeedbacks] = useState({});

  const whoNotifyIds = [...new Set([authorId, ...feedbacks.map(({editorGatekeeperId}) => editorGatekeeperId)])];

  useEffect(() => {
    if (feedbacks.length) {
      getUsersProfiles(whoNotifyIds).then(({users}) => {
        setEditors(getEditorsMap(users));
      });
    }
  }, []);

  const handleNotify = () => setNotify(!notify);

  const getNotifyList = () => notify && whoNotifyIds;

  const handlePostClick = () => {
    const articleId = article.id || article.breportId;
    if (!articleId || !message.length) return;
    dispatch(addFeedback(articleId, message, getNotifyList()));
    // Clean up
    setMessage('');
    setNotify(false);
  };

  const isButtonEnabled = () => {
    return message.length >= FEEDBACK_MINLENGTH;
  };

  const getEditorName = (id) => {
    const currentUserName = getStorageData(CTT_USER_NAME);
    if (id === getStorageData(CTT_USER) && currentUserName) {
      // Get current user first and last name
      // (we need to cover the case when current user doesn't write any feedback for an article)
      return currentUserName;
    }
    if (editors && editors[id]) {
      // Get editor user name from the gatekeeper response
      return `${editors[id].firstName} ${editors[id].lastName}`;
    }
    // Cannot determine the feedback author
    return 'Unknown Editor';
  };

  const getNotifyControl = () => {
    // Do not render checkbox for author when no editor feedbacks
    if (isCurrentUserTheAuthor(article) && whoNotifyIds.length === 1 && whoNotifyIds[0] === authorId) return null;

    return (
      <div className="control control-notify">
        <label>
          <input
            type="checkbox"
            checked={notify}
            onChange={() => {
              handleNotify();
            }}
          />
          Notify Commenters in Thread
        </label>
      </div>
    );
  };

  const getPostFeedbackForm = () => {
    return (
      <div className="group feedbacks-add">
        <EditorField
          type="text"
          name="feedbacks"
          autoFocus={false}
          rows={4}
          placeholder="Add new feedback…"
          maxLength={FEEDBACK_MAXLENGTH}
          value={message}
          onChange={({target: {value}}) => setMessage(value)}
          doNormalize={false}
          doNormalizeBlur={true}
        />
        {getNotifyControl()}
        <button disabled={!isButtonEnabled()} onClick={handlePostClick}>
          Post
        </button>
      </div>
    );
  };

  const toggleFeedback = (id) => {
    setExpandedFeedbacks((prev) => {
      const expanded = prev[id];
      return {...prev, [id]: !expanded};
    });
  };

  const getArticleFeedback = (feedback) => {
    const isExpanded = expandedFeedbacks[feedback.id];
    const toggleIconClass = classNames({expanded: isExpanded, collapsed: !isExpanded});

    return (
      <div key={feedback.id} className="feedback">
        <div className="author">{getEditorName(feedback.editorGatekeeperId)}</div>
        <div className="date">{formatDateWithTimezone(feedback.insertedAt)}</div>
        <p className="description">{isExpanded ? feedback.description : truncateText(feedback.description, FEEDBACK_TRUNCATION_LIMIT)} </p>
        {feedback.description.length > FEEDBACK_TRUNCATION_LIMIT && (
          <a onClick={() => toggleFeedback(feedback.id)}>
            {isExpanded ? 'See less' : 'See more'}
            <span className={toggleIconClass} />
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="feedbacks">
      <h3 className="sr-only">Feedbacks</h3>
      {withAddFeedback && getPostFeedbackForm()}
      <div className="group feedback-list">{feedbacks.map(getArticleFeedback)}</div>
    </div>
  );
};

Feedbacks.defaultProps = {
  withAddFeedback: true,
};

Feedbacks.propTypes = {
  withAddFeedback: PropTypes.bool,
};

export default Feedbacks;
