import React, {forwardRef, useEffect, useState} from 'react';

import Prompt from './promptComponent';
import FocusTrapWrapper from '../focusTrapWrapper';

import {EVENT_PROMPT_RAISE} from '../../../constants/events';
import ToolEventObserver from '../../../utils/editorjs/tools/toolEventObserver';
import {isPromptMuted} from '../../../helpers/promptHelper';

const PromptProvider = () => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    const sub = ToolEventObserver.subscribe(EVENT_PROMPT_RAISE, setOptions);
    return () => ToolEventObserver.unsubscribe(EVENT_PROMPT_RAISE, sub);
  }, []);

  const onPromptConfirmed = (...args) => {
    options.onConfirm(...args);
    setOptions(null);
  };

  const onPromptCanceled = () => {
    options.onCancel();
    setOptions(null);
  };

  // Skip rendering until prompt is requested
  if (!options) return null;

  // Exclude cancel prompt callback for overwrite
  const {onConfirm, onCancel: _onCancel, ...rest} = options;

  if (isPromptMuted(rest.type)) {
    // Trigger confirm directly for muted prompt
    onConfirm();
    return null;
  }

  const FocusTrapPrompt = forwardRef((_, ref) => {
    return <Prompt focusTrapRef={ref} onConfirmed={onPromptConfirmed} onCanceled={onPromptCanceled} {...rest} />;
  });

  // TODO: This focus trap currently has an issue with maintaining a lock on the focus when a prompt is opened
  // from the editorjs popover menu by clicking the Tweet, Iframe, or Statmilk options because the popover menu
  // remains open and steals the focus while tabbing. This issue will be resolved when CTT-950 is completed.
  return (
    <FocusTrapWrapper>
      <FocusTrapPrompt />
    </FocusTrapWrapper>
  );
};

export default PromptProvider;
