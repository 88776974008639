import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format, subMonths} from 'date-fns';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import {setDateEnd, setDateStart} from '../../../actions/search';
import {DATE_FORMAT_DAY} from '../../../constants/date';
import {formatStringToDate} from '../../../helpers/dateHelpers';
import {selectDateEnd, selectDateStart} from '../../../selectors/search';

const SearchFilterDates = () => {
  const dispatch = useDispatch();
  const dateEnd = useSelector(selectDateEnd);
  const dateStart = useSelector(selectDateStart);
  const dateFormat = DATE_FORMAT_DAY;

  const handleChangeDateStart = (dateSelected) => {
    const date = format(dateSelected, dateFormat);
    dispatch(setDateStart(date));
  };

  const handleChangeDateEnd = (dateSelected) => {
    const date = format(dateSelected, dateFormat);
    dispatch(setDateEnd(date));
  };

  return (
    <div className="atom filter-dates">
      <div className="date date-start">
        <span className="label">Start Date:</span>
        <DatePicker
          calendarIcon={null}
          clearIcon={null}
          dayPlaceholder="dd"
          format={dateFormat}
          locale="en-US"
          maxDate={formatStringToDate(dateEnd)}
          monthPlaceholder="mm"
          onChange={(selected) => handleChangeDateStart(selected)}
          onFocus={(e) => e.target.blur()}
          value={formatStringToDate(dateStart) || subMonths(new Date(), 3)}
          yearPlaceholder="yyyy"
        />
      </div>
      <div className="date date-end">
        <span className="label">End Date:</span>
        <DatePicker
          calendarIcon={null}
          clearIcon={null}
          dayPlaceholder="dd"
          format={dateFormat}
          locale="en-US"
          maxDate={new Date()}
          minDate={formatStringToDate(dateStart)}
          monthPlaceholder="mm"
          onChange={(selected) => handleChangeDateEnd(selected)}
          onFocus={(e) => e.target.blur()}
          value={formatStringToDate(dateEnd) || new Date()}
          yearPlaceholder="yyyy"
        />
      </div>
    </div>
  );
};

export default SearchFilterDates;
