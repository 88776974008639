import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {scheduleArticle} from '../../../actions/article';
import {STATUS_READY_FOR_PUBLISH} from '../../../constants/article';
import {DATE_FORMAT_PICKER} from '../../../constants/date';
import {getCorrectedISOString, getSchedulePublishMinimumDate, validateSelectedScheduledDate} from '../../../helpers/dateHelpers';
import {canSchedulePublish} from '../../../helpers/permissions';
import {getArticleData, getIsChangedArticle, getIsNew} from '../../../selectors/article';

const ScheduleButton = ({redirectToDashboard}) => {
  const dispatch = useDispatch();
  const article = useSelector(getArticleData);
  const isArticleChanged = useSelector(getIsChangedArticle);
  const isArticleNew = useSelector(getIsNew);
  const {authorGatekeeperId, id, isBreakingNews, publishedAt, scheduledAt, status} = article;
  const scheduleMinimumDate = getSchedulePublishMinimumDate();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [scheduled, setScheduled] = useState(scheduleMinimumDate);
  const classes = classnames('article-actions--schedule', {'is-active': isScheduleOpen});

  useEffect(() => {
    const selectedDate = setTimeout(() => {
      setScheduled(validateSelectedScheduledDate(scheduled));
    }, 600);

    return () => clearTimeout(selectedDate);
  }, [scheduled]);

  const handleClose = () => {
    setIsScheduleOpen(false);
  };

  const handlePublishScheduled = async () => {
    await dispatch(scheduleArticle(id, getCorrectedISOString(scheduled)));
    handleClose();
    redirectToDashboard(STATUS_READY_FOR_PUBLISH);
  };

  // don't show schedule button on new articles or if article already scheduled or published
  if (isArticleNew || publishedAt || scheduledAt) return null;

  // don't show schedule button if user not permitted
  if (!canSchedulePublish(authorGatekeeperId, status, isBreakingNews)) return null;

  return (
    <>
      <div className={classes}>
        <div className="inner">
          {isScheduleOpen ? (
            <>
              <div className="controls">
                <DateTimePicker
                  value={scheduled}
                  clearIcon={null}
                  disableClock={true}
                  locale="en-US"
                  onChange={(scheduled) => setScheduled(scheduled)}
                  format={DATE_FORMAT_PICKER}
                />
                <span className="note">Eastern Time (ET)</span>
              </div>
              <button onClick={handlePublishScheduled} className="action--date-set">
                Schedule Publish Date
              </button>
              <button onClick={handleClose} className="action--cancel">
                <span className="sr-only">Cancel</span>
              </button>
            </>
          ) : (
            <button className="article-action--schedule" onClick={() => setIsScheduleOpen(true)} disabled={isArticleChanged}>
              Schedule
            </button>
          )}
        </div>
      </div>
    </>
  );
};

ScheduleButton.defaultProps = {
  redirectToDashboard: () => {},
};

ScheduleButton.propTypes = {
  redirectToDashboard: PropTypes.func,
};

export default ScheduleButton;
