import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {selectShowChanges, selectShowCompareView} from '../../../selectors/versions';
import Differentiator, {DIFF_TYPE_ARRAY, DIFF_TYPE_DEFAULT, DIFF_TYPE_IMAGES, DIFF_TYPE_SENTENCES} from '../differentiator';
import {getUsersProfiles} from '../../../apis/auth/gatekeeper-api';
import {getTagsByIds} from '../../../apis/tags/tagsAPI';
import {getUserFullName} from '../../../helpers/userHelpers';
import {STATUS_LABELS} from '../../../constants/article';
import {formatDateWithTimezone} from '../../../helpers/dateHelpers';
import ExpandablePanel from '../expandablePanel';

export const FIELDS = {
  authorGatekeeperId: 'Author',
  hidePublishedAt: 'Hide Published At',
  image: 'Thumbnail',
  isBreakingNews: 'Breaking News Team',
  permalink: 'Permalink',
  primaryEditorGatekeeperId: 'QC Editor',
  publishedAt: 'Published At',
  scheduledAt: 'Scheduled At',
  socialTitle: 'Social Title',
  status: 'Status',
  tagList: 'Tags',
  teaser: 'Teaser',
};
export const SEPARATOR = ', ';

const SidebarMetadata = ({added, removed}) => {
  if (!added) return null;
  if (Object.keys(added).length === 0) return null;
  const addedIncludesFieldChanges = Object.keys(FIELDS).some((key) => Object.keys(added).includes(key));
  if (!addedIncludesFieldChanges) return null; // if added doesn't contain any of the fields we display

  const isComparison = useSelector(selectShowCompareView);
  const filteredChanges = Object.entries(added).filter(([key]) => Object.keys(FIELDS).includes(key));
  const filteredChangesLabels = filteredChanges.map(([key]) => `${FIELDS[key]}`).join(', ');
  const showChanges = useSelector(selectShowChanges);
  const stringify = (val) => (typeof val !== 'string' ? JSON.stringify(val) : val);

  const [tagsAdded, setTagsAdded] = useState([]);
  const [tagsRemoved, setTagsRemoved] = useState([]);
  const [userProfiles, setUserProfiles] = useState();
  useEffect(() => {
    // check if the metadata changes include any tags, these are passed as IDs only
    // so convert to human-friendly display names
    if (added['tagList']) {
      const tagIds = added['tagList'].split(',');
      getTagsByIds(tagIds).then((tags) => setTagsAdded(tags.map((tag) => tag.name.trim())));
    }
    if (removed['tagList']) {
      const tagIds = removed['tagList'].split(',');
      getTagsByIds(tagIds).then((tags) => setTagsRemoved(tags.map((tag) => tag.name.trim())));
    }
    if (added['authorGatekeeperId'] || removed['authorGatekeeperId']) {
      const userIds = [added['authorGatekeeperId'], removed['authorGatekeeperId']];
      getUsersProfiles(userIds).then(({users}) => setUserProfiles(users));
    }
    if (added['primaryEditorGatekeeperId'] || removed['primaryEditorGatekeeperId']) {
      const userIds = [added['primaryEditorGatekeeperId'], removed['primaryEditorGatekeeperId']];
      getUsersProfiles(userIds).then(({users}) => setUserProfiles(users));
    }
  }, []);

  const outputValue = (key) => {
    let diffType = DIFF_TYPE_DEFAULT;

    // Don't attempt to stringify null or undefined because when you do, null converts to the string 'null' (which is
    // partially why we've previously seen 'null' in the Scheduled By field) and undefined simply remains as undefined.
    let valueAdded = '';
    let valueRemoved = '';
    if (added[key]) valueAdded = Array.isArray(added[key]) ? added[key].join(SEPARATOR) : stringify(added[key]);
    if (removed[key]) valueRemoved = Array.isArray(removed[key]) ? removed[key].join(SEPARATOR) : stringify(removed[key]);

    switch (key) {
      case 'authorGatekeeperId':
      case 'primaryEditorGatekeeperId': {
        diffType = DIFF_TYPE_SENTENCES;
        // replace gatekeeper id with human name
        valueAdded = added[key] && userProfiles ? getUserFullName(userProfiles.find((profile) => profile.id === added[key])) : '';
        valueRemoved = removed[key] && userProfiles ? getUserFullName(userProfiles.find((profile) => profile.id === removed[key])) : '';
        break;
      }
      case 'image': {
        diffType = DIFF_TYPE_IMAGES;
        if (!showChanges) return <img src={valueAdded} />;
        break;
      }
      case 'publishedAt': {
        if (valueAdded) valueAdded = formatDateWithTimezone(valueAdded);
        valueRemoved = valueRemoved ? formatDateWithTimezone(valueRemoved) : 'Unpublished';
        break;
      }
      case 'scheduledAt': {
        diffType = DIFF_TYPE_SENTENCES;
        valueAdded = valueAdded ? formatDateWithTimezone(valueAdded) : 'Unscheduled';
        if (valueRemoved) valueRemoved = formatDateWithTimezone(valueRemoved);
        if (!valueRemoved) valueRemoved = valueAdded === 'Unscheduled' ? '' : 'Unscheduled';
        break;
      }
      case 'status': {
        diffType = DIFF_TYPE_SENTENCES;
        // update status keys with human-friendly display names
        valueAdded = STATUS_LABELS[valueAdded] || '';
        valueRemoved = STATUS_LABELS[valueRemoved] || '';
        break;
      }
      case 'tagList': {
        diffType = DIFF_TYPE_ARRAY;
        // update tagList ids with human-friendly display names
        valueAdded = tagsAdded;
        valueRemoved = tagsRemoved;
        break;
      }
      default:
      // do nothing for now
    }

    if (!showChanges) return Array.isArray(valueAdded) ? valueAdded.join(SEPARATOR) : valueAdded;
    return <Differentiator source={valueRemoved} target={valueAdded} diffType={diffType} />;
  };

  const outputChanges = () => {
    return (
      <ul>
        {filteredChanges.map(([key]) => (
          <li className={key} key={key}>
            <strong className="label">
              {FIELDS[key]}
              <span className="label-colon">:</span>
            </strong>
            <div className="value">{outputValue(key)}</div>
          </li>
        ))}
      </ul>
    );
  };

  if (isComparison) {
    return (
      <>
        <ExpandablePanel
          ariaLabel="Metadata"
          classes="group metadata"
          expanded={false}
          title="Metadata"
          titleText={filteredChangesLabels}
          titleTextVisibleOpen={false}>
          {outputChanges()}
        </ExpandablePanel>
      </>
    );
  }

  return (
    <>
      <div className="atom group metadata">
        <h3>Metadata</h3>
        {outputChanges()}
      </div>
    </>
  );
};

SidebarMetadata.propTypes = {
  added: PropTypes.shape({}),
  removed: PropTypes.shape({}),
};

export default SidebarMetadata;
