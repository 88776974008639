import {addDiffClass} from '../difference';

import {
  EDITOR_SETTINGS_EDIT_ICON,
  EDITOR_SETTINGS_EDIT_LABEL,
  EDITOR_SETTINGS_REPLACE_ICON,
  EDITOR_SETTINGS_REPLACE_LABEL,
} from '../../../../constants/editor';
import {MEDIA_TYPE_IMAGE} from '../../../../constants/media';
import {EVENT_MEDIA_SEARCH, EVENT_MEDIA_EDIT} from '../../../../constants/events';

import ToolEventObserver from '../toolEventObserver';
import {getOriginalURL} from '../../../media';

require('./index.css');

/**
 * Displays block with image
 */
export default class Image {
  constructor({api, data, readOnly}) {
    this.api = api;
    this.data = data;
    this.readOnly = readOnly;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  render() {
    const {userCaption, credit, file} = this.getImageData();
    return this.renderImageBlock(file.url, credit, userCaption);
  }

  renderSettings() {
    return [
      {
        icon: EDITOR_SETTINGS_EDIT_ICON,
        label: EDITOR_SETTINGS_EDIT_LABEL,
        onActivate: () => {
          const {caption, credit, file, sourceCaption, sourceCredit, userCaption} = this.getImageData();
          const url = getOriginalURL(file.url); // use original image url instead of cropped version

          ToolEventObserver.trigger(EVENT_MEDIA_EDIT, {
            editorBlockIndex: this.api.blocks.getCurrentBlockIndex(),
            mediaType: MEDIA_TYPE_IMAGE,
            media: {caption, credit, file: {url}, sourceCaption, sourceCredit, userCaption},
          });
        },
      },
      {
        icon: EDITOR_SETTINGS_REPLACE_ICON,
        label: EDITOR_SETTINGS_REPLACE_LABEL,
        onActivate: () => {
          ToolEventObserver.trigger(EVENT_MEDIA_SEARCH, {
            editorBlockIndex: this.api.blocks.getCurrentBlockIndex(),
            mediaType: MEDIA_TYPE_IMAGE,
            shouldReplace: true,
          });
        },
      },
    ];
  }

  renderImageBlock(url, credit, userCaption) {
    const container = document.createElement('div');
    container.classList.add('media-container', 'media-container-image');

    addDiffClass(container, this.data);

    const fragment = document.createDocumentFragment();
    fragment.appendChild(this.renderImage(url));

    const imageAdditional = document.createElement('div');
    imageAdditional.classList.add('image-additional');

    if (userCaption) imageAdditional.appendChild(this.renderText(userCaption, 'image-caption'));
    if (credit) imageAdditional.appendChild(this.renderText(credit, 'image-credit'));

    if (credit || userCaption) fragment.appendChild(imageAdditional);
    container.appendChild(fragment);
    return container;
  }

  renderImage(url) {
    const img = document.createElement('img');
    img.src = decodeURIComponent(url.replace(/&amp;/g, '&'));
    return img;
  }

  renderText(text, className) {
    const caption = document.createElement('div');
    caption.innerHTML = text || '';
    caption.classList.add(className);
    return caption;
  }

  save() {
    if (!this.data.__original__ && !this.data.file) return {};
    return this.data;
  }

  getImageData() {
    const {caption, credit, file, userCaption, __original__} = this.data;
    return {
      caption,
      credit,
      file,
      sourceCaption: __original__.sourceCaption || __original__.caption,
      sourceCredit: __original__.sourceCredit || __original__.credit,
      userCaption,
    };
  }
}
