import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDropzone} from 'react-dropzone';

import {IMAGE_ACCEPTED_MIME_TYPES, IMAGE_MAX_SIZE_IN_BYTES, IMAGE_MAX_SIZE_IN_MB} from '../../../constants/media';
import {getFileRejectionErrorMessages} from '../../../helpers/imageUploadHelpers';

function Confirmation({onAgree}) {
  return (
    <div className="confirmation">
      <p>
        {' '}
        I certify that I have the right to upload this image and it does not violate the{' '}
        <a href="https://bleacherreport.com/pages/terms" rel="noopener noreferrer" target="_blank">
          Terms of Use
        </a>
        .
      </p>
      <p>
        DO NOT UPLOAD IMAGES THAT YOU DO NOT HAVE RIGHTS TO - IF YOU UPLOAD IMAGES WHICH VIOLATE BLEACHER REPORT’S TERMS OF USE, OR INFRINGE
        COPYRIGHT, PRIVACY, OR OTHER THIRD PARTY RIGHTS, YOU MAY BE FINANCIALLY RESPONSIBLE.
      </p>
      <label>
        <input type="checkbox" onClick={onAgree} />I agree
      </label>
    </div>
  );
}

function ImageUploader({onUpload, accepted}) {
  const {acceptedFiles, fileRejections, getRootProps, getInputProps, open} = useDropzone({
    accept: IMAGE_ACCEPTED_MIME_TYPES,
    multiple: false,
    maxSize: IMAGE_MAX_SIZE_IN_BYTES,
    noClick: true,
  });

  // Compose list of error messages
  const errorMessages = getFileRejectionErrorMessages(fileRejections);

  // File considered as upload only it was not rejected
  const isUploaded = !fileRejections.length && (accepted || !!acceptedFiles.length);

  const classes = classnames('atom', 'image-uploader', {
    uploaded: isUploaded,
    'has-error': fileRejections.length,
  });

  return (
    <div className={classes}>
      <div {...getRootProps({className: 'dropzone', disabled: isUploaded})}>
        <input {...getInputProps()} />
        <div className="instructions">
          <div className="instructions--text">Drag and Drop Image</div>
          <div className="instructions--or">or</div>
          <div className="instructions--button">
            <button onClick={open}>Upload Image</button>
          </div>
          <div className="instructions--note">Uploaded images must be {IMAGE_MAX_SIZE_IN_MB}MB or smaller</div>
        </div>
        <div className="upload-error">{errorMessages.join('. ')}</div>
      </div>
      {isUploaded && <Confirmation onAgree={() => onUpload(accepted || acceptedFiles[0])} />}
    </div>
  );
}

ImageUploader.defaultProps = {
  // Represents selected file (selected but not uploaded yet)
  accepted: null,
};

ImageUploader.propTypes = {
  onUpload: PropTypes.func.isRequired,
  accepted: PropTypes.object,
};

export default ImageUploader;
