/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {Tooltip} from 'react-tooltip';
import classnames from 'classnames';
import logger from '../../logger';
import reporter from '../../reporter';

import PageTitle from '../atoms/pageTitle';
import ToolsHeader from '../atoms/tools/header';

export const ToolsHelp = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [tip, setTip] = useState('Click to copy');

  useEffect(() => {
    const timerCopied = setTimeout(() => {
      setTip('Click to copy');
      setIsCopied(false);
    }, 10000);

    return () => clearTimeout(timerCopied);
  }, [isCopied]);

  const bookmarklet =
    'javascript:window.open(`https://contenttools.${document.location.host}/article/${document.location.pathname.match(/\\/articles\\/(\\d+)/)[1]}`)'; // eslint-disable-line no-script-url

  const copyToClipboard = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setTip('Copied!');
        setIsCopied(true);
      })
      .catch((error) => {
        setTip('Copy to clipboard failed');
        setIsCopied(true);
        logger.error('Copy to clipboard failed', error);
        reporter.inform(error);
      });
  };

  return (
    <section className="organism tools">
      <PageTitle title="Help" />
      <ToolsHeader />

      <div className="tools-layout">
        <h2>Help</h2>
        <div className="page-content">
          <div className="section">
            <h3>Using an Adblocker</h3>
            <p>Use of an adblocker within Content Tools can cause issues with search and playback of YouTube videos.</p>
            <p>
              It's recommended to disable the use of any adblocker browser extension for Content Tools, or safelist the contenttools domain:
              https://contenttools.bleacherreport.com
            </p>
          </div>

          <div className="section">
            <h3>Edit Bookmarklet</h3>
            <p>This browser bookmarklet allows you to jump to the Content Tools article edit page when viewing a published B/R article.</p>
            <p>Follow these steps to add the bookmarklet to your browser:</p>
            <ol>
              <li>
                Click to copy the entire line of code below:{' '}
                <code
                  key={`tooltip-link-${isCopied}`}
                  className={classnames('tooltip-link', 'copyable', {'is-copied': isCopied})}
                  id={`tooltip-link-${isCopied}`}
                  data-tooltip-float="true"
                  data-tooltip-content={tip}
                  onClick={() => copyToClipboard(bookmarklet)}>
                  {bookmarklet}
                </code>
                <Tooltip anchorSelect={`#tooltip-link-${isCopied}`} />
              </li>
              <li>
                Right-click on the bookmark bar to get the dropdown menu, or click on <strong>"Bookmarks"</strong> on your browser's menu bar.
              </li>
              <li>
                Click on <strong>"Bookmark Manager"</strong>
              </li>
              <li>
                Right-click on a blank spot on the page (not the sidebar) and click on <strong>"Add new bookmark"</strong>
              </li>
              <li>Type in a name for the bookmarklet.</li>
              <li>In the URL input, paste the javascript code that you copied from above (Step 1).</li>
              <li>
                Click <strong>"Save"</strong>.
              </li>
            </ol>
          </div>

          <div className="section">
            <h3>Frequently Used Hotkeys</h3>
            <p>
              Content Tools uses a text editor called Editor.js. Some functionality of Editor.js may behave differently than other text editors you’re
              used to, however the use of hotkeys for these functions should behave the same.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Function</th>
                  <th>macOS Shortcut</th>
                  <th>Windows Shortcut</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Undo</td>
                  <td>Cmd + Z</td>
                  <td>Ctrl + Z</td>
                </tr>
                <tr>
                  <td>Redo</td>
                  <td>Cmd + Y</td>
                  <td>Ctrl + Y</td>
                </tr>
                <tr>
                  <td>Cut</td>
                  <td>Cmd + X</td>
                  <td>Ctrl + X</td>
                </tr>
                <tr>
                  <td>Copy</td>
                  <td>Cmd + C</td>
                  <td>Ctrl + C</td>
                </tr>
                <tr>
                  <td>Paste with formatting</td>
                  <td>Cmd + V</td>
                  <td>Ctrl + V</td>
                </tr>
                <tr>
                  <td>Paste without formatting</td>
                  <td>Cmd + Shift + V</td>
                  <td>Ctrl + Shift + V</td>
                </tr>
                <tr>
                  <td>Select All</td>
                  <td>Cmd + A</td>
                  <td>Ctrl + A</td>
                </tr>
                <tr>
                  <td>Bold Text</td>
                  <td>Cmd + B</td>
                  <td>Ctrl + B</td>
                </tr>
                <tr>
                  <td>Italicize Text</td>
                  <td>Cmd + I</td>
                  <td>Ctrl + I</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ToolsHelp;
