import EditorParagraph from '@editorjs/paragraph';

import {TEXT_HTML_SUBELEMENTS} from '../../../constants/text';

export default class Paragraph extends EditorParagraph {
  static get sanitize() {
    // Allow only specific child elements within paragraphs:
    return {
      text: TEXT_HTML_SUBELEMENTS,
    };
  }
}
