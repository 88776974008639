import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {ARTICLE_ACTIVE_STATUSES} from '../../../constants/article';
import {isArticleReadOnlyForWriter} from '../../../helpers/articleHelpers';

const ArticleEditLink = ({isLocked, status, title, url}) => {
  const isArticleLockedForEdit = () => {
    // Show lock icon for writer if the article was published or currently in review
    if (isArticleReadOnlyForWriter(status)) return true;

    // Show lock icon if article is editing by someone else
    return isLocked;
  };

  return (
    <Link to={{pathname: url, isAppLink: true}}>
      {isArticleLockedForEdit() && <span className="icon-inline icon-lock" />}
      {title}
    </Link>
  );
};

ArticleEditLink.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  editedBy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  isLocked: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(ARTICLE_ACTIVE_STATUSES).isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ArticleEditLink;
