// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ce-linebreak {
  line-height: 1.6em;
  width: 100%;
  height: 1.5rem;
  text-align: center;
  box-shadow: 0 0 0.5rem lightgrey inset;
}

.ce-linebreak:before {
  display: block;
  content: '--- line break ---';
  font-size: 1rem;
  line-height: 1.5rem;
  color: lightgrey;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/linebreak/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".ce-linebreak {\n  line-height: 1.6em;\n  width: 100%;\n  height: 1.5rem;\n  text-align: center;\n  box-shadow: 0 0 0.5rem lightgrey inset;\n}\n\n.ce-linebreak:before {\n  display: block;\n  content: '--- line break ---';\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: lightgrey;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
