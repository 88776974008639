// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-container-statmilk {
  padding-top: 0;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/statmilk/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".media-container-statmilk {\n  padding-top: 0;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
