import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {formatNumber} from '../../helpers/number';

const Pagination = (props) => {
  const {count, cursor, hasMore, isLoading, limit, offset, onPageChange} = props;

  const currentPage = offset ? offset / limit + 1 : props.currentPage;
  const isNextDisabled = isLoading || !hasMore;
  const isPrevDisabled = isLoading || currentPage === 1;
  const showPagesCount = count > 0;

  const prev = () => {
    const params = {page: currentPage - 1};
    if (cursor) params.cursor = cursor.prev;
    else if (offset !== null) params.offset = offset - limit;
    onPageChange(params);
  };

  const next = () => {
    const params = {page: currentPage + 1};
    if (cursor) params.cursor = cursor.next;
    else if (offset !== null) params.offset = offset + limit;
    onPageChange(params);
  };

  const pages = () => {
    if (!count) return null;
    const firstNum = (currentPage - 1) * limit + 1;
    const lastNum = Math.min(currentPage * limit, count);
    return `${firstNum}-${lastNum} of ${formatNumber(count)}`;
  };

  return (
    <div className={classnames('atom', 'pagination', {'is-loading': isLoading})}>
      {showPagesCount && <div className="pagination-pages">{pages()}</div>}
      <button className="pagination-prev" title="Previous" onClick={prev} disabled={isPrevDisabled}>
        {'<'}
      </button>
      <button className="pagination-next" title="Next" onClick={next} disabled={isNextDisabled}>
        {'>'}
      </button>
    </div>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  hasMore: false,
  limit: 20,
  onPageChange: () => {},
};

Pagination.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  cursor: PropTypes.object,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  offset: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
