import {configureStore} from '@reduxjs/toolkit';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';

import logger from '../logger';
import reducers from '../reducers';

const errorHandler = () => {
  return (next) => {
    return (action) => {
      if (action.error) {
        logger.error(`\x1b[91m 💩 Action Error: ${action.payload.message} \x1b[39m`, action);
        throw action.payload;
      }
      return next(action);
    };
  };
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: false, serializableCheck: false}).concat(promiseMiddleware, thunkMiddleware, errorHandler),
});

export default store;
