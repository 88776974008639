import React from 'react';
import PropTypes from 'prop-types';

import {STATUS_LABELS, STATUS_DRAFT, STATUS_PUBLISHED, ARTICLE_ACTIVE_STATUSES, STATUS_DELETED} from '../../../constants/article';

function ArticleStatusBadge({status}) {
  const validStatuses = [STATUS_DELETED, ...ARTICLE_ACTIVE_STATUSES];
  if (!validStatuses.includes(status)) return null;

  const label = status === STATUS_PUBLISHED ? STATUS_LABELS[STATUS_PUBLISHED] : STATUS_LABELS[STATUS_DRAFT];

  return (
    <div className="article-status-badge">
      <span>{label}</span>
    </div>
  );
}

ArticleStatusBadge.propTypes = {
  status: PropTypes.oneOf(ARTICLE_ACTIVE_STATUSES).isRequired,
};

export default ArticleStatusBadge;
