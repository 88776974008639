import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEqual} from 'lodash';

import SelectField from '../selectField';
import {fetchDashboard} from '../../../actions/dashboard';
import {selectFilter, selectSorting} from '../../../selectors/dashboard';
import {isAnyEditor} from '../../../helpers/articleHelpers';
import {EDITOR, SORT_OPTIONS, WRITER} from '../../../constants/sort';

const DashboardFiltersSort = () => {
  const dispatch = useDispatch();
  const sortedBy = useSelector(selectSorting);
  const role = isAnyEditor() ? EDITOR : WRITER;
  const status = useSelector(selectFilter);

  const onSortValueChanged = ({target: {value}}) => {
    dispatch(fetchDashboard({sorting: SORT_OPTIONS[role][status][value].sort, offset: 0}));
  };

  const getSortFilter = () => {
    const selected = SORT_OPTIONS[role][status].find(({sort}) => isEqual(sort, sortedBy));
    return (
      <SelectField
        wrapperClassName="atom dashboard-filters-sort"
        defaultValue={selected ? SORT_OPTIONS[role][status].indexOf(selected) : 0}
        onChange={onSortValueChanged}>
        {SORT_OPTIONS[role][status].map(({text, sort}, index) => {
          const sortDirection = sort[1];
          return (
            <option key={`${text}-${sortDirection}`} value={index}>
              {text}
            </option>
          );
        })}
      </SelectField>
    );
  };

  return getSortFilter();
};

export default DashboardFiltersSort;
