import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import reporter from '../../reporter';

import logger from '../../logger';

import {mediaVideoNode} from '../../utils/types';
import {formatDateWithTimezone} from '../../helpers/dateHelpers';
import {VIDEO_DATE_FORMAT, VIDEO_PLAYER_KEY} from '../../constants/media';

const getPayerConfig = ({title, hls, thumbnailUrl, mp4}) => {
  return {title, hls, poster: thumbnailUrl, progressive: mp4};
};

function Video({src, ...rest}) {
  const [player, setPlayer] = useState(null);
  const [error, setError] = useState(false);

  const {id, className, ...props} = rest;
  const nodeId = id || `video-${src.id}`;

  useEffect(() => {
    const node = document.getElementById(nodeId);
    const videoPlayer = new global.bitmovin.player.Player(node, {key: VIDEO_PLAYER_KEY});
    videoPlayer.load(getPayerConfig(src)).catch(() => setError(true));
    setPlayer(videoPlayer);

    return () => {
      if (player !== null) player.unload();
    };
  }, [src.id]);

  const errorState = () => {
    const err = new Error(`Video cannot be played: ${src.id}`);
    reporter.inform(err);
    logger.error(err);
    return <span className="error">Video cannot by played at the moment, please try again later</span>;
  };

  const classes = classnames('atom', 'video-preview', className || {});
  return (
    <div className={classes}>
      {error && errorState()}
      {!error && (
        <>
          <div id={nodeId} className="video-player" {...props} />
          <p className="meta">
            {src.analytics && src.analytics.publishedAt && (
              <span className="date">{formatDateWithTimezone(src.analytics.publishedAt, VIDEO_DATE_FORMAT)}</span>
            )}
            <span className="description">{src.description || 'No description'}</span>
          </p>
        </>
      )}
    </div>
  );
}

Video.propTypes = {
  src: mediaVideoNode.isRequired,
  id: PropTypes.string,
};

export default Video;
