import {createSelector} from 'reselect';

const editorStatsSelector = (state) => state.editorStats;

export const selectDateEnd = createSelector([editorStatsSelector], (editorStats) => editorStats.dateEnd);

export const selectDateStart = createSelector([editorStatsSelector], (editorStats) => editorStats.dateStart);

export const selectEditType = createSelector([editorStatsSelector], (editorStats) => editorStats.editType);

export const selectEditors = createSelector([editorStatsSelector], (editorStats) => editorStats.editors);

export const selectEditorsCount = createSelector([editorStatsSelector], (editorStats) => editorStats.editorsCount);

export const selectError = createSelector([editorStatsSelector], (editorStats) => editorStats.error);

export const selectLoading = createSelector([editorStatsSelector], (editorStats) => editorStats.fetching);

export const selectIndividual = createSelector([editorStatsSelector], (editorStats) => editorStats.individual);

export const selectIndividualCount = createSelector([editorStatsSelector], (editorStats) => editorStats.individualCount);
