import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import classnames from 'classnames';

import {setEditType, fetchEditorStats} from '../../actions/editorStats';
import {USER_TEMPLATE_BASIC} from '../../constants/user';
import {EDITOR_STATS_EDIT_TYPE_DEFAULT_VALUE} from '../../constants/editorStats';
import {formatNumber} from '../../helpers/number';
import {getUserFromProfile} from '../../helpers/userHelpers';
import {selectDateEnd, selectDateStart, selectEditors, selectError, selectLoading} from '../../selectors/editorStats';
import {selectProfiles} from '../../selectors/users';

import Loading from '../atoms/loading';
import User from '../atoms/user';

const EditorStatsTableRoster = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateStart = useSelector(selectDateStart);
  const dateEnd = useSelector(selectDateEnd);
  const editors = useSelector(selectEditors);
  const error = useSelector(selectError);
  const isLoading = useSelector(selectLoading);
  const profiles = useSelector(selectProfiles);

  useEffect(() => {
    dispatch(fetchEditorStats());
  }, [dateEnd, dateStart]);

  const goToEditorPage = (editorId, type = EDITOR_STATS_EDIT_TYPE_DEFAULT_VALUE) => {
    dispatch(setEditType(type));
    navigate(`${editorId}`);
  };

  const showEditors = () => {
    if (error) return null;
    if (isLoading) return <Loading />;

    return Object.values(editors).map((editor) => {
      const {editorId, sbsEdits: editsPrimaryQC, supplementalEdits: editsSupplemental, bntEdits: editsBNT} = editor;
      const profile = getUserFromProfile(profiles[editorId]);
      return (
        <div className="table-row" key={editorId}>
          <div className="table-data">
            <a onClick={() => goToEditorPage(editorId)}>
              <User user={profile} template={USER_TEMPLATE_BASIC} />
            </a>
          </div>
          <div className="table-data">
            <a onClick={() => goToEditorPage(editorId, 'sbs')}>{formatNumber(editsPrimaryQC)}</a>
          </div>
          <div className="table-data">
            <a onClick={() => goToEditorPage(editorId, 'supplemental')}>{formatNumber(editsSupplemental)}</a>
          </div>
          <div className="table-data">
            <a onClick={() => goToEditorPage(editorId, 'bnt')}>{formatNumber(editsBNT)}</a>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classnames('molecule', 'editor-stats-roster', {'is-loading': isLoading})}>
      <div className="table-header">
        <div className="column-header">Name</div>
        <div className="column-header">Primary QC</div>
        <div className="column-header">Supplemental</div>
        <div className="column-header">BNT</div>
      </div>
      <div className="table-body">{showEditors()}</div>
    </div>
  );
};

export default EditorStatsTableRoster;
