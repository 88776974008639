import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ButtonMenu = (props) => {
  const {icon, menuAlignment, classButton, text, textAria, children} = props;
  const [isVisible, setIsVisible] = useState(0);
  const ref = useRef(null);

  const classes = classnames('atom', 'button-menu', `button-menu--${icon}`, {
    'is-active': isVisible,
  });
  const buttonClasses = classnames('button-menu-toggle', `button-icon--${icon}`, `${classButton}`);
  const menuClasses = classnames('menu', `menu--${menuAlignment}`);

  const buttonAttributes = textAria ? {'aria-label': textAria} : {};

  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const clickListener = (event) => {
    // collapse menu if clicked outside the menu element
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
      // collapse the menu unless clicking the toggle button
    } else if (ref.current && ref.current.contains(event.target) && event.target.className !== buttonClasses) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [ref]);

  return (
    <div className={classes} ref={ref}>
      <button className={buttonClasses} onClick={toggleMenu} {...buttonAttributes}>
        <span className="sr-only">{text}</span>
      </button>
      <div className={menuClasses}>{children}</div>
    </div>
  );
};

ButtonMenu.defaultProps = {
  classButton: '',
  icon: 'more',
  menuAlignment: 'left',
  text: 'Menu',
  textAria: '',
};

ButtonMenu.propTypes = {
  classButton: PropTypes.string,
  icon: PropTypes.string,
  menuAlignment: PropTypes.string,
  text: PropTypes.string,
  textAria: PropTypes.string,
};

export default ButtonMenu;
