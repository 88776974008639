import reporter from '../../reporter';
import {Cloudinary} from '../../endpoints';
import {sendRequest} from '../helpers/fetch';
import {epoch} from '../../../src/helpers/epoch';
import {hash} from '../helpers/hash';

/**
 * Generates a SHA-256 hexadecimal hash value for the Cloudinary upload request signature
 * @param {Iterator} entries an iterator for the form data entries
 * @returns {Promise<string>} a SHA-256 hexadecimal hash value
 */
const getUploadSignature = async (entries) => {
  // Don't include these entries in signatureString
  // https://cloudinary.com/documentation/upload_images#uploading_with_a_direct_call_to_the_rest_api:~:text=should%20be%20included-,except,-%3A%20file%2C
  const excludedEntries = ['file', 'cloud_name', 'resource_type', 'api_key'];

  // Build the signature string with filtered entries in alphabetical order by key and add
  // the Cloudinary API secret to the end
  const signatureString = Array.from(entries)
    .filter(([key]) => !excludedEntries.includes(key))
    .sort(([key1], [key2]) => (key1 > key2 ? 1 : -1))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
    .concat(process.env.CLOUDINARY_API_SECRET);

  // Create a SHA-256 encoded hexadecimal string used to sign our Cloudinary request
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#:~:text=Converting%20a%20digest%20to%20a%20hex%20string
  const signatureBuffer = await hash('SHA-256', signatureString);
  const signatureArray = Array.from(new Uint8Array(signatureBuffer));
  return signatureArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

/**
 * Constructs the data to be used in the body of the Cloudinary upload request
 * @param {object|string} fileContent image blob (File) or url
 * @returns {Promise<FormData>} data for the body of the request
 */
const getRequestBody = async (fileContent) => {
  const fd = new FormData();
  fd.append('file', fileContent);
  fd.append('upload_preset', process.env.CLOUDINARY_UPLOAD_PRESET);
  fd.append('timestamp', epoch());
  fd.append('api_key', process.env.CLOUDINARY_API_KEY);

  // Modify the original asset before storing it in our account (stores the transformed image, not the original image)
  // https://cloudinary.com/documentation/transformations_on_upload#incoming_transformations
  fd.append('transformation', 'f_auto,w_1800,h_2700,q_auto,c_fit');

  // Sign the request so that we can actually use the transformation option! (An unsigned request does not allow
  // use of the 'transformation' optional upload parameter
  // https://cloudinary.com/documentation/upload_images#generating_authentication_signatures:~:text=Manual%20signature%20generation
  const signature = await getUploadSignature(fd.entries());
  fd.append('signature', signature);

  return fd;
};

/**
 * Uploads an image to Cloudinary using blob
 * @param {object} blob image blob
 * @returns {Promise<any>} object containing image data or an error
 */
export const uploadImage = async (blob) => {
  const body = await getRequestBody(blob);

  return sendRequest([Cloudinary.imageUpload(), {method: 'POST', body}])
    .then((res) => {
      // Replace http with https to prevent browser security warning
      const url = res.url.replace('http://', 'https://');
      return {uploaded: {...res, url}, error: false};
    })
    .catch((err) => {
      reporter.inform(err);
      return {error: true};
    });
};

/**
 * Uploads an image to Cloudinary using url
 * @param {string} url image url
 * @returns {Promise<any>} object containing image data or an error
 */
export const uploadURL = async (url) => {
  const body = await getRequestBody(url);

  return sendRequest([Cloudinary.imageUpload(), {method: 'POST', body}])
    .then((res) => {
      return {uploaded: {...res, url}, error: false};
    })
    .catch((err) => {
      reporter.inform(err, {url});
      return {error: err};
    });
};
