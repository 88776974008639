import {camelizeKeys, decamelizeKeys} from 'humps';

import logger from '../../logger';

import {ACCESS_TOKEN} from '../../constants';
import {getAuthTokens} from '../../helpers/authHelpers';
import {refreshToken} from '../../actions/users';

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  let message = `HTTP ${response.status}`;
  if (response.url && response.url.split) {
    message = `${message} from ${response.url.split('/')[2]}`;
  }
  const error = new Error(message);
  error.response = response;
  throw error;
};

const getFetchArgs = (url, {method = 'GET', ...opts}) => {
  const headers = new Headers();
  const options = {...opts};

  if (method !== 'GET') {
    // If we're doing a POST/PUT/etc we always default to a JSON body
    headers.append('Content-Type', 'application/json');
    // Stringify options.body if it exists AND it is an object (has the potential to already be a string value here)
    if (options.body && typeof options.body === 'object') {
      options.body = JSON.stringify(opts.body);
    }
  }

  if (opts.jwt) {
    headers.append('Authorization', `Bearer ${opts.jwt}`);
  }

  options.method = method;
  options.headers = headers;
  return [url, options];
};

const makeFetchOpts = (method) => {
  return (body) => {
    return {
      body: decamelizeKeys(body),
      jwt: localStorage.getItem(ACCESS_TOKEN),
      method,
    };
  };
};

export const patchFetchOpts = makeFetchOpts('PATCH');
export const postFetchOpts = makeFetchOpts('POST');
export const putFetchOpts = makeFetchOpts('PUT');
export const deleteFetchOpts = makeFetchOpts('DELETE');

const parseJSON = (response) => {
  return response.json();
};

const getNanoSeconds = () => {
  const hr = process.hrtime();
  return hr[0] * 1e9 + hr[1];
};

/**
 * Returns a function which tells you the time elapsed since the app started up.
 *
 * @returns {number} milliseconds since the app started
 */
const getNow = () => {
  let loadTime;
  if (process && process.hrtime) {
    // we're in Node.
    loadTime = getNanoSeconds();
    return () => {
      return (getNanoSeconds() - loadTime) / 1e6;
    };
  } else if (Date.now) {
    // Maybe really old versions of Node or older browsers.
    loadTime = Date.now();
    return () => {
      Date.now() - loadTime;
    };
  }
  // very old browsers with no support for Date.now
  loadTime = new Date().getTime();
  return () => {
    new Date().getTime() - loadTime;
  };
};

const now = getNow();

const isNode = process && process.browser !== true;

export const timerFactory = (...args) => {
  if (!isNode) {
    return (response) => response;
  }
  const start = now();
  return (response) => {
    const end = now();
    const total = (end - start).toFixed(2);
    if (response && !response.nologs) {
      const verb = (args[1] && args[1].method) || 'GET';
      logger.debug(`External: ${total.padStart(6)}ms to ${verb} ${args[0]}`);
    }
    return response;
  };
};

async function reSendRequestWithNewToken(options) {
  const {refreshToken: token} = getAuthTokens();
  if (!token) return null;

  // N.B. refreshToken will set tokens into LocalStorage
  if (!(await refreshToken(token))) return null;

  // Skip `jwt` and `headers` parameters 'cause it will be set later
  // eslint-disable-next-line no-unused-vars
  const [url, {jwt, headers, ...fetchOptions}] = options;

  // Update request jwt token
  const {accessToken} = getAuthTokens();
  fetchOptions.jwt = accessToken;

  // Disable linter for the following line because function is used in recursion
  // eslint-disable-next-line no-use-before-define
  return sendRequest(getFetchArgs(url, fetchOptions), false);
}

export const sendRequest = (options, reSendWithNewToken = true) => {
  const [url, ...fetchOptions] = options;
  return fetch(...options)
    .then(timerFactory(url, fetchOptions))
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .catch(async (thrownError) => {
      if ([401, 403].includes(thrownError.response.status) && reSendWithNewToken) {
        // Refresh token and resend initial request
        const res = await reSendRequestWithNewToken(options);
        // Return re-sended request result OR reject with and error
        if (res !== null) return res;
      }
      logger.error(`Error — could not load url: ${url}`);
      logger.error(thrownError);
      return Promise.reject(thrownError, {url, fetchOptions, thrownError});
    });
};

export const fetchJSON = (url, opts = {}) => {
  const fetchArgs = getFetchArgs(url, opts);
  return sendRequest(fetchArgs);
};

export const fetchText = (url, opts = {}) => {
  const fetchArgs = getFetchArgs(url, opts);
  return fetch(...fetchArgs)
    .then(timerFactory(url, opts))
    .then(checkStatus)
    .catch((thrownError) => {
      logger.error(`Error — could not load url: ${url}`);
      logger.error(thrownError);
      return Promise.reject(thrownError, {url, opts, thrownError});
    });
};

export const fetchFile = (url, opts = {}) => {
  const fetchArgs = getFetchArgs(url, opts);
  return fetch(...fetchArgs)
    .then(timerFactory(url, opts))
    .then(checkStatus)
    .catch((thrownError) => {
      logger.error(`Error — could not load url: ${url}`);
      logger.error(thrownError);
      return Promise.reject(thrownError, {url, opts, thrownError});
    });
};
