import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useNavigationType} from 'react-router-dom';
import usePrompt from '../../hooks/usePrompt';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isEqual, omit} from 'lodash';

import ArticleHeader from './articleHeader';
import ArticleSidebar from './articleSidebar';
import ArticleSlideshow from './articleSlideshow';
import ArticleStandard from './articleStandard';
import ArticleTags from './articleTags';
import Loading from '../atoms/loading';
import PageError from '../atoms/pageError';
import Notification from '../atoms/notification';
import TagsAdmin from './tagsAdmin';

import {
  bypassLock,
  bypassLockUpdateStatus,
  fetchArticle,
  fetchNewArticle,
  releaseLock,
  saveArticle,
  saveSlide,
  setActiveSlideContent,
  unsetArticle,
  updateArticle,
  updateReadOnly,
  updateStatus,
} from '../../actions/article';
import {
  BREAKING_NEWS_TAGS,
  NO_ADS_TAG,
  STATE_READONLY,
  STATUS_DRAFT,
  STATUS_IN_REVIEW,
  STATUS_PUBLISHED,
  STATUS_READY_FOR_PUBLISH,
  STATUS_READY_FOR_REVIEW,
  VALIDATE_FIELDS,
} from '../../constants/article';
import {FILTER_TO_PUBLISH} from '../../constants/dashboard';
import {
  BEFORE_UNLOAD_MESSAGE_EXPIRED,
  BEFORE_UNLOAD_MESSAGE_UNSAVED,
  READONLY_MESSAGE,
  READONLY_MESSAGE_LOCK_EXPIRED,
  READONLY_MESSAGE_LOCKED,
  READONLY_MESSAGE_LOCKED_CANT_STEAL,
} from '../../constants/messages';
import {VALIDATE_FIELD_MAIN, VALIDATE_FIELDS_SLIDESHOW} from '../../constants/slideshow';
import {getArticleDescription} from '../../helpers/articleContent';
import {
  isAdmin,
  isEditor,
  getArticleAuthor,
  isArticleReadOnlyDefault,
  isArticleReadOnlyForWriter,
  isFirstBlockFeaturedMediaType,
} from '../../helpers/articleHelpers';
import {addRecentArticle} from '../../helpers/recentArticlesHelper';
import {isArticleLockedByAnotherUser, isCurrentUserTheLastEditor} from '../../helpers/softlockHelpers';
import {
  getAction,
  getActiveSlide,
  getArticleState,
  getBetTags,
  getError,
  getHideBetTags,
  getIsArticleDeleted,
  getIsChangedArticle,
  getIsPublished,
  getIsReadOnly,
  getIsSlideshow,
  getStatus,
  getTagsList,
  getTitle,
} from '../../selectors/article';
import {selectProfiles} from '../../selectors/users';
import validateArticle from '../../utils/articleValidate';
import validateSlideshow from '../../utils/slideshowValidate';
import withGlobalNotifications, {typeNotification} from '../../utils/notifications/withGlobalNotifications';
import {getYouTubeThumbnail} from '../../utils/editorjs/tools/iframe';
import {getYouTubeId} from '../../utils/editorjs/tools/embed';

// all standard and slideshow validation fields
const VALIDATE_FIELDS_ALL = VALIDATE_FIELDS.concat(VALIDATE_FIELDS_SLIDESHOW);

const ArticleForm = (props) => {
  const {id, isNewArticle, isNewSlideshow} = props;
  const [action, setAction] = useState({});
  const [init, setInit] = useState(false);
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [isFeedbackEnabled, setIsFeedbackEnabled] = useState(false);
  const [isIdleTimerExpired, setIsIdleTimerExpired] = useState(false);
  const [isNew, setIsNew] = useState(isNewArticle || isNewSlideshow);
  const [isReadOnlyMessage, setIsReadOnlyMessage] = useState(READONLY_MESSAGE);
  const [wordcount, setWordcount] = useState(0);

  const articleAction = useSelector(getAction);
  const activeSlide = useSelector(getActiveSlide);
  const article = useSelector(getArticleState);
  const betTags = useSelector(getBetTags);
  const error = useSelector(getError);
  const hideBetTags = useSelector(getHideBetTags);
  const isChanged = useSelector(getIsChangedArticle);
  const isDeleted = useSelector(getIsArticleDeleted);
  const isReadOnly = useSelector(getIsReadOnly);
  const isPublished = useSelector(getIsPublished);
  const isSlideshow = useSelector(getIsSlideshow);
  const profiles = useSelector(selectProfiles);
  const status = useSelector(getStatus);
  const tags = useSelector(getTagsList);
  const title = useSelector(getTitle);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const prevIdRef = useRef(id);
  const prevArticleRef = useRef(article);

  // Reimplementing the react router v5 <Prompt> component API
  const Prompt = ({when, message, ...props}) => {
    usePrompt(when ? message : false, props);
    return null;
  };

  const setIsReadOnly = (newstate) => {
    const {
      data,
      data: {editedByGatekeeperId, status},
    } = article;

    // only update state if explictly passed new state
    if (newstate) dispatch(updateReadOnly(newstate));

    // update the readonly message that will appear to the user
    let message = READONLY_MESSAGE;
    if (isIdleTimerExpired) {
      message = READONLY_MESSAGE_LOCK_EXPIRED;
    } else if (isReadOnly && isArticleLockedByAnotherUser(data)) {
      // update the readonly message with the name of the person with the lock
      message = isArticleReadOnlyForWriter(status) ? READONLY_MESSAGE_LOCKED_CANT_STEAL : READONLY_MESSAGE_LOCKED;
      const editedBy = profiles[editedByGatekeeperId];
      // if someone hits an article url directly (not via dashboard link) profiles data will be empty so needs to fail gracefully
      if (editedBy) message = message.replace('an editor', `${editedBy.firstName} ${editedBy.lastName}`);
    }

    setIsReadOnlyMessage(message);
  };

  // eslint-disable-next-line consistent-return
  const resetIdleTimer = () => {
    const {
      isSlideshowType,
      notifications: {removeError},
    } = props;

    if (isSlideshowType) return null; // we only use the timer on standard articles

    removeError(STATE_READONLY);
    setIsIdleTimerExpired(false);
    setIsReadOnly();
  };

  const clearAndFetchArticle = async () => {
    const {
      id,
      isSlideshowType,
      notifications: {removeError},
    } = props;
    removeError(...VALIDATE_FIELDS_ALL);
    resetIdleTimer(); // reset idle timer if navigate directly from one article to another (ex./ clicking a recently edited article in the sidebar while viewing an article)
    await dispatch(unsetArticle()); // must clear article state before refetching or body content won't update
    await dispatch(fetchArticle({id, isSlideshow: isSlideshowType, readonly: isReadOnly})); // must wait until clear has completed before fetching, otherwise it will clear what we just fetched...
  };

  // We also have isCurrentUserTheAuthor() in /src/helpers/softlockHelpers, but it validates the author using
  // authorGatekeeperId, which is present on standard articles but can be null for slideshows. This function uses
  // the author's CTT_USER_LEGACY_ID from the article.data.author object because it is consistently present in the
  // data for both standard and slideshow articles.
  const isCurrentUserTheAuthor = () => {
    const {
      data: {author},
    } = article;
    return author?.id === getArticleAuthor().id;
  };

  const shouldUpdateStatusInReview = () => {
    return status === STATUS_READY_FOR_REVIEW && isEditor() && !isCurrentUserTheAuthor();
  };

  const setStatusInReview = () => {
    // when an editor opens a slideshow that's review ready,
    // automatically set article status to "in review" - but not if it's their own article
    // only update for slideshows, standard articles update the status when you take the lock
    const shouldUpdateStatus = isSlideshow && shouldUpdateStatusInReview();
    if (shouldUpdateStatus) dispatch(updateStatus(STATUS_IN_REVIEW));
  };

  const handleIdleTimerExpires = () => {
    const {
      notifications: {removeError},
    } = props;
    removeError(...VALIDATE_FIELDS_ALL);
    setIsIdleTimerExpired(true);
    setIsReadOnly(true);
  };

  const getPublishDateControl = () => {
    const {
      data: {hidePublishedAt},
    } = article;
    return (
      <div className={classnames('control', 'control-publish-date', {disabled: isReadOnly})}>
        <label>
          <input
            type="checkbox"
            defaultChecked={hidePublishedAt}
            disabled={isReadOnly}
            onChange={({target}) => {
              dispatch(updateArticle({hidePublishedAt: target.checked}));
            }}
          />
          Hide Publish Date
        </label>
      </div>
    );
  };

  const setBreakingNews = (checked) => {
    if (tags) {
      let newTags = tags.slice();
      const tagsHasBNT = tags.filter((tag) => BREAKING_NEWS_TAGS.includes(tag)).length;

      if (checked && !tagsHasBNT) {
        newTags.push(BREAKING_NEWS_TAGS);
      } else if (!checked && tagsHasBNT) {
        newTags = newTags.filter((tag) => !BREAKING_NEWS_TAGS.includes(tag));
      }

      const update = {tagList: newTags.join(','), isBreakingNews: checked};
      dispatch(updateArticle(update));
    }
  };

  const getPermissionsBNTControl = () => {
    const {
      data: {isBreakingNews},
    } = article;

    return (
      <div className={classnames('control', 'control-permissions-bnt', {disabled: isReadOnly})}>
        <label>
          <input
            type="checkbox"
            defaultChecked={isBreakingNews}
            disabled={isReadOnly}
            onChange={({target}) => {
              setBreakingNews(target.checked);
            }}
          />
          Breaking News Team
        </label>
      </div>
    );
  };

  const getTagsAdmin = () => {
    return (
      <TagsAdmin
        selected={tags}
        onTagSelect={(tagsSelected) => {
          dispatch(updateArticle({tagList: tagsSelected.join(',')}));
        }}
      />
    );
  };

  const getTagsArticle = () => {
    const noAds = tags.includes(NO_ADS_TAG);

    const articleTagsHandler = (tags) => {
      const update = {tagList: tags.join(',')};
      dispatch(updateArticle(update));
    };

    const filterBetTags = (tags) => {
      const teamFilteredTags = tags.filter((tag) => tag.type === 'Team');
      const leagueFilteredTags = tags.filter((tag) => tag.type === 'League');
      return [...teamFilteredTags, ...leagueFilteredTags].map((tag) => tag.id);
    };

    const betTagsHandler = (primaryBetTag, betTags) => {
      if (primaryBetTag) {
        const filteredBetTags = betTags.filter((tag) => tag !== primaryBetTag);
        const sortedBetTags = [primaryBetTag, ...filteredBetTags];
        const update = {betTags: sortedBetTags.join(',')};
        dispatch(updateArticle(update));
      } else {
        const update = {betTags: betTags.join(',')};
        dispatch(updateArticle(update));
      }
    };

    const toggleHideBetTags = (toggle) => {
      dispatch(updateArticle({hideBetTags: toggle}));
    };

    return (
      <ArticleTags
        betTags={betTags}
        filterBetTags={filterBetTags}
        hideBetTags={hideBetTags}
        onBetTagSelect={betTagsHandler}
        onTagSelect={articleTagsHandler}
        noAds={noAds}
        selected={tags}
        toggleHideBetTags={toggleHideBetTags}
      />
    );
  };

  const validateStandardArticle = async () => {
    const {data} = article;
    const {
      notifications: {removeError},
    } = props;
    removeError(...VALIDATE_FIELDS);
    return await validateArticle(data, tags, wordcount);
  };

  const validateSlideshowArticle = async () => {
    const {data} = article;
    const {
      notifications: {removeError},
    } = props;
    removeError(VALIDATE_FIELD_MAIN);
    return await validateSlideshow(data, tags);
  };

  const isArticleValid = async () => {
    const {
      notifications: {addError},
    } = props;
    const error = isSlideshow ? await validateSlideshowArticle() : await validateStandardArticle();

    if (error) {
      addError(error);
      return false;
    }
    return true;
  };

  const redirectToDashboard = (status) => {
    let hash = status;
    let params = '';
    if (status === STATUS_READY_FOR_REVIEW) {
      hash = STATUS_IN_REVIEW;
    } else if (status === STATUS_READY_FOR_PUBLISH) {
      hash = FILTER_TO_PUBLISH;
    } else if (status === STATUS_PUBLISHED && (isEditor() || isAdmin())) {
      // when an editor redirects to the dashboard after publishing an article, show all articles
      // so editors can grab the published by link quickly to share to the BNT team
      params = '?showMine=false';
    }

    const redirectTo = status !== STATUS_DRAFT ? `/${params}#${hash}` : '/';

    // slight delay to allow actions to complete, otherwise dashboard gets stuck in loading state
    setTimeout(() => navigate(redirectTo), 300);
  };

  const handleStatusChange = async (status) => {
    if (!status) return;
    if (!(await isArticleValid())) return;
    dispatch(updateStatus(status));
    redirectToDashboard(status);
  };

  const saveArticleToStorage = (path, title, type) => {
    const id = path.substring(path.lastIndexOf('/') + 1);
    const {
      data: {id: articleId},
    } = article;
    const lastEdited = Date.now();

    // Skip new and invalid articles
    if (id === 'undefined' || id === 'new') return;

    // Insert only currently loaded article
    if (parseInt(id, 10) !== articleId) return;

    addRecentArticle(articleId, title, type, lastEdited);
  };

  const trySavingArticleToStorage = async (data) => {
    const {isSlideshow} = article;

    if (location && data) {
      // must re-validate if the article's already been published
      if (isPublished && !(await isArticleValid())) return false;
      const articleType = isSlideshow ? 'slideshow' : 'standard';
      saveArticleToStorage(location.pathname, data.title, articleType);
    }

    return true;
  };

  const handleSave = async () => {
    const {
      data,
      data: {status},
    } = article;
    if (!trySavingArticleToStorage(data)) return;
    await dispatch(saveArticle());
    if (isPublished) redirectToDashboard(status);
  };

  const handleSaveSlide = async () => {
    const {
      data,
      data: {elements},
      dataOriginal: {elements: originalElements},
    } = article;
    const isInvalidPublishedSlideshow = isSlideshow && isPublished && !(await isArticleValid());
    if (isInvalidPublishedSlideshow) return;
    trySavingArticleToStorage(data);

    // when we add a new slide to a published slideshow and then click 'Publish Slide'
    // we need to use the `addSlide` endpoint. if you atttempt to use the `saveSlide endpoing with a slide ID
    // that doesn't already exist in the article it will return a 404
    const isNewSlideOnPublishedArticle = isPublished && elements.length !== originalElements.length;
    await dispatch(saveSlide(isNewSlideOnPublishedArticle));
  };

  const handleReleaseLock = () => {
    const {
      data: {status},
    } = article;
    dispatch(releaseLock());
    redirectToDashboard(status);
  };

  const handleStealLock = () => {
    const {id} = props;

    // when an editor opens an article that's review ready, automatically
    // set article status to "in review" - but not if it's your own article
    const shouldUpdateStatus = shouldUpdateStatusInReview();

    if (shouldUpdateStatus) dispatch(bypassLockUpdateStatus(id, STATUS_IN_REVIEW));
    else dispatch(bypassLock(id));
  };

  const isAddFeedbackActive = (status) => {
    // editors/admim allow leaving feedback for all status except "new"
    if (isAdmin() || isEditor()) {
      if (!status) return false;
      return true;
    }

    // writers/bntwriters allow leaving feedback only for specific article statuses
    const activeStatuses = [STATUS_DRAFT, STATUS_READY_FOR_PUBLISH, STATUS_PUBLISHED];
    if (status && !activeStatuses.includes(status)) return false;
    return true;
  };

  const cleanDataBlocksForComparison = (blocks) => {
    return blocks.map((block, index) => {
      if (block === null) return null;
      let temp = block;
      // must strip verbatim content '__original__' before detecting content changes
      temp = omit({...temp, data: omit(temp.data, '__original__')}, 'id');

      // completely prevent fm image replacement/cropping from triggering article body updates
      if (block.type === 'image' && index === 0) temp = {...temp, data: {}};

      if (block.type === 'embed') {
        // `caption, height, width` are part of the embed output data but not used
        // `thumbnail` is something we include, that's not part of the embed data
        temp = omit({...temp, data: omit(temp.data, ['caption', 'height', 'width', 'thumbnail'])}, null);
      }
      return temp;
    });
  };

  /**
   * Preserves the height, thumbnail and __original__ properties we add to custom embeds
   *
   * NOTE: Embed data received within dataNew.blocks does not include the height, thumbnail and __original__ properties
   * we manually add to the object returned by the embed plugin, so we need to re-add them in order to keep them in the
   * redux state when body changes occur. This helps prevent the featured media UI from refreshing when users type in
   * the body of the article.
   *
   * @param {object[]} elements the current elements held in redux
   * @param {object[]} blocks the new blocks to update redux with
   * @returns the amended blocks to update redux with
   */
  const preserveEmbedCustomProps = (elements, blocks) => {
    return blocks.map((block, index) => {
      if (block.type === 'embed') {
        block.data.height = elements[index]?.data.height;
        block.data.thumbnail = getYouTubeThumbnail(getYouTubeId(block.data.source));
        block.data.__original__ = elements[index]?.data.__original__;
      }
      return block;
    });
  };

  /**
   * Preserves the featured media when the body changes
   *
   * NOTE: This function ensures that the featured media block is maintained when the body content changes.
   * If the first block in the new data is not a featured media block and the current redux state has a featured
   * media block as its first block, we can assume that the user has deleted the featured media block by pressing
   * backspace at the beginning of the first block in the body. In this case, this function will prepend the featured
   * media block to the beginning of the blocks array to keep the UI consistent with the redux state.
   *
   * @see CTT-1229 for more details
   *
   * @param {object[]} elements the current elements held in redux
   * @param {object[]} blocks the new blocks to update redux with
   * @returns the amended blocks to update redux with
   */
  const preserveFeaturedMedia = (elements, blocks) => {
    if (!elements[0]) return blocks;
    if ((!blocks[0] || !isFirstBlockFeaturedMediaType(blocks)) && isFirstBlockFeaturedMediaType(elements)) {
      blocks.unshift(elements[0]);
    }
    return blocks;
  };

  const renderArticle = () => {
    const {
      data: {elements, teaser},
    } = article;

    if (isSlideshow) {
      return (
        <ArticleSlideshow
          onBodyChanged={(dataNew, words) => {
            const {
              data: {elements, teaser},
            } = article;

            setWordcount(words);
            dataNew.blocks = preserveEmbedCustomProps(elements[activeSlide].elements, dataNew.blocks);
            dataNew.blocks = preserveFeaturedMedia(elements[activeSlide].elements, dataNew.blocks);

            // when you edit/replace the featured media it triggers the body change event which clobbers
            // the updated featured media from displaying because the data passed is the older version
            // so we need to ensure the data has changed before updating the article data
            const dataClean = cleanDataBlocksForComparison(elements[activeSlide].elements);
            const dataNewClean = cleanDataBlocksForComparison(dataNew.blocks);
            const hasDataChanged = !isEqual(dataClean, dataNewClean);

            if (hasDataChanged) {
              // only update the teaser if this is the title slide
              // add teaser if we don't have, or update the teaser if it hasn't been unsync'd from the content
              const shouldUpdateTeaser = activeSlide === 0 && (!teaser || teaser === getArticleDescription(elements[0].elements));

              // only update the teaser when editing the title slide
              if (shouldUpdateTeaser) {
                dispatch(setActiveSlideContent({elements: dataNew.blocks}, {teaser: getArticleDescription(dataNew.blocks)}));
              } else {
                dispatch(setActiveSlideContent({elements: dataNew.blocks}));
              }
            }
          }}
        />
      );
    }

    return (
      <ArticleStandard
        isReadOnlyMessage={isReadOnlyMessage}
        onBodyChanged={(dataNew, words) => {
          setWordcount(words);
          dataNew.blocks = preserveEmbedCustomProps(elements, dataNew.blocks);
          dataNew.blocks = preserveFeaturedMedia(elements, dataNew.blocks);

          // when you edit/replace the featured media it triggers the body change event which clobbers
          // the updated featured media from displaying because the data passed is the older version
          // so we need to ensure the data has changed before updating the article data
          const dataClean = cleanDataBlocksForComparison(elements);
          const dataNewClean = cleanDataBlocksForComparison(dataNew.blocks);
          const hasDataChanged = !isEqual(dataClean, dataNewClean);

          if (hasDataChanged) {
            // add teaser if we don't have, or update the teaser if it hasn't been unsync'd from the content
            if (!teaser || teaser === getArticleDescription(elements))
              dispatch(updateArticle({blocks: dataNew.blocks, teaser: getArticleDescription(dataNew.blocks)}));
            else dispatch(updateArticle({blocks: dataNew.blocks}));
          }
        }}
      />
    );
  };

  const getPromptMessage = (currentLocation, nextLocation) => {
    // don't trigger the prompt for hash changes (toggling between the sidebar Metadata and Feedback tabs)
    if (currentLocation.pathname === nextLocation.pathname) return false;
    if (isIdleTimerExpired && isCopiedToClipboard) return false;
    if (isIdleTimerExpired && !isCopiedToClipboard) return BEFORE_UNLOAD_MESSAGE_EXPIRED;
    return BEFORE_UNLOAD_MESSAGE_UNSAVED;
  };

  useEffect(() => {
    const {data} = article;
    const {id, isNewSlideshow, isSlideshowType} = props;
    const shouldFetchArticle = navigationType === 'PUSH'; // fetch when navigating (PUSH), don't fetch when hitting a url directly (POP) or on article redirect (REPLACE)
    const isReadOnly = isArticleReadOnlyDefault(isNew, isSlideshowType);

    // The first argument to useEffect must be a non-async function (because async functions return a promise) that
    // returns either nothing (undefined) or a cleanup function, so we need to define these async functions inside
    // our useEffect in order to await article data
    const fetchNewArticleAsync = async () => {
      await dispatch(fetchNewArticle(isNewSlideshow));
    };
    const fetchArticleAsync = async () => {
      await dispatch(fetchArticle({id, isSlideshow: isSlideshowType, readonly: isReadOnly}));
    };
    const clearAndFetchArticleAsync = async () => {
      await clearAndFetchArticle();
    };

    dispatch(updateReadOnly(isReadOnly));

    if (data) setIsReadOnly();

    if (!data && isNew) fetchNewArticleAsync();
    // when navigating to an article or slideshow from the dashboard, the current article data
    // will be considered empty, and if it isn't new we must fetch the pre-existing article's data
    else if (!data && shouldFetchArticle) fetchArticleAsync();
    // if an article is open, and you navigate to another article using the recently edited list
    // clear the previous article data before fetching another article
    else if (data && shouldFetchArticle) clearAndFetchArticleAsync();

    return () => {
      const {
        notifications: {removeError},
      } = props;
      removeError(...VALIDATE_FIELDS_ALL);
      dispatch(unsetArticle());
    };
  }, []);

  useEffect(() => {
    // Ensures that the feedback tab gets enabled for saved articles
    if (!prevArticleRef.current.data && article.data) prevArticleRef.current = article;

    if (prevIdRef.current !== id) clearAndFetchArticle();
    if (!prevArticleRef.current.data || !article.data) return;

    const {breportId} = article.data;
    const {data} = prevArticleRef.current;

    if (data.breportId !== breportId && isNew) {
      // Special condition for handle redirect after article creation
      const path = `/articles/${isNewSlideshow ? 'slideshow' : 'standard'}/${breportId}`;
      navigate(path, {replace: true});
    }

    prevIdRef.current = id;
    prevArticleRef.current = article;

    if (articleAction) setAction(articleAction);

    const isExistingArticle = Boolean(id && id !== 'new');

    setIsFeedbackEnabled(isExistingArticle);
    setIsReadOnly();
    setIsNew(isNewArticle || isNewSlideshow);

    if (init) return;

    setStatusInReview();
    setInit(true);
  }, [id, article]);

  // display page error if error fetching the article
  if (error) {
    return (
      <PageError what="error fetching article data">
        <p>The error could be caused by a couple reasons:</p>
        <ul>
          <li>
            The article ID <code>{id}</code> you're trying to access doesn't exist
          </li>
          <li>You don't have permission to edit this article</li>
        </ul>
      </PageError>
    );
  }

  // display error message if article has been deleted AND current user is not admin
  if (isDeleted && !isAdmin()) {
    return (
      <div className="molecule article-form">
        <Notification>Article `{title}` has been deleted</Notification>
      </div>
    );
  }

  if (!article.data)
    return (
      <div className="molecule article-form">
        <Loading />
      </div>
    );

  const {
    data: {image: thumbnailUrl, status: articleStatus},
  } = article;

  return (
    <>
      <Prompt
        when={isChanged}
        message={getPromptMessage}
        beforeUnload={isChanged || (!isChanged && !isReadOnly && isCurrentUserTheLastEditor(article.data))}
      />
      <ArticleHeader
        isNewArticle={isNew}
        onReleaseLock={handleReleaseLock}
        onSave={handleSave}
        onSaveSlide={handleSaveSlide}
        onStatusChange={handleStatusChange}
        onStealLock={handleStealLock}
        onIdleTimerExpires={handleIdleTimerExpires}
        redirectToDashboard={redirectToDashboard}
        setIsCopiedToClipboard={setIsCopiedToClipboard}
        toastStatus={action}
        wordcount={wordcount}
      />
      <div className="article-layout">
        <div className="molecule article-form">{renderArticle()}</div>
        <ArticleSidebar
          isFeedbackEnabled={isFeedbackEnabled}
          permissionsBNTControl={getPermissionsBNTControl()}
          publishDateControl={getPublishDateControl()}
          tagsAdmin={getTagsAdmin()}
          tagsArticle={getTagsArticle()}
          thumbnailUrl={thumbnailUrl}
          withFeedbacks={!isNew}
          withAddFeedback={isAddFeedbackActive(articleStatus)}
          wordcount={wordcount}
        />
      </div>
    </>
  );
};

ArticleForm.defaultProps = {
  id: '',
  isNewArticle: true,
  isNewSlideshow: false,
  isSlideshowType: false,
};

ArticleForm.propTypes = {
  id: PropTypes.string,
  isNewArticle: PropTypes.bool,
  isNewSlideshow: PropTypes.bool,
  isSlideshowType: PropTypes.bool,
  notifications: typeNotification,
};

export default withGlobalNotifications(ArticleForm);
