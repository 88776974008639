import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {STATUS_DRAFT, STATUS_PUBLISHED, STATUS_READY_FOR_PUBLISH, STATUS_READY_FOR_REVIEW} from '../../constants/article';

// TODO: we may want to refactor the 'toast' and 'toastStatus' components to a single component
// 'toast' includes expriration (prop) and 'toastStatus' expires when article has unsaved changes also includes different props composition and customizes the message

const ToastStatus = ({toast, isArticleChanged}) => {
  if (!toast) return null;
  if (!toast.status) return null;

  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(!toast.error);
  const classes = classnames('atom toast toast-status', {
    'is-success': isSuccess,
    'is-error': !isSuccess,
    'is-expired': isArticleChanged,
  });

  const getStatusMessage = (status) => {
    switch (status) {
      case STATUS_READY_FOR_REVIEW: {
        return 'Submit for Review';
      }
      case STATUS_READY_FOR_PUBLISH: {
        return 'Ready for Publish';
      }
      case STATUS_PUBLISHED: {
        return 'Publish';
      }
      case STATUS_DRAFT: {
        return 'Return to Writer';
      }
      default: {
        return status;
      }
    }
  };

  useEffect(() => {
    if (!toast.complete) {
      setMessage('Pending…');
      return;
    }
    if (toast.error) setIsSuccess(false);
    const text = `'${getStatusMessage(toast.status)}' ${toast.error ? 'error' : 'complete'}`;
    setMessage(text);
  }, [toast]);

  useEffect(() => {
    if (isArticleChanged) setMessage('');
  }, [isArticleChanged]);

  return (
    <div className={classes}>
      <em>{message}</em>
    </div>
  );
};

ToastStatus.propTypes = {
  toast: PropTypes.shape({
    status: PropTypes.string,
    complete: PropTypes.bool,
    error: PropTypes.bool,
  }),
  isArticleChanged: PropTypes.bool,
};

export default ToastStatus;
