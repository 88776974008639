import React from 'react';
import PropTypes from 'prop-types';

import {STATUS_READY_FOR_REVIEW, STATUS_DRAFT, STATUS_IN_REVIEW, STATUS_READY_FOR_PUBLISH, STATUS_PUBLISHED} from '../../../constants/article';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_STATUS_CHANGE} from '../../../constants/prompt';
import {isEditor, isBNTWriter} from '../../../helpers/articleHelpers';
import {prompt} from '../../../helpers/promptHelper';
import {isCurrentUserTheAuthor} from '../../../helpers/softlockHelpers';

const isVisible = (status, statusTo, isNew, isBreakingsNews, authorGatekeeperId) => {
  if (isNew) return false;
  switch (statusTo) {
    case STATUS_DRAFT:
      return !status || (status === STATUS_IN_REVIEW && isEditor()) || (status === STATUS_READY_FOR_REVIEW && isEditor());
    case STATUS_READY_FOR_REVIEW:
      return !status || status === STATUS_DRAFT;
    case STATUS_IN_REVIEW:
      return status === STATUS_READY_FOR_REVIEW && isEditor();
    case STATUS_READY_FOR_PUBLISH:
      return (status === STATUS_IN_REVIEW || status === STATUS_READY_FOR_REVIEW) && isEditor();
    case STATUS_PUBLISHED:
      if (isBNTWriter() && isBreakingsNews && status !== STATUS_PUBLISHED) return true;
      if (isCurrentUserTheAuthor({authorGatekeeperId}) && status === STATUS_READY_FOR_PUBLISH) return true;
      return isEditor() && (status === STATUS_IN_REVIEW || status === STATUS_READY_FOR_REVIEW || status === STATUS_READY_FOR_PUBLISH);
    default:
      throw new Error(`Invalid status: ${statusTo}`);
  }
};

const handleClick = (to, onClick, confirmHeading, confirmText) => {
  if (!confirmHeading) {
    onClick(to);
  } else {
    prompt(PROMPT_STATUS_CHANGE)
      .withTexts(confirmHeading, confirmText, PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => onClick(to));
  }
};

function StatusButton(props) {
  const {authorGatekeeperId, children, confirm, from, isBreakingNews, isNewArticle, onClick, title, to, ...rest} = props;
  if (!isVisible(from, to, isNewArticle, isBreakingNews, authorGatekeeperId)) return null;

  return (
    <button onClick={() => handleClick(to, onClick, confirm, title)} {...rest}>
      {children}
    </button>
  );
}

StatusButton.defaultProps = {
  authorGatekeeperId: null,
  from: null,
  confirm: '',
};

StatusButton.propTypes = {
  authorGatekeeperId: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirm: PropTypes.string,
  from: PropTypes.string,
  isBreakingNews: PropTypes.bool.isRequired,
  isNewArticle: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};

export default StatusButton;
