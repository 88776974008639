import React from 'react';
import {useSelector} from 'react-redux';

import {PLACEHOLDER_EMPTY} from '../../../constants/date';
import {sanitizeArticleLink} from '../../../helpers/dashboardHelpers';
import {formatDateWithTimezone} from '../../../helpers/dateHelpers';
import {getArticleData} from '../../../selectors/article';

const PublishDates = () => {
  const article = useSelector(getArticleData);
  const {publishedAt, scheduledAt, title, url} = article;
  const date = scheduledAt && !publishedAt ? scheduledAt : publishedAt;

  if (!date) return null;

  return (
    <div className="article-date-inner">
      <div className="value">{formatDateWithTimezone(date) || PLACEHOLDER_EMPTY}</div>
      {publishedAt && (
        <div className="link-published">
          <a
            href={sanitizeArticleLink(url)}
            className="link-external link-external-icon-only"
            rel="noreferrer"
            target="_blank"
            title={`View published article: ${title}`}>
            <span className="sr-only">Published</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default PublishDates;
