import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isEqual} from 'lodash';

import {VALIDATE_FIELD_TAGS} from '../../constants/article';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';

import Tags from '../atoms/tags';
import {BREAKING_NEWS_TAGS} from '../../constants/article';
import {PERSON, TEAM, DIVISION, LEAGUE, SPORT, EVENT, LOCATION, TAG_TYPES} from '../../constants/tags';
import {getTagsByIds, getAllParentTags} from '../../apis/tags/tagsAPI';
import IconButton from '../atoms/iconButton';
import BetTags from './betTags';

const OTHER = 'other';
const TAGS = [
  {type: [PERSON], text: 'People', key: 'person'},
  {type: [TEAM], text: 'Team(s)', key: 'team'},
  {type: [DIVISION], text: 'Division', key: 'division'},
  {type: [LEAGUE, SPORT], text: 'Sport/League', key: 'sport', isRequired: true},
  {type: [EVENT], text: 'Event', key: 'event'},
  {type: [LOCATION], text: 'Location', key: 'location'},
  {type: OTHER, text: 'Other', key: 'other'},
];

const TAG_STYLES = {
  control: (styles) => ({...styles, border: 'none'}),
  indicatorsContainer: (styles) => ({...styles, display: 'none'}),
  valueContainer: (styles) => ({...styles, padding: '0'}),
  multiValue: (styles) => ({
    ...styles,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    borderRadius: '3rem',
    backgroundColor: '#2a2a2a',
    color: '#fff',
  }),
  multiValueLabel: (styles) => ({...styles, color: '#fff'}),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#f82a2a',
      cursor: 'pointer',
    },
  }),
};

function ArticleTags({betTags, filterBetTags, hideBetTags, onBetTagSelect, onTagSelect, noAds, selected, toggleHideBetTags}) {
  const [tags, setTags] = useState([]);
  const [suggestion, setSuggestion] = useState(null);
  const {error, removeError} = useGlobalNotificationsError();
  const isTagsError = error && error[VALIDATE_FIELD_TAGS];

  useEffect(() => {
    const isLoaded = isEqual(
      selected,
      tags.map((tag) => tag.id.toString())
    );
    if (selected.length && !isLoaded) {
      // Load tags
      getTagsByIds(selected).then(setTags);
    }
  }, [selected]);

  const notAlreadyInTags = (tag) => !tags.map(({id}) => id).includes(tag.id);

  const loadSuggestions = (newTags) => {
    const newTag = newTags.filter(notAlreadyInTags)[0];
    getAllParentTags(newTag).then((parents) => {
      const parentTagsNotAlreadyAdded = parents.filter(notAlreadyInTags);
      if (parentTagsNotAlreadyAdded.length) {
        setSuggestion(parents.filter(notAlreadyInTags));
      }
    });
  };

  const updateTags = (list) => {
    const listIds = list.map((tag) => tag.id);
    setTags(list);
    onTagSelect(listIds);

    const primaryBetTag = betTags && listIds.includes(betTags[0]) ? betTags[0] : null;
    const filteredBetTags = filterBetTags && filterBetTags(list);
    onBetTagSelect && onBetTagSelect(primaryBetTag, filteredBetTags);
  };

  const handleInsert = (tags) => {
    removeError(VALIDATE_FIELD_TAGS);
    loadSuggestions(tags);
    updateTags(tags);
  };

  const handleRemove = (type, left) => {
    const list = tags
      .filter((tag) => {
        return type === OTHER ? TAG_TYPES.includes(tag.type) : !type.includes(tag.type);
      })
      .concat(left);
    updateTags(list);
  };

  const getTag = ({type, key, text, isRequired}) => {
    const tagsList = tags.filter((tag) => {
      return type === OTHER ? !TAG_TYPES.includes(tag.type) && !BREAKING_NEWS_TAGS.includes(tag.id.toString()) : type.includes(tag.type);
    });

    return (
      <div key={key} className={classnames('row', `tags-search-${key}`, {'is-required': isRequired}, {'is-empty': !tagsList.length})}>
        <Tags
          value={tagsList}
          onSelect={(tags) => handleRemove(type, tags)}
          label={text}
          isRequired={isRequired}
          isSearchable={false}
          menuIsOpen={false}
          cacheOptions={false}
          placeholder="--"
          styles={TAG_STYLES}
          toggleHideBetTags={toggleHideBetTags}
          hideBetTags={hideBetTags}
          onBetTagSelect={onBetTagSelect}
        />
      </div>
    );
  };

  const getSuggestion = () => {
    if (!suggestion) return null;

    const handleAdd = (newTags) => {
      const leftToSuggest = suggestion.filter((tag) => !newTags.includes(tag));
      updateTags([...tags, ...newTags]);
      setSuggestion(leftToSuggest.length ? leftToSuggest : null);
    };

    return (
      <div className="row tags-suggestions">
        <div className="header">
          <span>Suggested Tags ({suggestion.length})</span>
          <button onClick={() => handleAdd(suggestion)}>ADD ALL</button>
        </div>
        <div className="list">
          {suggestion.map((tag) => {
            return (
              <IconButton key={tag.permalink} className="suggestion-tag" size="xsmall" icon="add" inline={false} onClick={() => handleAdd([tag])}>
                {tag.name}
              </IconButton>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row tags-search">
        <Tags
          value={tags}
          onSelect={handleInsert}
          label="What Is Your Article About?"
          isRequired={true}
          placeholder="Add a league, team or person"
          controlShouldRenderValue={false}
          hasError={isTagsError}
          toggleHideBetTags={toggleHideBetTags}
          hideBetTags={hideBetTags}
          onBetTagSelect={onBetTagSelect}
          noOptionsMessage={() => 'Search tags by name'}>
          <div className="note">
            <em>Pro Tip: Try adding tags to each of the categories below to improve the SEO of your article</em>
          </div>
        </Tags>
      </div>
      {getSuggestion()}
      <div className={classnames('tag-groups', {'tag-groups-error': isTagsError})}>{TAGS.map(getTag)}</div>
      <BetTags
        tags={tags}
        onTagSelect={onBetTagSelect}
        selectedBetTags={betTags}
        hideBetTags={hideBetTags}
        noAds={noAds}
        toggleHideBetTags={toggleHideBetTags}
      />
    </>
  );
}

ArticleTags.defaultProps = {
  selected: [],
  onTagSelect: () => {},
};

ArticleTags.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number),
  onTagSelect: PropTypes.func.isRequired,
};

export default ArticleTags;
