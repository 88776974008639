import {addDiffClass} from '../difference';

/**
 * Displays an Infogram
 * @see example {@link https://bleacherreport.com/articles/2645285 2645285}
 * and reference {@link https://github.com/br/nodereport/blob/fe1c563/src/components/atoms/infogramEmbed.js nodereport}
 */

export default class Infogram {
  constructor({data, readOnly}) {
    this.data = data;
    this.embed = '';
    this.readOnly = readOnly;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  render() {
    if (!this.data.id) {
      return this.embed;
    }

    const scriptId = 'infogram-async';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.src = 'https://e.infogr.am/js/dist/embed-loader-min.js';
      script.key = scriptId;
      script.id = scriptId;
      document.body.appendChild(script);
    }

    const container = document.createElement('div');
    container.classList.add('media-container', 'media-container-embed', 'media-container-infogram', 'infogram-embed');
    container.setAttribute('data-id', this.data.id);
    container.setAttribute('data-type', 'interactive');
    addDiffClass(container, this.data);

    this.embed = container;
    return this.embed;
  }

  save() {
    return this.data;
  }
}
