import {useSelector, useDispatch} from 'react-redux';

import {MEDIA_TYPE_IMAGE} from '../../constants/media';
import {selectImageSearch, selectVideoSearch, selectImages} from '../../selectors/media';
import {fetchImages, fetchVideos, clearMedia} from '../media';

export function useSearch(type) {
  const dispatch = useDispatch();

  const imgSearch = useSelector(selectImageSearch);
  const vidSearch = useSelector(selectVideoSearch);

  const isImage = type === MEDIA_TYPE_IMAGE;
  return {
    query: isImage ? imgSearch : vidSearch,
    makeSearch: (query) => {
      return dispatch(isImage ? fetchImages(query, 0) : fetchVideos(query, 50));
    },
    makeSearchClear: () => dispatch(clearMedia(type)),
  };
}

export function useImageSource(source) {
  const images = useSelector(selectImages);

  return {
    images: source === null ? images : images.filter((image) => image.source === source),
  };
}
