import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {BR} from '../../endpoints';
import {ACCESS_TOKEN} from '../../constants/user';
import PermissionDenied from './permissionDenied';
import PageTitle from '../atoms/pageTitle';
import Loading from '../atoms/loading';

const ArticlePreview = () => {
  const {id} = useParams(); // get the :id param from react router
  const [previewData, setPreviewData] = useState(null);
  const jwt = localStorage.getItem(ACCESS_TOKEN);
  if (!jwt) {
    return (
      <PermissionDenied title="Article Preview">
        <p>Your credentials were not found. Please try logging in again.</p>
      </PermissionDenied>
    );
  }

  useEffect(() => {
    async function fetchAndSetPreviewData(id, jwt) {
      const response = await fetch(BR.articlePreview(id), {headers: {'br-jwt': jwt}});
      const text = await response.text();
      return setPreviewData(text.replace('<head>', `<head><base href="https://${BR.host}" />`));
    }
    fetchAndSetPreviewData(id, jwt);
  }, []);

  if (!previewData) return <Loading />;

  const titleMatch = previewData.match(/<h1>(.+?)<\/h1>/);
  const title = titleMatch ? titleMatch[1] : 'UNKNOWN TITLE';
  return (
    <section className="organism article-preview">
      <PageTitle title={`Article Preview: "${title}"`} />
      {/* NOTE: This implies a lot of trust in our writers and editors! */}
      <iframe
        srcDoc={previewData}
        style={{
          height: '100vh',
          width: '100vw',
          border: 0,
        }}
      />
    </section>
  );
};

export default ArticlePreview;
