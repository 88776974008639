import React from 'react';
import PropTypes from 'prop-types';

import SidebarDocument from '../atoms/versions/sidebarDocument';
import SidebarMetadata from '../atoms/versions/sidebarMetadata';

const VersionsSidebar = ({added, removed, textChanged}) => {
  return (
    <>
      <div className="molecule versions-sidebar">
        <SidebarDocument textChanged={textChanged} />
        <SidebarMetadata added={added} removed={removed} />
      </div>
    </>
  );
};

VersionsSidebar.propTypes = {
  added: PropTypes.shape({}),
  removed: PropTypes.shape({}),
  textChanged: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
};

export default VersionsSidebar;
