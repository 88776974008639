import {createSelector} from 'reselect';

const versionsState = (state) => state.versions;

export const selectVersions = createSelector([versionsState], (state) => state.versions);

export const selectLoading = createSelector([versionsState], (state) => state.isLoading);

export const selectError = createSelector([versionsState], (state) => state.error);

export const selectCurrentVersion = createSelector([versionsState], (state) => state.currentVersion);

export const selectActiveVersion = createSelector([versionsState], (state) => state.activeVersion);

export const selectCompareVersion = createSelector([versionsState], (state) => state.compareVersion);

export const selectIndexes = createSelector([versionsState], (state) => ({
  activeIndex: state.activeVersion ? state.activeVersion.id : null,
  currentIndex: state.currentVersion ? state.currentVersion.id : null,
  compareIndex: state.compareVersion ? state.compareVersion.id : null,
}));

export const selectContents = createSelector([versionsState], (state) => ({
  activeContent: state.activeVersion ? state.activeVersion.content : null,
  currentContent: state.currentVersion ? state.currentVersion.content : null,
  compareContent: state.compareVersion ? state.compareVersion.content : null,
}));

export const selectActiveSlideNumber = createSelector([versionsState], (state) => state.activeSlideNumber);

export const selectChangedSlides = createSelector([versionsState], (state) => state.changedSlides);

export const selectActiveSlide = createSelector([versionsState], (state) => {
  const getSlideFor = (src) => {
    return src.content.elements.find((slide) => slide.slideNumber === state.activeSlideNumber) || null;
  };
  return {
    activeVersionSlide: state.activeVersion ? getSlideFor(state.activeVersion) : null,
    currentVersionSlide: state.currentVersion ? getSlideFor(state.currentVersion) : null,
    compareVersionSlide: state.compareVersion ? getSlideFor(state.compareVersion) : null,
  };
});

export const selectShowCompareView = createSelector([versionsState], (state) => state.showCompareView);

export const selectShowChanges = createSelector([versionsState], (state) => state.showChanges);

export const selectShowUnchangedSlides = createSelector([versionsState], (state) => state.showUnchangedSlides);
