import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {copyArticleLink, makeArticleDeleted, makeArticleUnscheduled} from '../../../actions/dashboard';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_DELETE_CONFIRM, PROMPT_UNSCHEDULE} from '../../../constants/prompt';
import {BUTTON_PREVIEW_LABEL, BUTTON_PREVIEW_TITLE} from '../../../constants/ui';
import {
  canDelete,
  canPublish,
  canViewVersionHistory,
  getArticleType,
  getArticleUrl,
  getArticleUrlAbsolute,
  getArticleUrlPreview,
  getArticleUrlVersions,
  openPreview,
} from '../../../helpers/articleHelpers';
import {sanitizeArticleLink} from '../../../helpers/dashboardHelpers';
import {isFutureDate} from '../../../helpers/dateHelpers';
import {prompt} from '../../../helpers/promptHelper';
import {removeRecentArticle} from '../../../helpers/recentArticlesHelper';
import {articleDashboard} from '../../../utils/types';

import ButtonMenu from '../buttonMenu';

const ArticleToolbar = ({article}) => {
  const dispatch = useDispatch();
  const {authorGatekeeperId, id, isBreakingNews, scheduledAt, title, url} = article;
  const type = getArticleType(article);
  const showDelete = canDelete(isBreakingNews);
  const showUnschedule = canPublish(authorGatekeeperId) && scheduledAt && isFutureDate(scheduledAt);
  const showVersionHistory = canViewVersionHistory(authorGatekeeperId, isBreakingNews);

  const handleDeleteArticle = (id, title) => {
    prompt(PROMPT_DELETE_CONFIRM)
      .withTexts(`Are you sure you want to delete this ${getArticleType(article)}?`, title, PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => {
        dispatch(makeArticleDeleted(id));
        removeRecentArticle(id);
      });
  };

  const handleCopyLink = (url, event, id, isEditLink = false) => {
    event.preventDefault();
    navigator.clipboard.writeText(sanitizeArticleLink(url));
    dispatch(copyArticleLink(id, isEditLink));
  };

  const handleUnschedule = (id) => {
    prompt(PROMPT_UNSCHEDULE)
      .withTexts(`Are you sure you want to unschedule this ${getArticleType(article)}?`, title, PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => dispatch(makeArticleUnscheduled(id)));
  };

  const handlePreviewLink = (id) => {
    return openPreview(id, getArticleUrlPreview(article));
  };

  if (!article) return false;

  return (
    <>
      <ButtonMenu icon="more" menuAlignment="right" classButton="button--basic" text="More…" textAria={`Article Menu for '${title}'`}>
        <ul>
          <li>
            <a className="link-preview" onClick={handlePreviewLink(id)} title={BUTTON_PREVIEW_TITLE}>
              {BUTTON_PREVIEW_LABEL} {type}
            </a>
          </li>
          <li>
            <a href={sanitizeArticleLink(url)} className="link-copy link-copy--published" onClick={(e) => handleCopyLink(url, e, id)}>
              Copy {type} published url
            </a>
          </li>
          <li>
            <a
              href={getArticleUrl(article)}
              className="link-copy link-copy--edit"
              onClick={(e) => handleCopyLink(getArticleUrlAbsolute(article), e, id, true)}>
              Copy {type} edit url
            </a>
          </li>
          {showVersionHistory && (
            <li>
              <a href={getArticleUrlVersions(article)} className="link-versions">
                View version history
              </a>
            </li>
          )}
          {showUnschedule && (
            <li>
              <Link to="#" className="link-unschedule" onClick={() => handleUnschedule(id)}>
                Unschedule
              </Link>
            </li>
          )}
          {showDelete && (
            <li>
              <Link to="#" className="link-delete" onClick={() => handleDeleteArticle(id, title)}>
                Delete {type}
              </Link>
            </li>
          )}
        </ul>
      </ButtonMenu>
    </>
  );
};

ArticleToolbar.propTypes = {
  article: articleDashboard.isRequired,
};

export default ArticleToolbar;
