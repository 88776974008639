import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import {Tooltip} from 'react-tooltip';

import {BR} from '../../../endpoints';
import {PLACEHOLDER_EMPTY} from '../../../constants/text';
import {formatDate, timeAgo, timeAgoDifference} from '../../../helpers/dateHelpers';
import {textChangedPercentage} from '../../../helpers/versionHelpers';
import {selectIndividual} from '../../../selectors/editorStats';
import ArticleMoreMenu from './articleMoreMenu';

const EditorStatsArticleRow = ({index}) => {
  const individual = useSelector(selectIndividual);
  const row = individual[index];

  const {
    article: {id, renderStrategy: articleType, title},
    primaryQcEdit,
  } = row;
  const {startTime: start, endTime: end, lastEdit: last, textChangePercentage: changed} = primaryQcEdit || {};
  const lastEdit = last ? timeAgo(last) : PLACEHOLDER_EMPTY;
  const percentageChanged = changed ? textChangedPercentage(changed) : PLACEHOLDER_EMPTY;

  // TODO: temporaily using the article ID as the permalink `BR.articlePublished(id)` until the BE returns permalink with the ES payload
  return (
    <div className={classnames('table-row', `article-type-${articleType}`, {odd: index % 2 !== 0})} key={`row-${id}`}>
      <div className="table-data table-data-title">
        <a href={BR.articlePublished(id)} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </div>
      <div className="table-data">{lastEdit}</div>
      <div className="table-data">
        {!start && PLACEHOLDER_EMPTY}
        {start && (
          <>
            <div id={`tooltip-duration-${id}`} className="tooltip-link" data-tooltip-float="true">
              {timeAgoDifference(start, end, 'short', true)}
            </div>
            <Tooltip anchorSelect={`#tooltip-duration-${id}`}>
              {!end && <div className="row row-right">In progress...</div>}
              <div className="row row-right">
                <strong className="label">Start:</strong> {formatDate(start)}
              </div>
              {end && (
                <div className="row row-right">
                  <strong className="label">End:</strong> {formatDate(end)}
                </div>
              )}
            </Tooltip>
          </>
        )}
      </div>
      <div className="table-data">{percentageChanged}</div>
      <div className="table-data table-data-more">
        <ArticleMoreMenu article={row.article} />
      </div>
    </div>
  );
};

export default EditorStatsArticleRow;
