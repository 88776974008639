import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {diffArrays, diffChars, diffSentences, diffWords} from 'diff';

import {SEPARATOR} from './versions/sidebarMetadata';

export const DIFF_TYPE_ARRAY = 'array';
export const DIFF_TYPE_CHARACTERS = 'characters';
export const DIFF_TYPE_IMAGES = 'images';
export const DIFF_TYPE_SENTENCES = 'sentences';
export const DIFF_TYPE_WORDS = 'words';
export const DIFF_TYPE_WORDS_CONSECUTIVE = 'wordsConsecutive';
export const DIFF_TYPES = [
  DIFF_TYPE_ARRAY,
  DIFF_TYPE_CHARACTERS,
  DIFF_TYPE_IMAGES,
  DIFF_TYPE_SENTENCES,
  DIFF_TYPE_WORDS,
  DIFF_TYPE_WORDS_CONSECUTIVE,
];

export const DIFF_TYPE_DEFAULT = DIFF_TYPE_WORDS_CONSECUTIVE;

function Differentiator({source, target, diffType}) {
  let isDiff = false;
  let output = '';

  const getChangeClass = (added, removed) => {
    return classnames({
      'ce-diff__added': added,
      'ce-diff__removed': removed,
    });
  };

  const getDiffOutput = ({added, removed, value}, index, withSeparatorStart = false, withSeparatorEnd = false) => {
    isDiff = true;
    const val = typeof value === 'string' ? value : value.join(SEPARATOR);

    if (withSeparatorStart || withSeparatorEnd) {
      return (
        <span key={index}>
          {withSeparatorStart && SEPARATOR}
          <span className={getChangeClass(added, removed)}>{val}</span>
          {withSeparatorEnd && SEPARATOR}
        </span>
      );
    }

    return (
      <span key={index} className={getChangeClass(added, removed)}>
        {val}
      </span>
    );
  };

  const getDiffOutputImage = ({added, removed, value}, index) => {
    isDiff = true;
    return <img key={index} src={value} className={classnames(getChangeClass(added, removed))} />;
  };

  const diffWordsConsecutive = (source, target) => {
    // Leverage diffWords by mapping its return value to our desired return value format
    // NOTE: Objects returned by the 'diff' library functions include a 'count' property but we are not preserving it
    // here because we do not use/require it
    return diffWords(source, target)
      .filter(({value}) => value.trim())
      .map(({added, removed, value}, index, diffWordsResult) => {
        const shouldCheckForConsecutiveAddition = added && index < diffWordsResult.length - 1;
        const shouldCheckForConsecutiveRemoval = removed && index < diffWordsResult.length - 2;
        const shouldCheckIfConsecutive = shouldCheckForConsecutiveAddition || shouldCheckForConsecutiveRemoval;

        let newValue = value.trim();

        // Return here if !added && !removed, or if added || removed and index is the index of the last added/removed entry in diffWordsResult
        if (!shouldCheckIfConsecutive) {
          const isFirstIndex = index === 0;
          const isLastIndex = index === diffWordsResult.length - 1;
          const hasLeadingSpace = /^\s/.test(value);

          return {
            added,
            removed,
            value: !added && !removed && !isFirstIndex && !isLastIndex && !hasLeadingSpace ? ` ${value}` : value,
          };
        }

        // Build new diff entries, checking for consecutive words
        while (diffWordsResult.find((entry) => (added ? entry?.added : entry?.removed))) {
          // Delete the current entry from diffWordsResult so we do not repeat it
          delete diffWordsResult[index];

          // Get the index of the next added/removed entry
          const nextIndex = diffWordsResult.findIndex((entry) => (added ? entry?.added : entry?.removed));

          // Exit the while loop if there's no nextIndex
          if (nextIndex === -1) break;

          // Get the value of the next added/removed entry using nextIndex
          const {value: nextValue} = diffWordsResult[nextIndex];

          // Append nextValue to newValue to form a temporary search phrase
          const tempValue = `${newValue} ${nextValue.trim()}`;

          // Check for 'newValue nextValue' in source if removed, target if added
          const isConsecutive = removed ? source.includes(tempValue) : target.includes(tempValue);

          // Return here if appending nextValue to newValue made tempValue not found in source/target
          if (!isConsecutive) return {added, removed, value: newValue};

          // We know that tempValue is consecutive, so assign it to newValue
          newValue = tempValue;

          // Delete the next added/removed entry since it has already been appended to newValue
          delete diffWordsResult[nextIndex];
        }

        // Return here if !isConsecutive is never true within the while loop
        return {added, removed, value: newValue};
      });
  };

  if (diffType === DIFF_TYPE_ARRAY) {
    const diffArray = diffArrays(source, target);
    output = diffArray.map(({added, removed, value}, index) => {
      const addSeparatorStart = index > 0;
      const addSeparatorEnd = index < diffArray.length - 1;
      if (added || removed) return getDiffOutput({added, removed, value}, index, addSeparatorStart, addSeparatorEnd);
      return value.join(SEPARATOR);
    });
  } else if (diffType === DIFF_TYPE_CHARACTERS) {
    output = diffChars(source, target).map(({added, removed, value}, index) => {
      if (added || removed) return getDiffOutput({added, removed, value}, index);
      return value;
    });
  } else if (diffType === DIFF_TYPE_IMAGES) {
    output = diffSentences(source, target).map(({added, removed, value}, index) => {
      if (added || removed) return getDiffOutputImage({added, removed, value}, index);
      return value;
    });
  } else if (diffType === DIFF_TYPE_SENTENCES) {
    output = diffSentences(source, target).map(({added, removed, value}, index) => {
      if (added || removed) return getDiffOutput({added, removed, value}, index);
      return value;
    });
  } else if (diffType === DIFF_TYPE_WORDS) {
    output = diffWords(source, target).map(({added, removed, value}, index) => {
      if (added || removed) return getDiffOutput({added, removed, value}, index);
      return value;
    });
  } else if (diffType === DIFF_TYPE_WORDS_CONSECUTIVE) {
    output = diffWordsConsecutive(source, target).map((result, index) => {
      const {added, removed, value} = result;
      if (added || removed) return getDiffOutput({added, removed, value}, index);
      return value;
    });
  }

  if (!isDiff) return output;

  return <span className={`differentiator diff-${diffType}`}>{output}</span>;
}

Differentiator.defaultProps = {
  diffType: DIFF_TYPE_DEFAULT,
};

Differentiator.propTypes = {
  diffType: PropTypes.oneOf(DIFF_TYPES),
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Differentiator;
