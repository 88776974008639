import logger from '../../../../logger';

import {addDiffClass} from '../difference';

import {MEDIA_TYPE_VIDEO} from '../../../../constants/media';
import {EVENT_MEDIA_SEARCH, EVENT_MEDIA_EDIT} from '../../../../constants/events';

import ToolEventObserver from '../toolEventObserver';

/**
 * Displays a BR video
 * @see example {@link https://bleacherreport.com/articles/2888619 2888619}
 * and {@link https://bleacherreport.com/articles/2637040 2637040}
 * and reference {@link https://github.com/br/nodereport/blob/fe1c563/src/components/atoms/videoPlayer.js nodereport}
 */
require('./index.css');

import {VIDEO_PLAYER_KEY} from '../../../../constants/media';
import {
  EDITOR_SETTINGS_EDIT_ICON,
  EDITOR_SETTINGS_EDIT_LABEL,
  EDITOR_SETTINGS_REPLACE_ICON,
  EDITOR_SETTINGS_REPLACE_LABEL,
} from '../../../../constants/editor';

export default class Video {
  constructor({data, api, readOnly}) {
    this.api = api;
    this.data = data;
    this.readOnly = readOnly;
    this.video = null;
    this.media = null;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  renderSettings() {
    return [
      {
        icon: EDITOR_SETTINGS_EDIT_ICON,
        label: EDITOR_SETTINGS_EDIT_LABEL,
        onActivate: () => {
          const {videoId: id, caption, title, description, videoTags, startSeconds} = this.data.metadata;

          ToolEventObserver.trigger(EVENT_MEDIA_EDIT, {
            editorBlockIndex: this.api.blocks.getCurrentBlockIndex(),
            mediaType: MEDIA_TYPE_VIDEO,
            media: {id, caption, title, description, videoTags, startSeconds},
          });
        },
      },
      {
        icon: EDITOR_SETTINGS_REPLACE_ICON,
        label: EDITOR_SETTINGS_REPLACE_LABEL,
        onActivate: () => {
          ToolEventObserver.trigger(EVENT_MEDIA_SEARCH, {
            editorBlockIndex: this.api.blocks.getCurrentBlockIndex(),
            mediaType: MEDIA_TYPE_VIDEO,
            shouldReplace: true,
          });
        },
      },
    ];
  }

  render() {
    const {metadata} = this.data;

    if (!metadata.videoId) return this.video;

    const container = this.renderVideoContainer();
    const video = this.renderVideo();
    container.appendChild(video);
    addDiffClass(container, this.data);

    if (metadata.caption) {
      const caption = this.renderCaption(metadata.caption);
      container.appendChild(caption);
    }
    this.video = container;

    this.getVideo()
      .then((media) => this.setVideoPlaceholder(video, media))
      .catch(() => this.setVideoError(video));

    this.api.listeners.on(
      this.video,
      'click',
      () => {
        if (!this.isVideoPlayerLoaded(video)) {
          this.showVideoPlayer(video);
        }
      },
      false
    );

    return this.video;
  }

  renderVideoContainer() {
    const container = document.createElement('div');
    container.classList.add('media-container', 'media-container-video');
    return container;
  }

  renderVideo() {
    const {videoId} = this.data.metadata;
    const video = document.createElement('div');
    video.classList.add('video');
    video.setAttribute('id', `video-${videoId}`);
    return video;
  }

  renderCaption(text) {
    const caption = document.createElement('i');
    caption.innerHTML = text || '';
    return caption;
  }

  setVideoPlaceholder(video, media) {
    video.innerHTML = `<img src="${media.thumbnail_url}" width="100%" class="video-placeholder__image"/><svg class="video-placeholder__play" viewBox="0 0 200 200"><circle cx="100" cy="100" r="90" fill="none" stroke-width="15" stroke="#fff"/><polygon points="70, 55 70, 145 145, 100" fill="#fff"/></svg>`; // eslint-disable-line max-len
    video.classList.add('--placeholder');
  }

  setVideoError(video) {
    const {videoId} = this.data.metadata;
    video.innerHTML = `Video load error: ${videoId}`;
    video.classList.add('--error');
  }

  isVideoPlayerLoaded(video) {
    return video.classList.contains('--loaded') || video.classList.contains('--error');
  }

  showVideoPlayer(video) {
    const player = new global.bitmovin.player.Player(video, {key: VIDEO_PLAYER_KEY});
    const {startSeconds} = this.data.metadata;

    player
      .load({
        title: this.media.title,
        poster: this.media.thumbnail_url,
        hls: this.media.hls,
        progressive: this.media.mp4,
      })
      .then(() => {
        video.classList.remove('--placeholder');
        video.classList.add('--loaded');
        if (startSeconds) player.seek(startSeconds);
        player.play();
      });
  }

  getVideo() {
    const {videoId} = this.data.metadata;
    // todo : #refactoring - CTT-144 - use getVideo() function from the apis/media/mediaAPI
    const videoUrl = `https://vid-origin.bleacherreport.com/videos/${videoId}`;
    return fetch(videoUrl)
      .then((resp) => resp.json())
      .then((data) => {
        this.media = data;
        return data;
      })
      .catch((err) => {
        logger.error(`Error — could not load url: ${videoUrl}`, err);
        throw err;
      });
  }

  save() {
    return this.data;
  }
}
