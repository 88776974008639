import * as types from '../constants/action-types';

export const initialState = {
  // [User Gatekeeper ID] -> [User Profile]
  profiles: {},
  error: false,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case types.USERS_PROFILES: {
      const {profiles, error} = action.payload;
      return {
        ...state,
        profiles: {...state.profiles, ...profiles},
        error,
      };
    }
    default: {
      return state;
    }
  }
};

export default users;
