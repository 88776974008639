import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';
import {isEqual} from 'lodash';
import {Tooltip} from 'react-tooltip';

import {getTagsByIds} from '../../apis/tags/tagsAPI';
import {getIsReadOnly} from '../../selectors/article';

const BetTags = (props) => {
  const {hideBetTags, noAds, onTagSelect, selectedBetTags, toggleHideBetTags} = props;
  const isReadOnly = useSelector(getIsReadOnly);
  const [betTags, setBetTags] = useState([]);
  const isDisabled = isReadOnly || noAds;

  useEffect(() => {
    const isLoaded = isEqual(
      selectedBetTags,
      betTags.map((tag) => tag.id.toString())
    );
    if (!selectedBetTags.length) {
      setBetTags([]);
    }
    if (selectedBetTags && selectedBetTags.length && !isLoaded) {
      // Load tags
      getTagsByIds(selectedBetTags).then(setBetTags);
    }
  }, [selectedBetTags]);

  const updateTags = (primaryTag) => {
    onTagSelect(
      primaryTag.id,
      betTags.map((tag) => tag.id)
    );
  };

  return (
    <div className="betting-group">
      <h4>Betting Module</h4>
      {!hideBetTags && (
        <div className="bet-selector">
          <p className="note">Show upcoming bets for a single team tagged in the article or popular bets in the league.</p>
          <div className="row tags-search">
            <Select
              isDisabled={isDisabled}
              isMulti={false}
              value={betTags[0]}
              options={betTags}
              onChange={(primaryTag) => updateTags(primaryTag)}
              getOptionLabel={(tag) => tag.name}
              getOptionValue={(tag) => tag.id}
            />
          </div>
        </div>
      )}
      <div className={classnames('control', 'control-bet-tags', {disabled: isDisabled})}>
        <label
          id="remove-no-ads-tag-tooltip"
          data-tooltip-float="true"
          data-tooltip-content={noAds ? 'To show Betting Module, remove the No Ads tag' : ''}>
          <input
            type="checkbox"
            checked={hideBetTags}
            disabled={isDisabled}
            onChange={() => {
              toggleHideBetTags(!hideBetTags);
            }}
          />
          Hide Bet Module
        </label>
      </div>
      <Tooltip anchorSelect="#remove-no-ads-tag-tooltip" />
    </div>
  );
};

BetTags.defaultProps = {
  onTagSelect: () => {},
  selectedBetTags: '',
};

BetTags.propTypes = {
  hideBetTags: PropTypes.bool,
  noAds: PropTypes.bool,
  onTagSelect: PropTypes.func.isRequired,
  selectedBetTags: PropTypes.string,
  toggleHideBetTags: PropTypes.func,
};

export default BetTags;
