import {addDiffClass} from '../difference';
require('./index.css');

export default class Unsupported {
  constructor({data, readOnly}) {
    this.div = '';
    this.wrapper = null;
    this.data = data;
    this.readOnly = readOnly;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  render(type) {
    const container = document.createElement('div');
    container.classList.add('media-container');
    addDiffClass(container, this.data);

    const inner = document.createElement('div');
    inner.classList.add('unsupported');

    const textMain = document.createElement('div');
    textMain.classList.add('header');
    textMain.innerHTML = type ? `Unsupported Type: ${type}` : 'Unsupported Type';

    const textSub = document.createElement('div');
    textSub.classList.add('content');
    textSub.innerHTML = 'This media will not display in the tool but it will appear on the published article.';
    inner.appendChild(textMain);
    inner.appendChild(textSub);
    container.appendChild(inner);

    this.div = container;
    return this.div;
  }

  save() {
    return this.data;
  }
}
