import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getUsersProfiles} from '../../../apis/auth/gatekeeper-api';
import {getUserFromProfile} from '../../../helpers/userHelpers';
import {getEditorPrimary, getStatus} from '../../../selectors/article';
import {STATUS_DRAFT} from '../../../constants/article';
import User from '../user';
import UserEditorButton from './userEditorButton';

const UserEditor = () => {
  const [editorPrimary, setEditorPrimary] = useState(null);
  const editorPrimaryId = useSelector(getEditorPrimary);
  const status = useSelector(getStatus);

  useEffect(() => {
    getUsersProfiles([editorPrimaryId]).then(({users, error}) => {
      // Failed calls to getUsersProfiles return an object with the format {error: true}
      // The actual errors are caught and logged from within a .catch block in the gatekeeper api
      if (error) return;
      setEditorPrimary(getUserFromProfile(users[0]));
    });
  }, [editorPrimaryId]);

  if (!status || status === STATUS_DRAFT) return null;
  return (
    <div className="atom group user-editor">
      <h3>QC Editor</h3>
      <div className="group-content">
        <User user={editorPrimary} usePlaceholder={true} />
        <UserEditorButton />
      </div>
    </div>
  );
};

export default UserEditor;
