import {SEARCH_TYPES} from '../../src/constants/search';

/*
 * Returns a string of query parameters, appropriate to append to a URL. String may be empty.
 */
export const generateQueryString = (searchData) => {
  const {cursor, endDate, limit, offset, searchInput, searchType, startDate} = searchData;
  const queryObj = {};

  // check for query params
  if (offset) queryObj.offset = offset;
  if (cursor) queryObj.cursor = cursor;
  if (limit) queryObj.limit = limit;

  if (searchInput?.length) {
    switch (searchType) {
      case SEARCH_TYPES.TITLE: {
        queryObj.title = searchInput;
        break;
      }
      case SEARCH_TYPES.TAG_NAME: {
        queryObj.permalink = searchInput.toLowerCase(); // 'NBA' returns error, 'nba' ok
        break;
      }
      case SEARCH_TYPES.EDITOR:
      case SEARCH_TYPES.WRITER: {
        queryObj.search_input = searchInput;
        queryObj.search_type = searchType;
        break;
      }
      default:
      // do nothing
    }
  }

  if (startDate) queryObj.date_begin = startDate;
  if (endDate) queryObj.date_end = endDate;

  // note: there is no attempt to escape/sanitize/protect the characters within the param name nor the value
  const queries = Object.entries(queryObj).map(([param, value]) => `${param}=${value}`);
  if (queries.length) {
    return `?${queries.join('&')}`;
  }
  return '';
};
