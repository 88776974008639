import {STATUS_READY_FOR_PUBLISH, STATUS_PUBLISHED} from '../constants/article';
import {canPublish, canScheduleBreakingNews, isAdmin} from './articleHelpers';
import {isCurrentUserTheAuthor} from './softlockHelpers';

// TODO: would like to move many of the permissions based helpers in ./articleHelpers to this file ahead of the larger permissions/roles refactor

/**
 * Can this article be scheduled for publish by this user?
 * - only allow schedule for articles marked as ready when a user has publish permissions
 * - exception for BNT writers who can schedule BNT articles from any status
 *
 * @param {String} status article status (ex./ draft, published)
 * @param {String} isBreakingNews is this breaking news?
 * @param {String} authorId author gatekeeper id
 * @returns {Boolean} can this article be scheduled by this user?
 */
export const canSchedulePublish = (authorId, status, isBreakingNews = false) => {
  if (!authorId || !status) return false;
  if (status === STATUS_PUBLISHED) return false;
  if (status === STATUS_READY_FOR_PUBLISH && isCurrentUserTheAuthor({authorGatekeeperId: authorId})) return true;
  if (status === STATUS_READY_FOR_PUBLISH && canPublish(authorId)) return true;
  if (canScheduleBreakingNews(authorId, isBreakingNews)) return true;
  return false;
};

/**
 * Editor Stats
 * - roster page only visible to admins, editors can view their own page
 */
export const canAccessEditorStatsRoster = () => {
  return isAdmin();
};
