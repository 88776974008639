/* istanbul ignore file */

import 'core-js/stable';
import {Provider} from 'react-redux';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';

import store from './store/';

import AuthorizedWithSidebar, {Authorized} from '../src/components/layouts';
import GlobalNotificationsProvider from '../src/utils/notifications/globalNotificationsProvider';

import Article from '../src/components/organisms/article';
import ArticlePreview from '../src/components/organisms/articlePreview';
import ArticleRedirect from '../src/components/organisms/articleRedirect';
import ArticleVersions from '../src/components/organisms/articleVersions';
import Dashboard from '../src/components/organisms/dashboard';
import EditorStats from '../src/components/organisms/editorStats';
import EditorStatsIndividual from '../src/components/organisms/editorStatsIndividual';
import Error404 from './components/organisms/error404';
import Loading from '../src/components/atoms/loading';
import Login from '../src/components/organisms/login';
import Search from '../src/components/organisms/search';
import Slideshow from '../src/components/organisms/slideshow';
import ToolsDeleted from '../src/components/organisms/toolsDeleted';
import ToolsHelp from '../src/components/organisms/toolsHelp';
import ToolsRequest from '../src/components/organisms/toolsRequest';

/**
 * Adding error handling for block render() returning null. We are currently returning null
 * block that triggers our custom modal to add/enter content: media, iframe, statmilk
 * @see https://statmilk.atlassian.net/browse/CTT-940
 *
 * We've created an issue on the Editor.js github to try to allow block render() returning null
 * @see https://github.com/codex-team/editor.js/issues/2092
 *
 * And have a ticket on our backlog to create an Editor.js PR to fix this issue
 * @see https://statmilk.atlassian.net/browse/CTT-950
 */
window.onerror = (error) => {
  if (error.includes("Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'")) return true;
  return false;
};

// Wrapper for route component to optionally include layout
const RouteElement = ({element: Element, layout: Layout, elementProps = {}}) => {
  return Layout ? (
    <Layout>
      <Element {...elementProps} />
    </Layout>
  ) : (
    <Element {...elementProps} />
  );
};

// Need to use one of the new routers that supports the new react-router-dom data APIs
// in order to use the useBeforeUnload navigation blocking hook in src/hooks/usePrompt
// https://reactrouter.com/en/main/routers/picking-a-router
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" exact={true} element={<RouteElement element={Dashboard} layout={AuthorizedWithSidebar} />} />
      <Route path="articles/:type/:id/preview" element={<RouteElement element={ArticlePreview} layout={Authorized} />} />
      <Route path="articles/:type/:id/versions" element={<RouteElement element={ArticleVersions} layout={Authorized} />} />
      <Route path="articles/standard/:id" element={<RouteElement element={Article} layout={AuthorizedWithSidebar} />} />
      <Route path="articles/slideshow/:id" element={<RouteElement element={Slideshow} layout={AuthorizedWithSidebar} />} isSlideshow={true} />
      <Route path="article/:id" element={<RouteElement element={ArticleRedirect} />} />
      <Route path="preview/:id" element={<RouteElement element={ArticlePreview} layout={Authorized} />} />
      <Route path="search" element={<RouteElement element={Search} layout={AuthorizedWithSidebar} />} />
      <Route path="tools" element={<RouteElement element={Navigate} elementProps={{replace: true, to: '/tools/help'}} />} />
      <Route path="tools/editor-stats" element={<RouteElement element={EditorStats} layout={AuthorizedWithSidebar} />} />
      <Route path="tools/editor-stats/:id" element={<RouteElement element={EditorStatsIndividual} layout={AuthorizedWithSidebar} />} />
      <Route path="tools/request" exact={true} element={<RouteElement element={ToolsRequest} layout={AuthorizedWithSidebar} />} />
      <Route path="tools/deleted" exact={true} element={<RouteElement element={ToolsDeleted} layout={AuthorizedWithSidebar} />} />
      <Route path="tools/help" element={<RouteElement element={ToolsHelp} layout={AuthorizedWithSidebar} />} />
      <Route path="login" exact={true} element={<RouteElement element={Login} />} />
      <Route path="*" element={<RouteElement element={Error404} layout={AuthorizedWithSidebar} />} />
    </>
  )
);

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <GlobalNotificationsProvider>
        <Suspense fallback={<Loading />}>
          {/* Remove this opt-in future flag when we upgrade to v7: https://github.com/remix-run/react-router/releases/tag/react-router%406.13.0 */}
          <RouterProvider router={router} future={{v7_startTransition: true}} />
        </Suspense>
      </GlobalNotificationsProvider>
    </Provider>
  </ErrorBoundary>
);
