import {isEqual} from 'lodash';
import {getSlideThumbnail, isSlideshow} from './articleHelpers';

/**
 * Returns an array of changed slides (ids + slideNumbers)
 *
 * @param {Object} activeVersion active version
 * @param {Object} compareVersion compare version
 * @returns {Array} array output of changed slides (ids + slideNumbers)
 */
export const getChangedSlides = (activeVersion, compareVersion) => {
  if (!activeVersion) return null;
  const activeElements = activeVersion?.content.elements;
  const compareElements = compareVersion?.content.elements;
  if (!activeElements) return null;
  const indexes = [];
  const output = (i) => ({id: activeElements[i].id, slideNumber: activeElements[i].slideNumber});

  if (!compareElements) {
    // make the title slide the active slide if no comparison version (article only has 1 version)
    indexes.push(output(0));
  } else if (activeElements.length < compareElements.length) {
    // make no filmstrip slide active when slide was deleted
    indexes.push({id: -1, slideNumber: -1});
  } else {
    for (let i = 0; i < activeElements.length; i++) {
      if (!isEqual(activeElements[i], compareElements[i])) indexes.push(output(i));
    }
  }
  return indexes;
};

/**
 * Returns the active slide number based on what slides were changed
 *
 * @param {Array} changedSlides returned from getChangedSlides()
 * @returns {number} activeSlideNumber
 */
export const getActiveSlideNumber = (changedSlides) => {
  if (!changedSlides) return 0;
  if (changedSlides.length === 0) return 0;
  return changedSlides[0].slideNumber;
};

/**
 * Returns whether the current version was a slideshow slide added or deleted
 *
 * @param {Object} activeVersion active version
 * @param {Object} compareVersion compare version
 * @param {String} checkFor what type of slide action are we checking for? added or deleted (default)
 * @returns {Object || null} slide object, or null if not meet criteria for the checkFor type
 */
export const isVersionSlideAction = (activeVersion, compareVersion, checkFor = 'deleted') => {
  if (!activeVersion || !compareVersion) return null;
  if (!isSlideshow(activeVersion.content)) return null;
  const activeElements = activeVersion.content.elements;
  const compareElements = compareVersion.content.elements;
  const activeSlideIds = activeElements.map((slide) => slide.id);
  const compareSlideIds = compareElements.map((slide) => slide.id);

  if (checkFor === 'added') {
    if (activeElements.length <= compareElements.length) return null;
    const addedSlideId = activeSlideIds.filter((id) => !compareSlideIds.includes(id))[0];
    const addedSlide = activeElements.filter((slide) => slide.id === addedSlideId)[0];
    return addedSlide;
  }

  if (activeElements.length >= compareElements.length) return null;
  const deletedSlideId = compareSlideIds.filter((id) => !activeSlideIds.includes(id))[0];
  const deletedSlide = compareElements.filter((slide) => slide.id === deletedSlideId)[0];
  return {...deletedSlide, thumbnail: getSlideThumbnail(deletedSlide)};
};

/**
 * Returns textChanged float as a percentage
 *
 * @param {Number} textChanged float number 0 to 1 (up to 2 decimal place)
 * @returns {String} float number as percentage ex./ 0.32 --> 32%
 */
export const textChangedPercentage = (textChangedFloat) => {
  if (textChangedFloat === null || typeof textChangedFloat !== 'number') return null;
  return `${textChangedFloat * 100}%`;
};
