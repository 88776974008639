import {addMinutes, format, formatDistance, intervalToDuration, isAfter, isDate, isEqual, parse, parseISO, subMonths} from 'date-fns';
import {utcToZonedTime, format as formatWithTimezone, toDate} from 'date-fns-tz';
import {DATE_FORMAT_DATE_TIME, DATE_FORMAT_DATE_TIMEZONE, DATE_FORMAT_DAY, TIMEZONE} from '../constants/date';

export const formatDate = (dateTimeString, dateFormat = DATE_FORMAT_DATE_TIME) => {
  const dateString = !isDate(dateTimeString) ? parseISO(dateTimeString) : dateTimeString;
  return format(dateString, dateFormat);
};

/**
 * Converts a string date to a Date object.
 *
 * @param {string} date - The string representation of the date.
 * @param {string} [dateFormat=DATE_FORMAT_DAY] - The format of the date string. Defaults to 'yyyy-MM-dd'.
 * @returns {Date} The Date object representing the input string date.
 */
export const formatStringToDate = (date, dateFormat = DATE_FORMAT_DAY) => {
  return parse(date, dateFormat, new Date());
};

export const formatDateWithTimezone = (dateTimeString, dateFormat = DATE_FORMAT_DATE_TIMEZONE, timezone = TIMEZONE) => {
  const dateZoned = utcToZonedTime(parseISO(dateTimeString), timezone);
  return formatWithTimezone(dateZoned, dateFormat, {timeZone: timezone});
};

export const getCorrectedISOString = (date) => {
  const dateString = typeof date === 'object' ? date.toISOString() : date;
  return dateString.substr(0, 19).replace('T', ' ').concat('Z');
};

/**
 * How much time has passed between a date and now
 *
 * @param {String} dateTimeStringUTC datetimestamp
 * @returns {String} how much time has passed
 */
export const timeAgo = (dateTimeStringUTC) => {
  return formatDistance(parseISO(getCorrectedISOString(dateTimeStringUTC)), new Date(), {
    addSuffix: true,
  });
};

/**
 * How much time has passed between 2 dates?
 *
 * @param {String} start datetimestamp (required)
 * @param {String} end datetimestamp (optional, will use now if missing)
 * @param {String} format full (all attributes) or short (2 attributes max)
 * @param {Boolean} showIconInProgress should an icon display if the end date missing?
 * @returns {String} years/days/hours/minutes difference (excl)
 */
export const timeAgoDifference = (start, end, format = 'full', showIconInProgress = false) => {
  if (!start) return null;
  const endDate = end ? new Date(end) : new Date(); // if end date missing, use now
  const duration = intervalToDuration({start: new Date(start), end: endDate});

  const output = [];
  if (duration.years) output.push(`${duration.years} years`);
  if (duration.days) output.push(`${duration.days} days`);
  if (duration.hours) output.push(`${duration.hours} hours`);
  if (duration.minutes) output.push(`${duration.minutes} minutes`);
  if (duration.seconds) output.push(`${duration.seconds} seconds`);

  const outputText = format === 'short' ? output.slice(0, 2) : output;
  const durationString = `${outputText.join(', ')}${showIconInProgress && !end ? ' ⏲' : ''}`;
  return durationString;
};

/**
 * Converts date formatted as a string OR date object into the BR server format string
 * @param {string|Date} dateTimeString date for conversion
 * @returns {string}
 * @example
 *
 * formatDateForServer(new Date()) // 2021-12-11T07:00:00-05:00
 * formatDateForServer('2001-02-03 04:05:06') // 2001-02-03T04:05:06-05:00
 */
export const formatDateForServer = (dateTimeString) => {
  const estDate = toDate(dateTimeString);
  const date = formatWithTimezone(estDate, 'yyyy-MM-dd HH:mm:ssXXX', {timeZone: TIMEZONE});
  return date.split(' ').join('T');
};

/**
 * Checks whether a date/timestamp is in the future
 * @param {string|Date} date date for comparison
 * @returns {boolean}
 * @example
 * isFutureDate(new Date()) // 2021-12-11T07:00:00-05:00
 * isFutureDate('2001-02-03 04:05:06') // 2001-02-03T04:05:06-05:00
 */
export const isFutureDate = (datetime) => {
  const date = isDate(datetime) ? datetime : parseISO(datetime);
  const now = new Date();
  return isAfter(date, now);
};

/**
 * Get default schedule publish time, adjusted for default timezone
 * @returns {object}
 */
export const getSchedulePublishMinimumDate = () => {
  const now = utcToZonedTime(new Date(), TIMEZONE);
  return addMinutes(now, 10);
};

/**
 * Validate that the selected scheduled date is after the minimum schedule publish date
 * @param {Date} scheduledDate selected schedule date
 * @param {Date} minDate minimum schedule publish date
 * @returns {object}
 */
export const validateSelectedScheduledDate = (scheduledDate, minDate = getSchedulePublishMinimumDate()) => {
  // should be able to use prop minDate={scheduleMinimumDate} with DateTimePicker in
  // articlePubish.js & scheduleButton.js components, but that overrides
  // any manual validation that is done on the component and won't prevent
  // someone from changing PM to AM selecting a datetime that is less than the min date
  // @see https://github.com/wojtekmaj/react-datetime-picker/issues/39
  if (scheduledDate < minDate) return minDate;
  return scheduledDate;
};

/**
 * Check if the difference between two dates is less than or equal to the specified number of months
 *
 * @param {String} startDate The start date in yyyy-MM-dd format
 * @param {String} endDate The end date in yyyy-MM-dd format
 * @param {Number} numberOfMonths The number of months to compare the difference against
 * @returns {Boolean} Whether the difference between the two dates is less than or equal to the specified number of months
 */
export const isDateRangeLessThanOrEqualTo = (startDate, endDate, numberOfMonths) => {
  const parsedStartDate = parse(startDate, DATE_FORMAT_DAY, new Date());
  const parsedEndDate = parse(endDate, DATE_FORMAT_DAY, new Date());
  const diff = subMonths(parsedEndDate, numberOfMonths);
  if (isEqual(parsedStartDate, diff)) return true;
  return isAfter(parsedStartDate, diff);
};

/**
 * When you supply a date in format 'yyyy-MM-dd' to the Editor Stats date picker, it will
 * shift a day earlier because there is no timezone information
 *
 * @param {String} date date in `yyyy-MM-dd` format
 * @returns {Date} Date object day of month updated
 */
export const setDateValue = (date) => {
  const d = parse(date, DATE_FORMAT_DAY, new Date());
  d.setDate(d.getUTCDate());
  return d;
};
