import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import Editor from '../editor';
import Differentiator from '../differentiator';
import VersionsSidebar from '../../molecules/versionsSidebar';
import NotificationStableIds from './notificationStableIds';

import {jsonTransformForEditor} from '../../../helpers/jsonTransform';
import {selectActiveVersion, selectCompareVersion, selectCurrentVersion, selectShowChanges, selectShowCompareView} from '../../../selectors/versions';
import {setDifferenceLegacyIds, setDifferenceStableIds} from '../../../utils/articleDifference';
import {articleHasStableEditorIds} from '../../../helpers/articleHelpers';

function VersionStandard({isCompareVersion}) {
  const isComparison = useSelector(selectShowCompareView);
  const selectActive = useSelector(selectActiveVersion);
  const selectCompare = useSelector(selectCompareVersion);
  const selectCurrent = useSelector(selectCurrentVersion);
  const showChanges = useSelector(selectShowChanges);

  // set the main and comparison versions (depending on single or comparison view)
  const mainVersion = isComparison && isCompareVersion ? selectCompare || selectActive : selectActive;
  const compVersion = isComparison && isCompareVersion ? selectActive : selectCompare || selectCurrent;
  const {id: mainId, content: mainContent} = mainVersion;
  const {id: compId, content: compContent} = compVersion;
  const hasStableIds = articleHasStableEditorIds(mainVersion);

  const getArticleTitle = () => {
    if (!showChanges) return mainContent.title;
    return <Differentiator source={compContent.title} target={mainContent.title} />;
  };

  const getSidebarComponent = () => {
    return (
      <VersionsSidebar added={mainVersion.changes?.added} removed={mainVersion.changes?.removed} textChanged={mainVersion?.textChangePercentage} />
    );
  };

  let elements = mainContent.elements;

  if (showChanges && hasStableIds) elements = setDifferenceStableIds(compContent.elements, mainContent.elements);
  if (showChanges && !hasStableIds) elements = setDifferenceLegacyIds(compContent.elements, mainContent.elements);

  return (
    <div className={`atom version version-standard article-layout main-${mainId} compare-${compId}`}>
      <div className="article-form">
        <h1>{getArticleTitle()}</h1>
        {isComparison && getSidebarComponent()}
        <NotificationStableIds />
        <Editor
          key={`${mainId}-${compId}-${isCompareVersion}-${showChanges}`}
          name={`versions-${mainId}-${compId}`}
          data={jsonTransformForEditor({elements})}
        />
      </div>
      {!isComparison && getSidebarComponent()}
    </div>
  );
}

VersionStandard.defaultProps = {
  isCompareVersion: false,
};

VersionStandard.propTypes = {
  isCompareVersion: PropTypes.bool,
};

export default VersionStandard;
