import {sanitizeText} from '../../../../helpers/textHelpers';

// Nodes that are required by tweet parser
const SELECTORS = ['blockquote', 'blockquote > div > a'];

/**
 * Class is responsible for instagram embed code parsing.
 * It used in tweet Editor.JS tool for transform embed instagram post to default article instagram post format
 *
 * Example usage:
 * const parser = new EmbedCodeParser(`
 *  <blockquote class="instagram-media" ...>
 *  <div ...><a href="https://www.instagram.com/p/CHJYitupiFd/?utm_source=ig_embed&utm_campaign=loading" style=" background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;" target="_blank">...</a></div>
 *  </blockquote>
 *  <script async src="//www.instagram.com/embed.js"></script>
 * `)
 * console.log(parser.getCreatedAt()) // "Fri Jun 01 1900 00:00:00"
 * console.log(parser.getPostId()) // {ID}
 * console.log(parser.getPostUrl()) // "https://www.instagram.com/p/CHJYitupiFd"
 * console.log(parser.getPost()) // "instagram post content goes here"
 * console.log(parser.getUser()) // ["IG Post Author", "ighandle"]
 */
export default class EmbedCodeParser {
  constructor(code) {
    const doc = new DOMParser().parseFromString(code, 'text/html');
    if (!this.isEmbedCodeValid(doc)) {
      throw new Error('Invalid embed document format');
    }
    this.doc = doc;
  }

  /**
   * Verify that passed document is valid and can be parsed
   *
   * @param {Document} doc represents embed code document
   * @returns {boolean}
   */
  isEmbedCodeValid(doc) {
    return doc.querySelectorAll(SELECTORS.join(',')).length === SELECTORS.length;
  }

  /**
   * Parse embed code and retrieve url
   *
   * <a href="https://www.instagram.com/p/CHJYitupiFd/?utm_source=ig_embed&utm_campaign=loading">...</a>
   * @returns {string} with ?ref_src=... stripped
   */
  getPostUrl() {
    const link = this.doc.querySelector('blockquote > div > a');
    const linkParts = link.href.split('?');
    return sanitizeText(linkParts[0]);
  }

  /**
   * Parse embed code and retrieve ID
   *
   * <a href="https://www.instagram.com/p/{ID}/?utm_source=ig_embed&utm_campaign=loading">...</a>
   * @returns {string}
   * @throws {Error} if id cannot be parsed from the document
   */
  getPostId() {
    const link = this.doc.querySelector('blockquote > div > a');
    const path = new URL(link.href).pathname.split('/');
    if (path.length < 3) {
      throw new Error(`Cannot parse instagram post id from ${link.href}`);
    }
    return sanitizeText(path[2]);
  }

  /**
   * Parse embed code and retrieve creation date
   *
   * <time datetime="2020-11-03T23:12:51+00:00">Nov 3, 2020 at 3:12pm PST</time>
   * @returns {string}
   */
  getCreatedAt() {
    const elem = this.doc.querySelector('blockquote time');
    return elem ? sanitizeText(elem.getAttribute('datetime')) : '';
  }

  /**
   * Parse embed code and retrieve content
   * @returns {string}
   */
  getPost() {
    const elem = this.doc.querySelector('blockquote');
    return elem.innerHTML;
  }
}
