import React from 'react';

export const ToolsHeader = ({title}) => {
  return (
    <div className="atom tools-header">
      {!title && <h1>Tools</h1>}
      {title && (
        <h1>
          Tools: <span>{title}</span>
        </h1>
      )}
    </div>
  );
};

export default ToolsHeader;
