import reporter from '../../reporter';

import logger from '../../logger';

import {Tags} from '../../endpoints';
import {fetchJSON} from '../helpers/fetch';

/**
 * Fetch a list of suggested tags based on user input.
 *
 * @param {string} queryString search string
 * @returns {Promise[Array]} promise which resolves to array of results (potentially empty)
 */
export const searchForTags = async (queryString) => {
  const endpoint = Tags.searchTags(encodeURI(queryString));
  return await fetchJSON(endpoint)
    .catch((error) => {
      reporter.inform(error);
      return {tags: []};
    })
    .then((res) => res.tags || []);
};

export const getTagsByIds = (ids) => {
  const endpoint = Tags.getTagsByIds(ids.join(','));

  return fetchJSON(endpoint)
    .then((res) => res.tags)
    .catch((error) => {
      reporter.inform(error);
      return [];
    });
};

export const getParentTag = (tag) => {
  if (!tag?.parentId) return null;

  const endpoint = Tags.getTagsByIds([tag.parentId]);
  return fetchJSON(endpoint)
    .then((res) => res.tags[0])
    .catch((error) => {
      reporter.inform(error);
      logger.error(error);
      return null;
    });
};

/**
 * Generator that load the information about tag parent.
 * @param {object} tag object that represents an article tag
 * @returns {object} generator object
 * @example
 *  const loader = loadParentTags({id: 99, name: "Player", parentId: 98, ...})
 *  const parent = await loader.next().value; // {id: 98, name: "Team", parentId: null}
 */
export async function* loadParentTags(tag) {
  // Use this assignment for make recursion possible
  let parent = tag;
  while (true) {
    parent = await getParentTag(parent); // eslint-disable-line no-await-in-loop
    if (!parent) {
      // Mark generator as "done"
      return;
    }
    yield parent;
  }
}

/**
 * Load all parents for the tag
 * @param {object} tag object that represents an article tag
 * @returns {array} list of parent tags
 */
export async function getAllParentTags(tag) {
  const loader = loadParentTags(tag);

  const list = [];
  let parent = await loader.next();
  while (!parent.done) {
    list.push(parent.value);
    // Due to parents number uncertainty keep loading until generator is done
    parent = await loader.next(); // eslint-disable-line no-await-in-loop
  }

  return list;
}
