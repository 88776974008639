export const PERSON = 'Person';
export const TEAM = 'Team';
export const DIVISION = 'Division';
export const LEAGUE = 'League';
export const SPORT = 'Sport';
export const EVENT = 'Event';
export const LOCATION = 'Location';

export const TAG_TYPES = [PERSON, TEAM, DIVISION, LEAGUE, SPORT, EVENT, LOCATION];
export const TAG_TYPES_REQUIRED = [LEAGUE, SPORT];
export const MIN_SEARCH_LENGTH = 3;
