import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {unsetArticle} from '../../actions/article';
import {clearAuthData} from '../../helpers/authHelpers';
import {clearGettyAuthTokens} from '../../apis/media/gettyAPI';

const ButtonLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(unsetArticle()); // first clear article to release the softlock
    // wait for unsetArticle to finish or softlock will not release
    setTimeout(() => {
      clearAuthData();
      clearGettyAuthTokens();
      navigate('/login', {replace: true});
    }, 200);
  };

  return (
    <a href="#" className="logout" onClick={handleLogout}>
      <span className="icon-nav-logout" />
      <span>Logout</span>
    </a>
  );
};

export default ButtonLogout;
