export const STATUS_DRAFT = 'draft';
export const STATUS_READY_FOR_REVIEW = 'ready_for_review';
export const STATUS_IN_REVIEW = 'in_review';
export const STATUS_READY_FOR_PUBLISH = 'ready_for_publish';
export const STATUS_PUBLISHED = 'published';
export const STATUS_DELETED = 'deleted';

export const STATUS_LABELS = {
  [STATUS_DRAFT]: 'Draft',
  [STATUS_READY_FOR_REVIEW]: 'Ready for Review',
  [STATUS_IN_REVIEW]: 'In Review',
  [STATUS_READY_FOR_PUBLISH]: 'Ready for Publish',
  [STATUS_PUBLISHED]: 'Published',
};

export const ARTICLE_ACTIVE_STATUSES = [STATUS_DRAFT, STATUS_READY_FOR_REVIEW, STATUS_IN_REVIEW, STATUS_READY_FOR_PUBLISH, STATUS_PUBLISHED];

export const BREAKING_NEWS_TAGS = [
  '7021', // Breaking News
];
export const NO_ADS_TAG = '98031';

export const ARTICLES_RECENT = 'articlesRecent';
export const ARTICLES_RECENT_MAX = 5;
export const ARTICLE_TITLE_MINLENGTH = 8;
export const ARTICLE_TITLE_MAXLENGTH = 85;
export const ARTICLE_CONTENT_MINLENGTH = 5;
export const ARTICLE_SOCIAL_TITLE_MAXLENGTH = ARTICLE_TITLE_MAXLENGTH;
export const ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH = 160;
export const SLIDE_TITLE_MINLENGTH = 3; // non-title slideshow slides

export const TYPE_STANDARD = 'article';
export const TYPE_SLIDESHOW = 'slideshow';
export const TYPES = [TYPE_STANDARD, TYPE_SLIDESHOW];

export const FEEDBACK_MINLENGTH = 3;
export const FEEDBACK_MAXLENGTH = 500;
export const FEEDBACK_TRUNCATION_LIMIT = 256;

export const TAB_FEEDBACK_HASH = '#feedback';

// Article validation field keys
export const VALIDATE_SLIDES_BODY_INDEX = 'article.body.index';
export const VALIDATE_FIELD_TITLE = 'article.title';
export const VALIDATE_FIELD_BODY = 'article.body';
export const VALIDATE_FIELD_FEATUREDMEDIA = 'article.featuredmedia';
export const VALIDATE_FIELD_TAGS = 'article.tags';
export const VALIDATE_FIELD_THUMBNAIL = 'article.thumbnail';
export const VALIDATE_FIELD_SOCIAL_TITLE = 'article.socialTitle';
export const VALIDATE_FIELD_SOCIAL_DESCRIPTION = 'article.teaser';

export const VALIDATE_FIELDS = [
  VALIDATE_FIELD_TITLE,
  VALIDATE_FIELD_FEATUREDMEDIA,
  VALIDATE_FIELD_BODY,
  VALIDATE_FIELD_THUMBNAIL,
  VALIDATE_FIELD_TAGS,
  VALIDATE_FIELD_SOCIAL_TITLE,
  VALIDATE_FIELD_SOCIAL_DESCRIPTION,
];

// Article states
export const STATE_READONLY = 'article.readOnly';

// Featured media
export const FEATURED_MEDIA_BLOCK_INDEX = 0;
export const FEATURED_MEDIA_CONTENT_TYPES = ['embed', 'gif', 'iframe', 'image', 'statmilk', 'video'];
