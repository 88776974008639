const REGEX_PROTOCOL = /^[a-z]+:\/\//i;

/**
 * Backend constructor function: helper for creating simpler Endpoint objects easily.
 *
 * @param {String} host FQDN to use as the basis for all routes/endpoints
 * @returns {Backend} instance which accepts setters/getters (see description)
 *
 * When provided a domain name for the `host`, this constructs an instance which allows you to
 * easily set and get routes associated with the domain name. The setter accepts a string for a
 * static route, or a function which returns a string for a route which requires parameters. The
 * routes are exposed as functions on the instance. Depends on native Proxy and Reflect.
 *
 * Example usage:
 * > const Umpire = new Backend("umpire.bleacherreport.com");
 * > Umpire.up = "/up/elb";
 * > Umpire.deleteTerm = (id) => `terms/${id}`;
 * > // then in e.g. an API file which can import Umpire from "endpoints"...
 * > Umpire.up(); // "https://umpire.bleacherreport.com/up/elb"
 * > Umpire.deleteTerm(3); // "https://umpire.bleacherreport.com/terms/3"
 *
 * (Not-recommended usage: the instance also exposes a property `host` which is the string provided
 * at instantiation. This may be needed for Non Standard uses of endpoints, e.g. GatekeeperAPI...)
 */
export default function Backend(host) {
  if (!host || typeof host !== 'string') {
    throw new Error('Missing host');
  }
  if (REGEX_PROTOCOL.test(host)) {
    throw new Error(`Do not specify a protocol for the host (${host}); HTTPS will be used.`);
  }
  const withHost = (path) => `https://${host}${path[0] === '/' ? '' : '/'}${path}`;
  function handleRouteValue(routeValue) {
    switch (typeof routeValue) {
      case 'function':
        return (...routeArgs) => withHost(routeValue(...routeArgs));
      case 'string':
        return () => withHost(routeValue);
      default:
        throw new Error(`Unhandled type for backend route value: ${typeof routeValue}`);
    }
  }
  return new Proxy(
    {host},
    {
      set(backendObject, routeName, routeFunctionOrString) {
        // eslint-disable-next-line no-prototype-builtins
        if (backendObject.hasOwnProperty(routeName)) {
          // Prevent re-assigning a route. If this causes you trouble when
          // writing a test, try to import the related endpoint and then call
          // Jest's mockReturnValue or mockImplementation on the related route
          // to control its return value.
          throw new Error(`Backend already has defined: ${routeName}`);
        }
        const routeFunction = handleRouteValue(routeFunctionOrString);
        return Reflect.set(backendObject, routeName, routeFunction);
      },
    }
  );
}
