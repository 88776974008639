import React from 'react';
import PropTypes from 'prop-types';

import {CTT_USER_IMAGE, CTT_USER_NAME} from '../constants/user';
import {getStorageData} from '../helpers/authHelpers';

import Sidebar from './organisms/sidebar';
import PromptProvider from './atoms/prompt/promptProvider';
import useAuthRefresh from '../helpers/hooks/useAuthRefresh';

function Base({children}) {
  return (
    <>
      <PromptProvider />
      {children}
    </>
  );
}

export function Authorized({children}) {
  useAuthRefresh();

  return <Base>{children}</Base>;
}

export default function AuthorizedWithSidebar({children}) {
  const user = {
    image: getStorageData(CTT_USER_IMAGE),
    name: getStorageData(CTT_USER_NAME),
  };

  return (
    <Authorized>
      <Sidebar key="sidebar" user={user} />
      {children}
    </Authorized>
  );
}

AuthorizedWithSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};
