import {isEqual, isEmpty} from 'lodash';
import {diffWords} from 'diff';

function isTextBlock(block) {
  const type = block.contentType || block.type;
  const tools = ['h2', 'h3', 'h4', 'paragraph', 'quote_indent', 'quote_pull'];
  return tools.includes(type);
}

/**
 * Detect the difference between two string and inject styling markup
 * @param {string} ver1 source string
 * @param {string} ver2 target string
 * @param {string}
 */
function getDiffString(ver1, ver2) {
  // if ver1 or ver2 is undefined or null (like in the case of FM image object with no caption property),
  // pass an empty string to diffWords
  const changes = diffWords(ver1 || '', ver2 || '');
  return changes.reduce((text, {value, added, removed}) => {
    if (added || removed) {
      return `${text}<span class="ce-diff__${added ? 'added' : 'removed'}">${value}</span>`;
    }
    return text + value;
  }, '');
}

/**
 * Add difference flag parameter for mark block as DELETED for editor.js tools
 * @param {object} block article element block
 * @returns {object}
 */
function makeBlockRemoved(block) {
  let content = {...block.content, diff: {removed: true}};
  if (isTextBlock(block)) {
    // Wrap entire block content
    content = {...block.content, html: `<div class="ce-diff__block ce-diff__removed">${block.content.html}</div>`};
  }
  return {...block, content};
}

/**
 * Add difference flag parameter for mark block as ADDED for editor.js tools
 * @param {object} block article element block
 * @returns {object}
 */
function makeBlockAdded(block) {
  let content = {...block.content, diff: {added: true}};
  if (isTextBlock(block)) {
    // wrap entire block content
    content = {...content, html: `<div class="ce-diff__block ce-diff__added">${block.content.html}</div>`};
  }
  return {...block, content};
}

/**
 * Add difference flag parameter for mark block as MOVED for editor.js tools
 * @param {object} block article element block
 * @returns {object}
 */
function makeBlockMoved(block) {
  let content = {...block.content, diff: {moved: true}};
  if (isTextBlock(block)) {
    // wrap entire block content
    content = {...content, html: `<div class="ce-diff__block ce-diff__moved">${block.content.html}</div>`};
  }
  return {...block, content};
}

/**
 * Detect changes between two blocks
 * @param {object} a source block
 * @param {object} b target block
 * @returns {array}
 */
function getBlocksDifference(a, b) {
  const type = a.contentType || a.content_type;
  switch (type) {
    case 'h2':
    case 'h3':
    case 'h4':
    case 'pullquote':
    case 'blockquote':
    case 'paragraph':
    case 'quote_indent':
    case 'quote_pull': {
      return [
        {
          ...a,
          content: {
            ...a.content,
            html: getDiffString(a.content.html, b.content.html),
          },
        },
      ];
    }
    case 'image': {
      const same = isEqual(a.content.url, b.content.url);
      if (same) {
        return [
          {
            ...a,
            content: {
              ...a.content,
              caption: getDiffString(a.content.caption, b.content.caption),
              credit: getDiffString(a.content.credit, b.content.credit),
            },
          },
        ];
      }
      // Return block A as removed and block B as added 'cause image url was changed
      return [makeBlockRemoved(a), makeBlockAdded(b)];
    }
    case 'gif': {
      const same = isEqual(a.content.file, b.content.file);
      if (same) return [a];

      // Return block A as removed and block B as added 'cause gif url was changed
      return [makeBlockRemoved(a), makeBlockAdded(b)];
    }
    case 'video': {
      const same = isEqual(a.content.metadata.videoId, b.content.metadata.videoId);
      if (same)
        return [
          {
            ...a,
            content: {
              ...a.content,
              metadata: {
                ...a.content.metadata,
                caption: getDiffString(a.content.metadata.caption, b.content.metadata.caption),
              },
            },
          },
        ];

      // Return block A as removed and block B as added 'cause video id was changed
      return [makeBlockRemoved(a), makeBlockAdded(b)];
    }
    case 'ul':
    case 'ol': {
      let items = a.content.items.map((text, index) => {
        return getDiffString(text, b.content.items[index]);
      });
      if (b.content.items.length > a.content.items.length) {
        const rest = b.content.items.slice(a.content.items.length, b.content.items.length);
        items = [...items, ...rest.map((text) => `<div class="ce-diff__added">${text}</div>`)];
      }

      return [{...a, content: {...a.content, items}}];
    }
    default: {
      return [makeBlockRemoved(a), makeBlockAdded(b)];
    }
  }
}

/**
 * Return the difference results between two articles/slides WITHOUT stable (editorjs-generated) ids
 * @param {array} ver1 source article/slide list of blocks
 * @param {array} ver2 target article/slide list of blocks
 * @returns {array}
 */
function setDifferenceLegacyIds(ver1 = [], ver2 = []) {
  // Strip legacy numeric ids from blocks as these were not useful for comparison because they changed between saves
  ver1.map((block) => delete block.id);
  ver2.map((block) => delete block.id);

  const articleDifference = ver1.reduce((acc, block, index) => {
    const block2 = ver2[index];

    if (!block2) {
      // block A was removed in the second version
      return [...acc, makeBlockRemoved(block)];
    }

    if (!isEqual(block.content, block2.content)) {
      // test for deleted block A by comparing content types
      if (block.contentType !== block2.contentType) {
        ver2.splice(index, 0, {}); // add a placeholder for the deleted block in the comparison blocks to keep rest of comparisons the same
        return [...acc, makeBlockRemoved(block)];
      }

      // test for deleted block A by checking equality to any of the next blocks
      for (let i = index; i <= ver1.length; i++) {
        if (ver1[i + 1] && isEqual(ver1[i + 1].content, block2.content)) {
          ver2.splice(index, 0, {}); // add a placeholder for the deleted block in the comparison blocks to keep rest of comparisons the same
          return [...acc, makeBlockRemoved(block)];
        }
      }

      // just a content difference to be marked up
      return [...acc, ...getBlocksDifference(block, block2)];
    }

    if (block.contentType !== block2.contentType) {
      // block A was replaced by block B with different type (ex./ convert paragraph to heading)
      return [...acc, makeBlockRemoved(block), makeBlockAdded(block2)];
    }

    // blocks are the same
    return [...acc, block];
  }, []);

  if (ver2.length > ver1.length) {
    // "comparable" version larger than "origin", delta blocks should be considered as ADDED
    const rest = ver2.slice(ver1.length, ver2.length);
    return [...articleDifference, ...rest.map(makeBlockAdded)];
  }

  return articleDifference;
}

/**
 * Return the difference results between two articles/slides WITH stable (editorjs-generated) ids
 * @param {array} ver1 source article/slide list of blocks
 * @param {array} ver2 target article/slide list of blocks
 * @returns {array}
 */
function setDifferenceStableIds(ver1 = [], ver2 = []) {
  const articleDifference = ver2.reduce((blocksWithDifference, ver2Block, ver2BlockIndex) => {
    const ver1Block = Object.hasOwn(ver2Block, 'id')
      ? ver1.find((v1block) => v1block.id === ver2Block.id)
      : ver1.find((v1block) => !Object.hasOwn(v1block, 'id'));

    // If the block from the active version is not in the previous version then mark it as added
    if (!ver1Block) return [...blocksWithDifference, makeBlockAdded(ver2Block)];

    const ver1BlockIndex = ver1.findIndex((v1block) => isEqual(v1block, ver1Block));
    const hasDifferentIndex = ver2BlockIndex !== ver1BlockIndex;
    const hasDifferentPreviousBlock = ver2[ver2BlockIndex - 1]?.id !== ver1[ver1BlockIndex - 1]?.id;
    const hasDifferentNextBlock = ver2[ver2BlockIndex + 1]?.id !== ver1[ver1BlockIndex + 1]?.id;

    // Handle blocks that exist in both versions but at different index positions (blocks that have been moved)
    if (hasDifferentIndex && hasDifferentPreviousBlock && hasDifferentNextBlock) return [...blocksWithDifference, makeBlockMoved(ver2Block)];

    // Just a content difference to be marked up
    if (!isEqual(ver2Block.content, ver1Block.content)) return [...blocksWithDifference, ...getBlocksDifference(ver1Block, ver2Block)];

    // Blocks are the same
    return [...blocksWithDifference, ver2Block];
  }, []);

  const blocksToMakeRemoved = ver1.reduce((blocksNotInActiveVersion, ver1Block) => {
    const ver2Block = Object.hasOwn(ver1Block, 'id')
      ? ver2.find((v2block) => v2block.id === ver1Block.id)
      : ver2.find((v2block) => !Object.hasOwn(v2block, 'id'));

    if (!ver2Block) return [...blocksNotInActiveVersion, ver1Block];

    return [...blocksNotInActiveVersion, {}];
  }, []);

  // If the previous version contains blocks that are not in the active version then mark those blocks as removed
  for (const [index, block] of blocksToMakeRemoved.entries()) {
    if (!isEmpty(block)) articleDifference.splice(index, 0, makeBlockRemoved(block));
  }

  return articleDifference;
}

export {setDifferenceLegacyIds, setDifferenceStableIds};
