import Unsupported from '../unsupported';

/**
 * Poll content type not currently supported, appears in legacy articles
 * @see examples 2628429, 2857893, 2830226
 */
export default class Poll extends Unsupported {
  constructor({api, config, data}) {
    super({api, config, data});
    this.originalData = data;
  }

  render() {
    const type = 'Poll';
    const el = super.render(type);
    return el;
  }
}
