/**
 * EditorJS placeholder block for embed (iframe, statmilk, tweet, insagram...)
 * Used so we can trigger changes to saved article data after embed code entered in prompt
 */
export default class PlaceholderEmbed {
  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  constructor({api, data, readOnly}) {
    this.api = api;
    this.data = data;
    this.readOnly = readOnly;
  }

  render() {
    return document.createElement('div');
  }

  save() {
    return this.data;
  }
}
