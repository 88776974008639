/* eslint-disable max-len, quotes */

export const BEFORE_UNLOAD_MESSAGE_UNSAVED =
  "Warning: you're about to leave the page, but there's unsaved data.\n\n[ OK ] leave the page and lose any unsaved data\n[ Cancel ] stay on this page";

export const BEFORE_UNLOAD_MESSAGE_EXPIRED =
  "Warning: you're about to leave the page, but your lock has expired and there's unsaved data.\n\nIf you haven't saved your work, choose [ Cancel ] and select \"Copy to Clipboard.\" This captures all of the text within the article content, so that you can paste it into another app for safe-keeping (e.g. Google Docs). If you don't copy to the clipboard you will lose your most recent updates.\n\n[ OK ] leave the page and lose any unsaved data\n[ Cancel ] stay on this page";

export const BEFORE_UNLOAD_MESSAGE_RELEASE_LOCK =
  "Warning: you're about to leave the page, without releasing the lock.\n\n[ OK ] leave the page and don't release lock\n[ Cancel ] stay on this page to click 'Release Lock' in the header";

export const READONLY_LOCK_EXPIRED_MESSAGE =
  "Article is presented in read-only mode.\n\nYour lock expired and someone else may be editing your work. If you haven't saved your work, select “Copy to Clipboard.” This captures all of the text within the article content, so that you can paste it into another app for safe-keeping (e.g. Google Docs). If you don't copy to the clipboard you will lose your most recent updates.";

export const READONLY_MESSAGE = 'Article displayed in read-only mode.';

export const READONLY_MESSAGE_LOCK_EXPIRED =
  "Article displayed in read-only mode.\n\nYour lock expired and someone else may be editing your work. If you haven't saved your work, select “Copy to Clipboard.” This captures everything currently on the page. If you don't copy to the clipboard you will lose your most recent updates.";

export const READONLY_MESSAGE_LOCKED =
  'Article displayed in read-only mode, locked by an editor. Please reach out to them or steal the lock if you need to make changes.';

export const READONLY_MESSAGE_LOCKED_CANT_STEAL =
  'Article displayed in read-only mode, being reviewed by an editor. Please reach out to them if you need to make changes.';

export const READONLY_INREVIEW_MESSAGE =
  'Article is presented in read-only mode.\n\nYour article is currently in review by an editor. Please reach out them if you need to make changes.';
