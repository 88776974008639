// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * An embedded tweet will briefly render as a blockquote before loading the Twitter styles
 * We need to override the primary CSS styles and make it render closer to the final embedded tweet styles first
 * @see https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/css-for-embedded-tweets
 */
blockquote.twitter-tweet {
  display: block;
  font-family: 'Helvetica Neue', Roboto, 'Segoe UI', Calibri, sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6rem;
  border-left: 0;
  border-color: #eee #ddd #bbb;
  border-radius: 1.5rem;
  border-style: solid;
  border-width: 0.1rem;
  box-shadow: 0 0.1rem 0.3rem rgb(0 0 0 / 0.15);
  margin: 1rem auto;
  padding: 1rem 1.6rem 1.6rem 1.6rem;
  max-width: 550px;
  opacity: 0.4;
}

blockquote.twitter-tweet p {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2rem;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}

blockquote.twitter-tweet::before {
  content: none;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/tweet/index.css"],"names":[],"mappings":"AAAA;;;;EAIE;AACF;EACE,cAAc;EACd,sEAAsE;EACtE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,4BAA4B;EAC5B,qBAAqB;EACrB,mBAAmB;EACnB,oBAAoB;EACpB,6CAA6C;EAC7C,iBAAiB;EACjB,kCAAkC;EAClC,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;;EAEE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf","sourcesContent":["/**\n * An embedded tweet will briefly render as a blockquote before loading the Twitter styles\n * We need to override the primary CSS styles and make it render closer to the final embedded tweet styles first\n * @see https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/css-for-embedded-tweets\n */\nblockquote.twitter-tweet {\n  display: block;\n  font-family: 'Helvetica Neue', Roboto, 'Segoe UI', Calibri, sans-serif;\n  font-size: 1.6rem;\n  font-weight: bold;\n  line-height: 1.6rem;\n  border-left: 0;\n  border-color: #eee #ddd #bbb;\n  border-radius: 1.5rem;\n  border-style: solid;\n  border-width: 0.1rem;\n  box-shadow: 0 0.1rem 0.3rem rgb(0 0 0 / 0.15);\n  margin: 1rem auto;\n  padding: 1rem 1.6rem 1.6rem 1.6rem;\n  max-width: 550px;\n  opacity: 0.4;\n}\n\nblockquote.twitter-tweet p {\n  font-size: 1.6rem;\n  font-weight: normal;\n  line-height: 2rem;\n}\n\nblockquote.twitter-tweet a {\n  color: inherit;\n  font-weight: normal;\n  text-decoration: none;\n  outline: 0 none;\n}\n\nblockquote.twitter-tweet a:hover,\nblockquote.twitter-tweet a:focus {\n  text-decoration: underline;\n}\n\nblockquote.twitter-tweet::before {\n  content: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
