export const DASHBOARD_LIMIT_DEFAULT = 20;

// Dashboard article filters
export const FILTER_DRAFT = 'draft';
export const FILTER_IN_REVIEW = 'in_review';
export const FILTER_TO_PUBLISH = 'to_publish';
export const FILTER_PUBLISHED = 'published';
export const FILTER_DELETED = 'deleted';

export const WRITER_FILTERS = {
  [FILTER_DRAFT]: 'Draft',
  [FILTER_PUBLISHED]: 'Published',
};
export const EDITOR_FILTERS = {
  [FILTER_DRAFT]: 'Draft',
  [FILTER_IN_REVIEW]: 'In Review',
  [FILTER_TO_PUBLISH]: 'Publish Ready',
  [FILTER_PUBLISHED]: 'Published',
};

// default state for 'show my articles' filter
export const FILTER_ARTICLES_SHOW_MINE_DEFAULT = true;

// default state for published tab 'edited/unedited' filters
export const FILTER_ARTICLES_EDITED_DEFAULT = false;
