import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {makeArticleDeleted} from '../../../actions/search';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_DELETE_CONFIRM} from '../../../constants/prompt';
import {getArticleType, getArticleUrl, getArticleUrlVersions, isAdmin, isEditAllowed} from '../../../helpers/articleHelpers';
import {prompt} from '../../../helpers/promptHelper';
import {removeRecentArticle} from '../../../helpers/recentArticlesHelper';
import {selectArticles} from '../../../selectors/search';

import ButtonMenu from '../buttonMenu';

// Note: CTT-1038 Access to 'View Version History' button should be restricted by
// helpers/articleHelpers/canViewVersionHistory(authorGatekeeperId)
// But, this 'more' menu currently only displays for editors
// And we don't have the authorGatekeeperId in the search results (just the author profile object)
// So would need the to add the authorGatekeeprId to the search results to use this here

const SearchArticleMoreMenu = ({index}) => {
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const article = articles[index];
  const {id, title} = article;
  const type = getArticleType(article);
  const showDelete = isAdmin(); // delete from search restricted to admins (not using articleHelpers/canDelete() like on dashboard)
  const showEdit = isEditAllowed();

  const handleDeleteArticle = (id, title) => {
    prompt(PROMPT_DELETE_CONFIRM)
      .withTexts(`Are you sure you want to delete this ${type}?`, title, PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => {
        dispatch(makeArticleDeleted(id));
        removeRecentArticle(id);
      });
  };

  if (!article) return null;

  return (
    <>
      <ButtonMenu icon="more" menuAlignment="right" classButton="button--basic" text="More…" textAria={`Article Menu for '${title}'`}>
        <ul>
          {showEdit && (
            <li>
              <a href={getArticleUrl(article)} className="link-edit">
                Edit {type}
              </a>
            </li>
          )}
          <li>
            <a href={getArticleUrlVersions(article)} className="link-versions">
              View version history
            </a>
          </li>
          {showDelete && (
            <li>
              <Link to="#" className="link-delete" onClick={() => handleDeleteArticle(id, title)}>
                Delete {type}
              </Link>
            </li>
          )}
        </ul>
      </ButtonMenu>
    </>
  );
};

SearchArticleMoreMenu.defaultProps = {
  index: 0,
};

SearchArticleMoreMenu.propTypes = {
  index: PropTypes.number,
};

export default SearchArticleMoreMenu;
