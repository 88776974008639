import Unsupported from '../unsupported';

/**
 * Table content type not currently supported, appears in legacy articles
 * @see examples 2929567, 2926371, 2925805, 2925717, 2920516, 2920240, 2906293, 2901484, 2897525, 2896525, 2895371
 */
export default class Table extends Unsupported {
  constructor({api, config, data}) {
    super({api, config, data});
    this.originalData = data;
  }

  render() {
    const type = 'Table';
    const el = super.render(type);
    return el;
  }
}
