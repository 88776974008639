import {IconHeading, IconH3, IconListBulleted, IconListNumbered} from '@codexteam/icons';
import {
  EDITOR_TOOL_HEADER_LABEL,
  EDITOR_TOOL_LIST_ORDERED_LABEL,
  EDITOR_TOOL_LIST_ORDERED_STYLE,
  EDITOR_TOOL_LIST_UNORDERED_LABEL,
  EDITOR_TOOL_LIST_UNORDERED_STYLE,
  EDITOR_TOOL_SUBHEADER_LABEL,
} from '../../constants/editor';

/**
 * By default the "Header" plugin will only add a single header block type to the add block menu regardless of the number of levels,
 * so to add a "Subheader" you first need to add a Heading, then convert it to a Subheader, which isn't obvious for users.
 * Instead adding custom config so we display both a "Heading" and "Subheading" to the block menu
 * Note: this will only modify the block menu, block tuning menu labels configured using i18n
 * @see https://codex.so/editorjs-toolbox
 */
export const configHeader = {
  inlineToolbar: false,
  config: {
    levels: [2, 3],
    defaultLevel: 2,
  },
  toolbox: [
    {
      icon: IconHeading,
      title: EDITOR_TOOL_HEADER_LABEL,
      data: {
        level: 2,
      },
    },
    {
      icon: IconH3,
      title: EDITOR_TOOL_SUBHEADER_LABEL,
      data: {
        level: 3,
      },
    },
  ],
};

/**
 * By default the "List" plugin will only add a single list block type to the add block menu, whatever is the default list type (unordered)
 * so to add "Ordered List" you first need to add a List, then convert it to an Ordered List, which isn't obvious for users.
 * Instead adding custom config so we display both a "Unordered List" and "Unordered List" to the block menu
 * Note: this will only modify the block menu, block tuning menu labels configured using i18n
 * @see https://codex.so/editorjs-toolbox
 */
export const configList = {
  inlineToolbar: true,
  config: {
    defaultStyle: EDITOR_TOOL_LIST_UNORDERED_STYLE,
  },
  toolbox: [
    {
      icon: IconListBulleted,
      title: EDITOR_TOOL_LIST_UNORDERED_LABEL,
      data: {
        style: EDITOR_TOOL_LIST_UNORDERED_STYLE,
      },
    },
    {
      icon: IconListNumbered,
      title: EDITOR_TOOL_LIST_ORDERED_LABEL,
      data: {
        style: EDITOR_TOOL_LIST_ORDERED_STYLE,
      },
    },
  ],
};
