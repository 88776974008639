import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'react-tooltip';

import {getUsersProfiles} from '../../../apis/auth/gatekeeper-api';
import {getStorageData} from '../../../helpers/authHelpers';
import {getArticleEditorNames} from '../../../helpers/articleHelpers';
import {CTT_USER} from '../../../constants/user';

const ArticleEditorsCurrent = ({userIds}) => {
  if (!userIds || userIds.length < 1) return null;

  const currentUserGkId = getStorageData(CTT_USER);
  const filteredUserIds = [...userIds].filter((userId) => userId !== currentUserGkId);

  if (filteredUserIds.length < 1) return null;

  const [userNames, setUserNames] = useState([]);

  useEffect(() => {
    getUsersProfiles([...new Set(filteredUserIds)]).then(({users, error}) => {
      // Failed calls to getUsersProfiles return an object with the format {error: true}
      // The actual errors are caught and logged from within a .catch block in the gatekeeper api
      if (error) return;
      setUserNames(getArticleEditorNames(users));
    });
    return () => setUserNames([]);
  }, []);

  return (
    <>
      <div id="article-editors-current-tooltip" className="tooltip-link" data-tooltip-float="true" data-tooltip-content={userNames.join(', ')}>
        (current editors)
      </div>
      <Tooltip anchorSelect="#article-editors-current-tooltip" />
    </>
  );
};

ArticleEditorsCurrent.propTypes = {
  userIds: PropTypes.array,
};

export default ArticleEditorsCurrent;
