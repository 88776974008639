// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ce-blockquote:before {
  content: none;
  display: none;
}

.ce-blockquote {
  padding-left: 4rem;
  border-left: 0;
  font-size: inherit;
  font-style: italic;
  font-weight: normal;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/block-quote/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".ce-blockquote:before {\n  content: none;\n  display: none;\n}\n\n.ce-blockquote {\n  padding-left: 4rem;\n  border-left: 0;\n  font-size: inherit;\n  font-style: italic;\n  font-weight: normal;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
