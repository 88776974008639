import {createSelector} from 'reselect';

const dashboardSelector = (state) => state.dashboard;
const articlesSelector = createSelector([dashboardSelector], (dashboard) => dashboard.articles);

export const selectAction = createSelector([dashboardSelector], (dashboard) => dashboard.action);

export const selectArticles = createSelector([dashboardSelector], (dashboard) => dashboard.articles.data.list);

export const selectOffset = createSelector([dashboardSelector], (dashboard) => dashboard.articles.data.offset);

export const selectHasMore = createSelector([dashboardSelector], (dashboard) => dashboard.articles.data.hasMore);

export const selectSorting = createSelector([articlesSelector], (articles) => articles.sorting);

export const selectFilter = createSelector([articlesSelector], (articles) => articles.filter);

export const selectShowMine = createSelector([articlesSelector], (articles) => articles.showMine);

export const selectShowEdited = createSelector([articlesSelector], (articles) => articles.showEdited);

export const selectShowUnedited = createSelector([articlesSelector], (articles) => articles.showUnedited);

export const selectError = createSelector([dashboardSelector], (dashboard) => dashboard.error);

export const selectLoading = createSelector([dashboardSelector], (dashboard) => dashboard.isLoading);
