/*
 * word list maintained by BR content team
 * @see https://docs.google.com/spreadsheets/d/1lf6hxaqxP5svm71pGXbiexupRccnQLe5v5MWlRmtrY8/edit#gid=0
 */
export const TEXT_LOWERCASE = [
  'a',
  'amid',
  'an',
  'and',
  'as',
  'at',
  'atop',
  'but',
  'by',
  'for',
  'from',
  'in',
  'into',
  'nor',
  'of',
  'off',
  'on',
  'onto',
  'or',
  'out',
  'over',
  'per',
  'so',
  'the',
  'to',
  'via',
  'vs',
  'vs.',
  'with',
];
