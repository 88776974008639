import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {selectPickedImage} from '../../../selectors/media';
import {selectGettyImageFromId, selectImageFromURL} from '../../../actions/media';
import {getCroppedURL} from '../../../utils/media';
import classnames from 'classnames';

const ImageSidebar = (props) => {
  const dispatch = useDispatch();
  const selectedImage = useSelector(selectPickedImage);
  const creditRef = useRef(null);
  const userCaptionRef = useRef(null);
  const [creditError, setCreditError] = useState(false);

  useEffect(() => {
    // in editorMedia.js, `shouldReplaceArticleThumbnail` defaults to false
    // but when displayed in the sidebar the checkbox defaults to true so need to update state to match
    const {onChangeThumbnail, showReplaceThumbnail} = props;
    if (showReplaceThumbnail) onChangeThumbnail(true);
  }, []);

  useEffect(() => {
    // Show credit error if image has no credit when initially loaded/selected
    const {credit} = selectedImage;
    if (credit) {
      setCreditError(false);
      return;
    }
    setCreditError(true);
  }, [selectedImage]);

  const renderPickedImageMeta = () => {
    const {caption} = props.picked;
    return (
      <>
        <div className="form-control">
          <label htmlFor="image-description">Provider's Description</label>
          <div className="text">{caption}</div>
        </div>
      </>
    );
  };

  // Credit is invalid if null, an empty string, a string of spaces, or a string beginning with any number of spaces
  const isInvalidCredit = (creditText) => !creditText || /^\s+/.test(creditText);

  const renderSelectedImageMeta = () => {
    const {credit, userCaption, sourceCaption, sourceCredit, uploadedByUserID} = selectedImage;

    return (
      <>
        <div className="form-control">
          <label htmlFor="image-caption">Image Caption</label>
          <textarea ref={userCaptionRef} defaultValue={userCaption} name="image-caption" />
        </div>
        <div className="form-control image-credit-input">
          <label htmlFor="image-credit" className={classnames({'is-required': creditError})}>
            Image Credit (required)
          </label>
          <textarea
            className={classnames({'has-error': creditError})}
            onChange={() => {
              const {value} = creditRef.current;
              setCreditError(isInvalidCredit(value));
            }}
            ref={creditRef}
            defaultValue={credit}
            name="image-credit"
          />
        </div>
        {!uploadedByUserID && (
          <div className="image-credit-provider">
            <strong className="label">Below is the credit / caption from the provider:</strong>
            {sourceCredit && <p>{sourceCredit}</p>}
            {sourceCaption && <p>{sourceCaption}</p>}
          </div>
        )}
      </>
    );
  };

  const renderImageMeta = () => {
    const {picked} = props;
    if (selectedImage.url) {
      // Show editable image credit for selected image
      return renderSelectedImageMeta();
    } else if (picked) {
      // Show readonly picked image caption
      return renderPickedImageMeta();
    }
    return null;
  };

  const handleSubmit = () => {
    // This handler is used when an image is picked from among search results
    // (to move to the Crop step), and once the Crop / Credit step is done (to
    // add the data for the image into the article content), and (for an image
    // file which the user has uploaded from desktop) once the Crop / Credit
    // step is done.
    const {picked, cropConfig, onSubmit} = props;
    if (selectedImage.url) {
      // Image has already been uploaded to BR's media service.
      // Add the image data (with crop data if it exists) to the article.
      const credit = creditRef.current ? creditRef.current.value : picked && picked.credit;
      const userCaption = userCaptionRef.current ? userCaptionRef.current.value : picked && picked.caption;
      const isForThumbnail = !props.showMeta;
      // Do not allow submission if image is not for the thumbnail AND is null,
      // an empty string, a string of spaces, or a string beginning with any number of spaces
      if (!isForThumbnail && isInvalidCredit(credit)) return false;
      // Add image into the editor
      const url = cropConfig ? getCroppedURL(selectedImage.url, cropConfig) : selectedImage.url;
      onSubmit(url, credit, userCaption, selectedImage, isForThumbnail);
    } else {
      // Image has been chosen; now it needs to be uploaded to BR's media service.
      // Here we determine the URL for the high-res image asset.
      // Once it has uploaded, the modal will show the Crop step.
      const {caption: sourceCaption, credit: sourceCredit, id, fullUrl, source} = picked;

      if (source === 'Getty') dispatch(selectGettyImageFromId({id, sourceCaption, sourceCredit}));
      else if (source === 'AP') dispatch(selectImageFromURL(fullUrl, sourceCredit, sourceCaption));
    }

    return true;
  };

  const {onCancel, onChangeThumbnail, picked, showMeta, showReplaceThumbnail} = props;
  const isDisabled = !picked && !selectedImage.url;

  let buttonLabel = 'Next';
  if (picked && picked.url && selectedImage.url) {
    buttonLabel = 'Add Image';
  } else if (selectedImage.uploadedByUserID) {
    buttonLabel = 'Add Image';
  } else if (selectedImage.url) {
    buttonLabel = 'Update Image';
  }

  return (
    <div className="modal-sidebar">
      <div className="modal-sidebar-content">{showMeta && renderImageMeta()}</div>
      <div className="modal-actions">
        {showReplaceThumbnail && (
          <div>
            <label>
              Also update article thumbnail&nbsp;
              <input
                type="checkbox"
                defaultChecked={true}
                onChange={({target}) => {
                  onChangeThumbnail(target.checked);
                }}
              />
            </label>
          </div>
        )}
        <div>
          <button onClick={onCancel}>Cancel</button>
          <button className="button--primary" disabled={isDisabled} onClick={handleSubmit}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

ImageSidebar.defaultProps = {
  showMeta: true,
};

ImageSidebar.propTypes = {
  cropConfig: PropTypes.object,
  picked: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onChangeThumbnail: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showMeta: PropTypes.bool,
  showReplaceThumbnail: PropTypes.bool,
};

export default ImageSidebar;
