import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useSelector} from 'react-redux';

import {useSearch} from '../../../actions/hooks/media';
import {MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO, MEDIA_SEARCH_NO_RESULTS_TITLE, MEDIA_SEARCH_NO_RESULTS_TEXT} from '../../../constants/media';
import {PROMPT_MEDIA_SEARCH_NO_RESULTS} from '../../../constants/prompt';
import {prompt} from '../../../helpers/promptHelper';
import {selectIsLoading, selectIsSearched, selectTotalImages, selectImageSearch} from '../../../selectors/media';

import IconButton from '../iconButton';

const Search = (props) => {
  const {hideImageFilter, hideVideoFilter, mediaType, onCancel, onMediaTypeSelect, onSearch} = props;
  const {query, makeSearch, makeSearchClear} = useSearch(mediaType);
  const [search, setSearch] = useState(query);
  const isLoading = useSelector(selectIsLoading);
  const isSearched = useSelector(selectIsSearched);
  const totalImages = useSelector(selectTotalImages);
  const searchQuery = useSelector(selectImageSearch);
  const ref = useRef(true);

  // when we change media types, if there's a search input value do a search.
  // or if there's no input value clear results incase there were any previous searches
  useEffect(() => {
    if (searchQuery.length > 2) {
      setSearch(searchQuery);
      makeSearch(searchQuery);
    } else {
      isSearched && onCancel();
    }
  }, [mediaType, searchQuery]);

  useEffect(() => {
    if (isSearched && totalImages.all === 0) {
      prompt(PROMPT_MEDIA_SEARCH_NO_RESULTS)
        .withTexts(MEDIA_SEARCH_NO_RESULTS_TITLE, MEDIA_SEARCH_NO_RESULTS_TEXT, 'OK', null)
        .withMute(false)
        .show(() => {});
    }
  }, [query]);

  useEffect(() => {
    // autofocus search input
    setTimeout(() => ref.current && ref.current.focus(), 100);
  }, [ref.current]);

  const isDisabled = () => {
    return isLoading || !search || search.length < 3;
  };

  const isSearchClearVisible = () => {
    return search.length > 0;
  };

  const handleSubmit = () => {
    onSearch();
    makeSearch(search);
  };

  const handleClear = () => {
    setSearch('');
    onCancel();
    makeSearchClear();
  };

  const handleFilterKeyUp = ({key}) => {
    if (key === 'Enter' && !isDisabled()) {
      handleSubmit();
    }
  };

  const isSearchForImage = mediaType === MEDIA_TYPE_IMAGE;

  const placeholder = isSearchForImage ? 'Images' : 'Video';

  return (
    <div className="atom media-search">
      <div className="content-top filter">
        {!hideImageFilter && (
          <IconButton
            icon="image"
            className={classnames('action--type', {'is-active': isSearchForImage})}
            onClick={() => !isSearchForImage && onMediaTypeSelect(MEDIA_TYPE_IMAGE)}
            inline={true}
          />
        )}
        {!hideVideoFilter && (
          <IconButton
            icon="video"
            className={classnames('action--type', {'is-active': !isSearchForImage})}
            onClick={() => isSearchForImage && onMediaTypeSelect(MEDIA_TYPE_VIDEO)}
            inline={true}
          />
        )}
        <input
          type="text"
          placeholder={`Search for ${placeholder}`}
          value={search}
          onKeyUp={handleFilterKeyUp}
          onChange={({target}) => setSearch(target.value)}
          disabled={isLoading}
          autoFocus={true}
          ref={ref}
        />
        <button className="action--search" disabled={isDisabled()} onClick={handleSubmit}>
          Search
        </button>
        {isSearchClearVisible() && (
          <button className="action--clear" onClick={handleClear}>
            <span className="sr-only">Clear</span>
          </button>
        )}
      </div>
    </div>
  );
};

Search.defaultProps = {
  hideImageFilter: false,
  hideVideoFilter: false,
  onCancel: () => {},
};

Search.propTypes = {
  mediaType: PropTypes.oneOf([MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO]).isRequired,
  hideImageFilter: PropTypes.bool,
  hideVideoFilter: PropTypes.bool,
  onMediaTypeSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default Search;
