// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ce-delimiter {
  line-height: 1.6em;
  width: 100%;
  text-align: center;
}

.ce-delimiter:before {
  display: inline-block;
  content: "—————";
  font-size: 30px;
  line-height: 65px;
  height: 30px;
  letter-spacing: 0.2em;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/delimiter/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".ce-delimiter {\n  line-height: 1.6em;\n  width: 100%;\n  text-align: center;\n}\n\n.ce-delimiter:before {\n  display: inline-block;\n  content: \"—————\";\n  font-size: 30px;\n  line-height: 65px;\n  height: 30px;\n  letter-spacing: 0.2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
