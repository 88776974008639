import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import {VALIDATE_FIELD_SOCIAL_DESCRIPTION, VALIDATE_FIELD_SOCIAL_TITLE, VALIDATE_FIELD_TITLE} from '../../constants/article';
import {VALIDATE_FIELD_MAIN} from '../../constants/slideshow';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';
import {capitalize, normalizeSpaces} from '../../helpers/textHelpers';
import {isSlideTitleValid, isTitleValid} from '../../utils/articleValidate';
import {getIsSlideshow, getIsTitleSlide} from '../../selectors/article';

export const FIELD_MAX_LENGTH = 256;

const EditorField = (props) => {
  const {doCapitalize, doNormalize, doNormalizeBlur, disabled, children, maxLength, name, type, readOnly, value, ...rest} = props;
  const {error, removeError} = useGlobalNotificationsError();
  const [hasError, setHasError] = useState(false);
  const ref = useRef(0);
  const [fieldMessage, setFieldMessage] = useState('');
  const fieldWrapperID = `editor-${name}`;
  const fieldID = `${fieldWrapperID}-field`;
  const classes = classnames('atom', 'editor-field', fieldWrapperID, 'control', {disabled});
  const [autoCapitalize, setAutoCapitalize] = useState(doCapitalize);
  const isSlideshow = useSelector(getIsSlideshow);
  const isTitleSlide = useSelector(getIsTitleSlide);

  const checkForValidationError = () => {
    if (!error) return setHasError(false); // no global errors to check against

    if (error[VALIDATE_FIELD_SOCIAL_TITLE] && name === 'meta-social-title') return setHasError(true);
    if (error[VALIDATE_FIELD_SOCIAL_DESCRIPTION] && name === 'meta-social-description') return setHasError(true);

    if (name !== 'title') return setHasError(false);
    if (isSlideshow) {
      // new slideshow first save validation check
      if (error[VALIDATE_FIELD_TITLE] && isTitleSlide) return setHasError(true);
      // exisitng slideshow validation check
      if (error[VALIDATE_FIELD_MAIN] && isTitleSlide && !isTitleValid(value)) return setHasError(true);
      if (error[VALIDATE_FIELD_MAIN] && !isTitleSlide && !isSlideTitleValid(value)) return setHasError(true);
      return setHasError(false);
    }
    // standard articles
    if ((error[VALIDATE_FIELD_TITLE] || error[VALIDATE_FIELD_MAIN]) && !isTitleValid(value)) return setHasError(true);

    return setHasError(false);
  };

  const handleCapitalize = () => {
    if (autoCapitalize) {
      const {selectionStart, selectionEnd} = ref.current; // get cursor position before we update the value
      ref.current.value = capitalize(ref.current.value);
      // set the cursor position where it was, otherwise if you edit in the middle of the string the cursor will shift to the end
      ref.current && ref.current.focus();
      ref.current.selectionStart = selectionStart;
      ref.current.selectionEnd = selectionEnd;
    }
  };

  const handleFieldMessage = (doAddChar = false) => {
    let fieldLength = ref.current.value.length;
    // onBeforeInput fires before the field has updated so we need to pad the length with an extra character
    if (doAddChar && fieldLength < maxLength) fieldLength = fieldLength + 1;

    setFieldMessage(`${fieldLength} of ${maxLength} max character limit`);
  };

  const getFieldMessage = () => {
    if (!fieldMessage.length) return null;
    return <div className="editor-field-message">{fieldMessage}</div>;
  };

  const onKeyUp = (event) => {
    // listen for backspace - backspace key event not picked up by onBeforeInput so need to capture this
    if (event.key === 'Backspace') handleFieldMessage();
  };

  useEffect(() => {
    handleFieldMessage();
    checkForValidationError();
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  useEffect(() => {
    checkForValidationError();
  }, [error]);

  useEffect(() => {
    handleCapitalize();
  }, [autoCapitalize]);

  useEffect(() => {
    ref.current.value = doNormalize ? normalizeSpaces(value) : value;
    handleFieldMessage();
  }, [value]);

  const getCapitalizeControl = () => {
    if (!doCapitalize) return null;

    return (
      <div className="control control-capitalize">
        <label>
          <input
            type="checkbox"
            checked={autoCapitalize}
            onChange={() => {
              setAutoCapitalize(!autoCapitalize);
            }}
          />
          Auto-capitalize
        </label>
      </div>
    );
  };

  const handleBeforeInput = () => {
    if (hasError) removeError(VALIDATE_FIELD_TITLE);
    handleFieldMessage(true);
    handleCapitalize();
  };

  const handleBlur = () => {
    const {onChange} = props;

    if (doNormalizeBlur) {
      // only normalize if there are changes, will trigger article isChanged: true
      const valueNormalized = normalizeSpaces(value);
      if (value !== valueNormalized) {
        ref.current.value = valueNormalized;
        onChange(ref.current.value);
      }
    }
  };

  return (
    <>
      <div id={fieldWrapperID} className={classes}>
        <label htmlFor={fieldID} className={fieldID}>
          {children}
        </label>
        <TextareaAutosize
          id={fieldID}
          className={classnames({'has-error': hasError})}
          type={type}
          name={name}
          defaultValue={value}
          disabled={disabled}
          maxLength={maxLength}
          ref={ref}
          readOnly={readOnly}
          onBeforeInput={handleBeforeInput}
          onBlur={handleBlur}
          {...rest}
        />
        <div className="editor-field-additional">
          {getFieldMessage()}
          {getCapitalizeControl()}
        </div>
      </div>
    </>
  );
};

EditorField.defaultProps = {
  autoFocus: false,
  disabled: false,
  doCapitalize: false,
  doNormalize: true,
  doNormalizeBlur: false,
  maxLength: FIELD_MAX_LENGTH,
  minLength: 0,
  readOnly: false,
  rows: 1,
  type: 'text',
};

EditorField.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  doCapitalize: PropTypes.bool,
  doNormalize: PropTypes.bool,
  doNormalizeBlur: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default EditorField;
