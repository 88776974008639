import React, {useRef} from 'react';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

import Editor from '../editor';
import Differentiator from '../differentiator';
import VersionsSidebar from '../../molecules/versionsSidebar';
import NotificationStableIds from './notificationStableIds';
import SlideTitles from './slideTitles';
import VersionsFilmstrip from '../../molecules/versionsFilmstrip';

import {setShowUnchangedSlides} from '../../../actions/versions';
import {getSlideThumbnail} from '../../../helpers/articleHelpers';
import {jsonTransformForEditor} from '../../../helpers/jsonTransform';
import {isVersionSlideAction} from '../../../helpers/versionHelpers';
import {
  selectActiveSlide,
  selectActiveSlideNumber,
  selectActiveVersion,
  selectChangedSlides,
  selectCompareVersion,
  selectContents,
  selectIndexes,
  selectShowChanges,
  selectShowUnchangedSlides,
} from '../../../selectors/versions';
import {setDifferenceLegacyIds, setDifferenceStableIds} from '../../../utils/articleDifference';
import {articleHasStableEditorIds} from '../../../helpers/articleHelpers';

function VersionSlideshow() {
  const dispatch = useDispatch();
  const mainVersion = useSelector(selectActiveVersion);
  const compareVersion = useSelector(selectCompareVersion);
  const {id: activeId, changes: activeChanges} = mainVersion;
  const {id: compId} = compareVersion || {id: null}; // catch for when an article has only 1 version so nothing to compare against

  const activeSlideNumber = useSelector(selectActiveSlideNumber);
  const changedSlides = useSelector(selectChangedSlides);
  const {activeIndex, currentIndex} = useSelector(selectIndexes);
  const {activeContent} = useSelector(selectContents);
  const {activeVersionSlide, compareVersionSlide} = useSelector(selectActiveSlide);

  const slideAdded = isVersionSlideAction(mainVersion, compareVersion, 'added');
  const slideDeleted = isVersionSlideAction(mainVersion, compareVersion);
  const showSlideAdded = slideAdded && activeSlideNumber === slideAdded.slideNumber;
  const showSlideDeleted = slideDeleted && activeSlideNumber === -1;

  const showChanges = useSelector(selectShowChanges);
  const showUnchangedSlides = useSelector(selectShowUnchangedSlides);
  const refShowUnchangedSlides = useRef(0);
  const hasStableIds = articleHasStableEditorIds(mainVersion);

  const getSlidesList = () => {
    const changedSlideIds = changedSlides.map((slide) => slide.id);

    let elements = activeContent.elements;
    if (!showUnchangedSlides) elements = elements.filter((slide) => changedSlideIds.includes(slide.id));

    return elements.reduce((acc, slide) => {
      return [
        ...acc,
        {
          ...slide,
          thumbnail: getSlideThumbnail(slide),
          changed: showChanges && changedSlideIds.includes(slide.id),
        },
      ];
    }, []);
  };

  const getArticleBody = () => {
    if (showSlideAdded) return [{id: 666, contentType: 'paragraph', content: {html: `Slide #${slideAdded?.slideNumber} was added.`}}];
    if (showSlideDeleted) return [{id: 666, contentType: 'paragraph', content: {html: `Slide #${slideDeleted?.slideNumber} was deleted.`}}];
    if (!showChanges) return activeVersionSlide?.elements;
    if (hasStableIds) return setDifferenceStableIds(compareVersionSlide?.elements, activeVersionSlide?.elements);
    return setDifferenceLegacyIds(compareVersionSlide?.elements, activeVersionSlide?.elements);
  };

  const getArticleTitle = () => {
    if (showSlideAdded) return '(Added) New Slide';
    if (showSlideDeleted) return `(Deleted) ${slideDeleted.title}`;
    if (!showChanges) return activeVersionSlide.title;
    return (
      <Differentiator source={compareVersionSlide ? compareVersionSlide.title : ''} target={activeVersionSlide ? activeVersionSlide.title : ''} />
    );
  };

  const onShowUnchangedSlides = () => {
    dispatch(setShowUnchangedSlides(!refShowUnchangedSlides.current.checked));
  };

  const editorKey = [activeIndex, currentIndex, activeSlideNumber, showChanges].join('-');
  const title = getArticleTitle();
  const elements = getArticleBody();
  const slides = getSlidesList();

  return (
    <div className={`atom version version-slideshow article-layout active-${activeId} compare-${compId}`}>
      <div className="article-form">
        <div className="version-slideshow-header">
          <VersionsFilmstrip frames={slides} />
          <div className="additional">
            <SlideTitles slides={slides} />
            {showChanges && (
              <div className={classnames('control-show-unchanged-slides', {visible: showChanges})}>
                <label>
                  <input type="checkbox" defaultChecked={!showUnchangedSlides} onChange={onShowUnchangedSlides} ref={refShowUnchangedSlides} />
                  Hide slides without changes
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="version-slide">
          <div className="slide-content">
            <h1 className={classnames({'is-slide-action': showSlideAdded || showSlideDeleted})}>{title}</h1>
            <NotificationStableIds />
            <Editor key={editorKey} name={`versions-${activeIndex}-${currentIndex}`} data={jsonTransformForEditor({elements})} />
          </div>
        </div>
      </div>
      <VersionsSidebar added={activeChanges?.added} removed={activeChanges?.removed} textChanged={mainVersion?.textChangePercentage} />
    </div>
  );
}

export default VersionSlideshow;
