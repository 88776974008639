import React from 'react';

import {getStorageData, isPermitted} from '../../helpers/authHelpers';
import {scopes} from '../../constants';
import {CTT_USER_NAME} from '../../constants/user';

import DashboardHeader from '../molecules/dashboardHeader';
import DashboardArticles from '../molecules/dashboardArticles';
import PageTitle from '../atoms/pageTitle';
import PermissionDenied from './permissionDenied';

const Dashboard = () => {
  const username = getStorageData(CTT_USER_NAME);
  const title = 'Dashboard';

  if (!isPermitted(scopes.ACCESS_ARTICLE_ADMIN)) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to access the dashboard.</p>
      </PermissionDenied>
    );
  }

  const usernameDisplay = () => {
    if (!username) return '';
    if (username.slice(-1) === 's') return `${username}'`;
    return `${username}'s`;
  };

  return (
    <section className="organism dashboard">
      <PageTitle title={title} />
      <h1>{usernameDisplay()} Dashboard</h1>
      <DashboardHeader />
      <DashboardArticles />
    </section>
  );
};

export default Dashboard;
