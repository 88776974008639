/**
 * EditorJS placeholder block
 * Used to preserve block 0 for featured media
 */
export default class Placeholder {
  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  constructor({api, data, readOnly}) {
    this.api = api;
    this.data = data;
    this.readOnly = readOnly;
  }

  render() {
    return document.createElement('div');
  }

  save() {
    return this.data;
  }
}
