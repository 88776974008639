import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import IconButton from '../iconButton';

function VersionsHeaderEmpty() {
  const location = useLocation();
  const navigate = useNavigate();

  const onCloseButtonClicked = () => {
    // return user to the article edit page
    navigate(location.pathname.replace('/versions', ''), {replace: true});
  };

  return (
    <div className="atom versions-header is-empty">
      <div className="header-side header-left">
        <h1>Version History</h1>
      </div>
      <div className="header-side header-right">
        <IconButton icon="close" className="button-close" onClick={onCloseButtonClicked} />
      </div>
    </div>
  );
}

export default VersionsHeaderEmpty;
