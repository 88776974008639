import {IconInstagram} from '@codexteam/icons';
import EmbedCodeParser from './embedCodeParser';
import {addDiffClass} from '../difference';
import ToolEventObserver from '../toolEventObserver';
import {EVENT_EMBED_EDIT} from '../../../../constants/events';
import {PROMPT_INSTAGRAM_EMBED} from '../../../../constants/prompt';
import {prompt} from '../../../../helpers/promptHelper';
import logger from '../../../../logger';

require('./index.css');

/**
 * Constructs an embedded instagram post from blockquote
 */
export default class Instagram {
  scriptId = 'instagram-media';
  constructor({api, data, block, readOnly}) {
    this.api = api;
    this.data = data;
    this.block = block;
    this.nodes = {};
    this.readOnly = readOnly;
    this.editorBlockIndex = 0;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  // CTT-735 -> Instagram embed tool turned off for now (due to legal reasons)
  // static get toolbox() {
  //   return {
  //     icon: IconInstagram,
  //     title: 'Instagram',
  //   };
  // }

  setDataFromParser(parser) {
    const data = {
      id: parser.getPostId(),
      url: parser.getPostUrl(),
      metadata: {
        instagramJson: {
          text: parser.getPost(),
          createdAt: parser.getCreatedAt(),
        },
      },
    };
    this.data = {...data, __original__: data};
  }

  renderNewPostFromEmbed() {
    if (!this.readOnly) {
      try {
        prompt(PROMPT_INSTAGRAM_EMBED)
          .withUserInput()
          .withTexts('Enter Instagram embed code', null, 'Save')
          .show((code) => {
            if (code) this.setDataFromParser(new EmbedCodeParser(code));

            // when the instagram embed is first added the block doesn't exist yet, but will exist when editing
            const blockExists = this.block.holder;

            if (!blockExists) {
              this.api.blocks.insert('instagram', this.data, {}, this.editorBlockIndex);
            } else {
              this.block.holder.appendChild(this.render());
            }

            // update the redux article state
            ToolEventObserver.trigger(EVENT_EMBED_EDIT, {editorBlockIndex: this.editorBlockIndex});
          });
      } catch (err) {
        logger.error(err);
      }
    }

    // Editor.js expects render() to return a valid Node otherwise will throw console error:
    // `Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'.`
    // But returning a node here will insert empty blocks into the editor which are
    // not necesssary and can cause unintended side effects
    // @see CTT-940/CTT-950
    // Note we are currently supressing the console error in `src/client.js`
    // and cypress uncaught:exception in `cypress/support/index.js`
    this.nodes.post = null;
  }

  renderPost() {
    const postId = this.data.id;
    if (!postId) {
      return;
    }

    // either add the instagram script or trigger the script to process again
    if (!document.getElementById(this.scriptId)) {
      const script = this.renderInstagramScript();
      document.body.appendChild(script);
    } else if (typeof window.instgrm !== 'undefined') {
      // allow post to be rendered first
      setTimeout(() => {
        window.instgrm.Embeds.process();
      });
    }

    const content = this.renderContentBody();
    const blockquote = this.renderBlockquote();
    blockquote.appendChild(content);
    addDiffClass(blockquote, this.data);
    this.nodes.post = blockquote;
  }

  render() {
    this.isNewBlock() ? this.renderNewPostFromEmbed() : this.renderPost();

    // index / getCurrentBlockIndex() needs to be set during render() to catch new blocks being added
    // inside renderNewPostFromEmbed() it only correctly grabs the index for edit/replace events, not new blocks being added
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    if (currentBlockIndex >= 0) this.editorBlockIndex = currentBlockIndex;

    return this.nodes.post;
  }

  renderContentBody() {
    const {text} = this.data.metadata.instagramJson;
    const content = document.createElement('div');
    content.innerHTML = text;
    return content;
  }

  renderBlockquote() {
    const url = this.data.url ? this.data.url : this.data.file;
    const blockquote = document.createElement('blockquote');
    blockquote.classList.add('instagram-media');
    blockquote.classList.add('preload-media');
    blockquote.setAttribute('data-instgrm-permalink', url.replace(/&#x2F;/g, '/'));
    return blockquote;
  }

  renderInstagramScript() {
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.key = this.scriptId;
    script.id = this.scriptId;
    script.async = true;
    return script;
  }

  isNewBlock() {
    return Object.keys(this.data).length === 0;
  }

  save() {
    return this.data;
  }

  validate(savedData) {
    if (!savedData) return false;
    return true;
  }
}
