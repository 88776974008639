import './index.css';

export default class LinkOpen {
  static get isInline() {
    return true;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  constructor({api}) {
    this.api = api;
    this.button = null;
    this._state = false;

    this.url = '';
    this.linkOpen = '';
    this.css = {
      button: 'ce-inline-tool--linkopen',
      link: 'ce-inline-tool-linkopen',
      linkShowed: 'ce-inline-tool-linkopen--showed',
    };
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML =
      '<svg class="icon icon--link" width="14px" height="10px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#link"></use></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton, this.css.button);

    return this.button;
  }

  renderActions() {
    this.linkOpen = document.createElement('button');
    this.linkOpen.classList.add(this.css.link);
    this.linkOpen.innerHTML = 'Open link';
    this.linkOpen.addEventListener('click', () => {
      window.open(this.url);
    });
    return this.linkOpen;
  }

  surround() {}

  checkState() {
    // check selection and set visible button state if there's an <a> tag
    const anchorTag = this.api.selection.findParentTag('A');
    if (anchorTag) {
      this.url = anchorTag.href;
      this.linkOpen.classList.add(this.css.linkShowed);
    } else {
      this.linkOpen.classList.remove(this.css.linkShowed);
    }
    this.state = !!anchorTag;
  }
}
