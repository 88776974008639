import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as promptTypes from '../../../constants/prompt';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_OK} from '../../../constants/prompt';
import {isPromptMuted, mutePrompt} from '../../../helpers/promptHelper';
import {getActiveSlide} from '../../../selectors/article';

const Prompt = (props) => {
  const {
    additionalInput,
    cancel,
    confirm,
    defaultValue,
    focusTrapRef,
    text,
    title,
    type,
    onCanceled,
    onConfirmed,
    userInputValidate,
    withAdditionalInput,
    withMute,
    withUserInput,
  } = props;
  const muteRef = useRef(null);
  const textRef = useRef(null);
  const additionalRef = useRef(null);

  const [error, setError] = useState(null);
  const [errorAdditional, setErrorAdditional] = useState(null);

  const isTitleSlide = useSelector(getActiveSlide) === 0; // should return true both for standard articles and slideshow title slides

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current && textRef.current.focus();
      // autofocus prompt, delay added to fix editor.js body steal focus after prompt openned
      setTimeout(() => textRef.current && textRef.current.focus(), 100);
    }
  }, []);

  const showAdditionalInput = () => {
    if (!withAdditionalInput) return false;
    if (!isTitleSlide && additionalInput?.restrictToTitleSlides) return false;
    return true;
  };

  const onTextChanged = () => error && setError(null);

  const onAdditionalChanged = () => errorAdditional && setErrorAdditional(null);

  const onYesClicked = () => {
    if (muteRef.current && muteRef.current.checked) mutePrompt(type);

    // check if user input has an error
    const input = textRef.current ? textRef.current.value : null;
    const inputError = userInputValidate(input);
    if (inputError) setError(inputError);

    // check if there's an additional input
    const additional = additionalRef.current;
    const input2 = (additional && (additional.type === 'checkbox' ? additional.checked : additional.value)) ?? null;

    // check if additional input has an error
    const input2Error = withAdditionalInput && additionalInput.validate ? additionalInput.validate(input2) : null;
    if (input2Error) setErrorAdditional(input2Error);

    // if no errors, continue
    const hasError = inputError || input2Error;
    if (!hasError && input2) onConfirmed(input, input2);
    else if (!hasError) onConfirmed(input);
  };

  const onNoClicked = () => {
    onCanceled && onCanceled();
  };

  if (isPromptMuted(type)) {
    // Target prompt was muted by client, go directly to the "Yes" branch
    onConfirmed();
    return null;
  }

  return (
    <div ref={focusTrapRef} className={classnames('atom', 'prompt', {'with-input': withUserInput})}>
      <div className="prompt-confirmation">
        <h3 className="prompt-title">{title}</h3>
        {text && <p>{text}</p>}
        {withUserInput && (
          <div className="prompt-input">
            <textarea ref={textRef} onChange={onTextChanged} autoFocus={true} defaultValue={defaultValue} />
            {error && <div className="prompt-error">{error}</div>}
          </div>
        )}
        {showAdditionalInput() && additionalInput.type === 'checkbox' && (
          <div className="prompt-input-additional">
            <label>
              <input ref={additionalRef} type="checkbox" onChange={onAdditionalChanged} defaultChecked={additionalInput.defaultValue} />
              {additionalInput.label}
            </label>
            {errorAdditional && <div className="prompt-error">{errorAdditional}</div>}
          </div>
        )}
        {showAdditionalInput() && additionalInput.type !== 'checkbox' && (
          <div className="prompt-input-additional">
            <label>{additionalInput.label}</label>
            <input ref={additionalRef} type={additionalInput.type} onChange={onAdditionalChanged} defaultValue={additionalInput.defaultValue} />
            {errorAdditional && <div className="prompt-error">{errorAdditional}</div>}
          </div>
        )}
        <div className="prompt-control">
          {cancel && (
            <button className="button--cancel button-textonly" onClick={onNoClicked}>
              {cancel}
            </button>
          )}
          <button className="button--ok" onClick={onYesClicked}>
            {confirm}
          </button>
        </div>
        {!withUserInput && withMute && (
          // do not render mute control if prompt require user input
          <div className="prompt-mute">
            <label>
              <input ref={muteRef} type="checkbox" /> Don't show me this again
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

Prompt.defaultProps = {
  additionalInput: null,
  cancel: PROMPT_BUTTON_CANCEL,
  confirm: PROMPT_BUTTON_OK,
  text: null,
  title: 'Are you sure?',
  userInputValidate: () => null,
  withAdditionalInput: false,
  withMute: true,
  withUserInput: false,
};

Prompt.propTypes = {
  additionalInput: PropTypes.shape({
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    restrictToTitleSlides: PropTypes.bool,
    type: PropTypes.string,
    validate: PropTypes.func,
  }),
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  focusTrapRef: PropTypes.func,
  onCanceled: PropTypes.func,
  onConfirmed: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(promptTypes)).isRequired,
  userInputValidate: PropTypes.func,
  withAdditionalInput: PropTypes.bool,
  withMute: PropTypes.bool,
  withUserInput: PropTypes.bool,
};

export default Prompt;
