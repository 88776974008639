export const PROMPT_TWEET_EMBED = 0;
export const PROMPT_AUTHOR_SWAP = 1;
export const PROMPT_AUTHOR_SWAP_ERROR_EMAIL = 2;
export const PROMPT_INSTAGRAM_EMBED = 3;
export const PROMPT_IFRAME_EMBED = 4;
export const PROMPT_IDLE_TIMER = 5;
export const PROMPT_VERSIONS_RESTORE = 6;
export const PROMPT_MEDIA_SEARCH_NO_RESULTS = 7;
export const PROMPT_STATMILK_EMBED = 8;
export const PROMPT_SLIDE_DELETE_CONFIRM = 9;
export const PROMPT_EMBED_EDIT = 10;
export const PROMPT_PUBLISH = 11;
export const PROMPT_SLIDE_CHANGE_UNSAVED = 12;
export const PROMPT_SLIDE_ADD_CONFIRM = 13;
export const PROMPT_SLIDE_REORDER_ERROR_TITLE = 14;
export const PROMPT_DELETE_CONFIRM = 15;
export const PROMPT_DELETE_RESTORE = 16;
export const PROMPT_STATUS_CHANGE = 17;
export const PROMPT_SOCIAL_EDIT_CONFIRM = 18;
export const PROMPT_DATE_RANGE_CONFIRM = 19;
export const PROMPT_RELEASE_LOCK_UNSAVED = 20;
export const PROMPT_UNSCHEDULE = 21;

export const PROMPT_BUTTON_CANCEL = 'Cancel';
export const PROMPT_BUTTON_CONFIRM = 'Yes';
export const PROMPT_BUTTON_CONTINUE_UNSAVED = 'Continue and lose changes';
export const PROMPT_BUTTON_OK = 'OK';
export const PROMPT_BUTTON_SAVE = 'Save';
