import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {clearEditors} from '../../actions/editorStats';
import {isAdmin, isAnyEditor} from '../../helpers/articleHelpers';
import {canAccessEditorStatsRoster} from '../../helpers/permissions';
import {getUserId} from '../../helpers/userHelpers';
import {isEditorStatsFeatureEnabled} from '../../utils/features';

import {selectError} from '../../selectors/editorStats';
import Filters from '../molecules/editorStatsFiltersRoster';
import PageError from '../atoms/pageError';
import PageTitle from '../atoms/pageTitle';
import PermissionDenied from './permissionDenied';
import TableRoster from '../molecules/editorStatsTableRoster';

const EditorStats = () => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shouldRedirectToEditorPage = isAnyEditor() && !isAdmin(); // roster page only visible to admins, editors can view their own page
  const title = 'Editor Stats';

  useEffect(() => {
    if (!isEditorStatsFeatureEnabled()) navigate('/', {replace: true});
    if (shouldRedirectToEditorPage) navigate(`${getUserId()}`, {replace: true});
    return () => dispatch(clearEditors());
  }, []);

  if (!canAccessEditorStatsRoster()) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to access editor stats.</p>
      </PermissionDenied>
    );
  }

  return (
    <section className="organism editor-stats">
      <PageTitle title={title} />
      <h1>Editor Stats</h1>
      {error ? (
        <>
          <PageError what="error fetching editor stats" />
        </>
      ) : (
        <>
          <Filters />
          <TableRoster />
        </>
      )}
    </section>
  );
};

export default EditorStats;
