import reporter from '../../reporter';

import logger from '../../logger';

import {AP} from '../../endpoints';
import {IMAGE_SEARCH_FILTERED_PAGE_SIZE, IMAGE_SOURCE_AP} from '../../constants/media';

const SEC = 1000; // milliseconds

/**
 * "Convert" a raw image search result into an object for EditorJS to handle
 *
 * @param {object} rawData raw image search result
 * @returns {object} bare minimum data to allow EditorJS to create a content block
 */
function transformImageData(rawData) {
  const {description_caption, firstcreated, renditions, altids} = rawData.item;
  return {
    caption: description_caption,
    dateCreated: firstcreated,
    fullUrl: `${renditions.main.href}&apikey=${process.env.AP_MEDIA_API_KEY}`,
    imageId: altids.itemid,
    // orientation: renditions.main.orientation, // "Vertical" or "Horizontal"… TODO: pass this data up & use in the UI if it's available
    source: IMAGE_SOURCE_AP,
    urlComp: renditions.preview.href,
  };
}

/**
 * Builds query params for a single page of search results from AP
 *
 * @param {string} query the search term
 * @returns {object} query params
 */
export function singlePageSearchParams(query) {
  return {
    q: encodeURI(query.replace(/[\s+]+/g, '+')),
    page_size: IMAGE_SEARCH_FILTERED_PAGE_SIZE,
    sort: 'versioncreated:desc',
    apikey: process.env.AP_MEDIA_API_KEY,
  };
}

/**
 * Decorator for `fetch` which will timeout after a customizable amount of
 * time. This allows us to control the timeout length, vs relying on the
 * browser default. Params are same as `fetch`, with the addition of `timeout`
 * to the `options` second param.
 *
 * @param {string} resource the url to fetch from
 * @param {object} options fetch options object
 * @returns {Promise}
 */
async function fetchWithTimeout(resource, options = {}) {
  const {timeout = 5 * SEC} = options;
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  const promise = await fetch(resource, {...options, signal: controller.signal});
  clearTimeout(timeoutId); // if the fetch completes before `timeout` has elapsed, then we don't need the timer any more
  return promise;
}

/**
 * Fetches a url with key from the AP's "Media API", and resolves the promise with an object
 * containing `itemTotalCount` and `assets`, in order to mimic the format of the CMS backend.
 * The default behavior of the `fetch` Promise has been modified so that it *will* reject if
 * the response has an error attribute (e.g. for HTTP errors). It will also reject if the
 * request takes too long (rather than relying on the browser's timeout behavior).
 *
 * @param {string} query query string to look for
 * @param {number} page page number
 * @param {string} paginationUrlTemplate template for next page of search results
 * @returns {Promise<object>}
 */
export async function searchImages({query, page = 1, paginationUrlTemplate}) {
  const url =
    page && page !== 1 && paginationUrlTemplate
      ? `${paginationUrlTemplate.replace('{PAGE_NUMBER}', page)}&apikey=${process.env.AP_MEDIA_API_KEY}`
      : AP.search(singlePageSearchParams(query));
  return fetchWithTimeout(url, {method: 'GET'})
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        logger.error('Error searching AP', {query, page, paginationUrlTemplate});
        logger.error(response.error);
        reporter.inform(response.error);
        throw response.error;
      }
      const images = response.data.items.filter(({item: {type}}) => type === 'picture');
      return {
        itemTotalCount: response.data.total_items,
        assets: images.map(transformImageData),
        page,
        paginationUrlTemplate: response.data.page_template,
      };
    });
}
