import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import {getArticle} from '../../apis/articles/articlesAPI';
import {receiveArticle} from '../../actions/article';
import {getUserProfilesByIds} from '../../actions/users';
import {TYPE_SLIDESHOW} from '../../constants/article';
import {isCreateAllowed} from '../../helpers/articleHelpers';
import PageTitle from '../atoms/pageTitle';
import ArticleForm from '../molecules/articleForm';
import PermissionDenied from './permissionDenied';

import reporter from '../../reporter';
import logger from '../../logger';

const Article = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isNew = id === 'new';
  const [title, setTitle] = useState(`${isNew ? 'New' : 'Edit'} ${location.pathname.includes(TYPE_SLIDESHOW) ? 'Slideshow' : 'Standard'} Article`);

  if (isNew && !isCreateAllowed()) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to create new articles.</p>
      </PermissionDenied>
    );
  }

  useEffect(() => {
    if (!isNew && 'isAppLink' in location === false) {
      const fetchAndDisplayArticle = async () => {
        const article = await getArticle(id);
        setTitle(article.title);
        dispatch(getUserProfilesByIds([article.editedByGatekeeperId]));
        dispatch(
          receiveArticle({
            article,
            error: !!article.error,
          })
        );
      };

      try {
        fetchAndDisplayArticle();
      } catch (error) {
        logger.error(error);
        reporter.inform(error);
      }
    }
  }, []);

  return (
    <section className="organism article">
      <PageTitle title={title} />
      <ArticleForm id={isNew ? '' : id} isNewArticle={isNew} isNewSlideshow={false} />
    </section>
  );
};

export default Article;
