import {createAction} from '@reduxjs/toolkit';

import * as ArticlesAPI from '../apis/articles/articlesAPI';
import * as types from '../constants/action-types';

import logger from '../logger';
import reporter from '../reporter';

export const clearAction = createAction(types.SEARCH_ACTION_CLEAR);
export const clearSearch = createAction(types.SEARCH_CLEAR);
export const searchResults = createAction(types.SEARCH_RESULTS);
export const setAction = createAction(types.SEARCH_ACTION);
export const setCursorLast = createAction(types.SEARCH_CURSOR_LAST);
export const setDateEnd = createAction(types.SEARCH_DATE_END);
export const setDateStart = createAction(types.SEARCH_DATE_START);
export const setError = createAction(types.SEARCH_ERROR);
export const setFetching = createAction(types.SEARCH_FETCHING);
export const setInputQuery = createAction(types.SEARCH_INPUT_QUERY);
export const setInputType = createAction(types.SEARCH_INPUT_TYPE);
export const setSortDirection = createAction(types.SEARCH_SORT_DIRECTION);
export const setSortKey = createAction(types.SEARCH_SORT_KEY);

const getTitleById = (articles, id) => {
  if (!articles || !id) return null;
  return articles.find((x) => x.id === id).title;
};

/**
 * Fetch Search Articles
 *
 * @param {string} fetchCursor paginated cursor search string
 * @param {string} page paginated cursor search should pass an updated page number (next/prev), fresh search default to first page
 */
export const fetchSearch = (fetchCursor, page = 1) => {
  return async (dispatch, getState) => {
    const {dateEnd, dateStart, fetching, inputQuery, inputType, limit} = getState().search;
    if (fetching) return;

    dispatch(setFetching(true));

    try {
      // set params depending on whether this is a paginated (cursor) search, or new query via filters
      const isCursorSearch = fetchCursor && typeof fetchCursor === 'string';
      const params = {
        endDate: dateEnd,
        limit,
        searchInput: inputQuery,
        searchType: inputType,
        startDate: dateStart,
      };

      if (isCursorSearch) params.cursor = fetchCursor;

      const results = await ArticlesAPI.getArticles(params);
      if (results.error) throw results.error;

      await dispatch(searchResults({articles: results.articles, count: results.count, cursor: results.cursor, page}));
      dispatch(setFetching(false));
    } catch (error) {
      dispatch(setError(error));
      logger.error(error);
      reporter.inform(error);
    }
  };
};

/**
 * Make Article Deleted
 *
 * @param {string} id article id to be deleted
 */
export const makeArticleDeleted = (id) => {
  return async (dispatch, getState) => {
    const {articles, dateEnd, dateStart, fetching, inputQuery, inputType, limit} = getState().search;
    if (fetching) return;

    dispatch(setFetching(true));
    await ArticlesAPI.deleteArticle(id);

    const results = await ArticlesAPI.getArticles({
      cursor: null,
      limit,
      searchInput: inputQuery,
      searchType: inputType,
      startDate: dateStart,
      endDate: dateEnd,
    });
    await dispatch(searchResults({articles: results.articles, count: results.count, cursor: results.cursor}));
    await dispatch(setAction({status: `'${getTitleById(articles, id)}' deleted`, complete: true}));
    dispatch(setFetching(false));
  };
};
