import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isAdmin, isQCEditor} from '../../../helpers/articleHelpers';

import {deleteEditorPrimary, saveEditorPrimary} from '../../../actions/article';
import {getEditorPrimary, getId, getIsReadOnly} from '../../../selectors/article';

const UserEditorButton = () => {
  const articleId = useSelector(getId);
  const editorPrimary = useSelector(getEditorPrimary);
  const isReadOnly = useSelector(getIsReadOnly);
  const dispatch = useDispatch();

  const handleAdd = () => {
    return dispatch(saveEditorPrimary(articleId));
  };

  const handleDelete = () => {
    return dispatch(deleteEditorPrimary(articleId));
  };

  if (!isAdmin() && !isQCEditor()) return null;
  if (editorPrimary !== null)
    return (
      <button className="button-delete" disabled={isReadOnly} onClick={() => handleDelete()} title="Delete Primary QC Editor">
        <span className="sr-only">Delete</span>
      </button>
    );
  return (
    <button className="button-add" disabled={isReadOnly} onClick={() => handleAdd()} title="Assign myself as the Primary QC Editor">
      Assign Myself
    </button>
  );
};

export default UserEditorButton;
