import logger from '../logger';
import * as types from '../constants/action-types';

import {transformElements} from '../helpers/jsonTransform';
import {detectBlocksChange, detectMetadataChange, isSlideshowChanged, removeIdFromFeaturedMedia} from './helpers/articleChanges';
import {addMediaBlock, addMediaBlockSlide, createNewArticleSlides, getArticleAuthor} from '../helpers/articleHelpers';
import {STATUS_PUBLISHED} from '../constants/article';

export const initialState = {
  activeSlide: 0,
  currentEditorsGkIds: [],
  data: null,
  dataOriginal: null,
  error: false,
  fetching: false,
  isChanged: false,
  isChangedMetadata: false,
  isReadOnly: false,
  isSlideshow: false,
};

export const initialStateData = {
  ...initialState,
  data: {
    title: 'Title goes here',
  },
};

export const initialStateSlideshow = {
  ...initialState,
  isSlideshow: true,
};

function transformSlide(rawSlide) {
  const {contentType, elements, ...rest} = rawSlide;
  if (!contentType) {
    logger.debug('missing a contentType...', rawSlide);
    return null;
  }
  if (contentType !== 'slide') {
    // only transform slides, and not ad blocks... these will be filtered out
    return null;
  }
  return {
    ...rest,
    elements: transformElements(elements),
    type: contentType,
  };
}

const article = (state = initialState, action) => {
  switch (action.type) {
    case types.ARTICLE_STANDARD_INIT: {
      const article = {
        title: '',
        elements: [],
        feedbacks: [],
      };
      return {
        ...state,
        data: article,
        dataOriginal: article,
        isSlideshow: false,
        error: false,
      };
    }

    case types.ARTICLE_SLIDESHOW_INIT: {
      const article = {
        title: `Slideshow ${new Date().getTime()}`,
        elements: createNewArticleSlides(),
        feedbacks: [],
      };
      return {
        ...state,
        data: article,
        dataOriginal: article,
        isSlideshow: true,
        error: false,
      };
    }

    case types.ARTICLE_RECEIVE: {
      const {article, error, activeSlide = 0} = action.payload;
      let elements;
      const isSlideshow = article?.renderStrategy === 'slideshow' || false;
      if (isSlideshow) {
        elements = (article.elements || []).map((slide) => transformSlide(slide)).filter((transformedSlide) => !!transformedSlide);
        delete article.isSoftLockExpired; // TODO: why does the backend return `isSoftLockExpired: true` for slideshows?
      } else {
        elements = transformElements(article.elements || []);
      }
      const data = {
        ...article,
        elements,
      };
      return {
        ...state,
        error,
        data,
        dataOriginal: data,
        isChanged: false,
        isChangedMetadata: false,
        isSlideshow,
        activeSlide,
      };
    }

    case types.ARTICLE_CLEAR: {
      return {
        ...state,
        data: null,
        dataOriginal: null,
        activeSlide: 0,
        error: false,
        isChanged: false,
        isChangedMetadata: false,
      };
    }

    case types.SET_FETCHING: {
      return {
        ...state,
        fetching: action.payload,
      };
    }

    case types.SET_ACTION: {
      const {complete, error = false, status} = action.payload;
      return {
        ...state,
        action: {
          status,
          complete,
          error,
        },
      };
    }

    case types.CLEAR_ACTION: {
      return {
        ...state,
        action: null,
      };
    }

    case types.SET_IDLETIMER: {
      return {
        ...state,
        idleTimer: action.payload,
      };
    }

    case types.ARTICLE_UPDATE_DATA: {
      // compare the 'current' and 'original' article data to see if there are changes
      // the payload only includes the latest change, so if multiple fields have been modified since
      // the last save, only the last field changed will be included in the payload
      const draft = {...state};
      let {isChanged, isChangedMetadata} = state;
      const {blocks, ...properties} = action.payload.properties;
      // omit the placeholder featured media block from comparisons because it is never saved with the article data
      // and will trigger the isChanged event (enabling the save button) when the Editor adds it to an article
      const blocksWithoutPlaceholder = blocks?.filter?.((block) => block.type !== 'placeholder');

      if (blocks) {
        // updated article contents... should be in the shape {type, data}
        if (!draft.data) draft.data = {};
        // Featured media blocks have no id when initially added to an article. We must ensure they
        // remain that way until the article is saved, or the UI holding the featured media will
        // refresh when the user begins adding text to the article body, causing a jump/stutter
        removeIdFromFeaturedMedia(blocks);
        draft.data.elements = blocks;
      }

      if (blocks && state.dataOriginal?.elements) {
        // detect changes in metadata...
        const dataTemp = {...state.data, ...properties};
        isChangedMetadata = detectMetadataChange({dataTemp, dataOriginal: state.dataOriginal});

        isChanged = detectBlocksChange(state.dataOriginal.elements, blocksWithoutPlaceholder);
      } else if (state.dataOriginal) {
        const dataTemp = {...state.data, ...properties};

        // detect changes in content...
        if (dataTemp.title && dataTemp.title !== state.dataOriginal.title) isChanged = true;

        // detect changes in metadata...
        isChangedMetadata = detectMetadataChange({dataTemp, dataOriginal: state.dataOriginal});
      }

      // set article author if it's not part of the payload properties, draft or saved article
      // (early in the history of this project, it was possible to save a draft/article without an author)
      if (!properties.author && state.dataOriginal && !state.dataOriginal.author && (!state.data || !state.data.author)) {
        properties.author = getArticleAuthor();
        isChangedMetadata = true;
      }

      draft.isChanged = isChanged;
      draft.isChangedMetadata = isChangedMetadata;

      return {
        ...draft,
        data: {...state.data, ...properties},
      };
    }

    case types.ARTICLE_SET_MEDIA: {
      const {mediaBlock} = action.payload;
      const elements = addMediaBlock(state.data.elements, mediaBlock);

      return {
        ...state,
        data: {...state.data, elements},
        isChanged: true,
      };
    }

    case types.ARTICLE_SLIDESHOW_UPDATE_DATA: {
      const {blockIndex, properties, articleProperties} = action.payload;
      const propertiesArticle = {...articleProperties};
      const elements = [
        ...state.data.elements.slice(0, blockIndex),
        {
          ...state.data.elements[blockIndex],
          ...properties,
        },
        ...state.data.elements.slice(blockIndex + 1),
      ];

      for (let i = 0; i < elements.length; i++) {
        // Featured media blocks have no id when initially added to a slide. We must ensure they
        // remain that way until the slide is saved, or the UI holding the featured media will
        // refresh when the user begins adding text to the slide body, causing a jump/stutter
        removeIdFromFeaturedMedia(elements[i].elements);
      }

      // set article author if it's not part of the draft or saved article
      // (early in the history of this project, it was possible to save a draft/article without an author)
      if (state.dataOriginal && !state.dataOriginal.author && (!state.data || !state.data.author)) {
        propertiesArticle.author = getArticleAuthor();
      }

      // set the 'current' data
      const currentData = {...state.data, elements, ...propertiesArticle};

      let isChanged, isChangedMetadata;
      if (state.dataOriginal) {
        isChanged = isSlideshowChanged(state.dataOriginal, currentData);
        isChangedMetadata = detectMetadataChange({
          dataTemp: {...state.data, ...propertiesArticle},
          dataOriginal: state.dataOriginal,
        });
      } else {
        // new slideshows don't have dataOriginal
        isChanged = true;
        isChangedMetadata = true;
      }

      return {
        ...state,
        data: currentData,
        isChanged,
        isChangedMetadata,
      };
    }

    case types.ARTICLE_SLIDESHOW_REORDER_SLIDES: {
      const {index, newIndex} = action.payload;
      const elements = [...state.data.elements];
      const [moved] = elements.splice(index, 1);
      elements.splice(newIndex, 0, moved);

      return {
        ...state,
        data: {...state.data, elements},
      };
    }

    case types.ARTICLE_SLIDESHOW_ADD_SLIDES: {
      const {count} = action.payload;
      const {status} = state.data;

      // get the highest ID and increment by 1 as the next starting ID
      const ids = state.data.elements.map((slide) => slide.id);
      const slideIdStartsAt = Math.max(...ids) + 1;

      const newSlides = createNewArticleSlides(count, slideIdStartsAt);
      const elements = [...state.data.elements, ...newSlides];
      const activeSlideIndex = elements.length - 1;

      // when adding a slide to a...
      // - draft (saved) slideshow, the new slide will be saved to articles backend immediately
      // - draft (unsaved) slideshow, the new slide is NOT saved until the entire article is saved for the first time
      // - published slideshow, the new slide is NOT saved so we need to enable the save button
      // we only need to trigger the save button to enable in the case of a published slideshow
      const isChanged = status === STATUS_PUBLISHED;

      return {
        ...state,
        data: {...state.data, elements},
        activeSlide: activeSlideIndex,
        isChanged,
      };
    }

    case types.ARTICLE_SLIDESHOW_REMOVE_SLIDES: {
      const {slideId} = action.payload;
      const {activeSlide} = state;
      const elements = state.data.elements.filter((_) => _.id !== slideId);
      // by default the active slide should be the same as the it was before removing, unless the previous active slide is now deleted
      const newActiveSlide = activeSlide >= elements.length ? elements.length - 1 : activeSlide;

      return {
        ...state,
        data: {...state.data, elements},
        activeSlide: newActiveSlide,
      };
    }

    case types.ARTICLE_SLIDESHOW_SELECT_SLIDE: {
      const activeSlideIndex = action.payload;
      return {...state, activeSlide: activeSlideIndex};
    }

    case types.ARTICLE_SLIDESHOW_SET_MEDIA: {
      if (!state.data || !state.data.elements || !state.data.elements.length) {
        logger.debug('Article reducer setting media, but state is missing elements…', state.data);
      }

      const {mediaBlock, blockIndex} = action.payload;

      if (mediaBlock?.video?.video) {
        const video = mediaBlock.video.video;
        // make sure the correct data is being sent to render the video in the editor and convert mediaBlock type to video
        const {id, hls, mp4, title, description, videoUrl, thumbnailUrl, providerUrl, tags} = video;

        mediaBlock.data = {
          metadata: {
            caption: '',
            description,
            hlsMediaUrl: hls,
            mp4,
            providerUrl,
            tags,
            thumbnail: thumbnailUrl,
            title,
            videoId: id,
            videoUrl,
          },
          __original__: {
            metadata: {
              videoId: id,
              caption: '',
              thumbnail: thumbnailUrl,
            },
          },
        };
        mediaBlock.type = 'video';
      }

      const updatedSlide = addMediaBlockSlide(state.data.elements[blockIndex], mediaBlock);
      const slides = [...state.data.elements.slice(0, blockIndex), updatedSlide, ...state.data.elements.slice(blockIndex + 1)];

      return {
        ...state,
        data: {...state.data, elements: slides},
        isChanged: true,
      };
    }

    case types.ARTICLE_UPDATE_READONLY: {
      const {isReadOnly} = action.payload;
      return {
        ...state,
        isReadOnly,
      };
    }

    case types.ARTICLE_UPDATE_STATUS: {
      const {status} = action.payload;
      if (status === null) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          status,
        },
      };
    }

    case types.ARTICLE_CREATED: {
      const {id} = action.payload;
      if (id === null) {
        return state;
      }
      return {
        ...state,
        isChanged: false, // Make article unchanged
        isChangedMetadata: false, // Make article unchanged
        data: {
          ...state.data,
          breportId: id, // Set created article ID into state
        },
      };
    }

    case types.ARTICLE_UPDATED: {
      // when save request completes, this should be triggered with isChanged:false
      // and then SET_ACTION complete:true error:false
      const {payload} = action;
      if (payload.articleId) {
        // standard articles will save both content and metadata in the same request
        return {
          ...state,
          isChanged: false,
          isChangedMetadata: false,
        };
      }
      if (payload.slideshowId && payload.metadata) {
        // the metadata (of a slideshow) has just been saved
        return {
          ...state,
          dataOriginal: state.data,
          isChangedMetadata: false,
        };
      }
      if (payload.slideId) {
        // the content of a single slide has just been saved
        return {
          ...state,
          isChanged: false,
        };
      }
      return state;
    }

    case types.ARTICLE_DELETED: {
      return {
        ...state,
        id: action.payload.id,
      };
    }

    case types.ARTICLE_ADD_FEEDBACK: {
      const {feedback} = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          feedbacks: [feedback, ...state.data.feedbacks],
        },
      };
    }

    case types.ARTICLE_LOCK_BYPASS: {
      const {editedByGatekeeperId} = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          editedByGatekeeperId,
        },
        isReadOnly: false,
      };
    }

    case types.ARTICLE_LOCK_BYPASS_UPDATE_STATUS: {
      const {editedByGatekeeperId, status} = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          editedByGatekeeperId,
          status,
        },
        isReadOnly: false,
      };
    }

    case types.ARTICLE_LOCK_RELEASE: {
      return {
        ...state,
        data: {
          ...state.data,
          editedByGatekeeperId: null,
        },
        isChanged: false,
        isChangedMetadata: false,
        isReadOnly: true,
      };
    }

    case types.ARTICLE_LOCK_SET: {
      const {editedByGatekeeperId} = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          editedByGatekeeperId,
        },
      };
    }

    case types.ARTICLE_EDITOR_PRIMARY: {
      const {primaryEditorGatekeeperId} = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          primaryEditorGatekeeperId,
        },
      };
    }

    case types.ARTICLE_SCHEDULED_AT: {
      const {scheduledAt} = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          scheduledAt,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default article;
