import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import PageTitle from '../atoms/pageTitle';

const PermissionDenied = ({children: content, title}) => {
  return (
    <section className="organism permission-denied">
      <PageTitle title={title} />
      <h1>{title}</h1>
      <div className="content">
        <h2>Permission Denied</h2>
        {content}
        <Link to="/">&rsaquo; Return to Dashboard</Link>
      </div>
    </section>
  );
};

PermissionDenied.defaultProps = {
  title: 'Permission Denied',
};

PermissionDenied.propTypes = {
  title: PropTypes.string,
};

export default PermissionDenied;
