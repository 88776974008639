import {createSelector} from 'reselect';

const searchSelector = (state) => state.search;

export const getSearchState = createSelector([searchSelector], (search) => search);

export const selectAction = createSelector([searchSelector], (search) => search.action);

export const selectArticles = createSelector([searchSelector], (search) => search.articles);

export const selectCount = createSelector([searchSelector], (search) => search.count);

export const selectCursorCurrent = createSelector([searchSelector], (search) => search.cursorCurrent);

export const selectCursorLast = createSelector([searchSelector], (search) => search.cursorLast);

export const selectDateEnd = createSelector([searchSelector], (search) => search.dateEnd);

export const selectDateStart = createSelector([searchSelector], (search) => search.dateStart);

export const selectError = createSelector([searchSelector], (search) => search.error);

export const selectHasMore = createSelector([searchSelector], (search) => search.hasMore);

export const selectInputQuery = createSelector([searchSelector], (search) => search.inputQuery);

export const selectInputType = createSelector([searchSelector], (search) => search.inputType);

export const selectLimit = createSelector([searchSelector], (search) => search.limit);

export const selectLoading = createSelector([searchSelector], (search) => search.fetching);

export const selectPage = createSelector([searchSelector], (search) => search.page);

export const selectSortDirection = createSelector([searchSelector], (search) => search.sortDirection);

export const selectSortKey = createSelector([searchSelector], (search) => search.sortKey);
