// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unsupported {
  width: 100%;
  min-height: 6rem;
  margin: 0 auto;
  padding: 3rem 1.5rem;
  background: #eee;
  color: #a6a6a6;
  line-height: 1;
}

.unsupported .header {
  font-weight: bold;
}

.unsupported .content {
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/unsupported/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".unsupported {\n  width: 100%;\n  min-height: 6rem;\n  margin: 0 auto;\n  padding: 3rem 1.5rem;\n  background: #eee;\n  color: #a6a6a6;\n  line-height: 1;\n}\n\n.unsupported .header {\n  font-weight: bold;\n}\n\n.unsupported .content {\n  margin-top: 1rem;\n  font-size: 1.2rem;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
