import reporter from '../../reporter';
import jwtDecode from 'jwt-decode';

import {fetchJSON, postFetchOpts} from '../helpers/fetch';
import {Gatekeeper} from '../../endpoints';
import logger from '../../logger';

import {grantTypes, scopes} from '../../constants';
import {getAuthTokens} from '../../helpers/authHelpers';

// gatekeeper api limit is 30 entries. If passed more than 30 ids, the service returns 422
export const GATEKEEPER_API_LIMIT = 30;

const userHasPermission = (tokenScopes) => {
  const hasPermission = tokenScopes && tokenScopes.includes(scopes.ACCESS_ARTICLE_ADMIN);
  if (!hasPermission) {
    throw new Error('Invalid permissions');
  }
  return true;
};

export const createJWT = async ({username, password}) => {
  const opts = postFetchOpts({
    grantType: grantTypes.PASSWORD,
    username,
    password,
  });

  const {accessToken, refreshToken} = await fetchJSON(Gatekeeper.oauth(), opts).catch((err) => {
    logger.error(`Authentication failed: ${err}`);
    return {err};
  });

  const {sub, scopes} = jwtDecode(accessToken);

  return (
    userHasPermission(scopes) && {
      accessToken,
      refreshToken,
      userId: sub,
    }
  );
};

export const refreshJWT = async (refresh) => {
  const opts = postFetchOpts({
    grantType: grantTypes.REFRESH_TOKEN,
    refreshToken: refresh,
  });

  return fetchJSON(Gatekeeper.oauth(), opts).catch((err) => {
    reporter.inform(err);
    logger.error(`Refreshing expired JWT failed: ${err}`);
    return {err};
  });
};

export const fetchUser = (userId, jwt) => {
  return fetchJSON(Gatekeeper.userPrivate(userId), {
    jwt,
  }).catch((err) => {
    reporter.inform(err);
    logger.error(`Error fetching User: ${err}`);
    return {
      err,
    };
  });
};

export const getUsersProfiles = (ids) => {
  const url = Gatekeeper.getUsersProfiles();
  const {accessToken} = getAuthTokens();
  return fetchJSON(url, postFetchOpts({ids, jwt: accessToken})).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error users profiles loading!');
    return {error: true};
  });
};

export const getUserProfileByProfileId = (profileId) => {
  const url = Gatekeeper.getUserProfile(profileId);
  return fetchJSON(url).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error user profile loading!');
    return {error: true};
  });
};

export const getUserByEmail = (email) => {
  const url = Gatekeeper.getUserList();
  const {accessToken} = getAuthTokens();
  const filters = {
    emails: [email],
  };
  return fetchJSON(url, postFetchOpts({filters, jwt: accessToken})).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error fetching user');
    return {error: true};
  });
};
