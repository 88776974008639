import * as types from '../constants/action-types';
import {DASHBOARD_LIMIT_DEFAULT, FILTER_ARTICLES_EDITED_DEFAULT, FILTER_ARTICLES_SHOW_MINE_DEFAULT, FILTER_DRAFT} from '../constants/dashboard';
import {isAnyEditor} from '../helpers/articleHelpers';

export const initialState = {
  articles: {
    filter: FILTER_DRAFT,
    showMine: isAnyEditor() ? false : FILTER_ARTICLES_SHOW_MINE_DEFAULT,
    showEdited: FILTER_ARTICLES_EDITED_DEFAULT,
    showUnedited: FILTER_ARTICLES_EDITED_DEFAULT,
    sorting: ['updated_at', 'desc'],
    data: {
      list: null,
      offset: 0,
      hasMore: false,
    },
  },
  error: false,
  isLoading: false,
  limit: DASHBOARD_LIMIT_DEFAULT,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_ACTION: {
      const {complete, error = false, status} = action.payload;
      return {
        ...state,
        action: {
          status,
          complete,
          error,
        },
      };
    }

    case types.DASHBOARD_ACTION_CLEAR: {
      return {
        ...state,
        action: null,
      };
    }

    case types.DASHBOARD_ARTICLES: {
      const {articles, filter, limit, showMine, showEdited, showUnedited, offset, sorting} = action.payload;
      return {
        ...state,
        articles: {
          ...state.articles,
          data: {
            offset,
            hasMore: articles.length === limit,
            list: articles,
          },
          filter,
          showMine,
          showEdited,
          showUnedited,
          sorting,
        },
      };
    }

    case types.DASHBOARD_CLEAR: {
      return initialState;
    }

    case types.DASHBOARD_FETCHING: {
      return {...state, isLoading: action.payload};
    }

    case types.DASHBOARD_PREFETCH: {
      return {
        ...state,
        articles: {...state.articles, filter: action.payload},
        isLoading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboard;
