import * as types from '../constants/action-types';

const initialState = {
  loading: true,
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default ui;
