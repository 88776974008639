import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {MIN_ADD_FRAMES, MAX_ADD_FRAMES} from '../../../constants/slideshow';
import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_SLIDE_ADD_CONFIRM} from '../../../constants/prompt';
import {prompt} from '../../../helpers/promptHelper';
import {getId, getIsChanged, getIsPublished} from '../../../selectors/article';

/**
 * Renders the filmstrip add more controls
 * @description contains view with user input validation logic for add more frames functionality
 * @example
 *  <AddMoreFrames
 *    frames={10} // number of frames in a strip
 *    onFrameCreate={(numberOfFramesToAdd) => {}} // callback function for frame create event
 *  />
 * @param {object} props list of component parameters
 */
function AddMoreFrames({frames, onFrameCreate}) {
  const id = useSelector(getId);
  const isChanged = useSelector(getIsChanged);
  const isPublished = useSelector(getIsPublished);
  const addFrameInput = useRef(null);
  const [error, setError] = useState(null);
  const isButtonDisabled = id && isChanged;

  const isAddMoreValid = (value) => {
    if (isNaN(value)) return false;
    return value >= MIN_ADD_FRAMES && value <= MAX_ADD_FRAMES;
  };

  const onAddBtnPressed = () => {
    const framesToAdd = addFrameInput.current && parseInt(addFrameInput.current.value, 10);
    if (!isAddMoreValid(framesToAdd)) {
      setError(`Enter a number from: ${MIN_ADD_FRAMES}-${MAX_ADD_FRAMES}`);
      return;
    }

    if (framesToAdd < 10) {
      onFrameCreate(framesToAdd);
      return;
    }

    prompt(PROMPT_SLIDE_ADD_CONFIRM)
      .withTexts(
        `Are you sure you want to add ${framesToAdd} slides?`,
        'Adding slides immediately saves them to the article.',
        PROMPT_BUTTON_CONFIRM,
        PROMPT_BUTTON_CANCEL
      )
      .withMute(false)
      .show(() => {
        onFrameCreate(framesToAdd);
      });
  };

  const onAddFramesValueChanged = (event) => {
    // If `Enter` key was pressed
    if (event.charCode === 13) onAddBtnPressed();
  };

  const onAddFramesValueFocused = () => {
    // Reset error on add more input focus change
    error && setError(null);
  };

  const classes = classnames('filmstrip-control', {
    'filmstrip-control__error': !!error,
  });
  return (
    <div className="filmstrip-control-wrapper">
      <span>{frames} Slides</span>
      <div className={classes}>
        <input
          ref={addFrameInput}
          type="text"
          disabled={isPublished}
          maxLength={MAX_ADD_FRAMES.toString().length}
          defaultValue={MIN_ADD_FRAMES}
          onFocus={onAddFramesValueFocused}
          onKeyPress={onAddFramesValueChanged}
        />
        <button className="button--primary" onClick={onAddBtnPressed} disabled={isButtonDisabled}>
          Add
        </button>
        {error && <span className="message-error">{error}</span>}
      </div>
    </div>
  );
}

AddMoreFrames.propTypes = {
  frames: PropTypes.number.isRequired,
  onFrameCreate: PropTypes.func.isRequired,
};

export default AddMoreFrames;
