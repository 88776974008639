import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import PageTitle from '../atoms/pageTitle';
import PermissionDenied from './permissionDenied';
import ArticleForm from '../molecules/articleForm';
import {isCreateAllowed} from '../../helpers/articleHelpers';
import {receiveArticle} from '../../actions/article';
import {getUserProfilesByIds} from '../../actions/users';
import {getArticle} from '../../apis/articles/articlesAPI';
import reporter from '../../reporter';
import logger from '../../logger';

const Slideshow = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isNew = id === 'new';
  const title = isNew ? 'New Slideshow Article' : 'Edit Slideshow Article';

  if (isNew && !isCreateAllowed()) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to create new slideshow articles.</p>
      </PermissionDenied>
    );
  }

  useEffect(() => {
    if (!isNew && 'isAppLink' in location === false) {
      const getArticleById = async () => {
        const article = await getArticle(id);
        dispatch(getUserProfilesByIds([article.editedByGatekeeperId]));
        dispatch(
          receiveArticle({
            article,
            error: !!article.error,
          })
        );
      };

      try {
        getArticleById();
      } catch (error) {
        logger.error(error);
        reporter.inform(error);
      }
    }
  }, []);

  return (
    <section className="organism article slideshow">
      <PageTitle title={title} />
      <ArticleForm id={isNew ? '' : id} isNewArticle={false} isNewSlideshow={isNew} isSlideshowType={true} />
    </section>
  );
};

export default Slideshow;
