import {default as EmbedSource} from '@editorjs/embed';
import {getYouTubeEmbedTool, getYouTubeId, isYouTubeUrl, sanitizeYouTubeUrl} from '../embed';
import {EDITOR_SETTINGS_EDIT_ICON, EDITOR_SETTINGS_EDIT_LABEL} from '../../../../constants/editor';
import {PROMPT_EMBED_EDIT} from '../../../../constants/prompt';
import {prompt} from '../../../../helpers/promptHelper';

import {EVENT_EMBED_EDIT, EVENT_FEATURED_MEDIA_IFRAME} from '../../../../constants/events';
import ToolEventObserver from '../toolEventObserver';
import {getYouTubeThumbnail} from '../iframe';

export const ERROR_MESSAGE = 'Ooops! Please enter a valid HTTPS YouTube url.';
export const PROMPT_EMBED_TITLE = 'Edit YouTube embed';
export const PROMPT_EMBED_DESCRIPTION = 'You can edit your YouTube url, HTTPS required';
export const PROMPT_EMBED_OK = 'Save';

/**
 * Extends the @editorjs/embed plugin to add render settings 'edit' button
 * @see https://www.npmjs.com/package/@editorjs/embed
 */
export default class Embed extends EmbedSource {
  constructor({api, data, block}) {
    super({api, data});
    this.api = api;
    this.block = block;
    this.data = data;
    this.editorBlockIndex = 0;
  }

  render() {
    const el = super.render();
    return el;
  }

  renderSettings() {
    return [
      {
        icon: EDITOR_SETTINGS_EDIT_ICON,
        label: EDITOR_SETTINGS_EDIT_LABEL,
        onActivate: () => {
          // current block index needs to be set when click edit button, setting in prompt will return -1
          const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
          if (currentBlockIndex >= 0) this.editorBlockIndex = currentBlockIndex;
          // edit button triggers url prompt
          this.openEditPrompt();
        },
      },
    ];
  }

  openEditPrompt() {
    const isFeaturedMedia = this.api.blocks.getCurrentBlockIndex() === 0;

    prompt(PROMPT_EMBED_EDIT)
      .withTexts(PROMPT_EMBED_TITLE, PROMPT_EMBED_DESCRIPTION, PROMPT_EMBED_OK)
      .withUserInput((input) => {
        const valid = isYouTubeUrl(input.trim());
        return !valid ? ERROR_MESSAGE : null;
      }, this.data.embed)
      .withAdditionalInput(isFeaturedMedia ? 'thumbnail' : null, 'Also update article thumbnail', true)
      .show((input, checked) => {
        const src = sanitizeYouTubeUrl(input.trim());

        // Create the new embed data
        const newEmbed = getYouTubeEmbedTool(this.api, src);

        // Update Editor.js block data for saving
        this.data = newEmbed._data;

        // although getYouTubeEmbedTool() includes thumbnail it doesn't get returned because it's not native to the embed tool. Re-add it.
        this.data.thumbnail = getYouTubeThumbnail(getYouTubeId(src));

        // Update embed in Editor.js instance
        this.api.blocks.update(this.block.id, this.data);

        // update the redux article state
        ToolEventObserver.trigger(EVENT_EMBED_EDIT, {
          blockId: false,
          content: {type: 'embed', data: this.data},
          editorBlockIndex: this.editorBlockIndex,
          shouldReplaceArticleThumbnail: checked || false,
          event: this.editorBlockIndex === 0 ? EVENT_FEATURED_MEDIA_IFRAME : EVENT_EMBED_EDIT,
        });
      });
  }
}
