/* istanbul ignore file */

import stringify from 'json-stringify-safe';

const baseData = {
  service: process.env.SERVICE || 'contenttools-app',
  env: process.env.ENV || 'dev',
  version: process.env.VERSION || 'dev',
};

function argsToJson(args) {
  return {
    message: args.map((arg) => (typeof arg === 'string' ? arg : stringify(arg))),
    timestamp: new Date().toISOString(), // must be ISO-8601 in Zulu timezone
    ...baseData,
  };
}

export const logger = {
  debug(...args) {
    // eslint-disable-next-line @br/laws-of-the-game/no-unauthorized-global-properties
    global.console.debug(
      stringify({
        ...argsToJson(args),
        level: 'notice',
      })
    );
  },
  log(...args) {
    // eslint-disable-next-line @br/laws-of-the-game/no-unauthorized-global-properties
    global.console.log(
      stringify({
        ...argsToJson(args),
        level: 'notice',
      })
    );
  },
  warn(...args) {
    // eslint-disable-next-line @br/laws-of-the-game/no-unauthorized-global-properties
    global.console.warn(
      stringify({
        ...argsToJson(args),
        level: 'warning',
      })
    );
  },
  error(...args) {
    // eslint-disable-next-line @br/laws-of-the-game/no-unauthorized-global-properties
    global.console.error(
      stringify({
        ...argsToJson(args),
        level: 'error',
      })
    );
  },
  info(...args) {
    // eslint-disable-next-line @br/laws-of-the-game/no-unauthorized-global-properties
    global.console.info(
      stringify({
        ...argsToJson(args),
        level: 'info',
      })
    );
  },
};

export default logger;
