import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';

import {clearSearch, fetchSearch} from '../../actions/search';
import {selectLoading} from '../../selectors/search';

import FilterDates from '../atoms/search/filterDates';
import FilterInput from '../atoms/search/filterInput';

const SearchFilters = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const isButtonsDisabled = isLoading;

  const handleSubmit = () => {
    dispatch(fetchSearch());
  };

  const handleClear = () => {
    dispatch(clearSearch());
    dispatch(fetchSearch());
  };

  const keyListener = (event) => {
    if (event.code === 'Enter' && !isButtonsDisabled) handleSubmit();
  };

  useEffect(() => {
    document.addEventListener('keyup', keyListener);
    return () => document.removeEventListener('keyup', keyListener);
  }, []);

  return (
    <section className={classnames('atom', 'search-filters', {'is-loading': isLoading})}>
      <div className="filters-group">
        <FilterInput />
        <FilterDates />
      </div>
      <div className="filters-group">
        <button className="button-search" disabled={isButtonsDisabled} onClick={handleSubmit}>
          Search
        </button>
        <button className="button-reset" disabled={isButtonsDisabled} onClick={handleClear}>
          Clear
        </button>
      </div>
    </section>
  );
};

export default SearchFilters;
