import {IconPicture} from '@codexteam/icons';
import {EVENT_MEDIA_SEARCH} from '../../../../constants/events';
import ToolEventObserver from '../toolEventObserver';

/**
 * EditorJS media block that triggers media search event
 * (event subscriber is atoms/EditorMedia/index.js)
 */
export default class Media {
  static get toolbox() {
    return {
      icon: IconPicture,
      title: 'Media',
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  constructor({api, data, readOnly}) {
    this.api = api;
    this.data = data;
    this.readOnly = readOnly;
  }

  render() {
    ToolEventObserver.trigger(EVENT_MEDIA_SEARCH, {
      // Pass currently in focus block ID as a parameter
      editorBlockIndex: this.api.blocks.getCurrentBlockIndex(),
    });

    // Editor.js expects render() to return a valid Node otherwise will throw console error:
    // `Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'.`
    // But returning a node here will insert empty blocks into the editor which are
    // not necesssary and can cause unintended side effects
    // @see CTT-940/CTT-950
    // Note we are currently supressing the console error in `src/client.js`
    // and cypress uncaught:exception in `cypress/support/index.js`
    return null;
  }

  save() {
    return this.data;
  }
}
