import React, {useRef, useEffect} from 'react';
import FocusTrapWrapper from './focusTrapWrapper';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Modal = ({children, onClose, title, isLoading, closeOnBodyClick}) => {
  const classes = classnames('atom', 'modal', {'is-loading': isLoading});
  const ref = useRef(null);

  const listenForClose = (event) => {
    // close modal if click outside the modal -or- click 'Esc' key
    if (ref.current && ref.current === event.target) {
      onClose();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  if (closeOnBodyClick) {
    // Add listeners on body click/keyup events to close modal
    useEffect(() => {
      document.addEventListener('click', listenForClose);
      document.addEventListener('keyup', listenForClose);
      return () => {
        document.removeEventListener('click', listenForClose);
        document.removeEventListener('keyup', listenForClose);
      };
    }, [ref]);
  }

  // TODO: This focus trap currently has an issue with maintaining a lock on the focus when a modal is opened
  // from the editorjs popover menu by clicking the Media option because the popover menu remains open and
  // steals the focus while tabbing. This issue will be resolved when CTT-950 is completed.
  return (
    <FocusTrapWrapper>
      <div className={classes} ref={ref}>
        <div className="modal-layout">
          <div className="modal-header">
            <h3 className="modal-title">{title}</h3>
          </div>
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </FocusTrapWrapper>
  );
};

Modal.defaultProps = {
  closeOnBodyClick: true,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  closeOnBodyClick: PropTypes.bool,
};

export default Modal;
