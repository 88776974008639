import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import PropTypes from 'prop-types';

import {makeArticlePublished, makeArticleScheduled} from '../../../actions/dashboard';
import {DATE_FORMAT_PICKER, PLACEHOLDER_EMPTY} from '../../../constants/date';
import {PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL, PROMPT_PUBLISH} from '../../../constants/prompt';
import {sanitizeArticleLink} from '../../../helpers/dashboardHelpers';
import {
  formatDateWithTimezone,
  getCorrectedISOString,
  getSchedulePublishMinimumDate,
  validateSelectedScheduledDate,
} from '../../../helpers/dateHelpers';
import {canSchedulePublish} from '../../../helpers/permissions';
import {prompt} from '../../../helpers/promptHelper';
import {promptPublishDescription, promptPublishTitle} from '../../molecules/articleHeader';
import {getArticleData} from '../../../selectors/article';

const ArticlePublish = (props) => {
  const dispatch = useDispatch();
  const article = props.article ? props.article : useSelector(getArticleData); // on dashboard article prop is passed. on article edit page use redux state
  const {authorGatekeeperId, id, isBreakingNews, publishedAt, renderStrategy: articleType, scheduledAt, status, title, url} = article;
  const scheduleMinimumDate = getSchedulePublishMinimumDate();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [scheduled, setScheduled] = useState(scheduleMinimumDate);

  useEffect(() => {
    const selectedDate = setTimeout(() => {
      setScheduled(validateSelectedScheduledDate(scheduled));
    }, 600);

    return () => clearTimeout(selectedDate);
  }, [scheduled]);

  const handleClose = () => {
    setIsScheduleOpen(false);
  };

  const handlePublish = () => {
    prompt(PROMPT_PUBLISH)
      .withTexts(promptPublishTitle(articleType), promptPublishDescription(title), PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => {
        return dispatch(makeArticlePublished(id, getCorrectedISOString(new Date())));
      });
  };

  const handlePublishScheduled = () => {
    dispatch(makeArticleScheduled(id, getCorrectedISOString(scheduled)));
    handleClose();
  };

  const renderPublishDates = (text, date, url) => {
    let label;
    if (url) {
      label = (
        <a href={sanitizeArticleLink(url)} className="link-external" rel="noreferrer" target="_blank" title={`View published article: ${title}`}>
          {text}
        </a>
      );
    } else {
      label = text;
    }
    return (
      <div className="article-date-inner">
        <div className="label">{label}</div>
        <div className="value">{formatDateWithTimezone(date) || PLACEHOLDER_EMPTY}</div>
      </div>
    );
  };

  // render article published information
  if (publishedAt) return renderPublishDates('Published', publishedAt, url);

  // render article scheduled information
  if (scheduledAt) return renderPublishDates('Scheduled', scheduledAt);

  // only render when permitted
  if (!canSchedulePublish(authorGatekeeperId, status, isBreakingNews)) return null;

  return (
    <>
      <div className="article-actions--schedule-publish">
        <div className="inner">
          {isScheduleOpen ? (
            <>
              <div className="controls">
                <DateTimePicker
                  value={scheduled}
                  clearIcon={null}
                  disableClock={true}
                  locale="en-US"
                  onChange={(scheduled) => setScheduled(scheduled)}
                  format={DATE_FORMAT_PICKER}
                />
                <span className="note">Eastern Time (ET)</span>
              </div>
              <button onClick={handlePublishScheduled} className="action--date-set">
                Schedule Publish Date
              </button>
              <button onClick={handleClose} className="action--cancel">
                <span className="sr-only">Cancel</span>
              </button>
            </>
          ) : (
            <>
              <button className="article-action--schedule" onClick={() => setIsScheduleOpen(true)}>
                Schedule
              </button>
              <button className="article-action--publish" onClick={handlePublish}>
                Publish Now
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

ArticlePublish.propTypes = {
  article: PropTypes.object,
};

export default ArticlePublish;
