import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {setSlideActive} from '../../../actions/versions';
import {selectActiveSlideNumber} from '../../../selectors/versions';

const Button = ({isDisabled, isOpen, onClick}) => {
  const label = 'Slide Titles';
  const title = isOpen ? `Hide ${label}` : `Show ${label}`;
  return (
    <button disabled={isDisabled} title={title} onClick={onClick}>
      {label}
    </button>
  );
};

const Title = ({isSelected, onClick, slide}) => {
  const handler = (event) => onClick(event, slide.id);
  const classes = classnames({active: isSelected, changed: slide.changed});
  const listTitle = slide.title ? slide.title : 'Untitled';
  return (
    <li className={classes} onClick={handler}>
      {slide.slideNumber === 0 && (
        <span className="list-num list-num-square">
          T<span className="sr-only">itle.</span>
        </span>
      )}
      {slide.slideNumber > 0 && (
        <span className="list-num list-num-square">
          {slide.slideNumber}
          <span className="sr-only">.</span>
        </span>
      )}
      <span className="list-title">{listTitle}</span>
    </li>
  );
};
const VersionsSlideTitles = ({slides}) => {
  const dispatch = useDispatch();
  const activeSlideNumber = useSelector(selectActiveSlideNumber);
  const [isOpen, setIsOpen] = useState(false);
  const isDisabled = !slides.length;
  const ref = useRef(null);
  const classes = classnames('atom', 'versions-slide-titles', {'is-open': isOpen, 'is-disabled': isDisabled});

  const onButtonClicked = () => {
    setIsOpen(!isOpen);
  };

  const onClick = async (slideNumber) => {
    if (activeSlideNumber === slideNumber) return;
    await dispatch(setSlideActive(slideNumber));
  };

  const listenToCloseMenu = (event) => {
    // collapse menu if clicked outside the menu element
    if (ref.current && !ref.current.contains(event.target)) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', listenToCloseMenu);
    return () => {
      document.removeEventListener('click', listenToCloseMenu);
    };
  }, [ref]);

  return (
    <div className={classes} ref={ref}>
      <Button isDisabled={isDisabled} isOpen={isOpen} onClick={onButtonClicked} />
      {isOpen && (
        <ul className="slide-titles">
          {slides.map((slide) => {
            const isSelected = slide.slideNumber === activeSlideNumber;
            return <Title isSelected={isSelected} key={`slide-${slide.id}`} onClick={() => onClick(slide.slideNumber)} slide={slide} />;
          })}
        </ul>
      )}
    </div>
  );
};

VersionsSlideTitles.propTypes = {
  slides: PropTypes.array.isRequired,
};

export default VersionsSlideTitles;
