import {default as EditorList} from '@editorjs/list';
import {addDiffClass} from '../difference';

/**
 * Provide a way for version history (VH) to be able to add a difference class to the list (unordered/ordered) component
 *
 * `makeBlockAdded()` and `makeBlockRemoved()` add the VH difference markers by wrapping the content in a div
 * But that doesn't work with lists because they're an array of items.
 *
 * Ideally Editor.js would provide a way for us to able to add the diff classes to the block (.ce-block) instead of wrapping the
 * content inside that block. If that becomes possible in the future, we can update how we're adding these
 * added/removed markers so that we apply them consistently at the block level.
 */
export default class List extends EditorList {
  constructor({api, config, data}) {
    super({api, config, data});
    this.originalData = data;
  }

  render() {
    const el = super.render();
    addDiffClass(el, this.originalData);
    return el;
  }
}
