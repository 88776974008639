import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';

import {formatNumber} from '../../../helpers/number';
import {selectIndividualCount, selectLoading} from '../../../selectors/editorStats';

const EditorStatsCountIndividual = () => {
  const count = useSelector(selectIndividualCount);
  const isLoading = useSelector(selectLoading);

  return (
    <div className={classnames('atom', 'editor-stats-count', {'is-loading': isLoading})}>
      {formatNumber(count)} {count === 1 ? 'article' : 'articles'}
    </div>
  );
};

export default EditorStatsCountIndividual;
