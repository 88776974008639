import {createSelector} from 'reselect';
import {STATUS_DELETED, STATUS_PUBLISHED} from '../constants/article';
import {getArticleAuthor} from '../helpers/articleHelpers';

export const getArticle = (state) => {
  return state.article;
};

export const getArticleState = createSelector(
  [getArticle],
  (article) => article
);

export const getArticleData = createSelector(
  [getArticle],
  (article) => article?.data || {}
);

// articles should always have an author. early content-tools test articles can have null author.
// when no author return current user
export const getAuthor = createSelector(
  [getArticleData],
  (data) => data.author || getArticleAuthor()
);

export const getAuthorId = createSelector(
  [getArticleData],
  (data) => data.authorGatekeeperId || null
);

export const getBetTags = createSelector([getArticleData], (data) =>
  data.betTags ? data.betTags.split(',') : []
);

export const getHideBetTags = createSelector(
  [getArticleData],
  (data) => data.hideBetTags || false
);

export const getId = createSelector(
  [getArticle],
  (article) => (article.data && article.data.id) || null
);

export const getIsNew = createSelector(
  [getArticleData],
  (data) => !data.id
);

export const getIdleTimer = createSelector(
  [getArticle],
  (article) => article.idleTimer || 0
);

export const getStatus = createSelector(
  [getArticleData],
  (data) => data.status || ''
);

export const getTitle = createSelector(
  [getArticleData],
  (data) => data.title || ''
);

export const getTagsList = createSelector(
  [getArticleData], (data) =>
    data.tagList ? data.tagList.split(',') : []
);

export const getIsChanged = createSelector(
  [getArticle],
  (article) => article.isChanged || false
);

export const getIsChangedMetadata = createSelector(
  [getArticle],
  (article) => article.isChangedMetadata || false
);

export const getIsChangedArticle = createSelector(
  [getArticle],
  (article) => (article.isChanged || article.isChangedMetadata) || false
);

export const getIsReadOnly = createSelector(
  [getArticle],
  (article) => (article?.isReadOnly || !article?.data) || false
);

export const getFeedbacks = createSelector(
  [getArticleData],
  (data) => data.feedbacks || []
);

export const getError = createSelector(
  [getArticle],
  (article) => article.error
);

export const getAction = createSelector(
  [getArticle],
  (article) => article.action
);

export const getIsSlideshow = createSelector(
  [getArticle],
  (article) => article.isSlideshow
);

export const getActiveSlide = createSelector(
  [getArticle],
  (article) => article.activeSlide
);

export const getIsTitleSlide = createSelector(
  [getArticle],
  (article) => article.isSlideshow && article.activeSlide === 0
);

export const getIsArticleDeleted = createSelector(
  [getArticleData],
  (data) => data.status === STATUS_DELETED
);

export const getIsPublished = createSelector(
  [getArticleData],
  (data) => data.status === STATUS_PUBLISHED
);

export const getThumbnail = createSelector(
  [getArticle],
  (article) => (article.data && article.data.image) || null
);

export const getEditorPrimary = createSelector(
  [getArticleData],
  (data) => data.primaryEditorGatekeeperId || null
);

export const getSocialTitle = createSelector(
  [getArticleData],
  (data) => data.socialTitle || null
);

export const getSocialTeaser = createSelector(
  [getArticleData],
  (data) => data.teaser || null
);
