import {isEqual, omit} from 'lodash';
import reporter from '../../reporter';

import logger from '../../logger';

import {EDITOR_TOOL_LIST_ORDERED_STYLE} from '../../constants/editor';
import {addAttrToExternalLinks, replaceNonBreakingSpacesAndTrim} from '../../helpers/textHelpers';
import {getYouTubeStartSeconds} from '../../utils/editorjs/tools/embed';

/**
 * Represents the list of complex blocks (block with complex UI interactions)
 * It should be skipped by block transformation function 'cause actual EditorJS block will be added later
 */
const customComplexBlocks = ['mediaBlock', 'placeholderEmbed'];

function getContentFromEmbed(data, id) {
  if (data.service === 'youtube') {
    const metadata = {};

    const url = new URL(data.embed);
    metadata.video_id = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);

    const startSeconds = getYouTubeStartSeconds(data.embed);
    if (startSeconds) metadata.start_seconds = startSeconds;

    return {
      id,
      content_type: 'youtube_video',
      content: {
        metadata,
      },
    };
  }

  return null;
}

function getContent(data) {
  return data && data.__original__ ? data.__original__ : data;
}

/**
 * Transform individual chunks of data from EditorJS's format to the structure used by the Articles service.
 * The data in EditorJS includes an `__original__` property, the value of which is the raw data for that media
 * block from when the data was originally fetched from the Articles service and loaded into EditorJS; all
 * media which has `content` on the Articles service should have that content preserved in __original__.
 * @returns {object | null} (if input can be transformed) the transformed media block data
 */
function transformBlock(rawBlock) {
  const {data, id, type} = rawBlock;

  if (rawBlock.contentType) {
    const {contentType, ...rest} = rawBlock;
    return {content_type: contentType, ...rest};
  }

  // Skip complex block (check explanation above)
  if (customComplexBlocks.includes(type)) return null;

  switch (type) {
    case 'ad':
      // The back-end will insert Ad blocks.
      return null;
    case 'blockquote': // Articles created via Breport can have "blockquote";
    case 'pullquote': // during development some were created with "pullquote";
    case 'quote_pull': // from here on out though, we'll call that style "quote_pull"
      return {
        content: {html: data.text},
        content_type: 'quote_pull',
        id,
      };
    case 'quote_indent':
      return {
        content: {html: data.text},
        content_type: 'quote_indent',
        id,
      };
    case 'embed':
      return getContentFromEmbed(data, id);
    case 'gif':
      return {
        content: data.__original__,
        content_type: 'gif',
        id,
        url: data.file.url,
      };
    case 'header':
      return {
        content: {html: data.text},
        content_type: `h${data.level}`,
        id,
      };
    case 'hr':
      return {
        content_type: 'hr',
        id,
      };
    case 'hr_transparent':
      return {
        content_type: 'hr_transparent',
        id,
      };
    case 'iframe':
      return {
        content: getContent(data),
        content_type: 'iframe',
        id,
      };
    case 'image': {
      const original = data.__original__;
      if (!original.caption) delete original.caption; // remove caption from payload if it's empty
      const url = data.file && data.file.url;
      return {
        content: {
          ...original,
          url: url.replace(/&amp;/g, '&'), // when adding new, there is no data.url
        },
        content_type: 'image',
        id,
      };
    }
    case 'infogram':
      return {
        content: getContent(data),
        content_type: 'infogram',
        id,
      };
    case 'instagram':
      return {
        content: getContent(data),
        content_type: 'instagram',
        id,
        url: data.url,
      };
    case 'list':
      // note: must convert data between Editor.js `list` type and BR.Articles `ul` and `ol` types
      // use data.style attribute from Editor.js `list` to determine `ul` or `ol type
      // Editorjs format: { type: list, data: {items: [], style: 'unordered/ordered'}}
      // BR.Articles format: { type: 'ul/ol', data: {items: []}}
      return {
        content: {items: data.items},
        content_type: data.style === EDITOR_TOOL_LIST_ORDERED_STYLE ? 'ol' : 'ul',
        id,
      };
    case 'paragraph':
      return {
        content: {html: replaceNonBreakingSpacesAndTrim(addAttrToExternalLinks(data.text))},
        content_type: 'paragraph',
        id,
      };
    case 'placeholder':
    case 'placeholderEmbed':
    case 'mediaBlock':
      // Do not include any content types that are specific to the editing environment
      return null;
    case 'statmilk':
      return {
        content: getContent(data),
        content_type: 'statmilk',
        id,
      };
    case 'subheader':
      return {
        content: {html: data.text},
        content_type: `h${data.level}`,
        id,
      };
    case 'tweet':
      return {
        content: getContent(data),
        content_type: 'tweet',
        id,
        url: data.url,
      };
    case 'unsupported':
      // Attempt to leave the data untouched.
      return {
        content: data.__original__,
        content_type: 'unsupported',
      };
    case 'video':
      return {
        content: data,
        content_type: 'video',
        hide_from_regions: data.metadata?.hideFromRegions,
        id,
      };
    default:
      logger.debug(`Transforming unrecognized data type: ${type}`, type, data);
      reporter.inform(new Error(`Transforming unrecognized data type: ${type}`), {original_data: data});
      return {
        content: getContent(data),
        content_type: type,
      };
  }
}

/**
 * Transform Article content block data from EditorJS into the structure used by the Articles service.
 *
 * @returns {array[object]} data ready to be saved as `elements` in the Articles service
 */
export function editorJsDataToArticlesFormat(editorBlocks) {
  if (!editorBlocks || !Array.isArray(editorBlocks)) {
    throw new Error('Transform must be done on an array.');
  }

  return editorBlocks.map(transformBlock).filter((transformedBlock) => transformedBlock !== null);
}

/**
 * Transform Slideshow content block data from EditorJS into the structure used by the Articles service.
 *
 * @returns {array[object]} data ready to be saved as `elements` in the Articles service
 */
export function editorJsDataToSlideshowFormat(slides) {
  if (!slides || !Array.isArray(slides)) {
    throw new Error('Slideshow transform must be done on an array.');
  }

  return slides.reduce((slidesTransformed, rawSlide, index) => {
    // Skip non slide blocks from the transformation (expecting to skip "ad" blocks)
    const {type, elements, ...slideData} = rawSlide;
    if (!type || type !== 'slide') {
      return [...slidesTransformed, rawSlide];
    }

    const transformedSlide = {
      ...slideData,
      contentType: 'slide',
      elements: editorJsDataToArticlesFormat(elements),
      slideNumber: index,
      id: index,
    };

    return [...slidesTransformed, transformedSlide];
  }, []);
}

/**
 * Compares current slideshow content against saved slideshow content to see if there's changes
 *
 * @returns {bool}
 */
export function editorJsDataCompareSlideshow(blocks, savedBlocks) {
  // slide is missing in original version from the server
  if (!savedBlocks) return true;

  let isChanged = false;

  // first check if the title's identical
  // otherwise compare the new block content against the original block content
  if (blocks.title !== savedBlocks.title) {
    isChanged = true;
  } else {
    const blockCompareOriginal = [];
    const blockCompareNew = [];

    // even when the content is the same, IDs will be different so need to strip from comparison
    savedBlocks.elements.forEach((block) => {
      blockCompareOriginal.push(omit(block, 'id', 'contentType', 'url'));
    });

    blocks.elements.forEach((block) => {
      // sanity check data format TODO: can we handle this better?
      if (!block.data) {
        block.data = block.content;
        block.data.text = block.content.html;
        delete block.content;
        delete block.data.html;
      }

      blockCompareNew.push(omit(block, 'id', 'content_type', 'url'));
    });

    if (!isEqual(blockCompareOriginal, blockCompareNew)) {
      isChanged = true;
    }
  }

  return isChanged;
}
