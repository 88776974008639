import * as types from '../constants/action-types';

export const initialState = {
  activeSlideNumber: 0,
  activeVersion: null, // represents version selected by the customer
  changedSlides: [], // represents which slide(s) were changed in the current version
  compareVersion: null, // represents the version immediately previous to the active version (for 'show changes') --ALSO-- in compare mode this is the compare version selected by the user
  currentVersion: null, // represents article current/latest version
  error: false,
  isLoading: false,
  showChanges: true,
  showCompareView: false,
  showUnchangedSlides: true,
  versions: [],
};

const versions = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FETCHING: {
      return {...state, isLoading: true};
    }

    case types.VERSIONS_CLEAR: {
      return initialState;
    }

    case types.VERSIONS_RECEIVE: {
      const {activeSlideNumber, error, compareVersion, currentVersion, versions} = action.payload;
      return {
        ...state,
        activeSlideNumber,
        activeVersion: currentVersion, // set current version as an active one be default
        compareVersion,
        currentVersion,
        error,
        isLoading: false,
        versions,
      };
    }

    case types.VERSIONS_SET_VERSIONS: {
      const {activeSlideNumber, error, ...rest} = action.payload;
      const newState = {
        ...state,
        activeVersion: rest.activeVersion || state.activeVersion,
        compareVersion: rest.compareVersion || state.compareVersion,
        error,
        isLoading: rest.hasPreviousVersionId || false,
      };

      // update the active slide number, if applicable
      if (typeof activeSlideNumber === 'number') newState.activeSlideNumber = activeSlideNumber;

      return newState;
    }

    case types.VERSIONS_SLIDE_ACTIVE: {
      const {number} = action.payload;
      return {...state, activeSlideNumber: number};
    }

    case types.VERSIONS_SLIDES_CHANGED: {
      const {changedSlides} = action.payload;
      return {...state, changedSlides};
    }

    case types.VERSIONS_SHOW_CHANGES: {
      const checked = action.payload;
      const showChanges = typeof checked === 'boolean' ? checked : initialState.showChanges;
      return {...state, showChanges};
    }

    case types.VERSIONS_SHOW_COMPARE_VIEW: {
      const checked = action.payload;
      const showCompareView = typeof checked === 'boolean' ? checked : initialState.showCompareView;
      return {...state, showCompareView};
    }

    case types.VERSIONS_SHOW_UNCHANGED_SLIDES: {
      const checked = action.payload;
      const showUnchangedSlides = typeof checked === 'boolean' ? checked : initialState.showUnchangedSlides;
      return {...state, showUnchangedSlides};
    }

    default: {
      return state;
    }
  }
};

export default versions;
