import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {updateArticle, setArticleMedia} from '../../actions/article';
import {ARTICLE_TITLE_MAXLENGTH, ARTICLE_TITLE_MINLENGTH, STATE_READONLY, VALIDATE_FIELD_FEATUREDMEDIA} from '../../constants/article';
import {EVENT_FEATURED_MEDIA_UPLOAD, EVENT_IMAGE_UPLOAD} from '../../constants/events';
import {READONLY_MESSAGE} from '../../constants/messages';
import {filterEditorBlocks, getArticleThumbnail, getFeaturedMediaBlock, isFeaturedMedia, shouldUpdateThumbnail} from '../../helpers/articleHelpers';
import {getArticleData, getIsNew, getIsReadOnly} from '../../selectors/article';

import EditorField from '../atoms/editorField';
import Editor from '../atoms/editor';
import FeaturedMediaForm from './featuredMediaForm';
import FeaturedMedia from '../atoms/featuredMedia';
import MediaUploader from '../molecules/mediaUploader';
import GlobalNotifications from '../../utils/notifications/globalNotificationsComponent';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';
import ToolEventObserver from '../../utils/editorjs/tools/toolEventObserver';

const ArticleStandard = ({isReadOnlyMessage, onBodyChanged}) => {
  const article = useSelector(getArticleData);
  const {elements, id, socialTitle, title} = article;
  const isNew = useSelector(getIsNew);
  const isReadOnly = useSelector(getIsReadOnly);
  const dispatch = useDispatch();
  const transformBlocksForEditorJs = () => {
    // if you open a saved article with no body content and take the lock, the editor is broken and you can't edit content
    // there is an open bug for this on Editor.js, once resolved we should remove this hack
    // note: adding a pargraph with the placeholder text instead of an empty placholder element so it
    // doesn't cause isues with adding featured media as first content block
    // @see CTT-929 & https://github.com/codex-team/editor.js/pull/1741 & https://github.com/codex-team/editor.js/issues/2084
    if (!isNew && isReadOnly && !elements.length) return {blocks: [{id: 1, type: 'paragraph', data: {text: 'Add content…'}}]};
    return {blocks: filterEditorBlocks(elements)};
  };

  const featuredData = getFeaturedMediaBlock(article);
  const [body, setBody] = useState(transformBlocksForEditorJs);
  const [isEditorUndo, setIsEditorUndo] = useState(true);
  const {error, addError, removeError} = useGlobalNotificationsError();
  const showImageCropper = (image, event) => ToolEventObserver.trigger(event, image);

  useEffect(() => {
    if (isReadOnly) addError({[STATE_READONLY]: isReadOnlyMessage});
    return () => removeError(STATE_READONLY);
  }, [isReadOnly, isReadOnlyMessage]);

  const onMediaAdded = (content, event, blockId, shouldReplaceFeaturedMedia, shouldReplaceArticleThumbnail) => {
    const {data, type} = content;

    // Only update the featured media if the onMediaAdded event was triggered by the featured media
    if (isFeaturedMedia(blockId, event, shouldReplaceFeaturedMedia)) dispatch(setArticleMedia(content));

    if (!shouldUpdateThumbnail(blockId, event, type, article, shouldReplaceArticleThumbnail)) return;

    const thumbnail = getArticleThumbnail(data, type);

    dispatch(updateArticle({image: thumbnail}));
  };

  const onFeatureMediaRemoved = () => {
    dispatch(setArticleMedia());
    dispatch(updateArticle({blocks: elements.slice(1)}));
  };

  const handleTitleChange = (event) => {
    const newTitle = typeof event === 'object' ? event.target.value : event; // usually an object, exception when we normalize title onblur
    // update the social title only if the current social title is `in sync` (matches) the article title
    if (!socialTitle || socialTitle === title) dispatch(updateArticle({socialTitle: newTitle, title: newTitle}));
    else dispatch(updateArticle({title: newTitle}));
  };

  const handleBodyChange = (data, words) => {
    onBodyChanged({blocks: filterEditorBlocks(data.blocks)}, words);
    setBody(transformBlocksForEditorJs);
  };

  return (
    <>
      <GlobalNotifications />
      <EditorField
        type="text"
        name="title"
        autoFocus={true}
        placeholder="Add title…"
        minLength={ARTICLE_TITLE_MINLENGTH}
        maxLength={ARTICLE_TITLE_MAXLENGTH}
        value={title}
        disabled={isReadOnly}
        doCapitalize={true}
        doNormalize={false}
        doNormalizeBlur={true}
        onChange={handleTitleChange}>
        Title
      </EditorField>
      <MediaUploader onMediaAccept={(image) => showImageCropper(image, EVENT_FEATURED_MEDIA_UPLOAD)}>
        {featuredData ? (
          <FeaturedMedia media={featuredData} onMediaRemove={onFeatureMediaRemoved} />
        ) : (
          <FeaturedMediaForm withError={error && error[VALIDATE_FIELD_FEATUREDMEDIA]} />
        )}
      </MediaUploader>
      <MediaUploader onMediaAccept={(image) => showImageCropper(image, EVENT_IMAGE_UPLOAD)}>
        <Editor
          key={id}
          data={body}
          featuredMedia={featuredData}
          name="body"
          placeholder="Add content…"
          onMediaAdd={onMediaAdded}
          onMediaModalOpen={(isOpen = true) => setIsEditorUndo(!isOpen)}
          onData={handleBodyChange}
          onReady={(data, wordcount) => handleBodyChange(data, wordcount)}
          useUndo={isEditorUndo}
        />
      </MediaUploader>
    </>
  );
};

ArticleStandard.defaultProps = {
  isReadOnlyMessage: READONLY_MESSAGE,
  onBodyChanged: () => {},
};

ArticleStandard.propTypes = {
  isReadOnlyMessage: PropTypes.string,
  onBodyChanged: PropTypes.func.isRequired,
};

export default ArticleStandard;
