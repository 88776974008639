import {TEXT_UPPERCASE} from './text.uppercase';
import {TEXT_LOWERCASE} from './text.lowercase';
import {TEXT_MIXEDCASE} from './text.mixedcase';

export const MAXLENGTH = 20;

export const TAGS_TO_ESCAPE = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;', // eslint-disable-line quotes
  '/': '&#x2F;',
};

export const TEXT_HTML_SUBELEMENTS = {
  a: {href: true, rel: 'noopener noreferer', target: '_blank'},
  br: {},
  em: {},
  strong: {},
};

export const PLACEHOLDER_EMPTY = '--';

export const QUOTES = ["'", '"', '`']; // eslint-disable-line quotes

export const WORDS_UPPERCASE = TEXT_UPPERCASE;
export const WORDS_LOWERCASE = TEXT_LOWERCASE;
export const WORDS_MIXEDCASE = TEXT_MIXEDCASE;
