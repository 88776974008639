import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {getArticle} from '../../apis/articles/articlesAPI';

/**
 * Article redirect used by...
 * - Emails that link to Content Tools articles use format `/article/:id`
 * - Browser bookmarlet used by writers/editors to take them from live pages to the edit page
 * We need to redirect these to the CT article format including type `/articles/:type/:id`
 */

export const ArticleRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // get the id from the url ('/article/0000000')
    const id = location.pathname.replace('/article/', '');

    // make sure we have a valid id, otherwise redirect to dashboard
    if (!id.length || !Number.isInteger(parseInt(id))) {
      navigate('/', {replace: true});
      return;
    }

    const redirectToArticle = async () => {
      // load the article so we can figure out the type
      const article = await getArticle(id);
      const type = article.renderStrategy === 'slideshow' ? 'slideshow' : 'standard';
      const hash = location.hash;

      // redirect to the article...
      navigate(`/articles/${type}/${id}${hash}`, {replace: true});
    };

    redirectToArticle();
  }, []);

  return <section>Redirecting to article…</section>;
};

export default ArticleRedirect;
