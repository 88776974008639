export const APPLE_NEWS_MIN_WIDTH_THUMBNAIL = 300;
export const IMAGE_CROP_MIN_WIDTH = 600; // must >= apple news min width
export const IMAGE_CROP_RATIO = 3 / 2;
// Since react-dropzone v13.0.0, the 'accept' prop requires an object instead of a string or array of strings.
// You can list specific file extensions for each mime type in their associated arrays
// https://react-dropzone.org/#!/Accepting%20specific%20file%20types
export const IMAGE_ACCEPTED_MIME_TYPES = {
  'image/jpg': [],
  'image/jpe': [],
  'image/jpeg': [],
  'image/png': [],
};
export const IMAGE_ACCEPTED_FILE_EXTENSIONS = Object.keys(IMAGE_ACCEPTED_MIME_TYPES).map((type) => type.slice(6));
export const IMAGE_MAX_SIZE_IN_BYTES = 5000000; // 5MB
export const IMAGE_MAX_SIZE_IN_MB = IMAGE_MAX_SIZE_IN_BYTES / 1000000;
export const IMAGE_SEARCH_UNFILTERED_PAGE_SIZE = 120;
export const IMAGE_SEARCH_FILTERED_PAGE_SIZE = 60;
export const IMAGE_SOURCE_GETTY = 'Getty';
export const IMAGE_SOURCE_AP = 'AP';

export const MEDIA_TYPE_IFRAME = 'iframe';
export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_VIDEO = 'video';

export const MEDIA_SEARCH_NO_RESULTS = 'No results found\n\nSuggestions:\n\u2022 Double check spelling\n\u2022 Try removing special characters ';
export const MEDIA_SEARCH_NO_RESULTS_TITLE = 'No results found';
export const MEDIA_SEARCH_NO_RESULTS_TEXT = 'Double check the spelling or try removing special characters.';

export const VIDEO_TYPE_HIGHLIGHT = 'highlight';
export const VIDEO_TYPE_VIDEO = 'video';
export const VIDEO_DATE_FORMAT = 'MMM d, Y';
export const VIDEO_PLAYER_KEY = '0b7f53b7-50bd-4fcf-9b07-4697d847e697';

export const YOUTUBE_URL_EMBED = 'youtube.com/embed'; // embed url, this is what all url formats will be converted to
export const YOUTUBE_URL_SHORT = 'youtu.be'; // copied from right clicking on video directly, or pressing share under video
export const YOUTUBE_URL_WATCH = 'youtube.com/watch'; // copied from browser url
export const YOUTUBE_URLS = [YOUTUBE_URL_EMBED, YOUTUBE_URL_SHORT, YOUTUBE_URL_WATCH]; // all supported YT url formats
