import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';

import {formatNumber} from '../../../helpers/number';
import {selectEditorsCount, selectLoading} from '../../../selectors/editorStats';

const EditorStatsCountRoster = () => {
  const count = useSelector(selectEditorsCount);
  const isLoading = useSelector(selectLoading);

  if (!count) return null;

  return (
    <div className={classnames('atom', 'editor-stats-count', {'is-loading': isLoading})}>
      {formatNumber(count)} {count === 1 ? 'editor' : 'editors'}
    </div>
  );
};

export default EditorStatsCountRoster;
