import {useEffect} from 'react';
import PropTypes from 'prop-types';

import {EVENT_EMBED_EDIT, EVENT_FEATURED_MEDIA_IFRAME, EVENTS_EMBED} from '../../constants/events';
import {PROMPT_BUTTON_SAVE, PROMPT_IFRAME_EMBED} from '../../constants/prompt';
import {prompt} from '../../helpers/promptHelper';

import {getYouTubeEmbedTool, getYouTubeId, sanitizeYouTubeUrl} from '../../utils/editorjs/tools/embed';
import {
  checkValidUrl,
  ERROR_MESSAGE as IFRAME_ERROR_MESSAGE,
  getIframeUrl,
  getYouTubeThumbnail,
  getYouTubeUrl,
  IFRAME_PROVIDER,
  IFRAME_THUMBNAIL,
  PROMPT_IFRAME_DESCRIPTION,
  PROMPT_IFRAME_TITLE,
} from '../../utils/editorjs/tools/iframe';
import {getStatmilkHeight, STATMILK_PROVIDER, STATMILK_THUMBNAIL} from '../../utils/editorjs/tools/statmilk';
import ToolEventObserver from '../../utils/editorjs/tools/toolEventObserver';
import {getEditorBlockContent} from './editorMedia';

const EditorEmbeds = ({onChange, onMediaAdd, onMediaModalOpen}) => {
  const handleSubmit = (block) => {
    onMediaAdd(block, false, EVENT_FEATURED_MEDIA_IFRAME, false);
    onMediaModalOpen(false);
  };

  const onFeaturedMediaIframeEventTriggered = () => {
    // TODO: CTT-572 Can we better share prompt between this component and editor.js plugins (/src/utils/editorjs/tools/iframe + embed-extended)
    prompt(PROMPT_IFRAME_EMBED)
      .withTexts(PROMPT_IFRAME_TITLE, PROMPT_IFRAME_DESCRIPTION, PROMPT_BUTTON_SAVE)
      .withUserInput((input) => {
        const text = input.trim();
        const valid = getIframeUrl(text) || getYouTubeUrl(text) || checkValidUrl(text);
        return !valid ? IFRAME_ERROR_MESSAGE : null;
      }, '')
      .withAdditionalInput('number', 'Height', false)
      .show((input, height = '') => {
        let src = input.trim();

        // update source when youtube iframe or iframe used instead of a url
        const youTubeUrl = getYouTubeUrl(src);
        const iframeUrl = getIframeUrl(src);
        if (youTubeUrl) src = youTubeUrl;
        else if (iframeUrl) src = iframeUrl;

        // check if this is is a block that should be handled by another tool
        const isYouTube = youTubeUrl || src.includes('youtube.com') || src.includes('youtu.be'); // can be added as an iframe or youtube url
        const isStatmilk = src.includes('https://statmilk.bleacherreport.com');

        let block = getEditorBlockContent('iframe', {src, height, thumbnail: IFRAME_THUMBNAIL, provider: IFRAME_PROVIDER});

        if (isYouTube) {
          src = sanitizeYouTubeUrl(src);
          const embed = getYouTubeEmbedTool(null, src);
          // although getYouTubeEmbedTool() includes thumbnail it doesn't get returned because it's not native to the embed tool. Re-add it.
          block = getEditorBlockContent('embed', {...embed._data, height, thumbnail: getYouTubeThumbnail(getYouTubeId(src))});
        } else if (isStatmilk) {
          block = getEditorBlockContent('statmilk', {
            src,
            height: getStatmilkHeight(input) || '',
            thumbnail: STATMILK_THUMBNAIL,
            provider: STATMILK_PROVIDER,
          });
        }

        handleSubmit(block);
      });

    onMediaModalOpen();
  };

  const onEmbedEditTriggered = (props) => {
    onChange(props);
  };

  const subscribeTo = (event) => {
    switch (event) {
      case EVENT_FEATURED_MEDIA_IFRAME: {
        // Subscribe to event that triggered by featured media 'iframe'
        return ToolEventObserver.subscribe(event, onFeaturedMediaIframeEventTriggered);
      }
      case EVENT_EMBED_EDIT: {
        return ToolEventObserver.subscribe(event, onEmbedEditTriggered);
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    const subs = EVENTS_EMBED.map((event) => [event, subscribeTo(event)]);

    return () =>
      subs.map(([event, id]) => {
        if (id !== null) ToolEventObserver.unsubscribe(event, id);
      });
  }, []);

  return null;
};

EditorEmbeds.defaultProps = {
  onChange: () => {},
  onMediaAdd: () => {},
  onMediaModalOpen: () => {},
};

EditorEmbeds.propTypes = {
  onChange: PropTypes.func.isRequired,
  onMediaAdd: PropTypes.func.isRequired,
  onMediaModalOpen: PropTypes.func.isRequired,
};

export default EditorEmbeds;
