import React from 'react';
import PropTypes from 'prop-types';

import {STATUS_LABELS, STATUS_DRAFT, STATUS_PUBLISHED, STATUS_READY_FOR_PUBLISH, ARTICLE_ACTIVE_STATUSES} from '../../../constants/article';
import {isEditor} from '../../../helpers/articleHelpers';
function ArticleStatusBadge({status}) {
  if (isEditor()) return null;

  const excludedStatus = [STATUS_DRAFT, STATUS_READY_FOR_PUBLISH, STATUS_PUBLISHED];
  if (excludedStatus.includes(status)) return null;

  const label = STATUS_LABELS[status];

  return (
    <div className="article-status-badge">
      <span>{label}</span>
    </div>
  );
}

ArticleStatusBadge.propTypes = {
  status: PropTypes.oneOf(ARTICLE_ACTIVE_STATUSES).isRequired,
};

export default ArticleStatusBadge;
