import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Select from 'react-select/async';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';

import {searchForTags, getTagsByIds} from '../../apis/tags/tagsAPI';
import {BREAKING_NEWS_TAGS} from '../../constants/article';
import {PERSON, TEAM, DIVISION, LEAGUE, EVENT, LOCATION, MIN_SEARCH_LENGTH} from '../../constants/tags';
import {getIsReadOnly} from '../../selectors/article';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';

const TagsAdmin = (props) => {
  const {onTagSelect} = props;
  const isReadOnly = useSelector(getIsReadOnly);
  const [selected, setSelected] = useState(props.selected);
  const [other, setOther] = useState(null);
  const {error, removeError} = useGlobalNotificationsError();

  const isSearchValue = (search) => {
    return search.length >= MIN_SEARCH_LENGTH;
  };

  const updateTags = (tags) => {
    setSelected(tags);
    setOther(
      tags.filter(
        (tag) =>
          tag.type !== PERSON &&
          tag.type !== TEAM &&
          tag.type !== DIVISION &&
          tag.type !== LEAGUE &&
          tag.type !== EVENT &&
          tag.type !== LOCATION &&
          !BREAKING_NEWS_TAGS.includes(tag.id.toString())
      )
    );
    onTagSelect(tags.map((tag) => tag.id));
  };

  const handleTagDelete = (option) => {
    const tags = selected.filter((value) => value.id !== option.id);
    updateTags(tags);
  };

  const handleChange = (selected, event) => {
    if (error && error.message && error.message.length) {
      removeError();
    }
    if (selected === null && event.action === 'remove-value') {
      return handleTagDelete(event.removedValue);
    }
    return updateTags(selected);
  };

  const loadTags = debounce((value, callback) => {
    if (!isSearchValue(value)) {
      return callback([]);
    }
    // load tags by query
    return searchForTags(value).then(callback);
  }, 200);

  useEffect(() => {
    if (selected.length) {
      // load tags that are selected by default
      getTagsByIds(selected).then((tags) => {
        updateTags(tags);
      });
    }
  }, []);

  const stylesType = {
    control: (styles) => ({...styles, border: 'none'}),
    indicatorsContainer: (styles) => ({...styles, display: 'none'}),
    valueContainer: (styles) => ({...styles, padding: '0'}),
    multiValue: (styles) => ({
      ...styles,
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      borderRadius: '3rem',
      backgroundColor: '#2a2a2a',
      color: '#fff',
    }),
    multiValueLabel: (styles) => ({...styles, color: '#fff'}),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '#fff',
      ':hover': {
        backgroundColor: 'transparent',
        color: '#f82a2a',
        cursor: 'pointer',
      },
    }),
  };

  return (
    <div className="molecule tags-search tags-admin">
      <div className="row">
        <h4>Admin Tags</h4>
        <Select
          className="tags-search-primary"
          isMulti={true}
          isClearable={false}
          isDisabled={isReadOnly}
          isSearchable={true}
          onChange={handleChange}
          loadOptions={loadTags}
          cacheOptions={true}
          value={selected}
          placeholder="Add Admin and Revenue tags"
          getOptionLabel={(tag) => tag.name}
          getOptionValue={(tag) => tag.id}
          controlShouldRenderValue={false}
          noOptionsMessage={() => 'Search tags by name'}
        />
      </div>
      <div className="row">
        <Select
          className="tags-search-type tags-search-other"
          isMulti={true}
          isClearable={false}
          isSearchable={false}
          onChange={handleChange}
          loadOptions={loadTags}
          cacheOptions={true}
          value={other}
          placeholder="--"
          getOptionLabel={(tag) => tag.name}
          getOptionValue={(tag) => tag.id}
          styles={stylesType}
        />
      </div>
    </div>
  );
};

TagsAdmin.defaultProps = {
  selected: [],
  onTagSelect: () => {},
};

TagsAdmin.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number),
  onTagSelect: PropTypes.func.isRequired,
};

export default TagsAdmin;
