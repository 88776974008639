import {createAction} from '@reduxjs/toolkit';

import * as types from '../constants/action-types';
import {getArticleVersions, getArticleVersionById} from '../apis/articles/articlesAPI';
import {isSlideshow, stripAdBlocks} from '../helpers/articleHelpers';
import {getActiveSlideNumber, getChangedSlides} from '../helpers/versionHelpers';

export const clearVersions = createAction(types.VERSIONS_CLEAR);
export const setFetching = createAction(types.VERSIONS_FETCHING);
export const setShowChanges = createAction(types.VERSIONS_SHOW_CHANGES);
export const setShowCompareView = createAction(types.VERSIONS_SHOW_COMPARE_VIEW);
export const setShowUnchangedSlides = createAction(types.VERSIONS_SHOW_UNCHANGED_SLIDES);
export const setSlideActive = createAction(types.VERSIONS_SLIDE_ACTIVE);
export const setSlidesChanged = createAction(types.VERSIONS_SLIDES_CHANGED);
export const setVersions = createAction(types.VERSIONS_SET_VERSIONS);
export const setVersionHistory = createAction(types.VERSIONS_RECEIVE);

async function fetchArticleVersion(articleId, versionId, dispatch, getState, hasPreviousVersionId = false) {
  const {isLoading} = getState().versions;

  // Do not fetch version content if fetching is in progress UNLESS we are in the process of fetching the
  // previousVersion immediately after fetching the activeVersion (in that case we display the loading
  // indicator until both versions have loaded)
  if (isLoading && !hasPreviousVersionId) return null;

  dispatch(setFetching());
  const {error, content, ...rest} = await getArticleVersionById(articleId, versionId);
  return {
    version: {
      ...rest,
      content: content ? stripAdBlocks(content) : null,
    },
    error,
  };
}

export const fetchVersions = (articleId) => {
  return async (dispatch) => {
    dispatch(setFetching());
    const versions = await getArticleVersions(articleId);

    const result = {
      activeSlideNumber: 0,
      versions: versions.articleVersions,
      compareVersion: null,
      currentVersion: null,
      error: versions.error,
    };

    // retrieve current version content
    if (!versions.error) {
      const currentVersionId = versions.articleVersions[0].id;
      const {error, content, ...rest} = await getArticleVersionById(articleId, currentVersionId);
      result.currentVersion = {...rest, content: content ? stripAdBlocks(content) : null};
      result.error = error;

      // set the compare version to the previous version
      if (versions.articleVersions.length > 1) {
        const previousVersionId = versions.articleVersions[1].id;
        const {content, ...rest} = await getArticleVersionById(articleId, previousVersionId);
        result.compareVersion = {...rest, content: content ? stripAdBlocks(content) : null};
      }

      // if this is a slideshow, default selected slide number should be the the first changed slide
      if (isSlideshow(result?.currentVersion.content)) {
        const changedSlides = getChangedSlides(result.currentVersion, result.compareVersion);
        dispatch(setSlidesChanged({changedSlides}));
        result.activeSlideNumber = getActiveSlideNumber(changedSlides);
      }
    }

    dispatch(setVersionHistory(result));
  };
};

export const setActiveVersion = (articleId, versionId) => {
  return async (dispatch, getState) => {
    const version = await fetchArticleVersion(articleId, versionId, dispatch, getState);

    if (!version) return;

    // get the previous version id (if this is the first version, there is no previous so just keep using the first version)
    let previousVersionId = null;
    const versions = await getArticleVersions(articleId);
    if (versions && !versions.error) {
      const activeVersionIndex = versions.articleVersions.findIndex((v) => v.id === parseInt(versionId));
      const previousVersionIndex = activeVersionIndex < versions.articleVersions.length - 1 ? activeVersionIndex + 1 : activeVersionIndex;
      previousVersionId = versions.articleVersions[previousVersionIndex].id;
    }

    const {error: activeVersionError, version: activeVersion} = version;
    const hasPreviousVersionId = !!previousVersionId;
    dispatch(setVersions({activeVersion, error: activeVersionError, hasPreviousVersionId}));

    if (!previousVersionId) return;

    // set previous version (for 'show changes')
    const previousVersion = await fetchArticleVersion(articleId, previousVersionId, dispatch, getState, hasPreviousVersionId); // We set isLoading: true if we have a previousVersionId
    if (!previousVersion) {
      // We set isLoading: false if there is no previous version
      dispatch(setVersions({isLoading: false}));
      return;
    }

    const {error: previousVersionError, version: compareVersion} = previousVersion;
    // We set isLoading: false once the previous version has loaded
    dispatch(setVersions({compareVersion, error: previousVersionError}));

    // when you change slideshow versions, update the active slide number to whatever slide number
    // was last changed in the version you're switching to
    if (isSlideshow(activeVersion?.content)) {
      const changedSlides = getChangedSlides(activeVersion, compareVersion);
      const activeSlideNumber = getActiveSlideNumber(changedSlides);
      dispatch(setSlidesChanged({changedSlides}));
      dispatch(setVersions({activeSlideNumber}));
    }
  };
};

export const setCompareVersion = (articleId, versionId) => {
  return async (dispatch, getState) => {
    const version = await fetchArticleVersion(articleId, versionId, dispatch, getState);
    if (!version) return;

    const {error, version: compareVersion} = version;
    dispatch(setVersions({compareVersion, error}));
  };
};
