import {sanitizeText} from '../../../../helpers/textHelpers';

// Nodes that are required by tweet parser
const SELECTORS = ['blockquote', 'blockquote > a', 'blockquote > p'];

// RegExp to extract/separate the author's name and username from a TextNode in
// the iframe embed code provided by Twitter
const REGEX_AUTHOR_NAME_AND_USERNAME = /^—?\s*(.+)\s\(@(.+)\)\s*$/;

/**
 * Class is responsible for tweeter embed code parsing.
 * It used in tweet Editor.JS tool for transform tweet embed code to default article tweet format
 *
 * Example usage:
 * const parser = new EmbedCodeParser(`
 *  <blockquote class="twitter-tweet">
 *    <p lang="en" dir="ltr">tweet content <strong>goes</strong> here</p>
 *    &mdash; Tweet Author (@tweetauthor)
 *    <a href="https://twitter.com/{USR}/status/{ID}?ref_src=twsrc%5Etfw">June 01, 1900</a>
 *  </blockquote>
 *  <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
 * `)
 * console.log(parser.getTweetId()) // {ID}
 * console.log(parser.getCreatedAt()) // "Fri Jun 01 1900 00:00:00"
 * console.log(parser.getLanguage()) // "en"
 * console.log(parser.getTweet()) // "tweet content <strong>goes</strong> here"
 * console.log(parser.getUser()) // ["Tweet Author", "tweetauthor"]
 */
export default class EmbedCodeParser {
  constructor(code) {
    const doc = new DOMParser().parseFromString(code, 'text/html');
    if (!this.isEmbedCodeValid(doc)) {
      throw new Error('Invalid embed document format');
    }
    this.doc = doc;
  }

  /**
   * Verify that passed document is valid and can be parsed
   * @param {Document} doc represents tweet embed code document
   * @returns {boolean}
   */
  isEmbedCodeValid(doc) {
    return doc.querySelectorAll(SELECTORS.join(',')).length === SELECTORS.length;
  }

  /**
   * Parse tweet embed code and retrieve url
   * ex. of parsing node
   *
   * <a href="https://twitter.com/{USR}/status/{ID}?ref_src=twsrc%5Etfw">June 01, 1900</a>
   * @returns {string} with ?ref_src=... stripped
   */
  getTweetUrl() {
    const tweetLink = this.doc.querySelector('blockquote > a');
    const tweetLinkParts = tweetLink.href.split('?');
    return tweetLinkParts[0];
  }

  /**
   * Parse tweet embed code and tweet ID
   * ex. of parsing node
   *
   * <a href="https://twitter.com/{USR}/status/{ID}?ref_src=twsrc%5Etfw">June 01, 1900</a>
   * @returns {string}
   * @throws {Error} if id cannot be parsed from the document
   */
  getTweetId() {
    const tweetLink = this.doc.querySelector('blockquote > a');
    const path = new URL(tweetLink.href).pathname.split('/');
    if (path.length !== 4) {
      throw new Error(`Cannot parse tweet id from ${tweetLink.href}`);
    }
    return sanitizeText(path[path.length - 1]);
  }

  /**
   * Parse tweet embed code and retrieve tweet creation date
   * ex. of parsing node
   *
   * <a href="https://twitter.com/{USR}/status/{ID}?ref_src=twsrc%5Etfw">June 01, 1900</a>
   * @returns {string}
   */
  getCreatedAt() {
    const tweetLink = this.doc.querySelector('blockquote > a');
    return sanitizeText(tweetLink.textContent);
  }

  /**
   * Parse tweet embed code and retrieve language
   * ex. of parsing node
   *
   * <p lang="en" dir="ltr">
   *  tweet content goes here
   * </p>
   * @returns {(string|null)}
   */
  getLanguage() {
    const paragraph = this.doc.querySelector('blockquote > p');
    return sanitizeText(paragraph.lang) || 'en';
  }

  /**
   * Parse tweet embed code and retrieve tweet content
   * ex. of parsing node
   *
   * <p lang="en" dir="ltr">
   *  tweet content <strong>goes</strong> here
   * </p>
   * @returns {string}
   */
  getTweet() {
    const paragraph = this.doc.querySelector('blockquote > p');
    return paragraph.innerHTML;
  }

  /**
   * Parse tweet embed code and retrieve tweet author react name along with twitter screen name
   * ex. of parsing node
   *
   * <blockquote class="twitter-tweet">
   *  <p lang="en" dir="ltr">
   *    tweet content <strong>goes</strong> here
   *   </p>&mdash; Tweet Author (@tweetauthor)
   *   <a href="https://twitter.com/{USR}/status/{ID}?ref_src=twsrc%5Etfw">June 01, 1900</a>
   * </blockquote>
   * @returns {Array}
   * @throws {Error} if user information cannot be parsed from the document
   */
  getUser() {
    const blockquote = this.doc.querySelector('blockquote');
    const node = Array.prototype.find.call(blockquote.childNodes, (child) => child.nodeType === Node.TEXT_NODE);
    if (!node) {
      throw new Error(`Cannot parse tweet author from ${blockquote.textContent}`);
    }
    // example of the source string: "— Pixel Art (@livepixelart) "
    // note that the author's name may have parentheses in it
    if (!REGEX_AUTHOR_NAME_AND_USERNAME.test(node.textContent)) {
      throw new Error(`Cannot parse tweet author from text node ${node.textContent}`);
    }
    const [_, ...names] = REGEX_AUTHOR_NAME_AND_USERNAME.exec(node.textContent);
    return names;
  }
}
