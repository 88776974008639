import React from 'react';
import {useSelector} from 'react-redux';
import Notification from '../notification';

import {selectActiveVersion} from '../../../selectors/versions';
import {articleHasStableEditorIds} from '../../../helpers/articleHelpers';
export const STABLE_IDS_RELEASE_DATE = 'December 12, 2022'; // CTT-1094

const NotificationStableIds = () => {
  const activeVersion = useSelector(selectActiveVersion);
  const hasStableIds = articleHasStableEditorIds(activeVersion?.content);

  if (hasStableIds) return null;
  return (
    <Notification type="warning">{`Note: Body changes may display less accurately on articles created before ${STABLE_IDS_RELEASE_DATE}.`}</Notification> // eslint-disable-line max-len
  );
};

export default NotificationStableIds;
