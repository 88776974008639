import {useEffect, useRef} from 'react';

function useScrollToFrame(framesInStrip) {
  const wrapperRef = useRef(null);
  const frameRef = useRef(null);

  const getVisibilityCell = () => {
    const wrapper = wrapperRef.current;
    const halfWidth = wrapper.offsetWidth / 2;
    return [wrapper.offsetLeft + wrapper.scrollLeft - halfWidth, wrapper.offsetLeft + wrapper.scrollLeft + halfWidth];
  };

  const isActiveFrameHidden = () => {
    const {offsetLeft: frameOffset} = frameRef.current;
    const [cellLeftBorder, cellRightBorder] = getVisibilityCell();
    return frameOffset < cellLeftBorder || frameOffset > cellRightBorder;
  };

  useEffect(() => {
    if (isActiveFrameHidden()) {
      // scroll frame wrapper if active frame is not in a visible cell
      wrapperRef.current.scrollTo(frameRef.current.offsetLeft, 0);
    }
  }, [framesInStrip]);

  return [wrapperRef, frameRef];
}

export default useScrollToFrame;
