import React, {useState} from 'react';

export const GlobalNotificationsContext = React.createContext({
  error: null,
  hasError: () => {},
  addError: () => {},
  removeError: () => {},
});

export default function GlobalNotificationsProvider({children}) {
  const [error, setError] = useState({});

  const context = {
    error,
    hasError: (field) => error && error[field],
    addError: (errorToAdd) => setError({...error, ...errorToAdd}),
    removeError: (...fieldsToRemove) => {
      if (!fieldsToRemove.length) return setError({});

      const newError = Object.entries(error).reduce((acc, [field, message]) => {
        if (!fieldsToRemove.includes(field)) {
          acc[field] = message;
        }
        return acc;
      }, {});
      return setError(newError);
    },
  };

  return <GlobalNotificationsContext.Provider value={context}>{children}</GlobalNotificationsContext.Provider>;
}
