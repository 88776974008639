/**
 * Split the `items` array into multiple, smaller arrays of the given `size`.
 *
 * Example
 * const items = [1, 2, 3, 4, 5, 6, 7, 8]
 * chunk(items, 3)
 * returns: [ [1, 2, 3], [4, 5, 6], [7, 8] ]
 *
 * @param {Array} items
 * @param {Number} size
 *
 * @returns {Array[]}
 */
export const chunk = (arr, size) => {
  const chunks = [];
  const items = [].concat(...arr);
  while (items.length) chunks.push(items.splice(0, size));
  return chunks;
};
