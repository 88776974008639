import {IMAGE_ACCEPTED_FILE_EXTENSIONS, IMAGE_MAX_SIZE_IN_MB} from '../constants/media';

export function getFileRejectionErrorMessages(fileRejections) {
  // Since we don't support multiple file uploads, we generate a single array of error strings rather than an
  // array of arrays that each contain errors relating to a specific file
  const fileRejectionErrors = fileRejections.map(({errors}) =>
    errors
      .map((err) => {
        // There are only four possible file rejection codes, as defined in
        // node_modules/react-dropzone/typings/react-dropzone.d.ts, which include 'file-invalid-type', 'file-too-large',
        // 'file-too-small' and 'too-many-files'. We don't currently set a minimum file size in our app, so we fall
        // through to returning the default value.
        switch (err.code) {
          case 'file-invalid-type':
            return `Uploaded images must be one of the following types: ${IMAGE_ACCEPTED_FILE_EXTENSIONS.join(', ')}`;
          case 'file-too-large':
            return `Uploaded images must be ${IMAGE_MAX_SIZE_IN_MB}MB or smaller`;
          case 'too-many-files':
            return 'Uploading multiple images is not allowed';
          case 'file-too-small':
          default:
            return err.message;
        }
      })
      .concat()
  )[0];

  // We remove duplicate errors because in the case of the 'too-many-files' error, the error message gets
  // repeated once for each file included in the current upload attempt
  return [...new Set(fileRejectionErrors)];
}
