// Sort
export const SORT_DIR_ASC = 'asc';
export const SORT_DIR_DSC = 'dsc';
export const SORT_KEY_ARTICLE_TITLE = 'title';
export const SORT_KEY_AUTHOR = 'author';
export const SORT_KEY_EDITOR = 'editor';
export const SORT_KEY_PUBLISH_DATE = 'publishedAt';

// Search
export const SEARCH_HEADERS = {
  [SORT_KEY_ARTICLE_TITLE]: 'Article Title',
  [SORT_KEY_AUTHOR]: 'Author',
  [SORT_KEY_EDITOR]: 'Editor',
  [SORT_KEY_PUBLISH_DATE]: 'Publish Date',
  actions: '',
};
export const SEARCH_HEADERS_SORTABLE = [SORT_KEY_ARTICLE_TITLE, SORT_KEY_AUTHOR, SORT_KEY_EDITOR, SORT_KEY_PUBLISH_DATE];

export const SEARCH_LIMIT_DEFAULT = 40;

export const SEARCH_TYPES = {
  EDITOR: 'Editor',
  TAG_NAME: 'Tag Name',
  TITLE: 'Title',
  WRITER: 'Writer',
};

export const SEARCH_INPUT_QUERY_TYPES = {
  title: {value: SEARCH_TYPES.TITLE, label: 'Title', placeholder: 'Enter article title'},
  writer: {value: SEARCH_TYPES.WRITER, label: 'Author', placeholder: "Enter author's full name, email or username"},
  editor: {value: SEARCH_TYPES.EDITOR, label: 'Editor', placeholder: "Enter editor's full name, email or username"},
};
