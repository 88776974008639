import {IconQuote} from '@codexteam/icons'; // TODO: currently using same icon for both blockquote + pullquote, nothing suitable currently in https://github.com/codex-team/icons
import {addDiffClass} from '../../tools/difference';

import './index.css';

/**
 * Constructs the blockquote
 */
export default class BlockQuote {
  /**
   * Allow Block Quote to be converted from/to another blocks
   */
  static get conversionConfig() {
    return {
      import: 'text',
      export: 'text',
    };
  }

  /**
   * Block Quote tool representation in toolbar
   */
  static get toolbox() {
    return {
      icon: IconQuote,
      title: 'Block Quote',
    };
  }

  /**
   * Sanitize user input information
   */
  static get sanitize() {
    // Clear all tags expect br
    return {
      text: {
        br: true,
      },
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Returns tool classes
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      quote_indent: 'ce-blockquote',
    };
  }

  constructor({api, data, readOnly}) {
    this.api = api;
    this.data = {
      text: data.text || '',
    };
    this.readOnly = readOnly;
  }

  /**
   * Display blockquote
   */
  render() {
    const quote = document.createElement('blockquote');
    quote.classList.add(this.CSS.baseClass, this.CSS.quote_indent);
    quote.contentEditable = !this.readOnly;
    quote.innerHTML = this.data.text;
    quote.dataset.placeholder = 'Enter block quote';
    addDiffClass(quote);

    return quote;
  }

  /**
   * Saves user input data
   * @param {object} el quote HTML node
   */
  save(el) {
    const text = el ? el.innerHTML : '';
    return Object.assign(this.data, {text});
  }

  /**
   * Validate the user input data
   * @param {object} saved user input date
   */
  validate(saved) {
    if (saved.text.trim() === '') {
      // Consider that quote is invalid
      return false;
    }
    return true;
  }

  /**
   * Provide merging two quote block merging
   * @param {string} text content of the merging block
   */
  merge({text}) {
    this.data.text += text;
  }
}
