import {useCallback, useEffect, useRef} from 'react';
import {useBeforeUnload, unstable_useBlocker as useBlocker} from 'react-router-dom';

// You can abstract `useBlocker` to use the browser's `window.confirm` dialog to
// determine whether or not the user should navigate within the current origin.
// `useBlocker` can also be used in conjunction with `useBeforeUnload` to
// prevent navigation away from the current origin.
//
// IMPORTANT: There are edge cases with this behavior in which React Router
// cannot reliably access the correct location in the history stack. In such
// cases the user may attempt to stay on the page but the app navigates anyway,
// or the app may stay on the correct page but the browser's history stack gets
// out of whack. You should test your own implementation thoroughly to make sure
// the tradeoffs are right for your users.
// @see https://gist.github.com/chaance/2f3c14ec2351a175024f62fd6ba64aa6
export default function usePrompt(message, {beforeUnload} = {}) {
  const blocker = useBlocker(
    useCallback(
      ({currentLocation, nextLocation}) => {
        if (typeof message === 'string') return !global.window.confirm(message);
        if (typeof message === 'function' && message(currentLocation, nextLocation)) {
          return !global.window.confirm(message(currentLocation, nextLocation));
        }
        return false;
      },
      [message]
    )
  );

  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (beforeUnload) {
          event.preventDefault();
          // Custom messages are no longer supported, so we just return a non-empty
          // string that matches the generic string returned by the Chrome browser
          // @see: https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
          event.returnValue = 'Changes you made may not be saved.';
        }
      },
      [beforeUnload]
    ),
    {capture: true}
  );
}
