import React from 'react';
import useGlobalNotificationsError from './useGlobalNotificationsError';

import Notification from '../../components/atoms/notification';

function GlobalNotifications() {
  const {error, removeError} = useGlobalNotificationsError();
  const getErrorMessage = () => {
    if (!error) return null;
    return Object.entries(error).reduce((acc, [_, message]) => {
      const text = typeof message === 'string' ? (message.slice(-1) !== '.' ? `${message}.` : message) : '';
      return acc === '' ? `${text}` : `${acc} ${text}`;
    }, '');
  };

  return <Notification onClear={() => removeError()}>{getErrorMessage()}</Notification>;
}

export default GlobalNotifications;
