import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import {PROMPT_BUTTON_OK, PROMPT_SLIDE_CHANGE_UNSAVED, PROMPT_SLIDE_REORDER_ERROR_TITLE} from '../../constants/prompt';
import {prompt} from '../../helpers/promptHelper';
import useScrollToFrame from '../../helpers/hooks/useScrollToFrame';
import {getId, getIsChanged} from '../../selectors/article';

import AddMoreFrames from '../atoms/filmstrip/addMoreFrames';
import FilmstripFrame from '../atoms/filmstrip/filmstripFrame';
import {STYLE_FRAME_WIDTH} from '../atoms/filmstrip/filmstripFrame';

/**
 * Renders the filmstrip with N frames
 * @description contains all logic related the filmstrip frame (create, select, delete)
 * @example
 *  <Filmstrip
 *    frames={[...]} // thumbnail data for each frame
 *    activeFrame={0} // index on currently selected frame
 *    onFrameSelect={(indexToSelect) => { }} // callback function for frame select event
 *    onFrameDelete={(indexToDelete) => { }} // callback function for frame delete event
 *    onFrameCreate={(numberOfFramesToAdd, totalNumberOfFrames) => { }} // callback function for frame create event
 *    framesWithError={[1, 4]} // list of frames which should be marked as containing error
 *  />
 * @param {object} props list of component parameters
 */
const Filmstrip = (props) => {
  const {activeFrame, frames, framesWithError, onFrameCreate, onFrameDelete, onFrameMove, onFrameSelect} = props;
  const id = useSelector(getId);
  const isChanged = useSelector(getIsChanged);
  const framesInStrip = frames.length;
  const [framesWrapperRef, frameActiveRef] = useScrollToFrame(framesInStrip);

  const calcFilmstripWidth = (framesNumber) => {
    const fullWidth = STYLE_FRAME_WIDTH * framesNumber;
    return fullWidth * 1.1; // 1.1 is a spacing coefficient (slide width + margin)
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#2a2a2a' : '#000',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    width: `${calcFilmstripWidth(framesInStrip)}rem`,
    background: isDraggingOver ? 'lightblue' : 'none',
  });

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const {source, destination} = result;

    // Prevent setting a slide without a title as the title slide for a saved slideshow
    const isInvalidTitleSlide = id && destination.index === 0 && !frames[source.index].title;

    // allow reorder slides when this is a new draft (unsaved) slideshow or if there are no unsaved changes
    // and we are not trying to move a slide without a title to the title slide position for a previously
    // saved slideshow
    const canReorderSlides = !id || (!isChanged && !isInvalidTitleSlide);

    if (canReorderSlides) {
      await onFrameMove(source.index, destination.index);
      await onFrameSelect(destination.index, false);
      return;
    }

    if (isInvalidTitleSlide) {
      prompt(PROMPT_SLIDE_REORDER_ERROR_TITLE)
        .withTexts('Warning', 'Slide must have a title before it can be moved to the title slide position.', PROMPT_BUTTON_OK, null)
        .withMute(false)
        .show(() => {});
      return;
    }

    prompt(PROMPT_SLIDE_CHANGE_UNSAVED)
      .withTexts(
        'Warning',
        `Save your changes on the current slide before reordering slides. To save your changes choose [${PROMPT_BUTTON_OK}] and then [Save Slide].`, // eslint-disable-line max-len
        PROMPT_BUTTON_OK,
        null
      )
      .withMute(false)
      .show(() => {});
  };

  return (
    <div className="molecule filmstrip">
      <AddMoreFrames frames={framesInStrip} onFrameCreate={onFrameCreate} />
      <div ref={framesWrapperRef} className="filmstrip-frames-wrapper">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className="filmstrip-frames" style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                {frames.map((frame, index) => {
                  const isActive = index === activeFrame;
                  return (
                    <Draggable key={`framedrag-${index}`} draggableId={`framedrag-${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <FilmstripFrame
                            ref={isActive ? frameActiveRef : null}
                            frame={{...frame, slideNumber: index}}
                            isActive={isActive}
                            isError={framesWithError.includes(index)}
                            onSelected={onFrameSelect}
                            onDeleted={onFrameDelete}
                            withDelete={true}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

Filmstrip.defaultProps = {
  activeFrame: 0,
  framesWithError: [],
};

Filmstrip.propTypes = {
  activeFrame: PropTypes.number.isRequired,
  frames: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
    })
  ).isRequired,
  framesWithError: PropTypes.arrayOf(PropTypes.number),
  onFrameCreate: PropTypes.func.isRequired,
  onFrameDelete: PropTypes.func.isRequired,
  onFrameMove: PropTypes.func.isRequired,
  onFrameSelect: PropTypes.func.isRequired,
};

export default Filmstrip;
