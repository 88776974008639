import {IconTwitter} from '@codexteam/icons';
import EmbedCodeParser from './embedCodeParser';
import {addDiffClass} from '../difference';
import ToolEventObserver from '../toolEventObserver';
import {EVENT_EMBED_EDIT} from '../../../../constants/events';
import {PROMPT_TWEET_EMBED} from '../../../../constants/prompt';
import {prompt} from '../../../../helpers/promptHelper';
import logger from '../../../../logger';

require('./index.css');

/**
 * Constructs an embedded tweet from blockquote
 * @see example {@link https://bleacherreport.com/articles/2876014 2876014}
 * @see https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/overview
 */
export default class Tweet {
  scriptId = 'twitter-widgets-script';
  constructor({api, data, block, readOnly}) {
    this.api = api;
    this.data = data;
    this.block = block;
    this.readOnly = readOnly;
    this.nodes = {};
    this.editorBlockIndex = 0;
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   */
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      icon: IconTwitter,
      title: 'Tweet',
    };
  }

  setDataFromParser(parser) {
    const [name, screenName] = parser.getUser();
    const data = {
      id: parser.getTweetId(),
      url: parser.getTweetUrl(),
      metadata: {
        twitterJson: {
          user: {name, screenName},
          text: parser.getTweet(),
          lang: parser.getLanguage(),
          createdAt: parser.getCreatedAt(),
        },
      },
    };
    this.data = {...data, __original__: data};
  }

  renderNewTweetFromEmbed() {
    if (!this.readOnly) {
      try {
        prompt(PROMPT_TWEET_EMBED)
          .withUserInput()
          .withTexts('Enter tweet embed code', null, 'Save')
          .show((code) => {
            if (code) this.setDataFromParser(new EmbedCodeParser(code));

            // when the tweet is first added the block doesn't exist yet, but will exist when editing
            const blockExists = this.block.holder;

            if (!blockExists) {
              this.api.blocks.insert('tweet', this.data, {}, this.editorBlockIndex);
            } else {
              this.block.holder.appendChild(this.render());
            }

            // update the redux article state
            ToolEventObserver.trigger(EVENT_EMBED_EDIT, {editorBlockIndex: this.editorBlockIndex});
          });
      } catch (err) {
        logger.error(err);
        global.window.alert(
          'That embed code does not appear to work. Please double-check that it is correct and try again, or visit Twitter to generate and copy the embed code again.' // eslint-disable-line max-len
        );
      }
    }

    // Editor.js expects render() to return a valid Node otherwise will throw console error:
    // `Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'.`
    // But returning a node here will insert empty blocks into the editor which are
    // not necesssary and can cause unintended side effects
    // @see CTT-940/CTT-950
    // Note we are currently supressing the console error in `src/client.js`
    // and cypress uncaught:exception in `cypress/support/index.js`
    this.nodes.tweet = null;
  }

  renderTweet() {
    const tweetId = this.data.id;
    if (!tweetId) return;

    if (!document.getElementById(this.scriptId)) {
      const script = this.renderTwitterScript();
      document.body.appendChild(script);
    }

    const author = this.renderAuthor();
    const content = this.renderContentBody();
    const link = this.renderTweetLink();

    const blockquote = this.renderBlockquote();
    blockquote.appendChild(author);
    blockquote.appendChild(content);
    blockquote.appendChild(link);

    // Need a containing element in order to retain the diff classes
    const container = document.createElement('div');
    container.classList.add('embed-container', 'embed-container-tweet');
    container.appendChild(blockquote);
    addDiffClass(container, this.data);
    this.nodes.tweet = container;

    this.twitterLoad(this.nodes.tweet);
  }

  render() {
    this.isNewBlock() ? this.renderNewTweetFromEmbed() : this.renderTweet();

    // index / getCurrentBlockIndex() needs to be set during render() to catch new blocks being added
    // inside renderNewTweetFromEmbed() it only correctly grabs the index for edit/replace events, not new blocks being added
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    if (currentBlockIndex >= 0) this.editorBlockIndex = currentBlockIndex;

    return this.nodes.tweet;
  }

  renderAuthor() {
    const {
      user: {name, screenName},
    } = this.data.metadata.twitterJson;

    const author = document.createElement('span');
    author.classList.add('tweet-author');
    author.innerHTML = `&mdash; ${name}`;

    const authorAccount = document.createElement('span');
    authorAccount.classList.add('tweet-author-account');
    authorAccount.innerHTML = ` @${screenName}`;

    author.appendChild(authorAccount);
    return author;
  }

  renderContentBody() {
    const {lang, text} = this.data.metadata.twitterJson;
    const content = document.createElement('p');
    content.setAttribute('lang', lang);
    content.setAttribute('dir', 'ltr');
    content.innerHTML = text;
    return content;
  }

  renderTweetLink() {
    const {
      createdAt,
      user: {screenName},
    } = this.data.metadata.twitterJson;
    const link = document.createElement('a');
    link.setAttribute('href', `https://twitter.com/${screenName}/status/${this.data.id}`);
    link.innerHTML = createdAt;
    return link;
  }

  renderBlockquote() {
    const {lang} = this.data.metadata.twitterJson;
    const blockquote = document.createElement('blockquote');
    blockquote.classList.add('twitter-tweet', 'tw-align-center');
    blockquote.setAttribute('id', `tweet-${this.data.id}`);
    blockquote.setAttribute('data-lang', lang);
    return blockquote;
  }

  renderTwitterScript() {
    const script = document.createElement('script');
    script.src = '//platform.twitter.com/widgets.js';
    script.key = this.scriptId;
    script.id = this.scriptId;
    script.async = true;
    return script;
  }

  twitterLoad = (elem) => {
    if (elem && global.twttr && global.twttr.widgets) {
      global.twttr.widgets.load(elem);
    }
  };

  isNewBlock() {
    return Object.keys(this.data).length === 0;
  }

  save() {
    return this.data;
  }

  validate(savedData) {
    if (!savedData) return false;
    return true;
  }
}
