import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import classnames from 'classnames';

import {getUserProfilesByIds} from '../../actions/users';
import {clearIndividual} from '../../actions/editorStats';
import {canAccessEditorStatsRoster} from '../../helpers/permissions';
import {getUserFromProfile, isCurrentUserThisUser} from '../../helpers/userHelpers';
import {selectError as selectErrorUsers, selectProfiles} from '../../selectors/users';
import {isEditorStatsFeatureEnabled} from '../../utils/features';

import Filters from '../molecules/editorStatsFiltersIndividual';
import PageError from '../atoms/pageError';
import PageTitle from '../atoms/pageTitle';
import PermissionDenied from './permissionDenied';
import TableIndividual from '../molecules/editorStatsTableIndividual';

const EditorStatsIndividual = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: editorId} = useParams();
  const profiles = useSelector(selectProfiles);
  const profile = getUserFromProfile(profiles[editorId]);
  const error = useSelector(selectErrorUsers);

  const hasProfile = typeof profiles[editorId] === 'object';
  const classes = classnames('organism', 'editor-stats', 'editor-stats-page', {
    'is-error': error,
    'is-loading': !hasProfile,
  });
  const title = profile?.name ? `Editor Stats: ${profile.name}` : 'Editor Stats';

  useEffect(() => {
    if (!isEditorStatsFeatureEnabled()) navigate('/', {replace: true});

    // if you've navigated to this page, redux will already have the profiles. but if you hit the url directly, profiles will be empty.
    if (!hasProfile) dispatch(getUserProfilesByIds([editorId]));
    return () => dispatch(clearIndividual());
  }, []);

  if (!canAccessEditorStatsRoster() && !isCurrentUserThisUser(editorId)) {
    return (
      <PermissionDenied title={title}>
        <p>You're not permitted to view this editor.</p>
      </PermissionDenied>
    );
  }

  // render page error if error fetching the user profiles
  // TODO: will need to update this to handle either user profile error or user data error with CTT-1081
  // TODO: also make conditional rendering consistent between this and ./editorStats component (preferred method TBD until CTT-1081)
  if (error) {
    return (
      <section className={classes}>
        <PageError what="error fetching user profiles">
          <p>The error could be caused by a couple reasons:</p>
          <ul>
            <li>
              The editor ID <code>{editorId}</code> you're trying to access doesn't exist
            </li>
            <li>There's an API error</li>
          </ul>
        </PageError>
      </section>
    );
  }

  return (
    <section className={classes}>
      <PageTitle title={title} />
      <div className="page-header">
        <h1>{title}</h1>
        {canAccessEditorStatsRoster() && (
          <div className="link link-back">
            <Link to="/tools/editor-stats">&lsaquo; back</Link>
          </div>
        )}
      </div>
      <Filters />
      <TableIndividual />
    </section>
  );
};

export default EditorStatsIndividual;
