import {FILTER_DELETED, FILTER_DRAFT, FILTER_IN_REVIEW, FILTER_TO_PUBLISH, EDITOR_FILTERS, WRITER_FILTERS} from '../constants/dashboard';
import {
  STATUS_DELETED,
  STATUS_DRAFT,
  STATUS_IN_REVIEW,
  STATUS_READY_FOR_PUBLISH,
  STATUS_READY_FOR_REVIEW,
  STATUS_PUBLISHED,
} from '../constants/article';
import {isEditor} from './articleHelpers';

export function getArticleStatusForFilter(filter) {
  switch (filter) {
    case FILTER_DRAFT:
      // Fetch all not published articles for writers
      if (!isEditor()) return [STATUS_DRAFT, STATUS_IN_REVIEW, STATUS_READY_FOR_REVIEW, STATUS_READY_FOR_PUBLISH];
      return [STATUS_DRAFT];
    case FILTER_IN_REVIEW:
      return [STATUS_IN_REVIEW, STATUS_READY_FOR_REVIEW];
    case FILTER_TO_PUBLISH:
      return [STATUS_READY_FOR_PUBLISH];
    case FILTER_DELETED:
      return [STATUS_DELETED];
    default:
      return [STATUS_PUBLISHED];
  }
}

export function getFiltersList() {
  return isEditor() ? EDITOR_FILTERS : WRITER_FILTERS;
}

export const getDefaultFilter = () => {
  return isEditor() ? FILTER_IN_REVIEW : FILTER_DRAFT;
};

export const getShowMineUrlParam = () => {
  return JSON.parse(new URLSearchParams(location.search).get('showMine'));
};

/**
 * Ensures a B/R article link is properly formatted
 * @param {url} b/r article link
 * @returns {url} https url
 */
export const sanitizeArticleLink = (url) => {
  const link = url.replace('http://bleacherreport.com', 'https://bleacherreport.com');
  return link;
};

/**
 * Opens a B/R article link
 * @param {url} b/r article link to open
 * @returns {false} opens window
 */
export const openArticleLink = (url) => {
  window.open(sanitizeArticleLink(url));
  return false;
};
