import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import reporter from '../../reporter';
import logger from '../../logger';

import {saveMetadata} from '../../actions/article';
import {
  SLIDE_TITLE_MINLENGTH,
  STATUS_DRAFT,
  STATUS_READY_FOR_REVIEW,
  STATUS_READY_FOR_PUBLISH,
  STATUS_PUBLISHED,
  VALIDATE_FIELD_SOCIAL_DESCRIPTION,
  VALIDATE_FIELD_SOCIAL_TITLE,
  VALIDATE_FIELD_TITLE,
} from '../../constants/article';
import {
  PROMPT_BUTTON_CANCEL,
  PROMPT_BUTTON_CONFIRM,
  PROMPT_BUTTON_CONTINUE_UNSAVED,
  PROMPT_PUBLISH,
  PROMPT_RELEASE_LOCK_UNSAVED,
} from '../../constants/prompt';
import {getArticleContentForClipboard} from '../../helpers/articleContent';
import {canViewVersionHistory, getArticleUrlPreview, getArticleUrlVersions, isArticleReadOnlyForWriter} from '../../helpers/articleHelpers';
import {prompt} from '../../helpers/promptHelper';
import {isArticleLockedByAnotherUser, isCurrentUserTheLastEditor} from '../../helpers/softlockHelpers';
import {
  getActiveSlide,
  getArticleData,
  getId,
  getIsChanged,
  getIsChangedMetadata,
  getIsPublished,
  getIsReadOnly,
  getIsSlideshow,
  getIsTitleSlide,
} from '../../selectors/article';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';
import validateArticle, {errorMessageFieldMinChars, isSlideTitleValid, isTitleValid} from '../../utils/articleValidate';
import validateSlideshow from '../../utils/slideshowValidate';

import FeedbackButton from '../atoms/article/feedbackButton';
import HistoryButton from '../atoms/article/historyButton';
import IdleTimer from '../atoms/article/idleTimer';
import PreviewButton from '../atoms/article/previewButton';
import ScheduleButton from '../atoms/article/scheduleButton';
import StatusButton from '../atoms/article/statusButton';
import Toast from '../atoms/toast';
import ToastStatus from '../atoms/toastStatus';

export const PROMPT_PUBLISH_TITLE = 'Publish Article';
export const PROMPT_PUBLISH_TITLE_SLIDESHOW = 'Publish Slideshow';
export const promptPublishTitle = (articleType = 'article') => {
  if (articleType === 'slideshow') return PROMPT_PUBLISH_TITLE_SLIDESHOW;
  return PROMPT_PUBLISH_TITLE;
};
export const promptPublishDescription = (title, isPublished = false) => {
  if (isPublished) return `Saving changes will immediately update this published article.\n\nAre you sure you want to update "${title}"?`;
  return `Are you sure you want to publish "${title}"?`;
};

const ArticleHeader = (props) => {
  const {
    isNewArticle,
    onReleaseLock,
    onSave,
    onSaveSlide,
    onStatusChange,
    onStealLock,
    onIdleTimerExpires,
    redirectToDashboard,
    setIsCopiedToClipboard,
    toastStatus,
    wordcount,
  } = props;

  const activeSlide = useSelector(getActiveSlide);
  const article = useSelector(getArticleData);
  const id = useSelector(getId);
  const isChanged = useSelector(getIsChanged);
  const isChangedMetadata = useSelector(getIsChangedMetadata);
  const isPublished = useSelector(getIsPublished);
  const isReadOnly = useSelector(getIsReadOnly);
  const isSlideshow = useSelector(getIsSlideshow);
  const isTitleSlide = useSelector(getIsTitleSlide);
  const dispatch = useDispatch();
  const {addError, removeError} = useGlobalNotificationsError();
  const [toast, setToast] = useState(null);

  const articleId = !isNewArticle && id;

  // button permissions
  const canReleaseLock = !isSlideshow && isCurrentUserTheLastEditor(article);
  const canStealLock = !isSlideshow && isArticleLockedByAnotherUser(article) && !isArticleReadOnlyForWriter(article.status);
  const canTakeLock = !isSlideshow && !isArticleLockedByAnotherUser(article) && !isArticleReadOnlyForWriter(article.status);
  const canViewVersions = canViewVersionHistory(article.authorGatekeeperId);
  const showSaveSlide = isSlideshow && !isNewArticle && !isPublished;
  const showSave = !isPublished && !showSaveSlide;
  const showSaveMetadata = isSlideshow && !isNewArticle && !isPublished && !isReadOnly;
  const showSavePublish = isPublished && !isSlideshow;
  const showSaveSlidePublish = isPublished && isSlideshow;
  const useIdleTimer = !isNewArticle && !isSlideshow;

  // buttons enabled/disabled
  const isArticleChanged = isChanged || isChangedMetadata;
  const isSaveDisabled = !isArticleChanged;
  const isSaveSlideDisabled = !isChanged;
  const isSaveMetadataDisabled = !isChangedMetadata;

  // status button common props
  const common = {
    authorGatekeeperId: article.authorGatekeeperId,
    disabled: isArticleChanged,
    from: article.status,
    isBreakingNews: article.isBreakingNews,
    isNewArticle,
    onClick: onStatusChange,
    title: article.title,
  };

  const validateArticleLite = (isSlideshow) => {
    const errors = {};

    // at a mimimum we need a title so it can be clicked on the dashboard
    // full validation done when article status changes or resave a published article
    const title = isSlideshow ? article.elements[0]?.title : article.title;
    if (!isTitleValid(title)) errors[VALIDATE_FIELD_TITLE] = errorMessageFieldMinChars(isSlideshow ? 'Slideshow title' : 'Title');

    // also titles on slideshow non-title slides should also meet requirements
    const isSlideTitleInvalid = isSlideshow && !isTitleSlide && !isSlideTitleValid(article.elements[activeSlide]?.title);
    if (isSlideTitleInvalid) errors[VALIDATE_FIELD_TITLE] = errorMessageFieldMinChars('Slide title', SLIDE_TITLE_MINLENGTH);

    if (!Object.keys(errors).length) return null;
    return errors;
  };

  const handleSave = () => {
    removeError(VALIDATE_FIELD_TITLE, VALIDATE_FIELD_SOCIAL_TITLE, VALIDATE_FIELD_SOCIAL_DESCRIPTION);
    const error = validateArticleLite(isSlideshow);
    if (error) return addError(error);
    return onSave();
  };

  const handleSaveSlide = () => {
    removeError(VALIDATE_FIELD_TITLE, VALIDATE_FIELD_SOCIAL_TITLE, VALIDATE_FIELD_SOCIAL_DESCRIPTION);
    const error = validateArticleLite(isSlideshow);
    if (error) return addError(error);
    return onSaveSlide();
  };

  const handleSaveMetadata = async () => {
    await dispatch(saveMetadata());
  };

  const handleSaveWhenAlreadyPublished = async (type = 'save') => {
    const {renderStrategy: articleType, tagList, title} = article;
    const tags = tagList.split(',');

    // before confirm resaving a published article, check that the entire article is still valid
    const error = isSlideshow ? await validateSlideshow(article, tags) : await validateArticle(article, tags, wordcount);
    if (error) return addError(error);

    prompt(PROMPT_PUBLISH)
      .withTexts(promptPublishTitle(articleType), promptPublishDescription(title, true), PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => {
        if (type === 'slide') return onSaveSlide();
        if (type === 'metadata') return handleSaveMetadata();
        return onSave();
      });

    return false;
  };

  const handlePublish = async () => {
    const {renderStrategy: articleType, tagList, title} = article;
    const tags = tagList.split(',');

    // before confirming publish, check that the entire article is still valid
    const error = isSlideshow ? await validateSlideshow(article, tags) : await validateArticle(article, tags, wordcount);
    if (error) return addError(error);

    prompt(PROMPT_PUBLISH)
      .withTexts(promptPublishTitle(articleType), promptPublishDescription(title), PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => {
        return onStatusChange(STATUS_PUBLISHED);
      });

    return false;
  };

  const handleReleaseLock = () => {
    if (!isArticleChanged) return onReleaseLock();

    prompt(PROMPT_RELEASE_LOCK_UNSAVED)
      .withTexts(
        'Warning',
        `You're about to release the lock without saving your current changes. To save your changes
        choose [${PROMPT_BUTTON_CANCEL}] and then [${isPublished ? 'Publish' : 'Save'}].`,
        PROMPT_BUTTON_CONTINUE_UNSAVED,
        PROMPT_BUTTON_CANCEL
      )
      .withMute(false)
      .show(() => onReleaseLock());

    return false;
  };

  const handleStealLock = () => {
    return onStealLock();
  };

  const copyToClipboard = async () => {
    const elements = isSlideshow ? article.elements[activeSlide].elements : article.elements;
    const content = getArticleContentForClipboard(elements);

    try {
      await navigator.clipboard.writeText(content);
      setToast({message: 'Successfully copied to clipboard'});
      setIsCopiedToClipboard(true);
    } catch (error) {
      setToast({message: 'Copy to clipboard failed', error: true});
      logger.error('Copy to clipboard failed', error);
      reporter.inform(error);
    }
  };

  return (
    <div className="molecule article-header">
      <div className="article-header-left">
        {canViewVersions && <HistoryButton articleId={articleId} articleUrl={getArticleUrlVersions(article)} />}
        <PreviewButton articleId={articleId} articleUrl={getArticleUrlPreview(article)} />
        <FeedbackButton isNewArticle={isNewArticle} />
      </div>
      <div className="article-header-right">
        {!isReadOnly && (
          <>
            {useIdleTimer && <IdleTimer onIdleTimerExpires={onIdleTimerExpires} />}
            <ToastStatus key={`${toastStatus.status}-${toastStatus.error}`} toast={toastStatus} isArticleChanged={isArticleChanged} />
            {canReleaseLock && (
              <button className="article-action--lock" onClick={handleReleaseLock}>
                Release Lock
              </button>
            )}
            {showSaveSlide && (
              <button className="article-action--save-slide" disabled={isSaveSlideDisabled} onClick={handleSaveSlide}>
                Save Slide
              </button>
            )}
            {showSaveMetadata && (
              <button className="article-action--save-metadata" disabled={isSaveMetadataDisabled} onClick={handleSaveMetadata}>
                Save Metadata
              </button>
            )}
            {showSaveSlidePublish && (
              <>
                <button className="article-action--save-slide" disabled={isSaveSlideDisabled} onClick={() => handleSaveWhenAlreadyPublished('slide')}>
                  Publish Slide
                </button>
                <button
                  className="article-action--save-metadata"
                  disabled={isSaveMetadataDisabled}
                  onClick={() => handleSaveWhenAlreadyPublished('metadata')}>
                  Publish Metadata
                </button>
              </>
            )}
            {showSave && (
              <button className="article-action--save" disabled={isSaveDisabled} onClick={handleSave}>
                Save
              </button>
            )}
            {showSavePublish && (
              <button className="article-action--save" disabled={isSaveDisabled} onClick={handleSaveWhenAlreadyPublished}>
                Publish
              </button>
            )}
            <StatusButton to={STATUS_READY_FOR_REVIEW} className="article-action--review" {...common}>
              Submit for Review
            </StatusButton>
            <StatusButton
              to={STATUS_DRAFT}
              className="article-action--return"
              confirm="Are you sure this article should be returned to the writer for more edits?"
              {...common}>
              Return to Writer
            </StatusButton>
            <StatusButton to={STATUS_READY_FOR_PUBLISH} className="article-action--for-publish" {...common}>
              Ready for Publish
            </StatusButton>
            <ScheduleButton redirectToDashboard={redirectToDashboard} />
            <StatusButton to={STATUS_PUBLISHED} className="article-action--publish" {...common} onClick={handlePublish}>
              Publish
            </StatusButton>
          </>
        )}
        {isReadOnly && (
          <>
            <Toast toast={toast} />
            {canTakeLock && (
              <button className="article-action--lock" onClick={handleStealLock}>
                Edit
              </button>
            )}
            {canStealLock && (
              <button className="article-action--lock" onClick={handleStealLock}>
                Steal Lock
              </button>
            )}
            <button onClick={() => copyToClipboard()}>Copy to Clipboard</button>
          </>
        )}
      </div>
    </div>
  );
};

ArticleHeader.defaultProps = {
  onIdleTimerExpires: () => {},
};

ArticleHeader.propTypes = {
  isNewArticle: PropTypes.bool.isRequired,
  onReleaseLock: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveSlide: PropTypes.func.isRequired,
  onStealLock: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onIdleTimerExpires: PropTypes.func,
  toastStatus: PropTypes.object,
};

export default ArticleHeader;
