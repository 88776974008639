import reporter from '../../reporter';

import logger from '../../logger';

import {Articles, Proxies} from '../../endpoints';
import {FILTER_ARTICLES_EDITED_DEFAULT, FILTER_ARTICLES_SHOW_MINE_DEFAULT} from '../../constants/dashboard';
import {fetchJSON, fetchText, patchFetchOpts, putFetchOpts, postFetchOpts, deleteFetchOpts} from '../helpers/fetch';
import {getAuthTokens} from '../../helpers/authHelpers';
import {getShowMineUrlParam} from '../../helpers/dashboardHelpers';
import {SEARCH_TYPES} from '../../constants/search';
import {SEARCH_LIMIT_DEFAULT} from '../../constants/search';

export const getEditorStats = async ({startDate, endDate}) => {
  // we are passed a date (yyyy-mm-dd) but api also requires a time
  const startDateWithTime = `${startDate}T00:00:00Z`; // use 12:00am ET for start date
  const endDateWithTime = `${endDate}T23:59:59Z`; // use 23:59pm ET for end date
  const endpoint = Articles.getEditorStats(startDateWithTime, endDateWithTime);
  const {accessToken} = getAuthTokens();

  const response = await fetchJSON(endpoint, {jwt: accessToken}).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {editors: [], error};
  });

  return response;
};

export const getEditorStatsById = async ({id, startDate, endDate}) => {
  // we are passed a date (yyyy-mm-dd) but api also requires a time
  const startDateWithTime = `${startDate}T00:00:00Z`; // use 12:00am ET for start date
  const endDateWithTime = `${endDate}T23:59:59Z`; // use 23:59pm ET for end date
  const endpoint = Articles.getEditorStatsById(id, startDateWithTime, endDateWithTime);
  const {accessToken} = getAuthTokens();

  const response = await fetchJSON(endpoint, {jwt: accessToken}).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {individual: [], error};
  });

  return response;
};

export const getArticles = async ({cursor, limit, searchInput, searchType, startDate, endDate}) => {
  // Searching by editor or writer (author) requires use of a proxy endpoint that is capable of
  // accessing the Gatekeeper SearchUserQuery endpoint in order to exchange the editor or writer
  // full name, username, or email for a gatekeeper id that is used to filter the returned articles
  let endpoint = Articles.searchArticles({cursor, limit, searchInput, searchType, startDate, endDate});
  const isAuthorOrEditorSearch = [SEARCH_TYPES.EDITOR, SEARCH_TYPES.WRITER].includes(searchType);
  if (isAuthorOrEditorSearch) endpoint = Proxies.articlesAuthorEditorSearch({cursor, limit, searchInput, searchType, startDate, endDate});
  const {accessToken} = getAuthTokens();

  const articlesList = await fetchJSON(endpoint, {jwt: accessToken}).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {articles: [], error};
  });

  return articlesList;
};

export const getArticle = async (id) => {
  const endpoint = Articles.getArticle(id);
  const {accessToken} = getAuthTokens();

  const article = await fetchJSON(endpoint, {jwt: accessToken}).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {article: [], error};
  });

  return article;
};

export const getDashboardArticles = async (
  statusList,
  sorting,
  offset = 0,
  limit = SEARCH_LIMIT_DEFAULT / 2,
  showMine = FILTER_ARTICLES_SHOW_MINE_DEFAULT,
  showEdited = FILTER_ARTICLES_EDITED_DEFAULT,
  showUnedited = FILTER_ARTICLES_EDITED_DEFAULT
) => {
  // when the showMine param is in the url, we're going to let that superseded state
  // exception used where when editors are redirected back to the dashboard after publishing an article
  // we want them to see everyone's articles so they can quickly grab the publish link to share back to the BNT team
  let showMineException = showMine;
  const showMineUrl = getShowMineUrlParam();
  if (showMineUrl !== null) showMineException = showMineUrl;

  const endpoint = Articles.getDashboardArticles(offset, limit, showMineException, showEdited, showUnedited);
  const filterByStatus = () => {
    const filter = statusList && statusList.map((status) => `status[]=${status}`).join('&');
    return filter || null;
  };

  const sort = () => {
    return sorting ? `sort=${sorting}` : null;
  };

  const {accessToken} = getAuthTokens();

  return await fetchJSON([endpoint, filterByStatus(), sort()].filter((part) => part).join('&'), {
    jwt: accessToken,
  }).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error fetching dashboard articles.');
    return {articles: [], error};
  });
};

export const saveArticle = async (articleID, data) => {
  const handleError = (error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error saving article!');
    return {error: true};
  };
  return await fetchJSON(Articles.saveArticle(articleID), putFetchOpts({article: data})).catch((error) => {
    handleError(error);
  });
};

export const createArticle = async (data) => {
  const url = Articles.createArticle();
  return await fetchJSON(url, postFetchOpts({article: data})).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error creating article!');
    return {id: null};
  });
};

export const createSlideshow = async (data) => {
  const url = Articles.slideshowCreate();
  return await fetchJSON(url, postFetchOpts({slideshow: data})).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error creating slideshow!');
    return {id: null};
  });
};

export const deleteArticle = async (id) => {
  const endpoint = Articles.deleteArticle(id);
  const article = await fetchJSON(endpoint, deleteFetchOpts({id})).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error deleting article!');
    return {article: [], error};
  });
  return article;
};

export const scheduleArticle = async (id, date) => {
  const url = Articles.scheduleArticle(id);
  return await fetchJSON(url, postFetchOpts({scheduled_at: date})).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error scheduling article!');
    return {error: true};
  });
};

export const unscheduleArticle = async (id) => {
  const url = Articles.scheduleArticle(id);
  return await fetchJSON(url, deleteFetchOpts()).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error unscheduling article!');
    return {error: true};
  });
};

export const saveSlide = async (articleID, data) => {
  const handleError = (error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error saving slide!');
    return {error: true};
  };
  const slideId = data.id;
  if (!articleID) handleError('saveSlide() called without article ID');
  if (!slideId && slideId !== 0) handleError('saveSlide() called without slide ID');
  const body = {slide: data};
  return await fetchText(Articles.slidesEdit(articleID, slideId), putFetchOpts(body)).catch((error) => {
    handleError(error);
  });
};

export const saveMetadata = async (articleID, data) => {
  const handleError = (error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error saving metadata!');
    return {error: true};
  };
  if (!articleID) handleError('saveMetadata() called without article ID');
  const body = {article_metadata: data};
  return await fetchText(Articles.saveMetadata(articleID), patchFetchOpts(body)).catch((error) => {
    handleError(error);
  });
};

export const addSlides = async (articleID, data) => {
  const handleError = (error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error adding slides!');
    return {error: true};
  };
  if (!articleID) handleError('addSlides() called without article ID');
  if (!data) handleError('addSlides() called without data (count or slides)');
  if (typeof data !== 'number' && typeof data !== 'object') handleError('addSlides() called with data param that is not a number or object');
  if (typeof data === 'object' && !Array.isArray(data)) handleError('addSlides() called with data param that is not an array of slide objects');

  // the data param will either include a number/count (when adding a new slide to a non-published article)
  // or an array of slide objects (when saving a new slide to a published article)
  const payload = typeof data === 'object' ? {slides: data} : {count: data};

  return await fetchText(Articles.slidesAdd(articleID), postFetchOpts(payload)).catch((error) => {
    handleError(error);
  });
};

export const deleteSlide = async (articleID, slideID) => {
  const handleError = (error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error deleting slide!');
    return {error: true};
  };
  if (!articleID) handleError('deleteSlide() called without article ID');
  if (!slideID) handleError('deleteSlide() called without slide ID');
  return await fetchText(Articles.slidesEdit(articleID, slideID), deleteFetchOpts()).catch((error) => {
    handleError(error);
  });
};

export const reorderSlides = async (articleID, slides) => {
  const handleError = (error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error reordering slide!');
    return {error: true};
  };
  if (!articleID) handleError('reorderSlides() called without article ID');
  if (!slides) handleError('reorderSlides() called without slides');
  return await fetchText(Articles.slidesReorder(articleID), patchFetchOpts({slides})).catch((error) => {
    handleError(error);
  });
};

export const addArticleFeedback = (id, description, notify) => {
  const url = Articles.addFeedback(id);
  const body = {feedback: {description}};
  if (notify) body.feedback.notify = notify;
  return fetchJSON(url, postFetchOpts(body)).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error article feedbacks fetching!');
    return {error: true};
  });
};

export const getArticleVersions = async (id) => {
  const endpoint = Articles.getVersions(id);
  const {accessToken} = getAuthTokens();

  const articleVersions = await fetchJSON(endpoint, {jwt: accessToken}).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    return {articleVersions: [], error};
  });

  return articleVersions;
};

export const getArticleVersionById = async (articleId, versionId) => {
  const endpoint = Articles.getVersion(articleId, versionId);
  const {accessToken} = getAuthTokens();

  return await fetchJSON(endpoint, {jwt: accessToken}).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error fetching particular article version.');
    return {content: null, error};
  });
};

export const addArticleEditorPrimary = (articleId, userId) => {
  const url = Articles.saveEditorPrimary(articleId);
  const body = {user_id: userId};
  return fetchJSON(url, putFetchOpts(body)).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error adding primary editor.');
    return {error};
  });
};

export const deleteArticleEditorPrimary = (articleId) => {
  const url = Articles.saveEditorPrimary(articleId);
  return fetchJSON(url, deleteFetchOpts()).catch((error) => {
    reporter.inform(error);
    logger.error(error);
    global.alert('Uh oh, error deleting primary editor.');
    return {error};
  });
};
