import React from 'react';
import classnames from 'classnames';

import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONFIRM, PROMPT_SLIDE_DELETE_CONFIRM} from '../../../constants/prompt';
import {prompt} from '../../../helpers/promptHelper';

export const STYLE_FRAME_WIDTH = 12; // rem units

/**
 * Renders the filmstrip frame
 * @description contains filmstrip frame related view logic
 * @example
 *  <FilmstripFrame
 *    frame={} // frame object (id, slideNumber, thumbnail, title)
 *    isActive={true} // frame activity flag
 *    isDeleted={false} // is this a deleted slide?
 *    isError={false} // frame validation correctness flag
 *    onDeleted={(indexToDelete) => { }} // callback function for frame delete event
 *    onSelected={(indexToSelect) => { }} // callback function for frame select event
 *    withDelete={false} // frame delete ability flag
 *  />
 * @param {object} props list of component parameters
 */

function FilmstripFrame(props, ref) {
  const {frame, isActive, isDeleted, isError, onDeleted, onSelected, withDelete} = props;
  const {id, slideNumber: index, thumbnail, title} = frame;
  const isChanged = frame?.changed ? frame.changed : false; // version history will include 'changed' but not the article edit page
  const frameLabel = index || 'Title';
  const classes = classnames('filmstrip-frame', {
    'is-active': isActive && !isDeleted,
    'is-changed': isChanged,
    'is-deleted': isDeleted,
    'has-error': isError,
  });
  const canDelete = !isDeleted && withDelete && index > 0; // do not render a delete button for title frame or deleted frame

  const onFrameSelected = () => {
    if (isActive || isDeleted) return;
    onSelected(index);
  };

  const onFrameDeleted = (event, index) => {
    event.stopPropagation();
    if (!onDeleted) return;

    const promptTitle = title?.length > 0 ? `Are you sure you want to delete "${title}"?` : `Are you sure you want to delete slide ${index}?`;
    const promptText = `Deleting slide ${index} is immediate and irrevocable.`;

    prompt(PROMPT_SLIDE_DELETE_CONFIRM)
      .withTexts(promptTitle, promptText, PROMPT_BUTTON_CONFIRM, PROMPT_BUTTON_CANCEL)
      .withMute(false)
      .show(() => {
        onDeleted(id);
      });
  };

  const getFrameStyles = () => {
    const styles = {width: `${STYLE_FRAME_WIDTH}rem`};
    if (!thumbnail) return styles;

    return {...styles, backgroundImage: `url(${thumbnail})`, backgroundSize: 'cover'};
  };

  return (
    <div ref={ref} className={classes} style={getFrameStyles()} onClick={onFrameSelected}>
      <span className="num">{frameLabel + (isError ? '*' : '')}</span>
      <span className="label">{title}</span>
      {canDelete && (
        <button className="button--delete" title="Delete slide" onClick={(event) => onFrameDeleted(event, index)}>
          <span className="sr-only">Delete</span>
        </button>
      )}
    </div>
  );
}

export default React.forwardRef(FilmstripFrame);
