/**
 * Hashes an encoded string value using the provided algorithm
 * This was initially extracted to allow us to stub it in unit tests because window.crypto is not defined in jsdom
 * @param {string} algorithm the hashing algorithm to use (i.e. SHA-256)
 * @param {string} originalString the string to hash
 * @returns {Promise<ArrayBuffer>} ArrayBuffer representing the hashed string
 */
export async function hash(algorithm, originalString) {
  return window.crypto.subtle.digest(algorithm, new TextEncoder().encode(originalString));
}
