import React from 'react';

const PageError = ({children, what}) => {
  const title = what ? `Uh oh, ${what}` : 'Uh oh, something happened';

  return (
    <div className="atom page-error">
      <div className="content">
        <h2>{title}</h2>
        {children}
      </div>
    </div>
  );
};

export default PageError;
