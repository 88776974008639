import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {selectAction, selectArticles, selectError, selectHasMore, selectLoading, selectOffset} from '../../selectors/dashboard';
import {clearDashboard, fetchDashboard} from '../../actions/dashboard';
import {FILTER_ARTICLES_SHOW_MINE_DEFAULT} from '../../constants/dashboard';
import {getDefaultFilter, getFiltersList} from '../../helpers/dashboardHelpers';
import {isAnyEditor} from '../../helpers/articleHelpers';

import Loading from '../atoms/loading';
import ArticleRow from '../atoms/dashboard/articleRow';
import DashboardFiltersEdited from '../atoms/dashboard/dashboardFiltersEdited';
import DashboardFiltersMine from '../atoms/dashboard/dashboardFiltersMine';
import DashboardFiltersSort from '../atoms/dashboard/dashboardFiltersSort';
import DashboardFiltersStatus from '../atoms/dashboard/dashboardFiltersStatus';
import Pagination from '../atoms/pagination';
import Toast from '../atoms/toast';

const DashboardArticles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [toast, setToast] = useState(null);

  const action = useSelector(selectAction);
  const articles = useSelector(selectArticles);
  const error = useSelector(selectError);
  const hasMore = useSelector(selectHasMore);
  const isLoading = useSelector(selectLoading);
  const offset = useSelector(selectOffset);

  useEffect(() => {
    const filters = getFiltersList();
    let filterDefault = getDefaultFilter();

    if (location.hash) {
      const hash = location.hash.substring(1); // strip #
      if (hash in filters) filterDefault = hash; // ensure valid hash
    }

    // always fetch new dashboard articles to catch updates from other users
    dispatch(
      fetchDashboard({
        filter: filterDefault,
        showMine: isAnyEditor() ? false : FILTER_ARTICLES_SHOW_MINE_DEFAULT,
      })
    );
    return () => dispatch(clearDashboard());
  }, []);

  useEffect(() => {
    const toast = action?.status ? {message: action.status} : null;
    setToast(toast);
  }, [action]);

  const getArticlesList = () => {
    if (isLoading || !articles) {
      return (
        <div className="group group-empty">
          <Loading />
        </div>
      );
    }

    if (!articles.length) return <div className="group group-empty">You have no articles</div>;

    return articles.map((article) => {
      return <ArticleRow key={article.id} article={article} />;
    });
  };

  const getPagination = () => {
    if ((!articles || !articles.length) && !offset) return null;
    return <Pagination hasMore={hasMore} isLoading={isLoading} offset={offset} onPageChange={({offset}) => dispatch(fetchDashboard({offset}))} />;
  };

  if (error) return <div>An unexpected error occurred</div>;

  return (
    <div className="molecule dashboard-articles">
      <div className="dashboard-articles-header">
        <h2>Articles</h2>
        <div className="right">
          <Toast toast={toast} />
          <div className="dashboard-filters">
            <DashboardFiltersMine />
            <DashboardFiltersSort />
          </div>
        </div>
      </div>
      <div className="dashboard-articles-container">
        <div className="articles-header">
          <DashboardFiltersStatus />
          {getPagination()}
        </div>
        <DashboardFiltersEdited />
        <div className="articles articles--active">
          {getArticlesList()}
          {getPagination()}
        </div>
      </div>
    </div>
  );
};

export default DashboardArticles;
