import {truncateText, decodeString, removeHtmlTags, removeNonBreakingSpaces, replaceNonBreakingSpacesAndTrim} from './textHelpers';

import {ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH} from '../constants/article';

/**
 * Get the block content (only from specified text-based types)
 *
 * @param {Object} block Editor.js block
 * @param {String} after override default block delimiter with custom string
 * @returns {String} string output of all Editor.js text-based elements
 */
function blockContent(block, after) {
  const delimiter = after ? after : '\n\n';
  let x = '';
  if (block.type === 'paragraph') {
    // catch for new article being saved with no body content
    if (block.data.text) x = block.data.text;
  } else if (block.type === 'ul' || block.type === 'ol') {
    x = `${block.data.items.join(' ')}.`;
  } else if (block.type === 'header' || block.type === 'subheader' || block.type === 'quote_pull' || block.type === 'quote_indent') {
    x = `${block.data.text}.`;
  } else {
    return x;
  }

  x = x.replace('<br>', ''); // last block of editor.js content will have this
  x += delimiter; // space block elements from each other (inline, line breaks, etc...)
  return x;
}

/**
 * Generate default meta description from article content
 */
export function getArticleDescription(data) {
  if (typeof data !== 'object') return '';

  const reducer = (accumulator, block) => {
    if (block === null) return accumulator;
    return accumulator + blockContent(block, ' ');
  };

  const isSlideshow = data[0] && data[0].type === 'slide';
  const content = isSlideshow ? data[0].elements.reduce(reducer, '') : data.reduce(reducer, '');
  const contentTrimmed = truncateText(replaceNonBreakingSpacesAndTrim(removeHtmlTags(content)), ARTICLE_SOCIAL_DESCRIPTION_MAXLENGTH);

  return contentTrimmed;
}

/**
 * Generate content for 'Copy to Clipboard' from article content
 */
export function getArticleContentForClipboard(data) {
  if (typeof data !== 'object') return '';

  const reducer = (accumulator, block) => {
    return accumulator + blockContent(block);
  };

  const content = data.reduce(reducer, '');
  return removeNonBreakingSpaces(removeHtmlTags(decodeString(content.trim())));
}
