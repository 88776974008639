// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ce-pull-quote {
  padding: 0 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/utils/editorjs/tools/pull-quote/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".ce-pull-quote {\n  padding: 0 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
