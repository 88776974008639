import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {PROMPT_BUTTON_CANCEL, PROMPT_BUTTON_CONTINUE_UNSAVED, PROMPT_SLIDE_CHANGE_UNSAVED} from '../../constants/prompt';
import {prompt} from '../../helpers/promptHelper';
import {getActiveSlide, getArticleData, getIsChanged, getIsNew, getIsPublished} from '../../selectors/article';

import Editor from '../atoms/editor';
import EditorField from '../atoms/editorField';
import FeaturedMedia from '../atoms/featuredMedia';
import FeaturedMediaForm from './featuredMediaForm';
import Filmstrip from './filmstrip';

import {ARTICLE_TITLE_MAXLENGTH, ARTICLE_TITLE_MINLENGTH, VALIDATE_FIELD_TITLE, VALIDATE_FIELDS} from '../../constants/article';
import {VALIDATE_FIELD_MAIN, VALIDATE_FIELD_MEDIA} from '../../constants/slideshow';
import {EVENT_FEATURED_MEDIA_UPLOAD, EVENT_IMAGE_UPLOAD} from '../../constants/events';
import {
  filterEditorBlocks,
  filterEditorBlocksSlide,
  getArticleThumbnail,
  getFeaturedMediaBlockSlide,
  getSlideThumbnail,
  isFeaturedMedia,
  shouldUpdateThumbnail,
} from '../../helpers/articleHelpers';
import {
  addSlides,
  fetchArticle,
  removeSlide,
  reorderSlides,
  setActiveSlide,
  setActiveSlideContent,
  setActiveSlideMedia,
  updateArticle,
} from '../../actions/article';
import MediaUploader from '../molecules/mediaUploader';
import ToolEventObserver from '../../utils/editorjs/tools/toolEventObserver';
import {getInvalidSlidesIndexList, errorMessage} from '../../utils/slideshowValidate';
import useGlobalNotificationsError from '../../utils/notifications/useGlobalNotificationsError';
import GlobalNotifications from '../../utils/notifications/globalNotificationsComponent';

const ArticleSlideshow = ({onBodyChanged}) => {
  const activeSlideIndex = useSelector(getActiveSlide);
  const article = useSelector(getArticleData);
  const isChanged = useSelector(getIsChanged);
  const isNew = useSelector(getIsNew);
  const isPublished = useSelector(getIsPublished);
  if (activeSlideIndex < 0 || activeSlideIndex >= article.elements.length)
    throw new Error('Invalid active slide index value, 0 <= activeSlideIndex < slides.length');
  const dispatch = useDispatch();
  const activeSlide = filterEditorBlocksSlide(article.elements[activeSlideIndex]);
  const [featuredData, setFeaturedData] = useState(getFeaturedMediaBlockSlide(activeSlide));
  const [canUpdateFeaturedData, setCanUpdateFeaturedData] = useState(true);
  const [isEditorUndo, setIsEditorUndo] = useState(true);
  const isTitleSlideActive = activeSlideIndex === 0;
  const {hasError, removeError} = useGlobalNotificationsError();

  useEffect(() => {
    if (canUpdateFeaturedData) setFeaturedData(getFeaturedMediaBlockSlide(activeSlide));
  }, [activeSlideIndex, article]);

  const showImageCropper = (image, event) => ToolEventObserver.trigger(event, image);

  const clearErrors = () => {
    removeError(VALIDATE_FIELD_MAIN, ...VALIDATE_FIELDS);
  };

  const getFilmstripFrames = () => {
    return article.elements.reduce((acc, slide) => {
      return [...acc, {id: slide.id, thumbnail: getSlideThumbnail(slide), title: slide.title}];
    }, []);
  };

  const onFeaturedMediaRemoved = () => {
    clearErrors();
    dispatch(setActiveSlideMedia());
  };

  const onFrameCreated = async (numFramesToAdd) => {
    await dispatch(addSlides(numFramesToAdd));
  };

  const onFrameDeleted = async (slideId) => {
    await dispatch(removeSlide(slideId));
  };

  const onFrameMove = async (index, newIndex) => {
    // when reordering slides we only want to execute setFeaturedData once
    const activeSlideIndexChanged = activeSlideIndex !== newIndex;
    if (activeSlideIndexChanged) setCanUpdateFeaturedData(false);
    await dispatch(reorderSlides(index, newIndex));
    if (activeSlideIndexChanged) setCanUpdateFeaturedData(true);
  };

  const onFrameSelectCallback = async (index, shouldFetch = true) => {
    clearErrors();
    if (isNew || !shouldFetch) await dispatch(setActiveSlide(index)); // shouldFetch is only false when reordering slides, no fresh data required
    else if (shouldFetch) await dispatch(fetchArticle({id: article.id, isSlideshow: true, activeSlide: index})); // load fresh data when switching slides
  };

  const onFrameSelect = (index, shouldFetch = true) => {
    if (isNew || !isChanged) return onFrameSelectCallback(index, shouldFetch);

    prompt(PROMPT_SLIDE_CHANGE_UNSAVED)
      .withTexts(
        'Warning',
        `You're about to change slides without saving your current changes. To save your changes
        choose [${PROMPT_BUTTON_CANCEL}] and then [${isPublished ? 'Publish' : 'Save Slide'}].`,
        PROMPT_BUTTON_CONTINUE_UNSAVED,
        PROMPT_BUTTON_CANCEL
      )
      .withMute(false)
      .show(() => onFrameSelectCallback(index));

    return null;
  };

  const onMediaAdded = async (content, event, blockId, shouldReplaceFeaturedMedia, shouldReplaceArticleThumbnail) => {
    clearErrors();

    const {data, type} = content;

    // Only update the featured media if the onMediaAdded event was triggered by the featured media
    if (isFeaturedMedia(blockId, event, shouldReplaceFeaturedMedia)) {
      await dispatch(setActiveSlideMedia(content));
    }

    if (!shouldUpdateThumbnail(blockId, event, type, article, shouldReplaceArticleThumbnail, activeSlideIndex)) return;

    const thumbnail = getArticleThumbnail(data, type);

    dispatch(updateArticle({image: thumbnail}));
  };

  const onMediaModalOpen = (isOpen = true) => {
    setIsEditorUndo(!isOpen);
  };

  const onSlideTitleChanged = async ({target}) => {
    if (!target) return null;

    clearErrors();

    // if this is the title slide, changing the slide title should also update the article title
    const isTitleSlide = activeSlideIndex === 0;

    // update the social title only if this is the title slide and the current social title is `in sync` (matches) the first slide title
    const shouldUpdateSocialTitle = isTitleSlide && (!article.socialTitle || article.socialTitle === article.elements[0].title);

    if (isTitleSlide && shouldUpdateSocialTitle)
      return await dispatch(setActiveSlideContent({title: target.value}, {title: target.value, socialTitle: target.value}));
    else if (isTitleSlide && !shouldUpdateSocialTitle) return await dispatch(setActiveSlideContent({title: target.value}, {title: target.value}));

    return await dispatch(setActiveSlideContent({title: target.value}));
  };

  const onSlideBodyChanged = (data, words) => {
    clearErrors();
    onBodyChanged({blocks: filterEditorBlocks(data.blocks)}, words);
  };

  let framesWithError = [];
  let currentSlideErrors = null;
  if (hasError(VALIDATE_FIELD_MAIN)) {
    framesWithError = getInvalidSlidesIndexList({elements: article.elements});
    currentSlideErrors = errorMessage({...activeSlide, elements: activeSlide.elements});
  }
  if (isNew && hasError(VALIDATE_FIELD_TITLE)) framesWithError = [0];

  return (
    <>
      <Filmstrip
        activeFrame={activeSlideIndex}
        frames={getFilmstripFrames()}
        framesWithError={framesWithError}
        isNewArticle={isNew}
        onFrameCreate={onFrameCreated}
        onFrameDelete={onFrameDeleted}
        onFrameMove={onFrameMove}
        onFrameSelect={onFrameSelect}
      />
      <GlobalNotifications />
      <EditorField
        type="text"
        name="title"
        placeholder={isTitleSlideActive ? 'Enter Slideshow Title' : 'Enter Slide Title'}
        minLength={ARTICLE_TITLE_MINLENGTH}
        maxLength={ARTICLE_TITLE_MAXLENGTH}
        value={activeSlide.title}
        doCapitalize={true}
        doNormalize={false}
        doNormalizeBlur={true}
        onChange={onSlideTitleChanged}>
        Title
      </EditorField>
      <MediaUploader onMediaAccept={(image) => showImageCropper(image, EVENT_FEATURED_MEDIA_UPLOAD)}>
        {featuredData ? (
          <FeaturedMedia media={featuredData} onMediaRemove={onFeaturedMediaRemoved} />
        ) : (
          <FeaturedMediaForm withError={currentSlideErrors && currentSlideErrors[VALIDATE_FIELD_MEDIA]} />
        )}
      </MediaUploader>
      <MediaUploader onMediaAccept={(image) => showImageCropper(image, EVENT_IMAGE_UPLOAD)}>
        <Editor
          key={`slideBody${activeSlide.id}`}
          data={{blocks: activeSlide.elements}}
          featuredMedia={featuredData}
          name="body"
          placeholder="Add content…"
          onMediaAdd={onMediaAdded}
          onMediaModalOpen={onMediaModalOpen}
          onData={onSlideBodyChanged}
          onReady={onSlideBodyChanged}
          useUndo={isEditorUndo}
          autofocus={false}
          activeSlideIndex={activeSlideIndex}
        />
      </MediaUploader>
    </>
  );
};

ArticleSlideshow.defaultProps = {
  onBodyChanged: () => {},
};

ArticleSlideshow.propType = {
  onBodyChanged: PropTypes.func.isRequired,
};

export default ArticleSlideshow;
