import {ARTICLES_RECENT, ARTICLES_RECENT_MAX} from '../constants/article';
import {CTT_USER_LEGACY_ID} from '../constants/user';

export function getAllRecentArticles() {
  const src = localStorage.getItem(ARTICLES_RECENT);
  return src ? new Map(JSON.parse(src)) : new Map();
}

export function getUserRecentArticles(userId) {
  const map = getAllRecentArticles();
  const recentArticles = map.get(userId);
  return recentArticles ? new Map(recentArticles) : new Map();
}

export function addRecentArticle(id, title, type, lastEdited) {
  const userId = parseInt(localStorage.getItem(CTT_USER_LEGACY_ID), 10);
  const map = getAllRecentArticles();
  const userMap = getUserRecentArticles(userId);

  if (!userMap.has(id) && userMap.size >= ARTICLES_RECENT_MAX) {
    // Remove the oldest article from the list
    const first = userMap.keys().next().value;
    userMap.delete(first);
  }

  userMap.set(id, {title, type, lastEdited});
  map.set(userId, Array.from(userMap.entries()));

  // If an article title has been edited, update that article
  // title for all other users on the SAME machine who have
  // also previously edited the same article
  map.forEach((recentArticles, uId, map) => {
    const uMap = new Map(recentArticles);

    if (uId === userId || !uMap.has(id)) return;

    const articleEntry = uMap.get(id);

    if (articleEntry.title !== title) {
      uMap.set(id, {...articleEntry, title});
      map.set(uId, Array.from(uMap.entries()));
    }
  });

  localStorage.setItem(ARTICLES_RECENT, JSON.stringify(Array.from(map)));
}

export function removeRecentArticle(id) {
  const map = getAllRecentArticles();

  map.forEach((value, key) => {
    const userMap = new Map(value);
    if (!userMap.has(id)) return;
    userMap.delete(id);
    map.set(key, Array.from(userMap.entries()));
  });

  localStorage.setItem(ARTICLES_RECENT, JSON.stringify(Array.from(map)));
}
