import {isAfter} from 'date-fns';
import {isPermitted} from './authHelpers';
import {scopes} from '../constants/';
import {getStorageData} from './authHelpers';
import {CTT_USER_TITLE, CTT_USER_TWITTER, CTT_USER_IMAGE, CTT_USER_NAME, CTT_USER_LEGACY_ID, USER_IMAGE_DEFAULT} from '../constants/user';
import {FEATURED_MEDIA_CONTENT_TYPES, STATUS_IN_REVIEW, TYPE_SLIDESHOW, TYPE_STANDARD} from '../constants/article';
import {SLIDES_COUNT_DEFAULT} from '../constants/slideshow';
import {isCurrentUserTheAuthor} from './softlockHelpers';
import {
  EVENT_FEATURED_MEDIA_IFRAME,
  EVENT_FEATURED_MEDIA_SEARCH,
  EVENT_FEATURED_MEDIA_UPLOAD,
  EVENT_IMAGE_UPLOAD,
  EVENT_MEDIA_EDIT,
  EVENT_MEDIA_PICKER,
  EVENT_MEDIA_SEARCH,
} from '../constants/events';
import {getYouTubeId} from '../utils/editorjs/tools/embed';
import {getYouTubeThumbnail} from '../utils/editorjs/tools/iframe/index';

// role-focused checks
export const isAdmin = () => isPermitted(scopes.VIEW_ADMIN_MENU);
export const isEditor = () => isPermitted(scopes.REVIEW_AND_PUBLISH);
export const isQCEditor = () => isPermitted(scopes.MANAGE_EDITORS);
export const isBNTWriter = () => isPermitted(scopes.PUBLISH_TEAM_CONTENT);
export const isStandardWriter = () => !isBNTWriter() && !isEditor() && !isQCEditor() && !isAdmin();
export const isAnyEditor = () => isEditor() || isQCEditor() || isAdmin();
export const isAnyWriter = () => isBNTWriter() || isStandardWriter();

// permission-focused checks
export const isCreateAllowed = () => isPermitted(scopes.CREATE_ARTICLE);
export const isEditAllowed = () => isPermitted(scopes.EDIT_THIS_ARTICLE); // note: "this" in the scope name is somewhat misleading, the permission is not always used in context with a specific article
export const isMoveToPublishAllowed = () => isPermitted(scopes.REVIEW_AND_PUBLISH);
export const isMoveToReviewAllowed = () => isPermitted(scopes.REVIEW_AND_PUBLISH);

// article data checks
export const isSlideshow = (article) => article.renderStrategy === TYPE_SLIDESHOW;
export const isStandard = (article) => article.renderStrategy === TYPE_STANDARD;

/**
 * Returns article type
 *
 * @param {object} article article object
 * @returns {string} slideshow or article (standard)
 */
export const getArticleType = (article) => {
  if (!article || typeof article !== 'object') return TYPE_STANDARD;
  if (isSlideshow(article)) return TYPE_SLIDESHOW;
  return TYPE_STANDARD;
};

/**
 * View version history feature is available to...
 * - Admins
 * - Editors (any)
 * - Writers (only their own stories)
 * - BNT Writers (only on breaking news articles)
 *
 * @param {string} authorGatekeeperId gatekeeper id of the article author
 * @param {bool} isBreakingNews is this article breaking news?
 * @returns {bool}
 */
export const canViewVersionHistory = (authorGatekeeperId, isBreakingNews = false) => {
  return isAnyEditor() || (isBNTWriter() && isBreakingNews) || isCurrentUserTheAuthor({authorGatekeeperId});
};

/**
 * Delete article feature is available to...
 * - Admins
 * - Editors (any)
 * - BNT Writers on breaking news stories
 *
 * @param {bool} isBreakingNews is this article breaking news?
 * @returns {bool}
 */
export const canDelete = (isBreakingNews = false) => {
  return isAnyEditor() || (isBNTWriter() && isBreakingNews);
};

/**
 * Publish feature is available to...
 * - Editors (any)
 * - Writers (only their own stories)
 * - BNT Writers (own stories) & can publish articles from the editor page as well
 *
 * @param {string} authorGatekeeperId gatekeeper id of the article author
 * @returns {bool}
 */
export const canPublish = (authorGatekeeperId) => {
  return isEditor() || isCurrentUserTheAuthor({authorGatekeeperId});
};

/**
 * Schedule Breaking News Articles feature is available to...
 * - Editors (any bnt article)
 * - BNT Writers (own articles) of any status that are breaking news
 * @returns {bool}
 */
export const canScheduleBreakingNews = (authorGatekeeperId, isBreakingNews = false) => {
  if (!isBreakingNews) return false;
  if (isEditor()) return true;
  return isBNTWriter() && isCurrentUserTheAuthor({authorGatekeeperId});
};

/**
 * Returns the article author as the currently logged in user
 *
 * @returns {object} user object
 */
export const getArticleAuthor = () => {
  return {
    id: getStorageData(CTT_USER_LEGACY_ID),
    name: getStorageData(CTT_USER_NAME),
    photoUrl: getStorageData(CTT_USER_IMAGE, USER_IMAGE_DEFAULT),
    title: getStorageData(CTT_USER_TITLE, ''),
    twitterHandle: getStorageData(CTT_USER_TWITTER, ''),
  };
};

export const isArticleThumbnailValid = (article) => {
  // Abstract the thumbnail validation into the function for make it easier to grow in a future
  return !!article.image;
};

/**
 * Default new standard articles and new or existing slideshows to editable
 * and existing standard articles to readonly
 *
 * @param {bool} isNew is this a new article?
 * @param {bool} isSlideshow is this a slideshow?
 * @returns {bool}
 */
export const isArticleReadOnlyDefault = (isNew, isSlideshow) => {
  if (isSlideshow) return false;
  if (isNew) return false;
  return true;
};

/**
 * Check if article should be shown in read-only mode. This occurs when the
 * current user is a Writer (n.b. not BNT Writer) and the article is in the
 * Review process.
 *
 * note: we may want to change the name of this after CTT-682.
 * Articles are now readonly by default but we use this to restrict actions
 * like stealing the lock when in review.
 *
 * @param {string} status article status
 * @returns {bool}
 */
export const isArticleReadOnlyForWriter = (status) => {
  return !isEditor() && !isBNTWriter() && status === STATUS_IN_REVIEW;
};

/**
 * Creates slideshow article block
 * @returns {object}
 */
export const createArticleSlideBlock = (id) => {
  return {
    id,
    type: 'slide',
    elements: [],
    title: '',
  };
};

/**
 * Creates a list of slideshow blocks
 * @param {number} slidesNumber slideshow blocks number
 * @returns {array}
 */
export const createNewArticleSlides = (slidesNumber = SLIDES_COUNT_DEFAULT, slideIdStartsAt = 0) => {
  const size = typeof slidesNumber === 'number' ? slidesNumber : 0;
  return new Array(size).fill(null).map((_, index) => createArticleSlideBlock(slideIdStartsAt + index));
};

/**
 * Is the first block a featured media type?
 * @param {array} blocks array of article blocks
 * @returns {bool}
 */
export const isFirstBlockFeaturedMediaType = (blocks) => {
  if (!blocks || !blocks.length) return false;
  const firstBlockType = blocks[0].type;
  return FEATURED_MEDIA_CONTENT_TYPES.includes(firstBlockType);
};

/**
 * Adds media block into the slide content, or removes it
 * @param {object} slide object that represents slide content
 * @param {object} block featured media block
 * @returns {object}
 */
export const addMediaBlockSlide = (slide, block) => {
  if (!slide.elements || !slide.elements.length) return {...slide, elements: [block]};

  const firstBlockType = slide?.elements[0]?.type;
  const isFirstBlockFeaturedMediaType = FEATURED_MEDIA_CONTENT_TYPES.includes(firstBlockType);

  // add media block, strip first block featured media placeholder
  if (isFirstBlockFeaturedMediaType) return {...slide, elements: [block, ...slide.elements.slice(1)]};

  // add media block, there's no featured media already
  return {...slide, elements: [block, ...slide.elements]};
};

/**
 * Adds featured media (fm) block into the standard article content
 * @param {object} elements object that represents article content
 * @param {object} block featured media block
 * @returns {array[object]} array of content blocks
 */
export const addMediaBlock = (elements, block) => {
  if (!elements.length) return [block];

  const firstBlock = elements[0];
  const firstBlockType = firstBlock.type || firstBlock.contentType;
  const isFirstBlockFeaturedMediaType = FEATURED_MEDIA_CONTENT_TYPES.includes(firstBlockType);

  // add media block, strip first block featured media
  if (isFirstBlockFeaturedMediaType) return [block, ...elements.slice(1)];

  // add media block, there's no featured media already
  return [block, ...elements];
};

/**
 * Retrieve the featured media block for an Article.
 *
 * @param {object} object that represents the article content
 * @returns {object|null} featured media block
 */
export const getFeaturedMediaBlock = (article) => {
  if (!Array.isArray(article.elements) || !article.elements.length || article.elements[0] === null) return null;
  const [firstElement] = article.elements;
  return FEATURED_MEDIA_CONTENT_TYPES.includes(firstElement.type) ? firstElement : null;
};

/**
 * Retrieve the featured media block for an individual slide within a slideshow
 * @param {object} slide object that represents the slideshow slide content
 * @returns {object|null} featured media block
 */
export const getFeaturedMediaBlockSlide = (slide) => {
  if (!Array.isArray(slide.elements) || !slide.elements.length || slide.elements[0] === null) return null;
  const [firstElement] = slide.elements;
  const type = firstElement.contentType || firstElement.type;
  if (type) return FEATURED_MEDIA_CONTENT_TYPES.includes(type) ? firstElement : null;
  return null;
};

/**
 * Format URL of slide thumbnail with "image" media type
 * @param {string} urlString original URL of image representing the slide
 * @returns {string/null} correct URL of image representing the slide
 */
const getImageMediaTypeThumbnailUrl = (urlString) => {
  if (!urlString) return null;

  const url = new URL(urlString);

  // Should resize if served from cloudinary prod or dev account
  const shouldResize = url.hostname === 'media.bleacherreport.com' || url.hostname === 'res.cloudinary.com';

  if (!shouldResize) return url.href;

  const [first, second] = url.href.split('/v');

  return `${first}/w_120/v${second}`;
};

/**
 * Retrieve slide thumbnail image
 * @param {object} slide object that represent the slideshow slide content
 * @returns {string|null} URL of image representing the slide
 */
export const getSlideThumbnail = (slide) => {
  const media = getFeaturedMediaBlockSlide(slide);

  // skip if featured media missing or block data corrupted
  if (!media) return null;

  // check media type specific data for thumbnails
  if (media.type === 'image' && media.data?.file) return getImageMediaTypeThumbnailUrl(media.data.file.url);
  if (media.type === 'video' && media.data?.metadata) return media.data.metadata.thumbnail || null;
  // additional check - version history slide data structure is different than live slide data structure
  if (media.contentType === 'image' && media.content) return getImageMediaTypeThumbnailUrl(media.content.url);
  if (media.contentType === 'video' && media.content?.metadata) return media.content.metadata.thumbnail || null;

  // sanity check for embed, thumbnail is not native to that plugin and it gets dropped
  if (media.type === 'embed' && !media.data.thumbnail) return getYouTubeThumbnail(getYouTubeId(media.data.source));

  // generic check for thumbnail
  if (media.data?.thumbnail) return media.data.thumbnail || null;
  if (media.content?.thumbnail) return media.content.thumbnail || null;

  // no thumbnail found
  return null;
};

/**
 * Filter the 'mediaBlock' which is only used to trigger the add media modal
 * If left here it can trigger unintended side effects ex./ add media modal re-triggers when undo or changing slideshow slides
 * @param {array} blocks editor.js blocks
 * @returns {array}
 */
export const filterEditorBlocks = (blocks) => {
  if (!Array.isArray(blocks) || !blocks.length) return blocks;
  return blocks.filter((block) => block?.type !== 'mediaBlock');
};

/**
 * Filter the 'mediaBlock' which is only used to trigger the add media modal
 * If left here it can trigger unintended side effects ex./ the add media modal to re-trigger when undo or changing slideshow slides
 * @param {array} slide editor.js blocks
 * @returns {array}
 */
export const filterEditorBlocksSlide = (slide) => {
  if (!slide.elements || !slide.elements.length) return slide;
  return {...slide, elements: filterEditorBlocks(slide.elements)};
};

/**
 * Remove all "ad" blocks from the article content
 * @param {object} article object that represents entire article JSON blob
 * @returns {object}
 */
export const stripAdBlocks = (article) => {
  return {
    ...article,
    elements: article.elements.filter((block) => (block.type || block.contentType) !== 'ad'),
  };
};

/**
 * Returns article thumbnail
 * @param {object} data object that represents featured media block content
 * @param {string} type type of featured media block
 * @returns {string}
 */
export const getArticleThumbnail = (data, type) => {
  if (type === 'video') return data?.metadata?.thumbnail;
  if (type === 'embed') return data?.thumbnail;
  return data?.file?.url;
};

/**
 * Returns article edit page url
 * @param {object} article object that represents entire article JSON blob
 * @returns {string}
 */
export const getArticleUrl = (article) => {
  const id = article.articleId || article.breportId || article.id;

  const group = isSlideshow(article) ? 'slideshow' : 'standard';
  return `/articles/${group}/${id}`;
};

/**
 * Returns absolute article edit page url
 * @param {object} article object that represents entire article JSON blob
 * @returns {string}
 */
export const getArticleUrlAbsolute = (article) => {
  const articleUrl = getArticleUrl(article);
  return `https://${process.env.CONTENT_TOOLS_HOST || 'contenttools.bleacherreport.com'}${articleUrl}`;
};

/**
 * Returns article versions page url
 * @param {object} article object that represents entire article JSON blob
 * @returns {string}
 */
export const getArticleUrlVersions = (article) => {
  const articleUrl = getArticleUrl(article);
  return `${articleUrl}/versions`;
};

/**
 * Returns article preview url
 * @param {object} article object that represents entire article JSON blob
 * @returns {string}
 */
export const getArticleUrlPreview = (article) => {
  const articleUrl = getArticleUrl(article);
  return `${articleUrl}/preview`;
};

/**
 * Opens an article preview in a new window
 * @param {id} article id to open
 * @returns {null}
 */
export const openPreview = (id, url) => {
  return () => {
    global.open(url, `preview-${id}`, 'width=768,location=yes,noopener=yes');
  };
};

/**
 * Determines if the media added to an article/slide is featured media
 * @param {number|boolean|null} blockId editor block id
 * @param {string} event event name
 * @param {boolean} shouldReplaceFeaturedMedia whether the featured media should be replaced or not
 * @returns {boolean} whether the media added to an article/slide is featured media or not
 */
export const isFeaturedMedia = (blockId, event, shouldReplaceFeaturedMedia) => {
  // Returns true if:
  // - 1) the user adds an initial featured media image from AP/Getty
  // - 2) the user adds an initial featured media image by uploading an image
  // - 3) the user adds an initial featured media iframe or edits an existing featured media iframe
  // - 4) the user replaces an existing featured media image with an AP/Getty image
  // - 5) the user replaces an existing featured media image with an uploaded image
  // - 6) the user replaces an existing featured media image with a cropped version of the original image
  if (blockId === false && event === EVENT_FEATURED_MEDIA_SEARCH) return true; // 1
  if (blockId === null && event === EVENT_FEATURED_MEDIA_UPLOAD) return true; // 2
  if (blockId === false && event === EVENT_FEATURED_MEDIA_IFRAME) return true; // 3
  if (
    blockId === 0 &&
    shouldReplaceFeaturedMedia &&
    (event === EVENT_MEDIA_SEARCH || // 4
      event === EVENT_IMAGE_UPLOAD || // 5
      event === EVENT_MEDIA_EDIT) // 6
  )
    return true;

  return false;
};

/**
 * Determines if the standard or slideshow article thumbnail should be updated
 * @param {number|boolean|null} blockId editor block id
 * @param {string} event event name
 * @param {string} type the type of the media (i.e. - image, video, ...)
 * @param {object} article the article that media was just added to
 * @param {boolean} shouldReplaceArticleThumbnail whether the article thumbnail should be replaced or not
 * @param {number} activeSlideIndex the index of the active slide (defaults to 0 for standard articles)
 * @returns {boolean} whether the article thumbnail should be updated or not
 */
export const shouldUpdateThumbnail = (blockId, event, type, article, shouldReplaceArticleThumbnail, activeSlideIndex = 0) => {
  // Returns true if:
  // - 1) the user clicked on the thumbnail and:
  //      - a) searched for and selected a new AP/Getty image OR
  //      - b) subsequently clicked the 'Upload Image' button and selected a new image from their machine
  // - 2) the user clicked on the thumbnail and dragged and dropped a new image from their machine
  // - 3) this is a body image/video/embed and there is no thumbnail
  // - 4) explicitly specified when replacing a fm (by checking the 'Also update article thumbnail' checkbox)
  if (blockId === null && event === EVENT_MEDIA_PICKER) return true; // 1
  if (blockId === null && event === EVENT_IMAGE_UPLOAD) return true; // 2
  if ((type === 'image' || type === 'video' || type === 'embed') && !article.image && activeSlideIndex === 0) return true; // 3
  if (shouldReplaceArticleThumbnail) return true; // 4

  return false;
};

/**
 * Gets the full names of an article's editors from their profiles
 * @param {object[]} users an array of user profiles
 * @returns {string[]} an array of an article editor names
 */
export const getArticleEditorNames = (users) => users.map((profile) => `${profile.firstName} ${profile.lastName}`);

/**
 * Does this article use Editor.js stable IDs?
 * - Stable IDs exist on articles authored after deploy CTT-1094 (Editor.js stable IDs)
 * - CTT-1094 deployed to production December 12, 2022 at 3:30 PM
 * - All blocks have a stable ID, except featured media
 * @param {object} article article object
 * @returns {bool} does article contain stable IDs?
 */
export const articleHasStableEditorIds = (article) => {
  if (!article) return null;
  const {insertedAt} = article;
  const dateStableIdsDeployed = new Date('12-12-2022 18:00:00');
  return isAfter(new Date(insertedAt), new Date(dateStableIdsDeployed));
};
