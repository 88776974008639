import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {setSlideActive} from '../../actions/versions';
import {selectActiveVersion, selectActiveSlideNumber, selectCompareVersion} from '../../selectors/versions';
import FilmstripFrame from '../atoms/filmstrip/filmstripFrame';
import {isVersionSlideAction} from '../../helpers/versionHelpers';

function VersionsFilmstrip({frames}) {
  const dispatch = useDispatch();
  const activeSlideNumber = useSelector(selectActiveSlideNumber);

  // check if this version was a slide deletion and add it to the filmstrip (to display as a placeholder)
  const slideDeleted = isVersionSlideAction(useSelector(selectActiveVersion), useSelector(selectCompareVersion));
  if (slideDeleted) frames.splice(slideDeleted.slideNumber, 0, slideDeleted);

  const onClick = async (slideNumber) => {
    if (activeSlideNumber === slideNumber) return;
    await dispatch(setSlideActive(slideNumber));
  };

  return (
    <div className="molecule filmstrip">
      <div className="filmstrip-frames-wrapper">
        <div className="filmstrip-frames">
          {!frames.length && (
            <div className="filmstrip-frame is-disabled">
              <span className="label">No slides changed</span>
            </div>
          )}
          {frames.map((frame, index) => {
            return (
              <FilmstripFrame
                key={`frame-${index}`}
                frame={frame}
                isActive={frame.slideNumber === activeSlideNumber}
                isDeleted={slideDeleted && slideDeleted.id === frame.id}
                isError={false}
                onSelected={() => onClick(frame.slideNumber)}
                withDelete={false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

VersionsFilmstrip.propTypes = {
  frames: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
    })
  ).isRequired,
};

export default VersionsFilmstrip;
