import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import classnames from 'classnames';

import Loading from '../atoms/loading';
import PageError from '../atoms/pageError';
import VersionStandard from '../atoms/versions/versionStandard';
import VersionSlideshow from '../atoms/versions/versionSlideshow';
import VersionsHeader from '../atoms/versions/versionsHeader';
import VersionsHeaderEmpty from '../atoms/versions/versionsHeaderEmpty';
import VersionsCompare from '../molecules/versionsCompare';

import {clearVersions, fetchVersions} from '../../actions/versions';
import {selectActiveVersion, selectError, selectLoading, selectShowCompareView} from '../../selectors/versions';
import {isStandard} from '../../helpers/articleHelpers';

function ArticleVersions() {
  const dispatch = useDispatch();
  const {id: articleId} = useParams();

  const activeVersion = useSelector(selectActiveVersion);
  const error = useSelector(selectError);
  const isComparison = useSelector(selectShowCompareView);
  const isLoading = useSelector(selectLoading);
  const classes = classnames('organism', 'article-versions', {
    'is-comparison': isComparison,
    'is-error': error,
    'is-loading': isLoading,
  });

  useEffect(() => {
    dispatch(fetchVersions(articleId));
    return () => dispatch(clearVersions());
  }, []);

  // render page error if error fetching the article
  if (error) {
    return (
      <div className={classes}>
        <VersionsHeaderEmpty />
        <PageError what="error fetching article versions">
          <p>The error could be caused by a couple reasons:</p>
          <ul>
            <li>
              The article ID <code>{articleId}</code> you're trying to access doesn't exist
            </li>
            <li>You don't have permission to access version history for this article</li>
          </ul>
        </PageError>
      </div>
    );
  }

  // render loading state
  if (isLoading || (!activeVersion && !error)) {
    return (
      <div className={classes}>
        <VersionsHeaderEmpty />
        <Loading />
      </div>
    );
  }

  return (
    <div className={classes}>
      <VersionsHeader />
      {!isComparison && (isStandard(activeVersion.content) ? <VersionStandard /> : <VersionSlideshow />)}
      {isComparison && <VersionsCompare />}
    </div>
  );
}

export default ArticleVersions;
