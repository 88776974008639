import Embed from '@editorjs/embed';
import {YOUTUBE_URL_EMBED, YOUTUBE_URL_SHORT, YOUTUBE_URL_WATCH, YOUTUBE_URLS} from '../../../constants/media';

/**
 * Checks if a given string is a valid YouTube video url
 *
 * @param {string} url
 * @return {bool}
 */
export const isYouTubeUrl = (url) => {
  if (typeof url !== 'string') return false;
  if (!url) return false;
  if (!url.startsWith('https://')) return false;
  if (!url.length) return false;

  const isValidFormat = YOUTUBE_URLS.some((format) => url.includes(format));
  if (!isValidFormat) return false;

  return true;
};

export function getYouTubeEmbedTool(api, url) {
  const videoId = url.substring(url.lastIndexOf('/') + 1, url.length);

  return new Embed({
    api,
    data: {
      service: 'youtube',
      source: `https://${YOUTUBE_URL_SHORT}/${videoId}`,
      embed: `https://www.${YOUTUBE_URL_EMBED}/${videoId}`,
      thumbnail: `https://img.youtube.com/vi/${videoId}/0.jpg`,
      caption: '',
    },
  });
}

/**
 * Return the youtube ID
 *
 * @param {object} source - the youtube embed url
 * @return {string} id
 */
export const getYouTubeId = (source) => {
  if (!isYouTubeUrl(source)) return null;

  const url = new URL(source);

  if (source.includes(YOUTUBE_URL_WATCH)) {
    const args = new URLSearchParams(url.search);
    return args.get('v');
  } else if (source.includes(YOUTUBE_URL_SHORT)) {
    const path = url.pathname;
    return path.substring(path.lastIndexOf('/') + 1);
  } else if (source.includes(YOUTUBE_URL_EMBED)) {
    const path = url.pathname;
    return path.substring(path.lastIndexOf('/') + 1);
  }
  return null;
};

/**
 * Returns the given YouTube video's start time (in seconds)
 *
 * @param {object} embedUrl - the youtube source url
 * @return {string} startSeconds
 */
export const getYouTubeStartSeconds = (sourceUrl) => {
  if (!isYouTubeUrl(sourceUrl)) return null;

  const url = new URL(sourceUrl);
  const args = new URLSearchParams(url.search);

  // check if the url includes start @ seconds
  const startSeconds = args.get('t') || args.get('start');
  if (!startSeconds) return null;

  // using parseInt() as the startSeconds value will sometimes contain a 's' -> ex./ https://youtu.be/abcdefg?t=120s
  return parseInt(startSeconds);
};

/**
 * Converts any valid YouTube url to the correct formatted YouTube embed url
 *
 * @param {string} url
 * @return {string} embed url
 */
export const sanitizeYouTubeUrl = (url) => {
  if (!isYouTubeUrl(url)) return null;

  const videoId = getYouTubeId(url);
  const startSeconds = getYouTubeStartSeconds(url);

  let embed = `https://www.youtube.com/embed/${videoId}`;
  if (startSeconds) embed += `?start=${startSeconds}`;

  return embed;
};
