import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

import {FIELDS as METADATA_FIELDS} from './sidebarMetadata';
import {STATUS_LABELS} from '../../../constants/article';
import {textChangedPercentage} from '../../../helpers/versionHelpers';

/**
 * Changes Displayed (in order)
 * ===========================
 * [Title] Title
 * [Body] Body (% of text changed)
 * [Metadata] Primary Editor
 * [Metadata] Publish Scheduled
 * [Metadata] Status
 * [Metadata] Author
 * [Metadata] Article Thumbnail
 * [Metadata] Metadata (# of metadata fields changed)
 */

const CHANGE_TYPES = {
  body: 'Body',
  featuredMedia: 'Featured Media',
  metadata: 'Metadata',
  title: 'Title',
};

const displayItemContents = (item) => {
  if (!item) return null;
  if (typeof item === 'object') {
    return (
      <>
        {item.name} <span className="num">({item.num})</span>
      </>
    );
  }
  return item;
};

const displayItem = (item, key = 'key') => {
  if (!item) return null;
  return (
    <div className="item" key={`item-${key}`}>
      <span>{displayItemContents(item)}</span>
    </div>
  );
};

const VersionChanges = ({added, removed, textChanged}) => {
  // abort if we have no changes
  if (isEmpty(added) && isEmpty(removed) && !textChanged) return null;

  const addedKeys = added ? Object.keys(added) : [];
  const changes = [];

  // check for title changes
  if (addedKeys.includes('title')) changes.push(CHANGE_TYPES.title);

  // check for body text percentage changes - only include if > 0
  if (textChanged) changes.push({name: CHANGE_TYPES.body, num: textChangedPercentage(textChanged)});

  // check for metadata changes - only include fields we display in version history
  const changesMetadata = Object.keys(METADATA_FIELDS).filter((element) => addedKeys.includes(element));

  if (changesMetadata.length) {
    // check for primary editor change
    if (changesMetadata.includes('primaryEditorGatekeeperId')) changes.push(METADATA_FIELDS.primaryEditorGatekeeperId);

    // check for scheduled change
    if (changesMetadata.includes('scheduledAt')) changes.push(`${added.scheduledAt ? 'Publish Scheduled' : 'Publish Unscheduled'}`);

    // check for status change
    if (changesMetadata.includes('status')) changes.push(`${METADATA_FIELDS.status}: ${STATUS_LABELS[added.status]}`);

    // check for author
    if (changesMetadata.includes('authorGatekeeperId')) changes.push(METADATA_FIELDS.authorGatekeeperId);

    // check for thumbnail change
    if (changesMetadata.includes('image')) changes.push(METADATA_FIELDS.image);

    // check for metadata fields content (# of fields changed)
    changes.push({name: CHANGE_TYPES.metadata, num: changesMetadata.length});
  }

  // display nothing if nothing changed
  // TODO: CTT-1072 / CTT-1018 commenting this out until we're comparing both added and removed
  //if (!changes.length) return null;

  return <div className="version-changes">{changes.map((item, index) => displayItem(item, index))}</div>;
};

VersionChanges.propTypes = {
  added: PropTypes.shape({}),
  removed: PropTypes.shape({}),
  textChanged: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
};

export default VersionChanges;
