import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {throttle} from 'lodash';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {IMAGE_CROP_MIN_WIDTH, IMAGE_CROP_RATIO} from '../../../constants/media';
import {scaleCrop} from '../../../utils/media';

const MESSAGE_PORTRAIT = 'Portrait images must be cropped to landscape orientation. Crop can be moved and resized.';
const MESSAGE_LANDSCAPE = 'Add crop by click & drag on image, the crop can be moved and resized.';
function ImageCropper(props) {
  const {imageUrl, imageCrop, onCrop, testIsCropperActive, testIsImageTempLoaded} = props;
  const [crop, setCrop] = useState(imageCrop);
  const [message, setMessage] = useState(MESSAGE_LANDSCAPE);
  // Due to the current version of the design, we're scaling crop image on the screen (making it smaller)
  // To keep crop selection area correct we need to save scaling coefficient
  // ScalingCoefficient = OriginalImageSize / ImageDisplaySize
  const [scale, setScale] = useState({width: 1, height: 1});
  const [minWidth, setMinWidth] = useState(IMAGE_CROP_MIN_WIDTH);
  const [scaledImage, setScaledImage] = useState({
    naturalWidth: null,
    naturalHeight: null,
    scaledWidth: null,
    scaledHeight: null,
  });

  // Don't render <ReactCrop> until imageTemp.onload has fired and we know the orientation of the selected image
  // Note: testIsImageTempLoaded and testIsCropperActive props are false by default, but passed in as true
  // in cropper.test.js so that mocked <ReactCrop> html elements are immediately available for testing).
  const [isImageTempLoaded, setIsImageTempLoaded] = useState(testIsImageTempLoaded);
  const [isCropperActive, setIsCropperActive] = useState(testIsCropperActive);

  // determine if the image orientation is portrait or landscape
  // vertical images must be cropped and don't allow them to cancel the crop 'keepSelection'
  const [isOrientationPortrait, setIsOrientationPortrait] = useState(false);
  const imageTemp = new Image();
  imageTemp.src = imageUrl;
  imageTemp.onload = () => {
    if (imageTemp.height > imageTemp.width) {
      setIsOrientationPortrait(true);
      setMessage(MESSAGE_PORTRAIT);
    }
    setIsImageTempLoaded(true);
  };

  useEffect(() => {
    setMinWidth(IMAGE_CROP_MIN_WIDTH / Math.floor(scale.width)); // round number to prevent scale co-efficient with decimals resulting in image less than target min-width
  }, [scale]);

  const onChangeThrottled = throttle(setCrop, 200, {trailing: true});
  const onComplete = () => {
    // When a user selects a crop, and then clicks outside the crop rectangle to remove the crop, the ReactCrop component sets the `crop` prop's
    // height and width to 0. If these values are preserved through the cropping process, our implementation will request that Cloudinary crop
    // the image to 0x0, whereupon they return a 400 error, resulting in the browser-default "broken image" icon. So, rather than propagating the 0
    // values throughout the rest of the image cropping process (e.g. Cloudinary; or storing in the article data), we'll erase the cropping
    // data, as if the user had never selected a crop in the first place.
    onCrop(scaleCrop(crop.width ? crop : {}, scale));
  };

  const onImageLoaded = (e) => {
    const {naturalWidth, naturalHeight, offsetWidth, offsetHeight} = e.currentTarget;

    const imageCanBeCropped = naturalWidth > IMAGE_CROP_MIN_WIDTH + 100; // must pad image min-width, when images too close to the minimum the image cropper behaves janky in the UI
    setIsCropperActive(imageCanBeCropped);

    // calculate scaling coefficients
    const initScale = {
      width: naturalWidth / offsetWidth,
      height: naturalHeight / offsetHeight,
    };

    setScaledImage({
      naturalWidth,
      naturalHeight,
      scaledWidth: offsetWidth,
      scaledHeight: offsetHeight,
    });

    setScale(initScale);

    // set required crop for vertical images
    if (isOrientationPortrait) {
      // if height value isn't set here and the user leaves the default crop setting after selecting an image,
      // the image will not be cropped when user clicks "Add Image" as the height value in the crop obj
      // will only get updated if the user interacts with the crop sizing
      const initCrop = {
        x: 0,
        y: 0,
        width: offsetWidth,
        height: offsetWidth / IMAGE_CROP_RATIO,
        unit: 'px',
        ...crop,
      };
      setCrop(initCrop);
      onCrop(scaleCrop(initCrop, initScale));
    }
    return false;
  };

  const showCropper = isImageTempLoaded && isCropperActive;

  return (
    <div className="atom image-cropper">
      {showCropper && <p>{message}</p>}
      <div className="crop-image">
        {showCropper ? (
          <ReactCrop
            aspect={IMAGE_CROP_RATIO}
            crop={crop}
            keepSelection={isOrientationPortrait}
            minWidth={minWidth}
            minHeight={minWidth / IMAGE_CROP_RATIO}
            maxHeight={scaledImage.scaledHeight}
            maxWidth={scaledImage.scaledWidth}
            onChange={onChangeThrottled}
            onComplete={onComplete}>
            <img src={imageUrl} onLoad={onImageLoaded} />
          </ReactCrop>
        ) : (
          <img src={imageUrl} onLoad={onImageLoaded} />
        )}
      </div>
    </div>
  );
}

ImageCropper.defaultProps = {
  imageCrop: {},
  testImageTempLoaded: false,
  testCropperActive: false,
};

ImageCropper.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageCrop: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  onCrop: PropTypes.func,
  testImageTempLoaded: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  testCropperActive: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

// note: props starting with 'test' used only by jest for testing, those tests should be refactored so props not required

export default ImageCropper;
