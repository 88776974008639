/**
 * Checks that a string is a valid email address
 *
 * @param {email} email to validate
 * @return {bool} is this a valid email address?
 */

export function isEmailValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
