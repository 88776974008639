import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

function HistoryButton({articleId, articleUrl}) {
  const navigate = useNavigate();
  const onButtonClicked = () => navigate(articleUrl);

  return !articleId || !articleUrl ? null : (
    <button className="article-button--history" onClick={onButtonClicked} title="View version history">
      <span className="sr-only">Version History</span>
      <span className="icon icon-history icon-medium" />
    </button>
  );
}

HistoryButton.propTypes = {
  articleId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null, false])]),
  articleUrl: PropTypes.string,
};

export default HistoryButton;
