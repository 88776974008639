import React, {useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {default as EditorJS} from '@stfy/react-editor.js';

import {getIsReadOnly} from '../../selectors/article';
import {EDITORJS_TOOLS_FEATURED_MEDIA} from '../../utils/editorjs/tools';

const FeaturedMedia = ({media, onMediaRemove}) => {
  const isReadOnly = useSelector(getIsReadOnly);
  const classes = classnames('atom', 'editor', 'editor-featured-media', {'is-readonly': isReadOnly});
  const editorData = {blocks: [media]};
  const editorId = 'editor-featured-media';
  const editorRef = useRef(null);
  const [editorLoaded, setEditorLoaded] = useState(false); // once this is set, we can await the EditorJS instance

  const getEditorKey = () => {
    // Generate complex caching key for re-init editor.js on media information change
    return `featured-media-${JSON.stringify(media)}`;
  };

  const onEditorLoaded = () => {
    const listeners = editorRef.current.editor.listeners;
    const block = document.querySelector(`#${editorId} .ce-block`);

    // Prevent listeners undefined error when iframe added as featured media
    if (!listeners) return;

    // Prevent new blocks creation from the feature media one
    listeners.off(block, 'keyup');
    listeners.off(block, 'keydown');

    // Prevent new blocks creation from the document
    listeners.off(document, 'keyup');
    listeners.off(document, 'keydown');
  };

  useEffect(() => {
    if (editorLoaded?.data && editorRef?.current) {
      const waitUntilEditorReady = async () => {
        await editorRef.current?.editor.isReady;
        onEditorLoaded();
      };
      waitUntilEditorReady();
    }
  }, [editorLoaded, editorRef]);

  useEffect(() => {
    if (editorLoaded) {
      const waitUntilEditorReady = async () => {
        await editorRef.current?.editor.isReady;
        const readOnlyController = editorRef.current?.editor.readOnly;
        if (isReadOnly !== readOnlyController.isEnabled) {
          readOnlyController.toggle();
        }
      };
      waitUntilEditorReady();
    }
  }, [isReadOnly]);

  const onEditorDataChanged = (data) => {
    // Do not trigger remove call if data is present
    if (data.blocks.length) return;

    onMediaRemove();
  };

  return (
    <>
      <div id={editorId} className={classes} />
      <EditorJS
        key={getEditorKey()}
        ref={editorRef}
        reinitOnPropsChange={false}
        holder={editorId}
        readOnly={isReadOnly}
        tools={EDITORJS_TOOLS_FEATURED_MEDIA}
        data={editorData}
        onData={onEditorDataChanged}
        onReady={() => setEditorLoaded({data: editorData})}
        logLevel="ERROR"
      />
    </>
  );
};

FeaturedMedia.defaultProps = {
  onMediaRemove: () => {},
};

FeaturedMedia.propTypes = {
  media: PropTypes.object.isRequired,
  onMediaRemove: PropTypes.func.isRequired,
};

export default FeaturedMedia;
