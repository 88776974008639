import React from 'react';
import FocusTrap from 'focus-trap-react';

const FocusTrapWrapper = ({children, options = {}}) => {
  const baseOptions = {
    // Prevent scrolling when modal showing
    onActivate: () => document.body.classList.add('no-scroll'),
    onDeactivate: () => document.body.classList.remove('no-scroll'),
  };

  return <FocusTrap focusTrapOptions={{...baseOptions, ...options}}>{children}</FocusTrap>;
};

export default FocusTrapWrapper;
