// Common
export const CLEAR_ACTION = 'CLEAR_ACTION';
export const SET_ACTION = 'SET_ACTION';
export const SET_DATE_END = 'SET_DATE_END';
export const SET_DATE_START = 'SET_DATE_START';
export const SET_ERROR = 'SET_ERROR';
export const SET_FETCHING = 'SET_FETCHING';
export const SET_IDLETIMER = 'SET_IDLETIMER';
export const SET_LOADING = 'SET_LOADING';

// Dashboard
export const DASHBOARD_ACTION = 'DASHBOARD_ACTION';
export const DASHBOARD_ACTION_CLEAR = 'DASHBOARD_ACTION_CLEAR';
export const DASHBOARD_ARTICLES = 'DASHBOARD_ARTICLES';
export const DASHBOARD_CLEAR = 'DASHBOARD_CLEAR';
export const DASHBOARD_FETCHING = 'DASHBOARD_FETCHING';
export const DASHBOARD_PREFETCH = 'DASHBOARD_PREFETCH';

// Editor Stats
export const EDITOR_STATS_DATE_END = 'EDITOR_STATS_DATE_END';
export const EDITOR_STATS_DATE_START = 'EDITOR_STATS_DATE_START';
export const EDITOR_STATS_EDIT_TYPE = 'EDITOR_STATS_EDIT_TYPE';
export const EDITOR_STATS_EDITORS = 'EDITOR_STATS_EDITORS';
export const EDITOR_STATS_EDITORS_CLEAR = 'EDITOR_STATS_EDITORS_CLEAR';
export const EDITOR_STATS_ERROR = 'EDITOR_STATS_ERROR';
export const EDITOR_STATS_FETCHING = 'EDITOR_STATS_FETCHING';
export const EDITOR_STATS_INDIVIDUAL = 'EDITOR_STATS_INDIVIDUAL';
export const EDITOR_STATS_INDIVIDUAL_CLEAR = 'EDITOR_STATS_INDIVIDUAL_CLEAR';

// Search
export const SEARCH_ACTION = 'SEARCH_ACTION';
export const SEARCH_ACTION_CLEAR = 'SEARCH_ACTION_CLEAR';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_CURSOR_LAST = 'SEARCH_CURSOR_LAST';
export const SEARCH_DATE_END = 'SEARCH_DATE_END';
export const SEARCH_DATE_START = 'SEARCH_DATE_START';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_FETCHING = 'SEARCH_FETCHING';
export const SEARCH_INPUT_QUERY = 'SEARCH_INPUT_QUERY';
export const SEARCH_INPUT_TYPE = 'SEARCH_INPUT_TYPE';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_SORT_KEY = 'SEARCH_SORT_KEY';
export const SEARCH_SORT_DIRECTION = 'SEARCH_SORT_DIRECTION';

// Article
export const ARTICLE_STANDARD_INIT = 'ARTICLE_STANDARD_INIT';
export const ARTICLE_SLIDESHOW_INIT = 'ARTICLE_SLIDESHOW_INIT';
export const ARTICLE_CLEAR = 'ARTICLE_CLEAR';
export const ARTICLE_CREATED = 'ARTICLE_CREATED';
export const ARTICLE_DELETED = 'ARTICLE_DELETED';
export const ARTICLE_UPDATED = 'ARTICLE_UPDATED';
export const ARTICLE_RECEIVE = 'ARTICLE_RECEIVE';
export const ARTICLE_UPDATE_DATA = 'ARTICLE_UPDATE_DATA';
export const ARTICLE_UPDATE_READONLY = 'ARTICLE_UPDATE_READONLY';
export const ARTICLE_UPDATE_STATUS = 'ARTICLE_UPDATE_STATUS';
export const ARTICLE_ADD_FEEDBACK = 'ARTICLE_ADD_FEEDBACK';
export const ARTICLE_SET_MEDIA = 'ARTICLE_SET_MEDIA';
export const ARTICLE_LOCK_BYPASS = 'ARTICLE_LOCK_BYPASS';
export const ARTICLE_LOCK_BYPASS_UPDATE_STATUS = 'ARTICLE_LOCK_BYPASS_UPDATE_STATUS';
export const ARTICLE_LOCK_RELEASE = 'ARTICLE_LOCK_RELEASE';
export const ARTICLE_LOCK_SET = 'ARTICLE_LOCK_SET';
export const ARTICLE_EDITOR_PRIMARY = 'ARTICLE_EDITOR_PRIMARY';
export const ARTICLE_SCHEDULED_AT = 'ARTICLE_SCHEDULED_AT';

export const ARTICLE_SLIDESHOW_UPDATE_DATA = 'ARTICLE_SLIDESHOW_UPDATE_DATA';
export const ARTICLE_SLIDESHOW_ADD_SLIDES = 'ARTICLE_SLIDESHOW_ADD_SLIDES';
export const ARTICLE_SLIDESHOW_REMOVE_SLIDES = 'ARTICLE_SLIDESHOW_REMOVE_SLIDES';
export const ARTICLE_SLIDESHOW_REORDER_SLIDES = 'ARTICLE_SLIDESHOW_REORDER_SLIDES';
export const ARTICLE_SLIDESHOW_SELECT_SLIDE = 'ARTICLE_SLIDESHOW_SELECT_SLIDE';
export const ARTICLE_SLIDESHOW_SET_MEDIA = 'ARTICLE_SLIDESHOW_SET_MEDIA';

// Media
export const MEDIA_GET_IMAGES = 'MEDIA_GET_IMAGES';
export const MEDIA_GET_VIDEOS = 'MEDIA_GET_VIDEOS';
export const MEDIA_SELECT_IMAGE = 'MEDIA_SELECT_IMAGE';
export const MEDIA_IMAGE_FILTER = 'MEDIA_IMAGE_FILTER';
export const MEDIA_SELECT_VIDEO = 'MEDIA_SELECT_VIDEO';
export const MEDIA_RETRIEVE_VIDEO = 'MEDIA_RETRIEVE_VIDEO';
export const MEDIA_CLEAR = 'MEDIA_CLEAR';

// Users
export const USERS_PROFILES = 'USERS_PROFILES';

// Version History
export const VERSIONS_CLEAR = 'VERSIONS_CLEAR';
export const VERSIONS_FETCHING = 'VERSIONS_FETCHING';
export const VERSIONS_RECEIVE = 'VERSIONS_RECEIVE';
export const VERSIONS_SLIDE_ACTIVE = 'VERSIONS_SLIDE_ACTIVE';
export const VERSIONS_SLIDES_CHANGED = 'VERSIONS_SLIDES_CHANGED';
export const VERSIONS_SHOW_CHANGES = 'VERSIONS_SHOW_CHANGES';
export const VERSIONS_SHOW_COMPARE_VIEW = 'VERSIONS_SHOW_COMPARE_VIEW';
export const VERSIONS_SHOW_UNCHANGED_SLIDES = 'VERSIONS_SHOW_UNCHANGED_SLIDES';
export const VERSIONS_SET_VERSIONS = 'VERSIONS_SET_VERSIONS';
