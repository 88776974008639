import {getStorageData} from './authHelpers';
import {CTT_USER} from '../constants/user';

export const isCurrentUserTheAuthor = ({authorGatekeeperId}) => {
  return authorGatekeeperId === getStorageData(CTT_USER);
};

export const isCurrentUserTheLastEditor = (data) => {
  return data?.editedByGatekeeperId === getStorageData(CTT_USER);
};

/**
 * Checks if article is locked
 * @param {object} article represents article data object
 * @returns {boolean}
 */
export const isArticleLocked = (article) => {
  return !article.isSoftLockExpired;
};

/**
 * Checks if the article is locked and by someone other than the current user
 * @param {object} article represents article data object
 * @returns {boolean}
 */
export const isArticleLockedByAnotherUser = (article) => {
  if (!isArticleLocked(article)) return false;
  if (!article.editedByGatekeeperId) return false;
  return article.editedByGatekeeperId !== getStorageData(CTT_USER);
};

/**
 * Returns error message when article is locked by another user
 * @param {string} firstName locked by first name
 * @param {string} lastName locked by last name
 * @returns {string}
 */
export const getLockedArticleWarningMessage = (firstName, lastName) => {
  return `This article is currently being reviewed by ${firstName} ${lastName}.\n\nIf you need to make changes, please contact ${firstName} or reach out to your Editor for assistance.\n\nWarning: If you bypass this lock, your work is at risk of being overwritten or lost.`; // eslint-disable-line max-len
};
