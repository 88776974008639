import React, {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {login} from '../../actions/users';
import {isAuthorized, isTokenExpired} from '../../helpers/authHelpers';

import PageTitle from '../atoms/pageTitle';

const Login = () => {
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (isAuthorized() && !isTokenExpired()) {
      // redirect if user authorized
      navigate('/', {replace: true});
    }
  }, []);

  const submit = async (event) => {
    event.preventDefault();
    try {
      const err = await login(usernameRef.current.value, passwordRef.current.value);
      if (!err) {
        // Redirect customer to the dashboard
        navigate('/');
      }
    } catch {
      window.alert('The info entered is not valid. Please try again.'); // eslint-disable-line no-alert
    }
  };

  return (
    <div className="organism login">
      <PageTitle title="Login" />
      <form acceptCharset="UTF-8" method="POST" onSubmit={submit}>
        <h1>Admin Log In</h1>
        <input ref={usernameRef} type="text" placeholder="Email Address" autoComplete="off" />
        <input ref={passwordRef} type="password" placeholder="Password" autoComplete="off" />
        <div className="form-actions">
          <a rel="noreferrer" href="https://bleacherreportinternal.zendesk.com/hc/en-us/requests/new?ticket_form_id=7983317494419" target="_blank">
            Create Account
          </a>
          <input type="submit" name="commit" value="Log in" />
        </div>
      </form>
    </div>
  );
};

export default Login;
