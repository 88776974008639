import {createAction} from '@reduxjs/toolkit';

import * as ArticlesAPI from '../apis/articles/articlesAPI';
import * as Gatekeeper from '../apis/auth/gatekeeper-api';
import * as types from '../constants/action-types';
import {scopes} from '../constants/';
import {getAuthTokens} from '../helpers/authHelpers';

import {getUserProfilesByIds} from './users';
import logger from '../logger';
import reporter from '../reporter';

export const clearEditors = createAction(types.EDITOR_STATS_EDITORS_CLEAR);
export const clearIndividual = createAction(types.EDITOR_STATS_INDIVIDUAL_CLEAR);
export const setDateEnd = createAction(types.EDITOR_STATS_DATE_END);
export const setDateStart = createAction(types.EDITOR_STATS_DATE_START);
export const setEditType = createAction(types.EDITOR_STATS_EDIT_TYPE);
export const setEditors = createAction(types.EDITOR_STATS_EDITORS);
export const setIndividual = createAction(types.EDITOR_STATS_INDIVIDUAL);
export const setError = createAction(types.EDITOR_STATS_ERROR);
export const setFetching = createAction(types.EDITOR_STATS_FETCHING);

/**
 * Fetch Editor Stats
 *
 * Note that the backend returns us a list of stats for ALL users, unsorted.
 * But we only want to display QC Editors, sorted alphabetically.
 *
 * TODO: we're making an API call for each user returned from the backend so we can figure
 * out from their permissions if they're a QC Editor. Depending on the number of users returned,
 * this can add a lot of additional calls. Can we do this another way?
 */
export const fetchEditorStats = () => {
  return async (dispatch, getState) => {
    const {dateEnd, dateStart, fetching} = getState().editorStats;
    if (fetching) return;

    try {
      dispatch(setFetching(true));
      const stats = await ArticlesAPI.getEditorStats({startDate: dateStart, endDate: dateEnd}); // note: the api returns stats for ALL users not just editors
      if (stats.error) throw stats.error;

      // filter stats for QC Editors only (currently backend returns ANY user)
      // TODO: is there a way to do this without making an API call for each user?
      const {accessToken} = getAuthTokens();
      const userIds = await Promise.all(
        stats.map(async (editor, index) => {
          // TODO: this will throw console errors for user ids returned that don't have profiles
          const profileFull = await Gatekeeper.fetchUser(editor.editorId, accessToken);

          if (!profileFull?.user) return null; // filter ids without profiles

          // add editor name to the response data so we can sort alphabetically
          stats[index].editorName = `${profileFull.user.firstName} ${profileFull.user.lastName}`;

          // filter ids that aren't qc editors
          const isUserQCEditor = await profileFull.user.permissions.includes(scopes.MANAGE_EDITORS);
          return isUserQCEditor ? editor.editorId : null;
        })
      );

      const userIdsFiltered = userIds.filter((id) => id !== null);
      const statsFiltered = stats
        .filter((editor) => userIdsFiltered.includes(editor.editorId))
        .sort((a, b) => a.editorName.localeCompare(b.editorName));

      dispatch(getUserProfilesByIds(userIdsFiltered)); // note: additionally, loading profiles into redux so available for editor components (ex./ to display the avatars)
      dispatch(setEditors(statsFiltered));
      dispatch(setFetching(false));
    } catch (error) {
      dispatch(setError(error));
      logger.error(error);
      reporter.inform(error);
    }
  };
};

/**
 * Fetch Editor Stats by Gatekeeper ID
 *
 * @param {string} id Gatekeeper ID
 */
export const fetchEditorStatsById = ({id}) => {
  return async (dispatch, getState) => {
    const {dateEnd, dateStart, editType, fetching} = getState().editorStats;
    if (fetching) return;

    try {
      dispatch(setFetching(true));
      const stats = await ArticlesAPI.getEditorStatsById({id, startDate: dateStart, endDate: dateEnd});
      if (stats?.error) throw stats.error;

      // api returns all editor articles, need to filter results by edit type
      // TODO: api response may be faster if we could pass the editType to the BE and only return those results
      const statsFiltered = editType === 'all' ? stats : Object.values(stats).filter((stat) => stat.editType === editType);

      dispatch(setIndividual(statsFiltered));
      dispatch(setFetching(false));
    } catch (error) {
      dispatch(setError(error));
      logger.error(error);
      reporter.inform(error);
    }
  };
};
