export const EVENT_PROMPT_RAISE = 'EVENT_PROMPT_RAISE';

// Triggers by article edit page
// (customer drags an image to the article page)
export const EVENT_IMAGE_UPLOAD = 'EVENT_IMAGE_UPLOAD';

// Triggers by editor.js media search tool
// (customer clicks on editor.jd media tool icon)
export const EVENT_MEDIA_SEARCH = 'EVENT_MEDIA_SEARCH';

// Triggers by editor.js image/video block settings
// (customer clicks on edit icon in image/video settings tooltip)
export const EVENT_MEDIA_EDIT = 'EVENT_MEDIA_EDIT';

// Triggers by article edit page
// (customer clicks on thumbnail in article sidebar)
export const EVENT_MEDIA_PICKER = 'EVENT_MEDIA_PICKER';

// List of events that were triggered by editor
export const EDITOR_ORIGIN_EVENTS = [EVENT_IMAGE_UPLOAD, EVENT_MEDIA_SEARCH, EVENT_MEDIA_EDIT];

// Featured media block triggers
export const EVENT_FEATURED_MEDIA_IFRAME = 'EVENT_FEATURED_MEDIA_IFRAME';
export const EVENT_FEATURED_MEDIA_SEARCH = 'EVENT_FEATURED_MEDIA_SEARCH';
export const EVENT_FEATURED_MEDIA_UPLOAD = 'EVENT_FEATURED_MEDIA_UPLOAD';

// List of unique "embed" events triggered by the featured media block
export const FEATURED_MEDIA_EMBED_ORIGIN_EVENTS = [EVENT_FEATURED_MEDIA_IFRAME];

// List of events triggered by the featured media block
export const FEATURED_MEDIA_ORIGIN_EVENTS = [
  EVENT_FEATURED_MEDIA_SEARCH,
  EVENT_FEATURED_MEDIA_UPLOAD,
  EVENT_MEDIA_SEARCH,
  EVENT_MEDIA_EDIT,
  ...FEATURED_MEDIA_EMBED_ORIGIN_EVENTS,
];

// List of all possible events
export default [...new Set([EVENT_MEDIA_PICKER, ...EDITOR_ORIGIN_EVENTS, ...FEATURED_MEDIA_ORIGIN_EVENTS])];

/**
 * Embed events
 */
// Triggered by adding or editing an embed (embed-extended, iframe, instagram, statmilk, tweet)
export const EVENT_EMBED_EDIT = 'EVENT_EMBED_EDIT';

// List of unique events triggered by the embed plugins (embed-extended, iframe, instagram, statmilk, tweet)
export const EVENTS_EMBED = [EVENT_EMBED_EDIT, ...FEATURED_MEDIA_EMBED_ORIGIN_EVENTS];
